export function toggleTheme(name: any) {
    detachStylesheets();
    insertStylesheet(name);
}

function detachStylesheets() {
    Array.from(document.querySelectorAll('link[data-theme="true"]')).forEach(
        (style: any | null) => {
            style.parentNode.removeChild(style);
        }
    );
}

function insertStylesheet(name: any) {
    var link = document.createElement("link");
    link.href = "https://prod01-cdn-yoonit.plugitapps.com/cdn/css/" + name + ".css";
    //link.href = "/css/" + name + ".css";
    link.type = "text/css";
    link.setAttribute('data-theme', "true");
    link.rel = "stylesheet";
    document.getElementsByTagName("head")[0].appendChild(link);
}