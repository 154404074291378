import * as types from "../constants";

const languagesOptions = [//remove from helper as all will be props 
    { label: 'English', value: 'en' },
    { label: '中文', value: 'cn' },
    { label: 'العربية', value: 'ar' },
    { label: 'Français', value: 'fr' },
];
const DictlanguagesOptions: any = {
    'en': { label: 'English', value: 'en' },
    'cn': { label: '中文', value: 'cn' },
    'ar': { label: 'العربية', value: 'ar' },
    'fr': { label: "Français", value: "fr" },
};
const initialState: types.ILanguageData = {
    Options: languagesOptions,
    Dict: DictlanguagesOptions
}

export default function reducer(state = initialState, actions: types.IUpdateLanguageOptions) {
    switch (actions.type) {
        case "UPDATE_LANGAUGE_OPTIONS":
            return actions.payload;
        default:
            return state;
    }
}
