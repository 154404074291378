import { createStore, applyMiddleware, compose } from "redux";
import createRootReducer from "../reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import signalRMiddleware from '../../Middleware/signalRMiddleware';
//import logger from 'redux-logger';
import storage from "redux-persist/lib/storage";
import storageMiddleware from "../../Middleware/StorageMiddleware";
const persistConfig = {
    key: "root",
    storage,
    blacklist: ['router']
};
export const history: any = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const enhancers: any = [];
const windowIfDefined = typeof window === 'undefined' ? null : window as any;
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}
function configureStore(preloadedState?: any) {
    const store = createStore(persistedReducer, preloadedState, compose(applyMiddleware(routerMiddleware(history), signalRMiddleware, storageMiddleware, thunk), ...enhancers));
    return store;
}

const store: any = configureStore();
export default store;
export const persistor = persistStore(store);