import * as types from "../constants";

export function PushNotification(newNotifcation: types.PushNotificationStatusClass): types.PushNotifications {
    return {
        type: types.Push_Notifications,
        payload: newNotifcation
    };
}
export function SetSignalR(Connected: boolean): types.SignalR {
    return {
        type: types.SIGNAL_R,
        payload: Connected
    };
}
export function ClearPushNotifications(state: types.PushNotificationStatusClass): types.ClearPushNotifications {
    return {
        type: types.CLEAR_PUSH_NOTIFICATIONS,
    };
}
