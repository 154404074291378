import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { ArrowBack, Close } from '@material-ui/icons';
import { Divider } from 'antd';
import { default as ButtonANTD } from "antd/es/button";
import * as React from 'react';
import { Button, Label } from "reactstrap";

interface Modal1IndexProps {
    handleClose: any;
    open: any;
    title: any;
    body: any;
    showTitle: any;
    push?: any;
    theme?: any;
    isGraph?: boolean;
}

export class ModalPopup extends React.Component<Modal1IndexProps> {
    state = { open: false }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}


                >
                    <Fade in={this.props.open}>
                        <div className={"ModelPaper m10mr10"}>
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>
                            <Divider />
                            <div className={this.props.isGraph ? "BodyModalGraph" : "BodyModal"}>
                                {this.props.body}</div>

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}


interface Modal2IndexProps {
    handleClose: any;
    open: any;
    title: any;
    body: any;
    showTitle: any;
    onDiscard: any;
    onSave: any;
    theme?: any;
    cancel?: any;
    save?: any;
    isloadingAction?: any;
}

export class ModalPopup2 extends React.Component<Modal2IndexProps> {
    state = {
        open: false,
        isloadingAction: (this.props.isloadingAction === undefined || this.props.isloadingAction === null) ? false : this.props.isloadingAction,
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentDidUpdate(prevProps: Modal2IndexProps) {
        if (prevProps.isloadingAction !== this.props.isloadingAction) {
            this.setState({ isloadingAction: (this.props.isloadingAction === undefined || this.props.isloadingAction === null) ? false : this.props.isloadingAction })
        }
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={"ModelPaper2 m10mr10"}>
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2 mt-3"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>

                            <div className="BodyModal2">{this.props.body}</div>
                            <div className="ButtonsBottom">
                                <div className="btnModal">
                                    <div className="floatRight">
                                        <ButtonANTD className="mr18 ButtonsModal" onClick={this.props.onDiscard}> {this.props.cancel} </ButtonANTD>
                                        <ButtonANTD loading={this.state.isloadingAction} onClick={this.props.onSave} className="mr-2 ButtonsModal"> {this.props.save} </ButtonANTD>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}

interface PopUpProps {
    lang?: string;
    userData?: any;
    push?: any;
    histor?: any;
    location?: any;
    theme?: any;
    handleClose: any;
    open?: any;
    title?: any;
    body: any;
    showTitle?: any;
    buttons?: any;
    modalID?: any;
    inmiddle?: boolean;
}
interface PopUpState {
    open: boolean;
}
export class Popup extends React.Component<PopUpProps, PopUpState> {
    state = {
        open: false
    }

    componentDidMount() {
    }

    handleOpen = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model ModalPop"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    id={this.props.modalID}
                >
                    <Fade in={this.props.open}>
                        <div className={this.props.inmiddle ? "ModelPaper2 ModalinMiddle m10mr10" : "ModelPaper2 ModalPopBody m10mr10"}>
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>
                            <Divider />

                            <div className="BodyModal3 mb-2 LabelCreate2">{this.props.body}</div>
                            <div>
                                <div className="floatRight mt-2">
                                    {this.props.buttons}
                                </div>
                            </div>
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}
export class EmailViewModal extends React.Component<PopUpProps, PopUpState> {
    state = {
        open: false
    }

    componentDidMount() {
    }

    handleOpen = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model ModalPop"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    id={this.props.modalID}
                >
                    <Fade in={this.props.open}>
                        <div className="ModelPaper2 EmailViewModal m10mr10">
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>
                            <Divider />

                            <div className="BodyModal3 mb-2 LabelCreate2">{this.props.body}</div>
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}
interface PopupInMiddleProps {
    lang?: string;
    userData?: any;
    push?: any;
    histor?: any;
    location?: any;
    theme?: any;
    handleClose: any;
    open?: any;
    body?: any;
    modalID?: any;
    Cancel?: any;
    Save?: any;
    handleSave?: any;
    TraderName?: any;
    showDots?: boolean;
    dots?: any;
    hideBody?: any;
    Basebody?: any;
    hideSave?: boolean;
    isCloseBtn?: boolean;
    isLoading?: boolean;
}
interface PopupInMiddlepState {
    open: boolean;
}
export class PopupInMiddle extends React.Component<PopupInMiddleProps, PopupInMiddlepState> {
    state = {
        open: false, showDots: false, hideBody: false
    }

    componentDidMount() {
    }

    handleOpen = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model ModalPop ModalinMiddle"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    id={this.props.modalID}
                >
                    <Fade in={this.props.open}>
                        <div className="ModelPaper2 ModalinMiddle m10mr10">
                            {!this.props.isCloseBtn && <div className="d-flex">
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>}
                            {this.props.hideBody &&
                                <div className="LabelCreate2 align-items-center mb-3 text-center">
                                    <div className="commentIcon">
                                        <FontAwesomeIcon icon={faUserTie} className="text-center userTie  d-flex justify-content-center userTrader" />
                                    </div>
                                    <Label className="LabelCreate2 font-20 t-bold d-block" >{this.props.TraderName} </Label>
                                    <Label className="LabelCreate2 font-16 d-block" >{this.props.body} </Label>
                                </div>
                            }
                            {!this.props.hideBody &&
                                <div>
                                    {this.props.Basebody}
                                </div>
                            }
                            <div className="w100p text-center mt-2 align-items-center">
                                {!this.props.hideSave && <div className=" d-inline-flex text-center mt-2 align-items-center" >
                                    <ButtonANTD
                                        loading={!!this.props.isLoading}
                                        disabled={!!this.props.isLoading}
                                        className="mr-3 FilledButton2 ButtonsModal" onClick={this.props.handleSave}> {this.props.Save} </ButtonANTD>
                                </div>}
                                {this.props.isCloseBtn && <div className=" d-inline-flex text-center mt-2 align-items-center">
                                    <ButtonANTD className="mr-3 FilledButton2 ButtonsModal" onClick={this.props.handleClose}> Close </ButtonANTD>
                                </div>}
                            </div>
                            {this.props.showDots &&
                                <div>
                                    <div className="text-center mt-2 align-items-center">
                                        {this.props.dots}
                                    </div>
                                </div>
                            }
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}

interface SmallModalProps {
    theme?: any;
    handleClose: any;
    open: any;
    title: any;
    body: any;
    showTitle: any;
    buttons?: any;
}
interface SmallModalState {
    open: boolean;
}

export class SmallModalPop extends React.Component<SmallModalProps, SmallModalState> {
    state = {
        open: false
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    handleOpen = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={"ModelPaper2 m10mr10"}>
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2 mt-3"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>

                            <div className="BodyModal3">{this.props.body}</div>
                            <div className="ButtonsBottom">
                                <div className="btnModal">
                                    <div className="floatRight">
                                        {this.props.buttons}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}
interface ProfileModalProps {
    theme?: any;
    handleClose: any;
    open: any;
    body: any;
    title: any;
    backBtn?: any;
    buttons?: any;
    small?: boolean;
    nobuttons?: boolean;
}
interface ProfileModalState {
    open: boolean;
    small: boolean;
    nobuttons: boolean;
}

export class ProfileModal extends React.Component<ProfileModalProps, ProfileModalState> {
    state = {
        open: false, small: false, nobuttons: true
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    handleOpen = () => {
        this.setState({ open: true })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={this.props.small ? "ModelPaper4 m10mr10" : "ModelPaper2 m10mr10"}>
                            <h2 className="TitleModal">
                                <div hidden={this.props.backBtn} className="divLeft" >
                                    <ArrowBack className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} />
                                </div>
                                <div className="ml-auto floatRight">
                                    <div>
                                        <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} />
                                    </div>
                                </div></h2>

                            <h2 className="TitleModal"> {this.props.title}</h2>
                            <div className="BodyModal2">{this.props.body}</div>
                            {this.props.nobuttons &&
                                <div className="ButtonsBottom">
                                    <div className="btnModal">
                                        <div className="floatRight">
                                            {this.props.buttons}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Fade>

                </Modal>

            </div>
        );
    }
}

interface RegistrationModalProps {
    handleClose: any;
    open: any;
    title: any;
    body: any;
    showTitle: any;
    push?: any;
    theme?: any;

}

export class RegistrationModal extends React.Component<RegistrationModalProps> {
    state = { open: false }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}


                >
                    <Fade in={this.props.open}>
                        <div className={"ModelPaper m10mr10 RegistrationModal"}>
                            <div className="d-flex" hidden={this.props.showTitle}>
                                <div className="mr-auto p-2 mt-3"><h2 className="TitleModal">{this.props.title} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>
                            <Divider />

                            <div className="BodyModal RegBodyModal">{this.props.body}</div>

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

interface ApplicationCardProps {
    handleClose: any;
    handlePrevious: any;
    open: any;
    body: any;
    push?: any;
    theme?: any;

}

export class ApplicationCardModal extends React.Component<ApplicationCardProps> {
    state = { open: false }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.props.handleClose();
        }
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"ModalForm"}
                    open={this.props.open}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div >
                            <div className="mb-3">
                                <h2 className="TitleModal">

                                    {this.props.handlePrevious}
                                    <div className="ml-auto floatRight borderIcons">
                                        <div className="xHoverIcon2 ">
                                            <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} />
                                        </div>
                                    </div></h2>
                            </div>
                            {this.props.body}

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}



