
import * as React from 'react';
import { Crisp } from "crisp-sdk-web";
const CrispChatBot = ({ id }: { id: any }) => {
    React.useEffect(() => {
        Crisp.configure(id);
    }, []);
    return <div>  </div>
};

export default CrispChatBot;