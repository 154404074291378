import * as React from 'react';
import LeftSideBar from '../Bars/LeftSideBar';
import TopBar from '../Bars/TopBar';
import ErrorBoundary from '../../AdvancedComponents/ErrorBoundary';
export default (props: { children?: React.ReactNode, path: any, PreviousPath: any, closedMenu: any, subtab: any }) => (
    <ErrorBoundary>
        <TopBar />
        <div className="wrapper">
            <LeftSideBar
                PreviousPath={props.PreviousPath}
                path={props.path}
                closedMenu={props.closedMenu}
                subtab={props.subtab} />
            <ErrorBoundary>
                <div className="main">
                    <div className="settingsbars">
                        {props.children}
                    </div>
                </div>
            </ErrorBoundary>
        </div>
    </ErrorBoundary>
);
