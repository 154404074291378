import 'antd/dist/antd.css';
import axios from "axios";
import { ConnectedRouter, push } from "connected-react-router";
import * as React from 'react';
import 'react-app-polyfill/ie9';
import Helmet from 'react-helmet';
import { Provider } from "react-redux";
import ReduxToastr from 'react-redux-toastr';
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { Col, Label } from "reactstrap";
import { PersistGate } from "redux-persist/integration/react";
import { ExternalRoutes } from "../src/Routes/index";
import ErrorBoundary from './components/AdvancedComponents/ErrorBoundary';
import Lazy from './components/Lazy';
import { CallLocalRequestGetNoCache, GetAxiosResponse } from './Helpers/axiosHelpers';
import { CheckIfIsExternalPage, ConvertIntoDictionary, DisplayZendDeskChat, getImagesURL_LDN, ReturnSelectOptions, toDataUrl } from './Helpers/DataHelpers';
import { GetCompanyDataResult, LoadCompanyLogoResult, YoonitRequest } from './Interfaces/Interfaces';
import { Login, Logout } from './redux/actions/AuthActions';
import { UpdateCompanyData } from './redux/actions/CompanyDataAction';
import { UpdateCompanySettings } from './redux/actions/CompanySettingsAction';
import { UpdateLanguageOptions } from './redux/actions/LanguageOptionsAction';
import { RemoveAllMessages } from './redux/actions/NotificationActions';
import { ClearPushNotifications } from './redux/actions/PushNotificationActions';
import { CheckSignalR } from './redux/actions/SignalRAction';
import { enableBlackRedTheme, enableDarkTheme, enableIGainTheme, enableLightTheme, enableMixTheme, enableNavyTheme, enableOrangeTheme, enableLDNTheme, enableTNFXTheme_OrangeDark, enableATFXTheme, enableACMTheme, enableAvantTheme, enablePrimexTheme, enableGMCTheme, enableGivTradeTheme, enableiQuotoTheme, enablePureNorthTheme, enableOrbitLight, enableOrbitDark, enableGold, enableBrownTheme } from './redux/actions/themeActions';
import { HandleUserConnectivity } from './redux/actions/UserConnectivityAction';
import store, { history, persistor } from "./redux/store";
import Routes from "./Routes/Routes";
import { LiveChat } from './components/ReusableComponents/ReusableElements/BasicElements';
import i18n from './i18n';
import { ChangeLanguage } from './redux/actions/languageAction';
import EliteWbChat from './Pages/CompanyWidgets/EliteWbChat';
import CrispChatBot from './Pages/CompanyWidgets/CrispChatBot';

export interface AppProps {
}
declare var require: any

export interface AppState {
    DisplayExternalPage: boolean;
    icon: string;
    GetURL: string;
    titleName: string;
    googleAdsCode: any;
    googleAdsCodeName: any;
    googleAnalyticsCode: any;
    googleAnalyticsCodeName: any;
    GetCompanyDataResult: GetCompanyDataResult;
    companyCode: any;
    openOfflinePopup: boolean;
    isopenUserActivity: boolean;
    timeoutID: any;
}
class App extends React.Component<AppProps, AppState> {
    state = {
        DisplayExternalPage: false,
        titleName: '',
        googleAdsCode: 'null',
        googleAdsCodeName: 'null',
        googleAnalyticsCode: 'null',
        googleAnalyticsCodeName: 'null',
        GetCompanyDataResult: new GetCompanyDataResult(),
        icon: '',
        GetURL: "",
        companyCode: "",
        openOfflinePopup: false,
        isopenUserActivity: false,
        timeoutID: null as any,
    }
    //#region tCheck inactivity 
    checkUserActivity = () => {
        window.addEventListener("mousemove", this.resetTimer, false);
        window.addEventListener("mousedown", this.resetTimer, false);
        window.addEventListener("keypress", this.resetTimer, false);
        window.addEventListener("DOMMouseScroll", this.resetTimer, false);
        window.addEventListener("mousewheel", this.resetTimer, false);
        window.addEventListener("touchmove", this.resetTimer, false);
        window.addEventListener("MSPointerMove", this.resetTimer, false);

        this.startTimer();
    }
    resetTimer = (e: any) => {
        window.clearTimeout(this.state.timeoutID);
        this.goActive();
    }
    startTimer = () => {
        // wait 7200000 millseconds ,2hrs before calling goInactive
        this.setState({ timeoutID: window.setTimeout(this.goInactive, 7200000) })
    }
    goInactive = () => {
        if (!this.state.openOfflinePopup)
            this.openUserActivity();
    }

    goActive = () => {
        if (!this.state.openOfflinePopup)
            this.closeUserActivity();
        this.startTimer();
    }
    //#endregion
    CheckIfIsAnInternalRoute = (): boolean => {
        if (!window?.location?.href) return false;
        for (let route of ExternalRoutes) {
            if (window?.location?.href && window?.location?.href.toLowerCase().includes(route.path.toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    componentDidMount() {
        toDataUrl(`${getImagesURL_LDN(
            store?.getState()?.CompanyData?.BannerCDNURL,
            store?.getState()?.auth?.CompanyData?.CompanyName,
            store?.getState()?.CompanyData?.CDNURL)}connection.png`, function (myBase64: any) {
                //console.log(myBase64); // myBase64 is the base64 string
                window.localStorage.setItem("connectionImg", myBase64);
            });
        if (this.CheckIfIsAnInternalRoute()) {
            //console.log("External");
        } else {
            this.CheckActivityAndInternetStatus();
        }

        window.onstorage = () => {
            if (window?.localStorage?.getItem("STORAGE-KEY")) {
                let _storageKey = JSON.parse(String(window.localStorage.getItem("STORAGE-KEY")));
                //console.log({ _storageKey }, _storageKey?.action);
                if (_storageKey && _storageKey?.action === "AUTH_LOGOUT" && store?.getState()?.auth?.isAuth) {//check auth so don run in loop
                    this.Logout();
                }
                if (_storageKey && _storageKey?.action === "AUTH_LOGIN"
                    //&& !store?.getState()?.auth?.isAuth
                    && window?.location?.href.toLowerCase().endsWith('/login')) {
                    store.dispatch(Login(_storageKey?.actionPayload));
                    store.dispatch(push('/'));
                }

            }
        };
        this.GetCompanyData();
        this.LoadZendesk();

    }
    openUserActivity = () => {
        this.setState({ isopenUserActivity: true })
        store.dispatch(HandleUserConnectivity(false));
    }
    closeUserActivity = () => {
        if (store && !store.getState().UserConnectivity) {
            this.CheckToken();
            store.dispatch(HandleUserConnectivity(true));//only trigger when false to set true
        }
        this.setState({ isopenUserActivity: false }); //online
    }
    openOfflinePopup = () => {
        //store.dispatch(push('/Offline'));//keep Code in page(just remove page) resource nto loaded if reditrect to new page
        this.setState({ openOfflinePopup: true, isopenUserActivity: false })//ffline os inactive is false as diffwrnt actions
        store.dispatch(HandleUserConnectivity(false));
    }
    closeOfflinePopup = () => {
        if (store && !store.getState().UserConnectivity) {
            this.CheckToken(true);
        }
        this.setState({ openOfflinePopup: false }); //online
        store.dispatch(HandleUserConnectivity(true));
        //store.dispatch(push('/'));
    }
    LoadCompanyLogoJson = () => {
        CallLocalRequestGetNoCache<any, any>
            (`${store.getState().CompanyData.BannerCDNURL}${store.getState().CompanyData.CompanyCode?.toLowerCase().trim()}/logo/JsonData.json`,
                {}, (res: any) => {
                    if (res.data) {
                        let Logs: any = res.data;
                        let _icon = Logs.Icon;
                        _icon = (_icon === '' || _icon === null) ? `` : _icon;//${store?.getState()?.CompanyData?.BannerCDNURL}
                        store.dispatch(UpdateCompanySettings(
                            {
                                CompanyName: Logs.CompanyName,
                                CompanySite: Logs.CompanySite,
                                IsCircular: Logs.IsCircular ? true : false,
                                LightLogo: Logs.LightLogo,
                                DarkLogo: Logs.DarkLogo,
                                Icon: _icon,
                                SecondaryIcon: Logs.SecondaryIcon,
                            }
                        ));
                        this.setState({ icon: `${_icon}` })
                    } else {
                        store.dispatch(UpdateCompanySettings(new LoadCompanyLogoResult()));
                    }
                }, () => {
                    //this.LoadCompanyLogoDefaultJson()no need 
                    store.dispatch(UpdateCompanySettings(new LoadCompanyLogoResult()));
                });
    }
    CheckToken = (reload?: boolean) => {
        axios.post<boolean>("api/auth/CheckToken", { Token: store.getState().auth.UserData.Token })
            .then(res => {
                if (res.data) {
                    store.dispatch(CheckSignalR()); //check if on will triggrer again
                    if (reload) {
                        window.location.reload();
                    }
                }
                else {
                    store.dispatch(Logout());
                    store.dispatch(push('/login'));
                }
            })
            .catch((e: any) => {
                store.dispatch(Logout());
                store.dispatch(push('/login'));
                console.error(e);
            });
    }

    GetCompanyData = () => {
        axios.post<GetCompanyDataResult>("api/auth/GetCompanyData")
            .then(res => {
                store.dispatch(UpdateCompanyData(res.data ? res.data : new GetCompanyDataResult()));
                this.UpdateLanguageOptions(res.data.CompanyCode, res.data.YoonitGetURL, res.data.DefaultLanguage);
                this.setState({ googleAdsCode: res.data.GoogleAdsCode });
                this.setState({ googleAdsCodeName: "https://www.googletagmanager.com/gtag/js?id=" + res.data.GoogleAdsCode });
                this.setState({ googleAnalyticsCode: res.data.GoogleAnalyticsCode });
                this.setState({ googleAnalyticsCodeName: "https://www.googletagmanager.com/gtag/js?id=" + res.data.GoogleAnalyticsCode });
                this.setState({ titleName: res?.data?.HeaderTitle || "Portal" });
                this.setState({
                    companyCode: res.data.CompanyCode,
                    GetURL: res.data.YoonitGetURL,
                }, this.LoadCompanyLogoJson);
                this.setState({ GetCompanyDataResult: res.data });
                if (store && store.getState() && store.getState().theme && store.getState().theme.currentTheme) {
                    if (store.getState().theme.currentTheme === 'addons-MixTheme')
                        store.dispatch(enableMixTheme());
                    else if (store.getState().theme.currentTheme === 'addons-DarkTheme')
                        store.dispatch(enableDarkTheme());
                    else if (store.getState().theme.currentTheme === 'addons-OrangeTheme')
                        store.dispatch(enableOrangeTheme());
                    else if (store.getState().theme.currentTheme === 'addons-LightTheme')
                        store.dispatch(enableLightTheme());
                    else if (store.getState().theme.currentTheme === 'addons-BlackRedTheme')
                        store.dispatch(enableBlackRedTheme());
                    else if (store.getState().theme.currentTheme === 'addons-NavyTheme')
                        store.dispatch(enableNavyTheme());
                    else if (store.getState().theme.currentTheme === 'addons-IGainTheme')
                        store.dispatch(enableIGainTheme());
                    else if (store.getState().theme.currentTheme === 'addons-LDNTheme')
                        store.dispatch(enableLDNTheme());
                    else if (store.getState().theme.currentTheme === 'addons-TNFXTheme')
                        store.dispatch(enableTNFXTheme_OrangeDark());
                    else if (store.getState().theme.currentTheme === 'addons-ATFXTheme')
                        store.dispatch(enableATFXTheme());
                    else if (store.getState().theme.currentTheme === 'addons-ACMTheme')
                        store.dispatch(enableACMTheme());
                    else if (store.getState().theme.currentTheme === 'addons-GMCTheme')
                        store.dispatch(enableGMCTheme());
                    else if (store.getState().theme.currentTheme === 'addons-AvantTheme')
                        store.dispatch(enableAvantTheme());
                    else if (store.getState().theme.currentTheme === 'addons-PrimexTheme')
                        store.dispatch(enablePrimexTheme());
                    else if (store.getState().theme.currentTheme === 'addons-GivTradeTheme')
                        store.dispatch(enableGivTradeTheme());
                    else if (store.getState().theme.currentTheme === 'addons-iQuotoTheme')
                        store.dispatch(enableiQuotoTheme());
                    else if (store.getState().theme.currentTheme === 'addons-PureNorthTheme')
                        store.dispatch(enablePureNorthTheme());
                    else if (store.getState().theme.currentTheme === 'addons-Orbit-Light')
                        store.dispatch(enableOrbitLight());
                    else if (store.getState().theme.currentTheme === 'addons-Orbit-Dark')
                        store.dispatch(enableOrbitDark());
                    else if (store.getState().theme.currentTheme === 'addons-GoldTheme')
                        store.dispatch(enableGold());
                    else if (store.getState().theme.currentTheme === 'addons-BrownTheme')
                        store.dispatch(enableBrownTheme());
                }
                else {
                    if (res?.data?.DefaultTheme) {
                        if (res.data.DefaultTheme === 'mix')
                            store.dispatch(enableMixTheme());
                        else if (res.data.DefaultTheme === 'dark')
                            store.dispatch(enableDarkTheme());
                        else if (res.data.DefaultTheme === 'orange')
                            store.dispatch(enableOrangeTheme());
                        else if (res.data.DefaultTheme === 'blackRed') {
                            store.dispatch(enableBlackRedTheme());
                        }
                        else if (res.data.DefaultTheme === 'navy') {
                            store.dispatch(enableNavyTheme());
                        }
                        else if (res.data.DefaultTheme === 'greenGrey') {
                            store.dispatch(enableIGainTheme());
                        }
                        else if (res.data.DefaultTheme === 'orangeBlack') {
                            store.dispatch(enableLDNTheme());
                        } else if (res.data.DefaultTheme === 'orangeDark') {
                            store.dispatch(enableTNFXTheme_OrangeDark());
                        }
                        else if (res.data.DefaultTheme === 'orangeWhite') {
                            store.dispatch(enableATFXTheme());
                        }
                        else if (res.data.DefaultTheme === 'greenBlack') {
                            store.dispatch(enableACMTheme());
                        }
                        else if (res.data.DefaultTheme === 'gmcOrange') {
                            store.dispatch(enableGMCTheme());
                        }
                        else if (res.data.DefaultTheme === 'grey') {
                            store.dispatch(enableAvantTheme());
                        }
                        else if (res.data.DefaultTheme === 'primexOrange') {
                            store.dispatch(enablePrimexTheme());
                        }
                        else if (res.data.DefaultTheme === 'givBlackGreen') {
                            store.dispatch(enableGivTradeTheme());
                        }
                        else if (res.data.DefaultTheme === 'iQuotoBlue') {
                            store.dispatch(enableiQuotoTheme());
                        }
                        else if (res.data.DefaultTheme === 'PureNorthTheme')
                            store.dispatch(enablePureNorthTheme());
                        else if (res.data.DefaultTheme === 'OrbitLight')
                            store.dispatch(enableOrbitLight());
                        else if (res.data.DefaultTheme === 'OrbitDark')
                            store.dispatch(enableOrbitDark());
                        else if (res.data.DefaultTheme === 'Gold')
                            store.dispatch(enableGold());
                        else if (res.data.DefaultTheme === 'Brown')
                            store.dispatch(enableBrownTheme())
                        else //default
                            store.dispatch(enableLightTheme());
                    }
                    else
                        store.dispatch(enableLightTheme());
                }
            })
            .catch((e: any) => {
                console.error(e);
                store.dispatch(UpdateCompanyData(new GetCompanyDataResult()));
            });
    }

    Logout = () => {
        store.dispatch(ClearPushNotifications({
            PushNotificationStatus: [],
            signalRIsConnected: false
        }));
        store.dispatch(RemoveAllMessages());
        store.dispatch(Logout());
        store.dispatch(push('/'));
    }

    CheckActivityAndInternetStatus = () => {
        store.dispatch(CheckSignalR()); // reconnect if off    //may just need  in listeners
        window.addEventListener("online", this.closeOfflinePopup);
        window.addEventListener("offline", this.openOfflinePopup);
        //window.addEventListener("mouseout", () => console.log("Enttterrr"));
        this.checkUserActivity();
    }
    navigateToLogin = () => {
        store.dispatch(push('/login'));
    }
    UpdateLanguageOptions = async (CompanyName: string, yoonitGetURL: string, DefaultLanguage: string) => {
        let _languagesOptions: any[] | null = await this.GetLanguageOptions(CompanyName, yoonitGetURL);
        if (_languagesOptions && _languagesOptions.length > 0) {
            let _Dict = ConvertIntoDictionary(_languagesOptions, "value");//ass it is casted now
            store.dispatch(UpdateLanguageOptions({ Options: _languagesOptions, Dict: _Dict }));
            if (!store?.getState()?.auth?.isAuth && !!DefaultLanguage && store?.getState()?.language?.isDefault && CheckIfIsExternalPage(window?.location?.href)) {
                this.onLanguageChange(_Dict[DefaultLanguage]);

            } else {
                this.setState({ DisplayExternalPage: true });
            }
        } else {
            this.setState({ DisplayExternalPage: true });
        }
    }
    GetLanguageOptions = async (CompanyName: string, yoonitGetURL: string) => {
        try {
            let GetClientListParams: YoonitRequest<string> = {
                CompanyName: CompanyName,//link wrong as value of it from company settings 
            };
            const res: any = await GetAxiosResponse<any, any>(
                yoonitGetURL,// store.getState().auth.UserData.yoonitGetURL
                `External/GetLanguageOptions`,
                GetClientListParams,
                { Token: null },
                () => { },
                () => { },
                (value: any) => String(value),
                true, //external
                true
            );
            return ReturnSelectOptions(res?.data?.ListRes ? res.data.ListRes : [], "Code", "Name");
        } catch (e) {
            console.error("GetLanguageOptions: ", e);
            return [];
        }
    }
    LoadZendesk = () => {
        //function CheckIfIsExternalPage(href: any): boolean {
        //    if (!href) return false;
        //    for (let route of ExternalRoutes) {
        //        if (href && href.toLowerCase().includes(route.path.toLowerCase())) {
        //            return true;
        //        }
        //    }
        //    return false;
        //}
        //let _self = this;
        //let tag = document.createElement('script');
        //tag.async = true;
        //tag.id = "ze-snippet";
        //tag.src = 'https://static.zdassets.com/ekr/snippet.js?key=84807906-e8ec-41e0-b7a0-ab70afeb4600';
        //tag.onload = function () {
        //    if (!store?.getState()?.auth?.UserData?.isAdmin || CheckIfIsExternalPage(window?.location?.href)) {
        //        DisplayZendDeskChat(false);
        //    } else {
        //        DisplayZendDeskChat(true);
        //    }
        //};
        //let head = document.getElementsByTagName('head')[0];
        ////head.appendChild(tag);
        //try {
        //    document.head.appendChild(tag);
        //} catch (e) {
        //    console.error("ZendDesk", e);
        //}
        ////let body = document.getElementsByTagName('body')[0];
        ////body.appendChild(tag);//loaded after load page //as in index.html wont work 
    }
    onLanguageChange = async (selectedLanguage: any) => {
        await store.dispatch(ChangeLanguage(selectedLanguage));
        await i18n.changeLanguage(selectedLanguage.value);
        document.documentElement.setAttribute('lang', selectedLanguage.value);
        if (selectedLanguage.value === "ar") {
            document.documentElement.setAttribute('dir', "rtl");
        }
        else {
            document.documentElement.setAttribute('dir', "ltr");
        }
        this.setState({ DisplayExternalPage: true });//as langauge change 
    }
    render() {
        if (!store?.getState()?.auth?.UserData?.isAdmin || CheckIfIsExternalPage(window?.location?.href) || !store?.getState()?.auth?.isAuth) {
            DisplayZendDeskChat(false);
        } else {
            DisplayZendDeskChat(true);
        }
        return (
            <React.Suspense fallback={<Lazy />}>
                <ErrorBoundary>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>

                            <ConnectedRouter history={history}>
                                <div hidden={this.state.openOfflinePopup || this.state.isopenUserActivity}>
                                    {this.checkIFDefaultLanguageLoaded() && <Routes history={history} />}
                                    <LiveChat />
                                    {this.state.GetCompanyDataResult?.CompanyCode?.toLowerCase() === "elite" && <EliteWbChat />}
                                    {this.state.GetCompanyDataResult?.CompanyCode?.toLowerCase() === "paramount" && <CrispChatBot id={"541b1d43-fdfc-43b3-852a-405d3b1cf9f3"} />}
                                </div>
                                {this.state.openOfflinePopup &&
                                    <div>
                                        {this.RenderOfflienPopup()}
                                    </div>
                                }
                                {!this.state.openOfflinePopup && this.state.isopenUserActivity &&
                                    <div>
                                        {this.RenderUserActivityPopup()}
                                    </div>
                                }
                            </ConnectedRouter>
                        </PersistGate>
                        <Helmet>
                            <title>{this.state.titleName}</title>
                            <link rel="icon" type="image/png" href={this.state.icon} sizes="16x16" />
                            {this.state.googleAdsCode &&
                                this.state.googleAdsCode.toLowerCase() !== 'null' &&
                                <script async src={this.state.googleAdsCodeName}></script> &&
                                <script>
                                    {`
                                window.dataLayer = (window.dataLayer || []);
                                 function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());

                                gtag('config', '`+ this.state.googleAdsCode + `');
                                `}
                                </script>
                            }
                            {this.state.googleAnalyticsCode &&
                                this.state.googleAnalyticsCode.toLowerCase() !== 'null' &&
                                <script async src={this.state.googleAnalyticsCodeName}></script> &&
                                <script>
                                    {`
                                        (function(w, d, s, l, i) {
                                        w[l] = w[l] || [];
                                        w[l].push({
                                          'gtm.start': new Date().getTime(),
                                          event: 'gtm.js'
                                        });
                                        var f = d.getElementsByTagName(s)[0],
                                          j = d.createElement(s),
                                          dl = l != 'dataLayer' ? '&l=' + l : '';
                                        j.async = true;
                                        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                                        f.parentNode.insertBefore(j, f);
                                      })(window, document, 'script', 'dataLayer', '${this.state.googleAnalyticsCode}');
                                `}
                                </script>
                            }

                        </Helmet>
                        <ReduxToastr
                            timeOut={5000}
                            newestOnTop={true}
                            position="bottom-center"
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar
                            closeOnToastrClick
                            preventDuplicates
                        />
                    </Provider>
                </ErrorBoundary>
            </React.Suspense>
        );
    }

    RenderOfflienPopup = (): React.ReactNode => {
        let img: any = window.localStorage.getItem("connectionImg") ? window.localStorage.getItem("connectionImg") : 'not load';
        return <div className=" SessionPage "  >
            <Col sm="10" md="8" lg="8" className="mx-auto d-table h-100 ">
                <div className="d-table-cell align-middle ">

                    <div className="sessionBox text-center" >
                        <img src={img} alt="" className="mt-3" />
                        <div className="d-block mt-3 text-center m10">
                            <Label className="text-Grey font-32 t-bold d-block mb-2"> Connection Lost </Label>
                            <Label className="text-Grey font-24 d-block m-0"> Sorry we're having trouble reaching the server. </Label>
                            <Label className="text-Grey font-24 d-block m-0 mb-3"> Please check your connection and refresh the page. </Label>
                        </div>
                    </div>
                </div>
            </Col>
        </div>;
    }
    RenderUserActivityPopup = (): React.ReactNode => {
        return <div className=" SessionPage "  >
            <Col sm="10" md="8" lg="8" className="mx-auto d-table h-100 ">
                <div className="d-table-cell align-middle ">

                    <div className="sessionBox text-center p10" >
                        <img src={`${getImagesURL_LDN(
                            store?.getState()?.CompanyData?.BannerCDNURL,
                            store?.getState()?.auth?.CompanyData?.CompanyName,
                            store?.getState()?.CompanyData?.CDNURL)}session.png`} alt="" className="mt-3" />
                        <div className="d-block mt-3 text-center m10">
                            <Label className="text-Grey font-32 t-bold d-block mb-2"> Session Expired </Label>
                            <Label className="text-Grey font-24 d-block m-0"> Looks like your session has expired due to inactivity. </Label>
                        </div>
                    </div>
                </div>
            </Col>
        </div>;
    }

    checkIFDefaultLanguageLoaded = () => {
        return (this.state.DisplayExternalPage || !store?.getState()?.language?.isDefault || !CheckIfIsExternalPage(window?.location?.href));
    }
}

export default App;
