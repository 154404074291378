import { default as ButtonANTD } from "antd/es/button";
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { Col, Input, Label, Row } from "reactstrap";
import { SmallModalPop } from '../../components/ReusableComponents/ReusableElements/Modals';
import { CallAxiosRequest } from '../../Helpers/axiosHelpers';
import { ValidateInput } from '../../Helpers/DataHelpers';
import { ContactUsClass, translationType, YoonitRequest } from '../../Interfaces/Interfaces';
import { Login, Logout } from '../../redux/actions/AuthActions';
import { GetClientData, ICompanyData, IStore, IUserData } from '../../redux/constants';

const mapStateToProps = (state: IStore) => ({
    ClientData: state.auth.ClientData,
    userData: state.auth.UserData,
    lang: state.language.value,
    history: state.history,
    URL: state.auth.UserData.yoonitGetURL,
    Servers: state.auth.Servers,
    theme: state.theme,
    ActionURL: state.auth.UserData.yoonitActionURL,
    CompanyData: state.auth.CompanyData,
});

const dispatchProps = { push, Login, Logout }
interface IndexProps {
    ClientData: GetClientData;
    lang: translationType;
    userData: IUserData;
    push: any;
    URL: string;
    theme: any;
    ActionURL: string;
    CompanyData: ICompanyData;
    Servers: any;
    t: any;
    closeContact: any;
    OpenContactUs: any;
}

interface IndexState {
    subject: string;
    ContactUSMessage: string;
    OpenContactUs: boolean;
    SendMessageLoading: boolean;
}

class ContactUs extends React.Component<IndexProps, IndexState> {
    state = {
        OpenContactUs: false,
        subject: '',
        ContactUSMessage: '',
        SendMessageLoading: false,

    }
    ContactUs = () => {
        if (!ValidateInput(this.state.ContactUSMessage)) {
            toastr.success(" ", this.props.t("AddMessagePlease"));
        }
        else {
            let { yoonitActionURL = "", Token = "" }: IUserData = this.props.userData;
            let { ContactID = "", Email = "" }: GetClientData = this.props.ClientData;
            let Parms: YoonitRequest<ContactUsClass> = {
                CompanyName: this.props.CompanyData.CompanyName,
                Token: Token,
                CompanyID: String(this.props.CompanyData.CompanyID),
                UserName: Email,
                ObjData: {
                    ContactID: ContactID,
                    Message: this.state.ContactUSMessage
                }
            };
            this.setState({ SendMessageLoading: true });
            CallAxiosRequest<any, any>(
                yoonitActionURL,
                `Trader/ContactUs`,
                Parms,
                { Token },
                (res: any) => {
                    this.setState({ SendMessageLoading: false });
                    if (res.data.IntRes < 0) {
                        toastr.error(" ", this.props.t("FailedAction"));
                    }
                    else {
                        toastr.success(" ", this.props.t("MessageSent"));
                        this.props.closeContact();
                    }
                },
                () => {
                    this.props.closeContact(); //change close all both ways
                    this.setState({ SendMessageLoading: false });
                },
                this.props.t
            );
        }
    }
    onDetailsChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        } as any);
    };
    inputOnChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.value } as any);
    }
    render() {
        return (
            <div>
                <SmallModalPop
                    title={this.props.t('ContactUs')}
                    buttons={
                        <div>
                            <ButtonANTD
                                loading={this.state.SendMessageLoading}
                                disabled={this.state.SendMessageLoading}
                                className="mr-2 ButtonsModal mt-2"
                                onClick={this.ContactUs}

                            >
                                {this.props.t('SendEmail')}
                            </ButtonANTD>
                        </div>
                    }
                    showTitle={false}
                    open={this.props.OpenContactUs}
                    handleClose={this.props.closeContact}
                    theme={this.props.theme.currentTheme}
                    body={
                        <div className="overflowxHidden">
                            <Row >
                                <Col md={12} className="mt-2">
                                    <Label for="Login" className="LabelCreate2 d-contents" >{this.props.t('Message')}</Label>
                                    <Input
                                        type="textarea"
                                        className="gui-input textAreaMessage"
                                        name="ContactUSMessage"
                                        id="ContactUSMessage"
                                        value={this.state.ContactUSMessage}
                                        onChange={this.inputOnChange}
                                        placeholder={this.props.t('TypeInYourMessage')}
                                        bsSize="lg"
                                        rows="10"
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}
export default withTranslation()(connect(mapStateToProps, dispatchProps)(ContactUs) as any) as any;
