import * as types from "../constants";

export function AddNewMessage(newMessage: types.ActionMessage): types.NotificationActionsTypes {
    return {
        type: types.ADD_NEW_MESSAGE,
        payload: newMessage
    };
}
export function SetHasNewValue(value: boolean): types.NotificationActionsTypes {
    return {
        type: types.SET_HAS_NEW_VALUE,
        payload:value
    };
}

export function RemoveMessage(value: number): types.NotificationActionsTypes {
    return {
        type: types.REMOVE_MESSAGE,
        payload: value
    };
}

export function RemoveAllMessages(): types.NotificationActionsTypes {
    return {
        type: types.REMOVE_ALL_MESSAGES
    };
}

