//External Pages
import { lazy } from 'react';
import { ICompanyData, IPerms, GetClientData } from '../redux/constants';
import { UserPermissionOBjectClass } from '../Interfaces/Interfaces';
import { GetPermissionDetailsByName, CheckIfPermAlllowedByName, GetReportsPerms, CheckIfCompanyIs } from '../Helpers/DataHelpers';
//#region pages
const SignIn = lazy(() => import('../Pages/Auth/SignIn'));
const OfflinePage = lazy(() => import('../Pages/Auth/OfflinePage'));
const ResetPassword = lazy(() => import('../Pages/Auth/ResetPassword'));
const BridgerPay = lazy(() => import('../Pages/Auth/Payments/BridgerPay'));
const OperationRequests = lazy(() => import('../Pages/BackOffice/OperationRequest'));
const ApplicationRequest = lazy(() => import('../Pages/BackOffice/ApplicationRequest'));
const OperationLogs = lazy(() => import('../Pages/Reports/OperationLogs/OperationLogs'));
const DynamicReport = lazy(() => import('../Pages/Reports/DynamicReport'));
const Transactions = lazy(() => import('../Pages/Reports/Transactions'));
const BonusReports = lazy(() => import('../Pages/Reports/BonusReports'));
const BonusReferralReport = lazy(() => import('../Pages/Reports/BonusReferralReport'));
const ClientPage = lazy(() => import('../Pages/Client/ClientPage'));
const Wallet = lazy(() => import('../Pages/Client/Wallet'));
const ApplicationAdmin = lazy(() => import('../Pages/OnBoarding/ApplicationAdmin'));
const EachClientApplicationNote = lazy(() => import('../Pages/OnBoarding/EachClientApplicationNote'));
const DeletedClients = lazy(() => import('../Pages/Client/DeletedClients'));
const ClientMainPage = lazy(() => import('../Pages/Client/ClientMainPage'));
const DocumentsExpiration = lazy(() => import('../Pages/Reports/DocumentsExpiration'));
const RegistrationLogs = lazy(() => import('../Pages/Reports/RegistrationLogs'));
const BonusPromotion = lazy(() => import('../Pages/Bonus/BonusPromotion'));
const EachPromotion = lazy(() => import('../Pages/Bonus/EachPromotion'));
const LeadPage = lazy(() => import('../Pages/Lead/LeadPage'));
const LeadMainPage = lazy(() => import('../Pages/Lead/LeadMainPage'));
const ContactPage = lazy(() => import('../Pages/Contact/ContactsPage'));
const ContactMainPage = lazy(() => import('../Pages/Contact/ContactMainPage'));
const Task = lazy(() => import('../Pages/Task/Tasks'));
const Notes = lazy(() => import('../Pages/User/Notes'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const DashboardAdmin = lazy(() => import('../Pages/DashboardAdmin/Dashboard'));
const MyWallet = lazy(() => import('../Pages/MyWallet/MyWallet'));
const MAMProfiles = lazy(() => import('../Pages/MAM/MAMProfile'));
const InvestorMAM = lazy(() => import('../Pages/MAM/InvestorMAM'));
const SignalProfile = lazy(() => import('../Pages/MAM/SignalProfile'));
const CopiersPage = lazy(() => import('../Pages/MAM/CopiersPage'));
//const MAMprofiletemplate = lazy(() => import('../Pages/MAM/MAMProfileTemplate'));
const PerformanceFees = lazy(() => import('../Pages/Reports/PerformanceFees'));
const SignalsPerformanceFees = lazy(() => import('../Pages/Reports/SignalsPerformanceFees'));
//const PendingOrders = lazy(() => import('../Pages/Portfolio/PendingOrders'));
const AccountLogs = lazy(() => import('../Pages/Logs/AccountLogs'));
const TransactionLogs = lazy(() => import('../Pages/Logs/TransactionLogs'));
const MonthlyFees = lazy(() => import('../Pages/Portfolio/MonthlyFees'));
const FeesPerformanceTracker = lazy(() => import('../Pages/Portfolio/FeesPerformanceTracker'));
const TradeHistorySummary = lazy(() => import('../Pages/Portfolio/TradeHistorySummary'));
const Signals = lazy(() => import('../Pages/Signals/Signals'));
const AnalystView = lazy(() => import('../Pages/CompanyWidgets/AnalystView'));
const WebTerminal = lazy(() => import('../Pages/CompanyWidgets/WebTerminal'));
const WebTrader = lazy(() => import('../Pages/CompanyWidgets/WebTrader'));
const MarketCalendar = lazy(() => import('../Pages/CompanyWidgets/MarketCalendar'));
const Platforms = lazy(() => import('../Pages/CompanyWidgets/ElitePlatforms'));
const Investors = lazy(() => import('../Pages/Portfolio/Investors'));
const InvestorsTracker = lazy(() => import('../Pages/Portfolio/InvestorsTracker'));
const CopiersClientPage = lazy(() => import('../Pages/Portfolio/CopiersClientPage'));
const OpenPositionSummary = lazy(() => import('../Pages/Portfolio/OpenPositionSummary/OpenPositionSummary'));
const MyAccounts = lazy(() => import('../Pages/Manage/MyAccounts'));
const MyDemoAccounts = lazy(() => import('../Pages/Manage/DemoAccount'));
const DemoAccountCard = lazy(() => import('../Pages/Manage/MyAccountsTab/NewDemoAccountCard'));
const NewAccount = lazy(() => import('../Pages/Manage/MyAccountsTab/NewAccountCard'));
const CreditCardValidation = lazy(() => import('../Pages/Manage/MyAccountsTab/CreditCardValidation'));
const CPAReport = lazy(() => import('../Pages/IB/CPAReport'));
const SubIB = lazy(() => import('../Pages/IB/SubIB'));
const ClientIBTree = lazy(() => import('../Pages/IB/ClientIBTree'));
const AccountStatement = lazy(() => import('../Pages/IB/AccountStatement'));
const Leads = lazy(() => import('../Pages/IB/Leads'));
const IBClients = lazy(() => import('../Pages/IB/IBClients'));
const BreakdownReport = lazy(() => import('../Pages/IB/BreakdownReport'));
const BreakdownAdminReport = lazy(() => import('../Pages/IBAdmin/BreakdownReport'));
const Promotion = lazy(() => import('../Pages/IB/Promotion'));
const HistoricalPerformance = lazy(() => import('../Pages/Analytics/HistoricalPerformance'));
const Trades = lazy(() => import('../Pages/Analytics/Trades'));
const Overview = lazy(() => import('../Pages/Analytics/Overview'));
const RiskMeasures = lazy(() => import('../Pages/Analytics/RiskMeasures'));
const ContactUs = lazy(() => import('../Pages/ClientSettings/ContactUs'));
const CardJson = lazy(() => import('../Pages/Manage/ApplicationFormCards/CardJson'));
const ApplicationFormCard = lazy(() => import('../Pages/Manage/ApplicationFormCard'));
const ReviewCard = lazy(() => import('../Pages/Manage/ApplicationFormCards/ReviewCard'));
const PaymentPending = lazy(() => import('../Pages/Manage/PaymentPending'));
const PaymentSuccess = lazy(() => import('../Pages/Manage/PaymentSuccess'));
const PaymentFailure = lazy(() => import('../Pages/Manage/PaymentFailure'));
const PaymentCancelled = lazy(() => import('../Pages/Manage/PaymentCancelled'));
const FirstUser = lazy(() => import('../Pages/ClientSettings/FirstUser'));
const CreateAccount = lazy(() => import('../Pages/ClientSettings/CreateAccount'));
const RealAccountWrapper = lazy(() => import('../components/Wrappers/RealAccountWrapper'));
const ConfirmResetPassword = lazy(() => import('../Pages/Auth/ConfirmResetPassword'));
const PasswordConfirmed = lazy(() => import('../Pages/Auth/PasswordConfirmed'));
const ActivateUser = lazy(() => import('../Pages/Auth/ActivateUser'));
const ThankYouPage = lazy(() => import('../Pages/Auth/ThankYouPage'));
const DemoAccountSuccess = lazy(() => import('../Pages/Auth/DemoAccountSuccess'));
const NotAuth = lazy(() => import('../Pages/Auth/NotAuth'));
const HomePage = lazy(() => import('../Pages/ClientSettings/HomePage'));
const DemoAccountIframe = lazy(() => import('../Pages/ClientSettings/DemoAccount'));
const DemoAccountWrapper = lazy(() => import('../components/Wrappers/DemoAccountWrapper'));
const PromoCodeIframe = lazy(() => import('../Pages/ClientSettings/PromoCode'));
const PromoCodeWrapper = lazy(() => import('../components/Wrappers/PromoCodeWrapper'));
const LiveAccount = lazy(() => import('../Pages/ClientSettings/LiveAccount'));
const FormSuccess = lazy(() => import('../Pages/ClientSettings/FormSuccess'));
const MyProfile = lazy(() => import('../Pages/ClientSettings/MyProfile'));
const IBAllocation = lazy(() => import('../Pages/IBAdmin/IBAllocation'));
const ProfileTemplates = lazy(() => import('../Pages/IBAdmin/ProfileTemplate'));
const EachProfile = lazy(() => import('../Pages/IBAdmin/EachProfile'));
const IBAccount = lazy(() => import('../Pages/IBAdmin/IBAccount'));
const CampaignLinks = lazy(() => import('../Pages/IBAdmin/CampaignLinks'));
const IBDetailedReport = lazy(() => import('../Pages/Reports/IBDetailedReport'));
const closetrades = lazy(() => import('../Pages/Reports/CloseTrades'));
const CPAReportIB = lazy(() => import('../Pages/Reports/CPAReport'));
const InvestorsReport = lazy(() => import('../Pages/Reports/InvestorsReport'));
const ClicksConversion = lazy(() => import('../Pages/Reports/ClicksConversion'));
const Logs = lazy(() => import('../Pages/Logs/Logs'));
const AccountTypes = lazy(() => import('../Pages/Settings/AccountTypes'));
const Pools = lazy(() => import('../Pages/Settings/Pools'));
//const PoolSettings = lazy(() => import('../Pages/Settings/Pools/PoolSettings'));
const CLientIDRange = lazy(() => import('../Pages/Settings/Pools/CLientIDRange'));
const Templates = lazy(() => import('../Pages/Settings/Pools/Templates'));
const WalletCurrencies = lazy(() => import('../Pages/Settings/Pools/WalletCurrencies'));
const Countries = lazy(() => import('../Pages/Settings/Pools/Countries'));
const ClientCountries = lazy(() => import('../Pages/Settings/Pools/ClientCountries'));
const ApplicationSettings = lazy(() => import('../Pages/Settings/Pools/ApplicationSettings'));
const IBCodeRange = lazy(() => import('../Pages/Settings/AccountTypes/IBCodeRange'));
const AccountCurrency = lazy(() => import('../Pages/Settings/AccountTypes/AccountCurrency'));
const AccountLeverage = lazy(() => import('../Pages/Settings/AccountTypes/AccountLeverage'));
const LoginRanges = lazy(() => import('../Pages/Settings/AccountTypes/LoginRanges'));
const RegistrationGroups = lazy(() => import('../Pages/Settings/AccountTypes/RegistrationGroups'));
const PortalSettings = lazy(() => import('../Pages/Settings/PortalSettings'));
const Synchronize = lazy(() => import('../Pages/Settings/PortalSettings/Synchronize'));
const SystemNotifications = lazy(() => import('../Pages/Settings/PortalSettings/SystemNotifications'));
const DynamicMarginSettings = lazy(() => import('../Pages/Settings/PortalSettings/DynamicMarginSettings'));
const AffiliateSettings = lazy(() => import('../Pages/Settings/PortalSettings/AffiliateSettings'));
const MAMSettings = lazy(() => import('../Pages/Settings/PortalSettings/MAMSettings'));
const CompanySMTP = lazy(() => import('../Pages/Settings/PortalSettings/CompanySMTP'));
//const DepositWithdrawalRate = lazy(() => import('../Pages/Settings/PortalSettings/DepositWithdrawalRate'));
const PaymentMethod = lazy(() => import('../Pages/Settings/PortalSettings/PaymentMethod'));
//const PlatformLinks = lazy(() => import('../Pages/Settings/PortalSettings/PlatformLinks'));
const EmailTemplate = lazy(() => import('../Pages/Settings/PortalSettings/EmailTemplate'));
const WebHookIntegrations = lazy(() => import('../Pages/Settings/PortalSettings/WebHookIntegrations'));
//const Synchronization = lazy(() => import('../Pages/Settings/PortalSettings/Synchronization'));
const PermessionsSettings = lazy(() => import('../Pages/Settings/PortalSettings/PermessionsSettings'));
const Teams = lazy(() => import('../Pages/Settings/PortalSettings/Teams'));
const EachUserPermission = lazy(() => import('../Pages/Settings/PortalSettings/EachUserPermission'));
const EachRole = lazy(() => import('../Pages/Settings/PortalSettings/EachRole'));
const BonusSetttings = lazy(() => import('../Pages/Settings/PortalSettings/BonusSetttings'));
const RegistrationTemplates = lazy(() => import('../Pages/Settings/PortalSettings/RegistrationTemplates'));
const TradingAccount = lazy(() => import('../Pages/Trader/TradingAccount'));
const DemoAccounts = lazy(() => import('../Pages/Trader/DemoAccounts'));
const SymbolProfle = lazy(() => import('../Pages/DynamicMargin/SymbolProfile'));
const AccountProfile = lazy(() => import('../Pages/DynamicMargin/AccountProfile'));
const HistoricalPerformanceAdmin = lazy(() => import('../Pages/Reports/Analytics/HistoricalPerformance'));
const TradesAdmin = lazy(() => import('../Pages/Reports/Analytics/Trades'));
const OverviewAdmin = lazy(() => import('../Pages/Reports/Analytics/Overview'));
const RiskMeasuresAdmin = lazy(() => import('../Pages/Reports/Analytics/RiskMeasures'));
const InvestorsTrackerAdmin = lazy(() => import('../Pages/Reports/Portfolio/InvestorsTracker'));
const TradeHistorySummaryAdmin = lazy(() => import('../Pages/Reports/Portfolio/TradeHistorySummary'));
//#endregion

//#region page Holders
const dashboardRedirect: any[] = [//To Redirect to login
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,

        closedMenu: false,
        subtab: ''
    }
];
const dashboardRoutes: any[] = [

];
const DynamicMarginRoutes: any[] = [

];
const dashboardAdminRoutes: any[] = [
    {
        path: "/",
        name: "Dashboard",
        component: DashboardAdmin,
        closedMenu: false,
        subtab: ''
    }

];
const MyWalletRoutes: any[] = [

];
const manage: any[] = [
]
const MAMRoutes: any[] = [

]

const ReportsRoutes: any[] = [

]
const LogsReport: any[] = [

]
const TraderRoutes: any[] = [

]
const IBRoutes: any[] = [
];
const portalSettings: any[] = [
];
const logsRoutes: any[] = [
];
const RequestsRoutes: any[] = [

];
const IBAdminRoutes: any[] = [

];

const ClientRoutes: any[] = [

];
const OnBoardingRoutes: any[] = [
];
const BonusRoutes: any[] = [

];

const privateRoutes = [
    {
        path: "/client/myprofile",
        name: "Blank Page",
        component: MyProfile,

        closedMenu: false,
        subtab: ''
    },
    {
        path: "/client/contactUs",
        name: "Blank Page",
        component: ContactUs,

        closedMenu: false,
        subtab: ''
    }
];
const PortfolioRoutes: any = [
];
const TaskRoutes: any[] = [
];
const NotesRoutes: any[] = [

];
const SignalsRoutes: any[] = [

];
const AnalyticsRoutes: any[] = [
];
//#endregion

export function InternalRoutes(this: any, isA: boolean, CompanyData: ICompanyData, UserPermissions: IPerms[], ClientData: GetClientData, isAuth: boolean) {
    let isElite: boolean = CheckIfCompanyIs("elite");
    let isJKV: boolean = CheckIfCompanyIs("JKV");
    let isOrbit: boolean = CheckIfCompanyIs("Orbit");
    //#region Client
    var AllowAddDemoAcc = !isA && ClientData?.AllowAddDemoAcc;
    //IB
    var isClientIBPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('IB', UserPermissions);
    var isClientIB = !isA && CompanyData?.IsIB && isClientIBPermssion.Allowed;
    var isClientReporting: boolean = CheckIfPermAlllowedByName('Reporting', isClientIBPermssion.Perms);
    var isPromotion: boolean = CheckIfPermAlllowedByName('Promotion', isClientIBPermssion.Perms);
    var isClientBreakDownReport: boolean = CheckIfPermAlllowedByName('Breakdown Report', isClientIBPermssion.Perms);
    var isClientAccessSubIB: boolean = CheckIfPermAlllowedByName('Access SubIB', isClientIBPermssion.Perms);
    //CRM
    var isClientCRMPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('CRM', UserPermissions);
    var isClientCRM = !isA && CompanyData?.IsCRM && isClientCRMPermssion.Allowed;
    var ManageTradingAccount: boolean = CheckIfPermAlllowedByName('Manage Trading Account', isClientCRMPermssion.Perms);
    //MAM
    var isClientMM = !isA && CompanyData?.IsMM;
    //#region MAM parents
    var isClientMoneyManager: UserPermissionOBjectClass = GetPermissionDetailsByName('Money Manager', UserPermissions);
    var isClientInvestorCopier: UserPermissionOBjectClass = GetPermissionDetailsByName('Investor/Copier', UserPermissions);
    var isClientSignalMAMPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Signal', UserPermissions);//Copier
    //#endregion
    var LeaderBoard: boolean = CheckIfPermAlllowedByName('LeaderBoard', isClientInvestorCopier.Perms);
    var isRequireKYC = isKYCRequired();
    var isValid = !isA && (ClientData?.IsValid ? true : false);
    var isAccountExist = !isA && parseInt(String(ClientData?.NmbrOfAcc), 10) > 0;
    var isHasKYC = !isA && ClientData?.hasKYC;
    var isNotRequiredAndAccountExist = (!isRequireKYC && isAccountExist);
    var isRequiredKycSumbitedAndAccountExist = (isRequireKYC && isAccountExist && isHasKYC);
    var isRequiredKycAndSumbited = (isRequireKYC && isHasKYC);
    //#endregion
    //#region admin
    var isAdminBonus = isA && CompanyData?.IsBonus;
    var isAdminIB = isA && CompanyData?.IsIB;
    var isAdminMM = isA && CompanyData?.IsMM;
    var isAdminCRM = isA && CompanyData?.IsCRM;
    var isAdminDynamicMargin = isA && CompanyData?.IsDynamicMargin;
    var TraderUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Trader', UserPermissions);
    var ClientsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Clients', UserPermissions);
    var PartnersUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Partners', UserPermissions);
    var MAMUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('MAM', UserPermissions);
    var BackOfficeUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Back Office', UserPermissions);
    var BonusUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Bonus', UserPermissions);
    var OnBoardingUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('On Boarding', UserPermissions);
    var ReportsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Reports', UserPermissions);
    var NotesUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Notes', UserPermissions);
    var TasksUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Tasks', UserPermissions);
    var PortalSettingsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Portal Settings', UserPermissions);
    var DynamicMargin: UserPermissionOBjectClass = GetPermissionDetailsByName('Dynamic Margin', UserPermissions);
    let LogsPermission: UserPermissionOBjectClass = GetPermissionDetailsByName('Logs', UserPermissions);
    let ViewAccountTypes: boolean = CheckIfPermAlllowedByName('View Account Types', TraderUserPerms.Perms);
    let { BonusReportsPerm, CRMREports, IBReports, DLReports, BackofficeReports, MAMReports } = GetReportsPerms(ReportsUserPerms);
    //#endregion
    if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB || isAdminDynamicMargin) {
        if (isAdminCRM || (isAdminMM) || isAdminIB || isAdminBonus) {//as to use Redirect
            //Dashboard Admin
            dashboardAdminRoutes.push()
        }
        //#region clientUser
        if (ClientsUserPerms.Allowed) {
            if ((isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB)) {
                ClientRoutes.push(
                    {
                        path: "/Clients/ClientPage/Client",
                        name: "Client",
                        component: ClientPage,
                        closedMenu: true,
                        subtab: '/Clients'
                    },

                    {
                        path: "/Clients/ClientPage",
                        name: "Client",
                        component: ClientMainPage,
                        closedMenu: false,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Pools",
                        name: "Pools",
                        component: Pools,
                        closedMenu: false,
                        subtab: '/Clients'
                    },
                )
            }
            if (isAdminCRM || (isAdminMM) || isAdminIB) {
                ClientRoutes.push(
                    {
                        path: "/Clients/Wallet",
                        name: "Wallet",
                        component: Wallet,
                        closedMenu: true,
                        subtab: '/Clients'
                    })

            }
            if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                ClientRoutes.push({
                    path: "/Clients/DeletedClients",
                    name: "DeletedClients",
                    component: DeletedClients,
                    closedMenu: false,
                    subtab: '/Clients'
                },
                    {
                        path: "/Clients/Pools/WalletCurrencies",
                        name: "WalletCurrencies",
                        component: WalletCurrencies,
                        closedMenu: true,
                        subtab: '/Clients'
                    }
                )
            }
            if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                ClientRoutes.push(
                    {
                        path: "/Clients/Application",
                        name: "ApplicationAdmin",
                        component: ApplicationAdmin,
                        closedMenu: false,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Contact/ContactPage",
                        name: "Contact",
                        component: ContactPage,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Contact",
                        name: "Contact",
                        component: ContactMainPage,
                        closedMenu: false,
                        subtab: '/Clients'
                    })
            }
            if (isAdminCRM || isAdminIB) {
                ClientRoutes.push(
                    {
                        path: "/Clients/Leads/LeadPage",
                        name: "Lead",
                        component: LeadPage,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Leads",
                        name: "Lead",
                        component: LeadMainPage,
                        closedMenu: false,
                        subtab: '/Clients'
                    })
            }
            //Pools
            if (isAdminIB) {
                ClientRoutes.push(
                    {
                        path: "/Clients/Pools/IBCodeRange",
                        name: "IBCodeRange",
                        component: IBCodeRange,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                )
            }
            if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                ClientRoutes.push(
                    {
                        path: "/Clients/Pools/CLientIDRange",
                        name: "CLientIDRange",
                        component: CLientIDRange,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Pools/Templates",
                        name: "Templates",
                        component: Templates,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Pools/Countries",
                        name: "Countries",
                        component: Countries,
                        closedMenu: true,
                        subtab: '/Clients'
                    },
                    {
                        path: "/Clients/Pools/ClientCountries",
                        name: "ClientCountries",
                        component: ClientCountries,
                        closedMenu: true,
                        subtab: '/Clients'
                    })
            }
            if (isAdminCRM) {
                ClientRoutes.push({
                    path: "/Clients/Pools/ApplicationSettings",
                    name: "ApplicationSettings",
                    component: ApplicationSettings,
                    closedMenu: true,
                    subtab: '/Clients'
                })
            }
        }
        //#endregion
        //OnBoarding
        if (OnBoardingUserPerms.Allowed) {
            if (isAdminCRM) {
                OnBoardingRoutes.push({
                    path: "/OnBoarding/Application",
                    name: "ApplicationAdmin",
                    component: ApplicationAdmin,
                    closedMenu: false,
                    subtab: '/OnBoarding'
                })
            }
        }
        //Trader
        if (TraderUserPerms.Allowed) {
            if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                TraderRoutes.push(
                    {
                        path: "/Trader/TradingAccounts",
                        name: "TradingAccount",
                        component: TradingAccount,

                        closedMenu: false,
                        subtab: '/Trader'
                    },
                    {
                        path: "/Trader/DemoAccounts",
                        name: "DemoAccounts",
                        component: DemoAccounts,
                        closedMenu: false,
                        subtab: '/Trader'
                    },
                )
                if (ViewAccountTypes) {
                    TraderRoutes.push({
                        path: "/Trader/AccountTypes",
                        name: "AccountTypes",
                        component: AccountTypes,
                        closedMenu: false,
                        subtab: '/Trader'
                    })
                }
                //Acctypes
                if (ViewAccountTypes && (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB)) {
                    TraderRoutes.push(
                        {
                            path: "/Trader/AccountTypes/AccountCurrency",
                            name: "AccountCurrency",
                            component: AccountCurrency,
                            closedMenu: true,
                            subtab: '/Trader'
                        },
                        {
                            path: "/Trader/AccountTypes/AccountLeverage",
                            name: "AccountLeverage",
                            component: AccountLeverage,
                            closedMenu: true,
                            subtab: '/Trader'
                        },
                        {
                            path: "/Trader/AccountTypes/LoginRanges",
                            name: "LoginRanges",
                            component: LoginRanges,
                            closedMenu: true,
                            subtab: '/Trader'
                        })
                }
                if (ViewAccountTypes && (isAdminCRM || (isAdminMM) || isAdminIB)) {
                    TraderRoutes.push({
                        path: "/Trader/AccountTypes/RegistrationGroups",
                        name: "RegistrationGroups",
                        component: RegistrationGroups,
                        closedMenu: true,
                        subtab: '/Trader'
                    })
                }
            }
        }
        //IBPartners
        if (PartnersUserPerms.Allowed) {
            if (isAdminIB) {
                IBAdminRoutes.push({
                    path: "/IBAdmin/IBAllocation",
                    name: "IB Allocation",
                    component: IBAllocation,
                    closedMenu: false,
                    subtab: '/IBAdmin'
                },
                    {
                        path: "/IBAdmin/ProfileTemplates",
                        name: "Profile Templates",
                        component: ProfileTemplates,
                        closedMenu: false,
                        subtab: '/IBAdmin'
                    },
                    {
                        path: "/IBAdmin/ProfileTemplates/ProfileConfiguration",
                        name: "Profile",
                        component: EachProfile,
                        closedMenu: false,
                        subtab: '/IBAdmin'
                    },

                    {
                        path: "/IBAdmin/IBAllocation/IBAccount",
                        name: "IB Account",
                        component: IBAccount,
                        closedMenu: false,
                        subtab: '/IBAdmin'
                    },
                    {
                        path: "/IBAdmin/CampaignLinks",
                        name: "Capaign Links",
                        component: CampaignLinks,
                        closedMenu: false,
                        subtab: '/IBAdmin'
                    }

                )
            }
        }
        //MAMM
        if (MAMUserPerms.Allowed) {
            if (isAdminMM) {
                MAMRoutes.push({
                    path: "/MAM/MamAllocations",
                    name: "MAMALLocations",
                    component: MAMProfiles,
                    closedMenu: false,
                    subtab: '/MAM'
                },
                    {
                        path: "/MAM/MamAllocations/Investors",
                        name: "InvestorMAM",
                        component: InvestorMAM,
                        closedMenu: false,
                        subtab: '/MAM'
                    }, {
                    path: "/MAM/MamAllocations/Investor",
                    name: "InvestorMAM",
                    component: InvestorMAM,
                    closedMenu: false,
                    subtab: '/MAM'
                },
                    {
                        path: "/MAM/PerformanceFees",
                        name: "PerformanceFees",
                        component: PerformanceFees,
                        closedMenu: false,
                        subtab: '/MAM'
                    })
            }
            if (isAdminMM) {
                MAMRoutes.push(
                    {
                        path: "/MAM/SignalAllocations",
                        name: "SignalAllocations",
                        component: SignalProfile,
                        closedMenu: false,
                        subtab: '/MAM'
                    },
                    {
                        path: "/MAM/SignalAllocations/Copiers",
                        name: "Copiers",
                        component: CopiersPage,
                        closedMenu: false,
                        subtab: '/MAM'
                    },
                )
            }
        }
        //#region BackOffice
        if (BackOfficeUserPerms.Allowed) {
            if (isAdminCRM || (isAdminMM) || isAdminIB) {
                RequestsRoutes.push(

                )
                if (isAdminCRM || isAdminMM || isAdminIB) {
                    RequestsRoutes.push({
                        path: "/BackOffice/OperationRequest",
                        name: "Operation Requests",
                        component: OperationRequests,
                        closedMenu: false,
                        subtab: '/BackOffice'
                    })
                }
                if (isAdminCRM && OnBoardingUserPerms.Allowed) {
                    RequestsRoutes.push({
                        path: "/BackOffice/ApplicationRequest",
                        name: "Application Requests",
                        component: ApplicationRequest,
                        closedMenu: false,
                        subtab: '/BackOffice'
                    })
                }
            }
        }
        //#endregion
        //Bonus
        if (BonusUserPerms.Allowed) {
            if (isAdminBonus) {
                BonusRoutes.push({
                    path: "/Bonus/Promotion",
                    name: "BonusPromotion",
                    component: BonusPromotion,
                    closedMenu: false,
                    subtab: '/Bonus'
                },
                    {
                        path: "/Bonus/Promotion/EachPromotion",
                        name: "EachPromotion",
                        component: EachPromotion,
                        closedMenu: false,
                        subtab: '/Bonus'
                    })
            }
        }
        //#region Reports 
        if (isA && LogsPermission.Allowed) {//for all
            LogsReport.push(
                {//@point add var for it 
                    path: "/LogsReport",
                    name: "Logs",
                    component: Logs,
                    closedMenu: false,
                    subtab: ''
                }
            )
        }

        if ((isAdminCRM || (isAdminMM) || isAdminIB || isAdminBonus || isAdminDynamicMargin) && ReportsUserPerms.Allowed) {
            if (isAdminBonus && BonusReportsPerm) {
                ReportsRoutes.push({
                    path: "/Reports/BonusReport",
                    name: "BonusReports",
                    component: BonusReports,
                    closedMenu: false,
                    subtab: '/Reports'
                }, {
                    path: "/Reports/BonusReferralReport",
                    name: "BonusReferralReport",
                    component: BonusReferralReport,
                    closedMenu: false,
                    subtab: '/Reports'
                },
                )
            }
            if (isAdminIB || (isAdminMM) || isAdminCRM) {
                ReportsRoutes.push(
                    {
                        path: "/Reports/ClicksConversion",
                        name: "ClicksConversion",
                        component: ClicksConversion,
                        closedMenu: false,
                        subtab: '/Reports'
                    },
                )
                if (isAdminMM && MAMReports) {
                    ReportsRoutes.push(
                        {
                            path: "/Reports/PerformanceFees",
                            name: "PerformanceFees",
                            component: PerformanceFees,
                            closedMenu: false,
                            subtab: '/Reports'
                        },
                        {
                            path: "/Reports/SignalsPerformanceFees",
                            name: "SignalsPerformanceFees",
                            component: SignalsPerformanceFees,
                            closedMenu: false,
                            subtab: '/Reports'
                        }
                    )
                }
                if ((isAdminIB && IBReports) || (isAdminMM && MAMReports) || (isAdminCRM && CRMREports)) {
                    ReportsRoutes.push(

                        {
                            path: "/Reports/Transactions",
                            name: "Transactions",
                            component: Transactions,
                            closedMenu: false,
                            subtab: '/Reports'
                        },
                    )
                }
                if ((isAdminMM || isAdminCRM)) {
                    ReportsRoutes.push(
                        {
                            path: "/Analytics/HistoricalPerformance",
                            name: "HistoricalPerformance",
                            component: HistoricalPerformanceAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        },
                        {
                            path: "/Analytics/RiskMeasures",
                            name: "RiskMeasuresAdmin",
                            component: RiskMeasuresAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        }
                        ,
                        {
                            path: "/Analytics/Overview",
                            name: "OverviewAdmin",
                            component: OverviewAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        }
                        ,
                        {
                            path: "/Analytics/Trades",
                            name: "TradesAdmin",
                            component: TradesAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        },
                        {
                            path: "/Analytics/TradeHistorySummary",
                            name: "TradeHistorySummaryAdmin",
                            component: TradeHistorySummaryAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        }
                    )
                }
                if ((isAdminMM)) {
                    ReportsRoutes.push(
                        {
                            path: "/Analytics/InvestorsReport",
                            name: "InvestorsReport",
                            component: InvestorsReport,
                            closedMenu: false,
                            subtab: '/Analytics'
                        },
                        {
                            path: "/Analytics/InvestorsTracker",
                            name: "InvestorsTrackerAdmin",
                            component: InvestorsTrackerAdmin,
                            closedMenu: false,
                            subtab: '/Analytics'
                        }
                    )
                }
                if (isAdminIB && IBReports) {
                    ReportsRoutes.push({
                        path: "/Reports/IBDetailedReport",
                        name: "IBDetailedReport",
                        component: IBDetailedReport,
                        closedMenu: false,
                        subtab: '/Reports'
                    },
                        {
                            path: "/Reports/BreakdownReport",
                            name: "Breakdown Report",
                            component: BreakdownAdminReport,
                            closedMenu: false,
                            subtab: '/IBAdmin'
                        },
                        {
                            path: "/Reports/CPAReport",
                            name: "CPAReport",
                            component: CPAReportIB,
                            closedMenu: false,
                            subtab: '/Reports'
                        })
                }
                if (isAdminCRM && CRMREports) {
                    ReportsRoutes.push({
                        path: "/Reports/closetrades",
                        name: "closetrades",
                        component: closetrades,
                        closedMenu: false,
                        subtab: '/Reports'
                    })
                }
                if (isAdminCRM && CRMREports) {
                    ReportsRoutes.push({
                        path: "/Reports/DocumentsExpiration",
                        name: "DocumentsExpiration",
                        component: DocumentsExpiration,
                        closedMenu: false,
                        subtab: '/Reports'
                    })
                }
                if (isAdminCRM || (isAdminMM) || isAdminIB) {
                    if (BackofficeReports) {
                        ReportsRoutes.push({
                            path: "/Reports/OperationLogs",
                            name: "Operation Logs",
                            component: OperationLogs,
                            closedMenu: false,
                            subtab: '/Reports'
                        })
                    }
                    if (CRMREports) {
                        ReportsRoutes.push({
                            path: "/Reports/RegistrationLogs",
                            name: "RegistrationLogs",
                            component: RegistrationLogs,
                            closedMenu: false,
                            subtab: '/Reports'
                        })
                    }
                }
            }
            if (isAdminDynamicMargin && DLReports) {
                ReportsRoutes.push({
                    path: "/Reports/DynamicReport",
                    name: "DynamicReport",
                    component: DynamicReport,
                    closedMenu: false,
                    subtab: '/Reports'
                })
            }
        }
        //#endregion
        //Notes/tasks
        if (isA) {//Tasks/notes for all license
            if (TasksUserPerms.Allowed) {//perm for each
                TaskRoutes.push({
                    path: "/Tasks",
                    name: "Tasks",
                    component: Task,
                    closedMenu: false,
                    subtab: '/Tasks'
                })
            }
            if (NotesUserPerms.Allowed) {
                NotesRoutes.push({
                    path: "/Notes",
                    name: "Notes",
                    component: Notes,
                    closedMenu: false,
                    subtab: '/Notes'
                })
            }
        }
        //#region PortalSettings
        if (PortalSettingsUserPerms.Allowed) {
            if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB || isAdminDynamicMargin) {//All
                portalSettings.push({
                    path: "/PortalSettings",
                    name: "PortalSettings",
                    component: PortalSettings,
                    closedMenu: true,
                    subtab: ''
                }, {
                    path: "/PortalSettings/Synchronize",
                    name: "Synchronize",
                    component: Synchronize,
                    closedMenu: true,
                    subtab: ''
                },
                )
                if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB || isAdminDynamicMargin) {
                    portalSettings.push(
                        {
                            path: "/PortalSettings/CompanySettings",
                            name: "CompanySMTP",
                            component: CompanySMTP,
                            closedMenu: true,
                            subtab: ''
                        },
                        {
                            path: "/PortalSettings/Teams",
                            name: "Teams",
                            component: Teams,
                            closedMenu: true,
                            subtab: ''
                        },
                        {
                            path: "/PortalSettings/Permissions",
                            name: "Permissions",
                            component: PermessionsSettings,
                            closedMenu: true,
                            subtab: ''
                        },
                        {
                            path: "/PortalSettings/Permissions/EachRole",
                            name: "EachRole",
                            component: EachRole,
                            closedMenu: true,
                            subtab: ''
                        },
                        {
                            path: "/PortalSettings/Permissions/EachUserPermission",
                            name: "EachUserPermission",
                            component: EachUserPermission,
                            closedMenu: true,
                            subtab: ''
                        }
                    )
                }
                if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                    portalSettings.push(
                        {
                            path: "/PortalSettings/EmailTemplate",
                            name: "EmailTemplate",
                            component: EmailTemplate,
                            closedMenu: true,
                            subtab: ''
                        }, {
                        path: "/PortalSettings/WebHookIntegrations",
                        name: "WebHookIntegrations",
                        component: WebHookIntegrations,
                        closedMenu: true,
                        subtab: ''
                    }
                    )
                }
                if (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) {
                    portalSettings.push(
                        {
                            path: "/PortalSettings/Notifications",
                            name: "SystemNotifications",
                            component: SystemNotifications,
                            closedMenu: true,
                            subtab: ''
                        },

                    )
                }
                if (isAdminBonus) {
                    portalSettings.push({
                        path: "/PortalSettings/Bonus",
                        name: "Bonus",
                        component: BonusSetttings,
                        closedMenu: true,
                        subtab: ''
                    })
                }
                if ((isAdminDynamicMargin) && DynamicMargin.Allowed) {
                    portalSettings.push({
                        path: "/PortalSettings/DynamicMarginSettings",
                        name: "DynamicMarginSettings",
                        component: DynamicMarginSettings,
                        closedMenu: true,
                        subtab: ''
                    })
                }
                if (isAdminCRM || (isAdminMM) || isAdminIB) {//ib mama as wallet exist 
                    portalSettings.push({
                        path: "/PortalSettings/PaymentMethod",
                        name: "PaymentMethod",
                        component: PaymentMethod,
                        closedMenu: true,
                        subtab: ''
                    })
                }
                if (isAdminIB) {
                    portalSettings.push({
                        path: "/PortalSettings/IBSettings",
                        name: "AffiliateSettings",
                        component: AffiliateSettings,
                        closedMenu: true,
                        subtab: ''
                    })
                }
                if ((isAdminMM)) {
                    portalSettings.push({
                        path: "/PortalSettings/MAMSettings",
                        name: "MAMSettings",
                        component: MAMSettings,
                        closedMenu: true,
                        subtab: ''
                    })
                }
            }
        }

        if ((isAdminDynamicMargin) && DynamicMargin.Allowed) {
            //Dashboard Admin
            DynamicMarginRoutes.push(
                {
                    path: "/DynamicMargin/SymbolProfile",
                    name: "SymbolProfile",
                    component: SymbolProfle,
                    closedMenu: false,
                    subtab: '/DynamicMargin'
                },
                {
                    path: "/DynamicMargin/AccountProfile",
                    name: "AccountProfile",
                    component: AccountProfile,
                    closedMenu: false,
                    subtab: '/DynamicMargin'
                }
            )
        }
        portalSettings.push({
            path: "/PortalSettings/RegistrationTemplates",
            name: "RegistrationTemplates",
            component: RegistrationTemplates,
            closedMenu: true,
            subtab: ''
        })
        //#endregion
    }
    //client 
    if (!isA) {
        if (((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) {//checkp
            dashboardRoutes.push(
                {
                    path: "/",
                    name: "Dashboard",
                    component: Dashboard,

                    closedMenu: false,
                    subtab: ''
                }
            )
        }
        if (!isA && (checkIfAllowedForPureNorthLogs() || isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist))))) {
            //Logs
            logsRoutes.push(

                {
                    path: "/Logs/AccountLogs",
                    name: "Account Logs",
                    component: AccountLogs,

                    closedMenu: false,
                    subtab: '/Logs'
                }
            )

            if (isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) {//checkp
                //Logs
                logsRoutes.push(
                    {
                        path: "/Logs/TransactionsLogs",
                        name: "Transactions Logs",
                        component: TransactionLogs,

                        closedMenu: false,
                        subtab: '/Logs'
                    }
                )
            }
        }
        if (!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && isClientIB) {
            IBRoutes.push(
                {
                    path: "/IB/IBClients",
                    name: "IB's Clients",
                    component: IBClients,

                    closedMenu: false,
                    subtab: '/IB'
                },
                {
                    path: "/IB/leads",
                    name: "Leads",
                    component: Leads,

                    closedMenu: false,
                    subtab: '/IB'
                },
                {
                    path: "/IB/accountStatement",
                    name: "Account Statement",
                    component: AccountStatement,

                    closedMenu: false,
                    subtab: '/IB'
                },
                {
                    path: "/IB/IBDiagram",
                    name: "IBDiagram",
                    component: ClientIBTree,
                    closedMenu: false,
                    subtab: '/IB'
                },
            )
            if (isClientAccessSubIB) {
                IBRoutes.push({
                    path: "/IB/subIBStatement",
                    name: "Sub IB Statement",
                    component: SubIB,

                    closedMenu: false,
                    subtab: '/IB'
                })
            }
            if (isPromotion) {
                IBRoutes.push(
                    {
                        path: "/IB/Promotion",
                        name: "Promotion",
                        component: Promotion,

                        closedMenu: false,
                        subtab: '/IB'
                    },
                )
            }
            if (isClientReporting) {
                IBRoutes.push({
                    path: "/IB/CPAReport",
                    name: "CPA Report",
                    component: CPAReport,

                    closedMenu: false,
                    subtab: '/IB'
                })
            }
            if (isClientBreakDownReport) {
                IBRoutes.push(
                    {
                        path: "/IB/BreakdownReport",
                        name: "Breakdown Report",
                        component: BreakdownReport,

                        closedMenu: false,
                        subtab: '/IB'
                    })
            }
        }
        //#region application form  payment sub pages
        if (!isA) {
            manage.push(
                {
                    path: "/FormSuccess",
                    name: "FormSuccess",
                    component: FormSuccess,

                    closedMenu: false,
                    subtab: '/Manage'
                },
                {
                    path: "/Success",
                    name: "PaymentSuccess",
                    component: PaymentSuccess,

                    closedMenu: false,
                    subtab: '/Manage'
                },
                {
                    path: "/PendingPayment",
                    name: "PaymentPending",
                    component: PaymentPending,

                    closedMenu: false,
                    subtab: '/Manage'
                },
                {
                    path: "/Failure",
                    name: "PaymentFailure",
                    component: PaymentFailure,

                    closedMenu: false,
                    subtab: '/Manage'
                },
                {
                    path: "/PaymentCancelled",
                    name: "Payment Cancelled",
                    component: PaymentCancelled,

                    closedMenu: false,
                    subtab: '/Manage'
                },
            )
            if (DisplayOnbording() && isClientCRM) {
                manage.push(
                    {
                        path: "/OnBoarding/ApplicationForm",
                        name: "Application Form",
                        component: ApplicationFormCard,

                        closedMenu: false,
                        subtab: '/Manage'
                    },
                    {
                        path: "/OnBoarding/ApplicationForm/Card",
                        name: "CardJson",
                        component: CardJson,
                        closedMenu: false,
                        subtab: '/Manage'
                    },
                    {
                        path: "/OnBoarding/ApplicationForm/ReviewCard",
                        name: "ReviewCard",
                        component: ReviewCard,
                        closedMenu: false,
                        subtab: '/Manage'
                    },
                )

            }
            if ((!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))))) {
                MyWalletRoutes.push(
                    {
                        path: "/MyWallet",
                        name: "MyWallet",
                        component: MyWallet,
                        closedMenu: false,
                        subtab: ''
                    },
                )
            }
            if (!isA && DisplayMyAccounts()) {
                manage.push(
                    {
                        path: "/Manage/MyAccounts",
                        name: "My Accounts",
                        component: MyAccounts,
                        closedMenu: false,
                        subtab: '/Manage'
                    },
                    {
                        path: "/Manage/MyAccounts/CreditCardValidation",
                        name: "CreditCardValidation",
                        component: CreditCardValidation,
                        closedMenu: false,
                        subtab: '/Manage'
                    },
                )
                if (AllowAddDemoAcc)
                    manage.push(
                        {
                            path: "/Manage/DemoAccounts",
                            name: "Demo Accounts",
                            component: MyDemoAccounts,
                            closedMenu: false,
                            subtab: '/Manage'
                        },
                    )
                if (AllowAddDemoAcc && ManageTradingAccount)
                    manage.push(
                        {
                            path: "/Manage/DemoAccounts/NewDemoAccount",
                            name: "DemoAccount",
                            component: DemoAccountCard,
                            closedMenu: false,
                            subtab: '/Manage'
                        },
                    )
                if (isClientCRM && ManageTradingAccount)
                    manage.push(
                        {
                            path: "/Manage/MyAccounts/NewAccount",
                            name: "New Accounts",
                            component: NewAccount,
                            closedMenu: false,
                            subtab: '/Manage'
                        },//other now in same page
                    )
            }
        }
        //#endregion
        //Portfolio
        if (!isA && (!isClientCRM && (isClientMM && isClientMoneyManager.Allowed)) || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) {
            PortfolioRoutes.push(
                {
                    path: "/Portfolio/HistorySummary",
                    name: "Trade History Summary",
                    component: TradeHistorySummary,
                    closedMenu: false,
                    subtab: '/Portfolio'
                },
            )
            if (isClientMM && isClientMoneyManager.Allowed) {
                PortfolioRoutes.push(
                    {
                        path: "/Portfolio/PositionSummary",
                        name: "Open Position Summary",
                        component: OpenPositionSummary,
                        closedMenu: false,
                        subtab: '/Portfolio'
                    },
                    {
                        path: "/Portfolio/Investors",
                        name: "Investors",
                        component: Investors,

                        closedMenu: false,
                        subtab: '/Portfolio'
                    },
                    {
                        path: "/Portfolio/InvestorsTracker",
                        name: "InvestorsTracker",
                        component: InvestorsTracker,
                        closedMenu: false,
                        subtab: '/Portfolio'
                    },
                    {
                        path: "/Portfolio/PerformanceTracker",
                        name: "Fees & Performance Tracker",
                        component: FeesPerformanceTracker,
                        closedMenu: false,
                        subtab: '/Portfolio'
                    },
                    {
                        path: "/Portfolio/MonthlyFees",
                        name: "Monthly Fees",
                        component: MonthlyFees,
                        closedMenu: false,
                        subtab: '/Portfolio'
                    }
                )
            }
        }
        //Analytics
        if (!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && ((isClientMM && (isClientMoneyManager.Allowed || isClientInvestorCopier.Allowed || isClientSignalMAMPerms.Allowed)) || isClientCRM)) {
            AnalyticsRoutes.push(
                {
                    path: "/Analytics/Overview",
                    name: "Overview",
                    component: Overview,

                    closedMenu: false,
                    subtab: '/Analytics'
                },
                {
                    path: "/Analytics/trades",
                    name: "Trades",
                    component: Trades,

                    closedMenu: false,
                    subtab: '/Analytics'
                },
                {
                    path: "/Analytics/RiskMeasures",
                    name: "Risk Measures",
                    component: RiskMeasures,

                    closedMenu: false,
                    subtab: '/Analytics'
                },
                {
                    path: "/Analytics/HistoricalPerformance",
                    name: "Historical Performance",
                    component: HistoricalPerformance,

                    closedMenu: false,
                    subtab: '/Analytics'
                }
            )
        }
        if (!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientMM && LeaderBoard)) {
            SignalsRoutes.push(
                {
                    path: "/LeaderBoard",
                    name: "LeaderBoard",
                    component: Signals,

                    closedMenu: false,
                    subtab: ''
                }
            )
        }
        SignalsRoutes.push(
            {
                path: "/AnalystView",
                name: "AnalystView",
                component: AnalystView,
                closedMenu: false,
                subtab: '',
                isTableExists: true
            },
            {
                path: "/WebTerminal",
                name: "WebTerminal",
                component: WebTerminal,
                closedMenu: false,
                subtab: '',
                isTableExists: true
            },
            {
                path: "/WebTrader",
                name: "WebTrader",
                component: WebTrader,
                closedMenu: false,
                subtab: '',
                isTableExists: true
            }
        )
        if (!isA && (CheckIfCompanyIs("TiranForex") || CheckIfCompanyIs("PLUGIT"))) {
            SignalsRoutes.push({
                path: "/MarketCalendar",
                name: "MarketCalendar",
                component: MarketCalendar,
                closedMenu: false,
                subtab: '',
                isTableExists: true
            })
        }
        if (!isA && (isClientMM && isClientSignalMAMPerms.Allowed)) {
            PortfolioRoutes.push(
                {
                    path: "/Copiers",
                    name: "Copiers",
                    component: CopiersClientPage,
                    closedMenu: false,
                    subtab: ''
                });
        }
    }
    if (!isAuth) {
        return dashboardRedirect;
    }
    else {
        if (isA) {
            return IBAdminRoutes.concat(dashboardAdminRoutes, RequestsRoutes, ClientRoutes, privateRoutes, MAMRoutes, TraderRoutes, portalSettings, TaskRoutes, NotesRoutes, OnBoardingRoutes, BonusRoutes, ReportsRoutes, LogsReport, DynamicMarginRoutes);
        }
        else if (!isA) {
            return dashboardRoutes.concat(logsRoutes, IBRoutes, manage, privateRoutes, PortfolioRoutes, MyWalletRoutes, AnalyticsRoutes, SignalsRoutes, [
                {
                    path: "/Platforms",
                    name: "Platforms",
                    component: Platforms,
                    closedMenu: false,
                    subtab: ''
                }]);
        }
    }
    function DisplayMyAccounts() {
        if (isOrbit) return (!isClientCRM || (isClientCRM && (!ClientData?.RequireKYC || (ClientData?.RequireKYC && isValid))));
        return (!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))));
    }

    function checkIfAllowedForPureNorthLogs() {
        return CheckIfCompanyIs("PureNorth");
    }
    function isKYCRequired() {
        if (isElite) return false;
        if (isOrbit) return false;
        return !isA && ClientData?.RequireKYC;
    }
    function DisplayOnbording() {
        if (isElite) return !isA && ClientData.RequireKYC;
        if (isOrbit) return !isA && ClientData.RequireKYC;
        return !isA && isRequireKYC;
    }
}
//ExternalRoutes have no perms
export const ExternalRoutes = [
    {
        path: "/ResetPassword",
        name: "Reset password",
        component: ResetPassword,
        children: null,
        isExternal: true
    }, {
        path: "/BridgerPay",
        name: "BridgerPay",
        component: BridgerPay,
        children: null,
        isExternal: true
    },
    {
        path: "/Login",
        name: "SignIn",
        component: SignIn,
        children: null,
        isExternal: true
    }, {
        path: "/Offline",
        name: "OfflinePage",
        component: OfflinePage,
        children: null,
        isExternal: true
    },
    {
        path: "/RealAccountIframe",
        name: "BlankPage",
        component: CreateAccount,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/RealAccount",
        name: "BlankPage",
        component: RealAccountWrapper,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/FirstUser",
        name: "BlankPage",
        component: FirstUser,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/ConfirmPassword",
        name: "ConfirmPassword",
        component: ConfirmResetPassword,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/PasswordConfirmed",
        name: "PasswordConfirmed",
        component: PasswordConfirmed,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/ActivateUser",
        name: "ActivateUser",
        component: ActivateUser,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/RealAccountConfirmation",
        name: "ThankYouPage",
        component: ThankYouPage,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/DemoAccountConfirmation",
        name: "DemoAccountSuccess",
        component: DemoAccountSuccess,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/notAuth",
        name: "NotAuth",
        component: NotAuth,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/homepage",
        name: "BlankPage",
        component: HomePage,
        children: null,
        closedMenu: false,
        subtab: '',
        isExternal: true
    },
    {
        path: "/demoAccount",
        name: "BlankPage",
        component: DemoAccountWrapper,
        children: null,
        closedMenu: false,
        subtab: ''
    }, {
        path: "/DemoAccountIframe",
        name: "BlankPage",
        component: DemoAccountIframe,
        children: null,
        closedMenu: false,
        subtab: ''
    },
    {
        path: "/PromoCode",
        name: "BlankPage",
        component: PromoCodeWrapper,
        children: null,
        closedMenu: false,
        subtab: ''
    },
    {
        path: "/PromoCodeIframe",
        name: "BlankPage",
        component: PromoCodeIframe,
        children: null,
        closedMenu: false,
        subtab: ''
    },
    {
        path: "/liveaccount",
        name: "Live Account",
        component: LiveAccount,
        children: null,
        closedMenu: false,
        subtab: ''
    }

];



