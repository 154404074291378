import * as types from "../constants";

const initialState = {
    currentTheme: '',
    MenuIsCollapsed: false
};

export default function reducer(state = initialState, actions: any) {
    switch (actions.type) {
        case types.THEME_TOGGLE:
            return {
                ...state,
                currentTheme: actions.payload
            };
        case types.MENU_TOGGLE_COLLAPSE:
            return {
                ...state,
                MenuIsCollapsed: !state.MenuIsCollapsed
            };
        case types.MENU_SET_COLLAPSE:
            return {
                ...state,
                MenuIsCollapsed: actions.payload
            };
        default:
            return state;
    }
}
