import * as React from "react";
import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';

interface Props extends RouteProps {
    component: any;
    isAuth: boolean;
    path: string;
    PreviousPath: string|null;
}

const PrivateRoute = ({ component: Component, isAuth, path, PreviousPath, ...rest }: Props) => {  
    return (
        <Route {...rest}
            render={props => (
            isAuth ?<Component {...props} /> : <Redirect to="/login" />
        )} />
    )
}

export default PrivateRoute;