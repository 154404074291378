
import * as React from 'react';
import Helmet from 'react-helmet';

declare var require: any;

const FreshChat: any = require("react-freshchat" as any).default;

const EliteWbChat = () => {
    return <div>
        <Helmet>
            <script>
                {`
                                    function initFreshChat() {
                                      window.fcWidget.init({
                                        token: "bf3bee7a-ea52-4c84-a534-0f95820a7025",
                                        host: "https://accumarkets.freshchat.com"
                                      });
                                    }
                                    function initialize(i, t) {
                                      var e;
                                      if (i.getElementById(t)) {
                                        initFreshChat();
                                      } else {
                                        e = i.createElement("script");
                                        e.id = t;
                                        e.async = true;
                                        e.src = "https://accumarkets.freshchat.com/js/widget.js";
                                        e.onload = initFreshChat;
                                        i.head.appendChild(e);
                                      }
                                    }
                                    function initiateCall() {
                                      initialize(document, "Freshchat-js-sdk");
                                    }
                                    window.addEventListener
                                      ? window.addEventListener("load", initiateCall, false)
                                      : window.attachEvent("load", initiateCall, false);
                                        `}
            </script>
        </Helmet>
        <FreshChat
            token="bf3bee7a-ea52-4c84-a534-0f95820a7025"
            host="https://accumarkets.freshchat.com"
        />
    </div>
};

export default EliteWbChat;