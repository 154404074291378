import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Col, Label } from "reactstrap";
import { getImagesURL_LDN } from '../../Helpers/DataHelpers';
import { GetCompanyDataResult as IGetCompanyDataResult } from '../../Interfaces/Interfaces';
import { Login, Logout } from '../../redux/actions/AuthActions';
import { GetClientData, IStore } from '../../redux/constants';

const mapStateToProps = (state: IStore) => ({
    ClientData: state.auth.ClientData,
    userData: state.auth.UserData,
    lang: state.language.value,
    history: state.history, URL: state.auth.UserData.yoonitGetURL,
    theme: state.theme,
    CompanyDataProps: state.CompanyData,
});

const dispatchProps = { push, Login, Logout }
export interface Page404Props {
    ClientData: GetClientData;
    lang: string;
    userData: any;
    push: any;
    URL: string;
    theme: any
    isAuth: boolean;
    isAdmin: boolean;
    isBonus: boolean;
    t: any;
    CompanyDataProps: IGetCompanyDataResult;
    }

export interface Page404State {
    cdnLink: string;
}

class Page404 extends React.Component<Page404Props, Page404State> {
   
    state = {
        cdnLink: ""
    }
    componentDidMount() {
        this.setState({
            cdnLink: this.props.CompanyDataProps.CDNURL
        });

    }
    navigate = () => {
        if (this.props.isAuth) {
            if (!this.props.isAdmin)
                this.props.push('/');
            else {
                if (this.props.isBonus)
                    this.props.push('/Clients/ClientPage');
                else
                    this.props.push('/')
            };
        }
        else
            this.props.push('/login');
    }
    render() {
        return (
            <div className=" mainExternal signin heightSighnIn extraHeight">
                <div className="container">
                    <div className="row py-5 align-items-center">
                        <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100 containerMsg">
                            <div className=" text-center p10" >
                                <img src={`${getImagesURL_LDN(this.props.CompanyDataProps?.BannerCDNURL, this.props.CompanyDataProps.CompanyCode, this.state.cdnLink)}Page404.png`} alt="" className="mt-3" />
                                <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                    <Label className="text-Grey font-32 t-bold d-block mb-2"> {this.props.t('PageNotFound')} </Label>
                                    <Label className="text-Grey font-24 d-block m-0"> {this.props.t("Page404Text")}</Label>
                                    <Button className="SessionBtn mt-3 mb-3" onClick={this.navigate}>
                                        {this.props.t('GoBack')}</Button>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(connect(mapStateToProps, dispatchProps)(Page404) as any) as any;
