import { INotificationStore } from "./reducers/NotificationReducer";
import { DashboardItems, LoadCompanyLogoResult, GetCompanyDataResult } from "../Interfaces/Interfaces";
import { BasicSelectOption } from "../Interfaces/IApplicationForm";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";
export const MENU_TOGGLE_COLLAPSE = "MENU_TOGGLE_COLLAPSE";
export const MENU_SET_COLLAPSE = "MENU_SET_COLLAPSE";
// Auth
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const REFRESH_TOKENS = "REFRESH_TOKENS";
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const UPDATE_ISVALID = "UPDATE_ISVALID";

//Language
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

//notifications
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const SET_HAS_NEW_VALUE = "SET_HAS_NEW_VALUE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES";
//Push Notifications
export const Push_Notifications = "Push_Notifications";
export const SIGNAL_R = "SIGNAL_R";
export const CLEAR_PUSH_NOTIFICATIONS = "CLEAR_PUSH_NOTIFICATIONS";
export const IS_ENABLED = "IS_ENABLED";
export const IS_ENABLED_DESCRIPTION = "IS_ENABLED_DESCRIPTION";
export const Email_Notifications = "Email_Notifications";
//Dashboard_Items
export const UPDATE_DASHBOARD_ITEMS = "UPDATE_DASHBOARD_ITEMS";
//Language Options
export const UPDATE_LANGAUGE_OPTIONS = "UPDATE_LANGAUGE_OPTIONS";
//CompanySettings
export const UPDATE_COMPANY_SETTINGS = "UPDATE_COMPANY_SETTINGS";
//CompanyData
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";

//SINGAL R 
export const ADD_SIGNALR = "ADD_SIGNALR";
export const REMOVE_SIGNALR = "REMOVE_SIGNALR";
export const CHECK_SIGNALR = "CHECK_SIGNALR";
export const HANDLE_USER_CONNECTIVITY = "HANDLE_USER_CONNECTIVITY";

//Servers
export const HANDLE_SERVER_CONNECTIVITY = "HANDLE_SERVER_CONNECTIVITY";
export const ADD_SERVERS = "ADD_SERVERS";

//Table Columns 
export const UPDATE_COLUMN_DICT = "UPDATE_COLUMN_DICT";
//Table Filtration  
export const UPDATE_FILTRATION_DICT = "UPDATE_FILTRATION_DICT";
//synchronize IBs trades
export const UPDATE_IB_MANUAL_SYNC_TIMESTAMP = "UPDATE_IB_MANUAL_SYNC_TIMESTAMP";

export const UPDATE_NUMBER_OF_ACCOUNTS = "UPDATE_NUMBER_OF_ACCOUNTS";

export interface ServerConnection {
    ID: string;
    ServerID: number;
    IsConnected: boolean;
    IsWebAPI: boolean;
}

export interface ADDSIGNALR {
    type: typeof ADD_SIGNALR;
    payload: any;
}
export interface REMOVESIGNALR {
    type: typeof REMOVE_SIGNALR;
}
export interface CheckSignalR {
    type: typeof CHECK_SIGNALR;
}
export interface HandleUserConnectivity {
    type: typeof HANDLE_USER_CONNECTIVITY;
    payload: boolean;
}
export interface HandleServerConnectivity {
    type: typeof HANDLE_SERVER_CONNECTIVITY;
    payload: ServerConnection;
}
export interface AddServerConnectivity {
    type: typeof ADD_SERVERS;
    payload: any;
}
export interface UpdateColumnDict {
    type: typeof UPDATE_COLUMN_DICT;
    payload: any;
}
export interface UpdateFILTRATIONDict {
    type: typeof UPDATE_FILTRATION_DICT;
    payload: any;
}
export interface IBManualSyncTimeStamp {
    type: typeof UPDATE_IB_MANUAL_SYNC_TIMESTAMP;
    payload: any;
}
interface ChangeLanguageAction {
    type: typeof CHANGE_LANGUAGE;
    payload: LanguageOption;
}

export type LanguageActionsTypes = ChangeLanguageAction;

export interface LanguageOption {
    label: string;
    value: string;
    isDefault?: boolean;
}

export interface LoginAuthAction {
    type: typeof AUTH_LOGIN;
    payload: LoginAction;
}

interface LogoutAuthAction {
    type: typeof AUTH_LOGOUT;
}

interface RefreshTokensAuthAction {
    type: typeof REFRESH_TOKENS;
    payload: IRefreshTokens;
}
export interface UpdateTimezone {
    type: typeof UPDATE_TIMEZONE;
    payload: number;
}
export interface UpdateIsValid {
    type: typeof UPDATE_ISVALID;
    payload: boolean;
}

export interface IRefreshTokens {
    token: string;
    refreshToken: string;
}

export type AuthActionsTypes = LoginAuthAction | LogoutAuthAction | RefreshTokensAuthAction;

export interface AddNewMessageAction {
    type: typeof ADD_NEW_MESSAGE;
    payload: ActionMessage;
};


export interface SetHasNewValue {
    type: typeof SET_HAS_NEW_VALUE;
    payload: boolean;
};

export interface RemoveMessage {
    type: typeof REMOVE_MESSAGE;
    payload: number;
};

export interface RemoveAllMessages {
    type: typeof REMOVE_ALL_MESSAGES;
};

export interface PushNotificationStatus {//TODO check imports as differnt from Action
    PNID: number;
    IsEnabled: boolean;
    PNDescription: string;
}
export interface PushNotificationStatusClass {
    PushNotificationStatus: PushNotificationStatus[];
    signalRIsConnected: boolean;
}
export interface PushNotifications {
    type: typeof Push_Notifications;
    payload: PushNotificationStatusClass;
};
export interface SignalR {
    type: typeof SIGNAL_R;
    payload: boolean;
};
export interface ClearPushNotifications {
    type: typeof CLEAR_PUSH_NOTIFICATIONS;
};
export interface UpdateDashboardItems {
    type: typeof UPDATE_DASHBOARD_ITEMS;
    payload: DashboardItems[];
}
export interface IUpdateLanguageOptions {
    type: typeof UPDATE_LANGAUGE_OPTIONS;
    payload: ILanguageData;
}
export interface UpdateCompanySettings {
    type: typeof UPDATE_COMPANY_SETTINGS;
    payload: LoadCompanyLogoResult;
}
export interface UpdateCompanyData {
    type: typeof UPDATE_COMPANY_DATA;
    payload: GetCompanyDataResult;
}
export type ILanguageData = {
    Options: BasicSelectOption[];
    Dict: any;
}
export interface IUpdateNumberOfAccounts {
    type: typeof UPDATE_NUMBER_OF_ACCOUNTS;
}
export interface ActionMessage {
    companyName: string;
    messageContent: string;
    messageTitle: string;
    actionType: string;
    currency: string;
    subType: string;
    messageDate: string;
    pnid: number;
    login: string;
    amount: string;
    toLogin: string;
    fromLogin: string;
    fromServer: string;
    toServer: string;
    admin: string;
    clientID: string;
    clientName: string;
    server: string;
    task: string;
    lead: string;
    contact: string;
    ticket: string;
    ibName: string;
    ibCode: string;
    listEmails: string;
    date: string;
    lots: string;
    name: string;
    mamName: string;
    mamLogin: string;
    auiDs: string[];
    profileName: string;
    profileID: number;
}
export interface ServersStatus {
    serverID: number;
    isConnected: boolean;
    isWebAPI: boolean;
    serverName: string;
    serverType: string;
    companyName: string;
}

export type NotificationActionsTypes = AddNewMessageAction | SetHasNewValue | RemoveMessage | RemoveAllMessages;
export type SignalRTypes = ADDSIGNALR | REMOVESIGNALR | CheckSignalR;

export interface IClientPermissionSP {
    IsDemoGU: boolean;
    KYCIsApprvd: boolean;
    RequireKYC: boolean;
    IsIB: boolean;
    IsMM: boolean;
    IsTrader: boolean;
    IsActive: boolean;
    ActivationCode: string;
    IsManual: boolean;
    IsDeleted: boolean;
    IsValid: boolean;
    DeleteDate: Date;
    CreationDate: Date;
    PoolID: number;
    CategoryID: number;
    RestoreDate: Date;
    KTID: number;
    PermManageARule: boolean;
    PermManageARuleAmount: boolean;
    PermManageInvestorStatus: boolean;
    PermCloseTradesPerLogin: boolean;
    PermCloseTradesPerTicket: boolean;
    PermManageARuleReverse: boolean;
    PermAccessSubIB: boolean;
    PermAccessSubIBDetails: boolean;
    PermTransferClient: boolean;
    PermClientOpenOrders: boolean;
    PermManagement: boolean;
    PermBalance: boolean;
    PermCommission: boolean;
    PermReporting: boolean;
    PermSpecialReport: boolean;
    PermClientPersonalDetails: boolean;
    PermPromotion: boolean;
    GCENID: number;
    UserAuthID: string;
    EmailLang: string;
    PermRiskStatementReminder: boolean;
    RiskStatementReminderDesc: string;
}

export interface IUserData {
    id: string;
    Token: string;
    RefreshToken: string;
    isAdmin: boolean;
    isFirstUser: boolean;
    yoonitGetURL: string;
    yoonitActionURL: string;
    UserName: string;
    Language: string;
    TimeZone: number;
}
export interface IPerms {
    PermID: number | null;
    ParentPermID: number | null;
    PermName: string;
    PermValID: number | null;
    PermValName: string;
}
export interface ICompanyData {
    CompanyID: string;
    CompanyName: string;
    CompanyTitle: string;
    SignalRHubURL: string;
    IsIB: boolean;
    IsMM: boolean;
    IsBonus: boolean;
    IsDynamicMargin: boolean;
    IsCRM: boolean;
}

export interface LoginAction {
    CompanyAuth: boolean;
    isAuth: boolean;
    isPrimarySalt: boolean;
    isTokenExpired: boolean;
    isTokenValid: boolean;
    GUARules: any;
    UserData: IUserData;
    Servers: any;
    CompanyData: ICompanyData;
    ClientData: GetClientData;
    AdminData: GetAdminData;
    UserPermissions: IPerms[];
}

export interface IStore {
    history: any;
    router: any;
    sidebar: any;
    theme: any;
    toastr: any;
    auth: LoginAction;
    language: any;
    actionSideBar: any;
    Notifications: INotificationStore;
    PushNotifications: PushNotificationStatusClass;
    Items: DashboardItems[];
    CompanySettings: LoadCompanyLogoResult;
    CompanyData: GetCompanyDataResult;
    ServersConnectivity: any;
    TableColumns: any;
    TableFiltrationDict: any;
    LanguageData: ILanguageData;
    IBManualSyncTimeStamp: number;
}

export interface GetClientData {
    BUID: string;
    ClientID: string;
    ContactID: string;
    ClientRangeID: string;
    ContactStatus: string;
    SalutationID: number;
    SalutationTxt: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    CountryID: number;
    CountryName: string;
    CountryCode: string;
    DOB: string;
    ProfileImage: string;
    IsActive: boolean;
    RequireKYC: boolean;
    KYCStatus: string;
    KYCReason: string;
    hasKYC: boolean;
    NmbrOfAcc: number;
    CardValidation: boolean;
    IsValid: boolean;
    KTID: boolean;
    AllowAddDemoAcc: boolean;
    RiskStatementReminder: string;
    PoolID: number;
    ZipCode: string;
    ResidentialAddress: string;
    City: string;
    IsClientSegment: boolean;
    /** for Ozow from KYC */
    NationalIDCardNumber: string;
}

export interface GetAdminData {
    AUID: string;
    ContactID: string;
    ContactStatus: string;
    SalutationID: number;
    SalutationTxt: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    CountryID: number;
    CountryName: string;
    DOB: string;
    ProfileImage: string;
    IsActive: boolean;
}
