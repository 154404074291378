import * as types from "../constants";

export function Login(userData: types.LoginAction): types.AuthActionsTypes {
    return {
        type: types.AUTH_LOGIN,
        payload: userData
    };
}
export function Logout(): types.AuthActionsTypes {
    return {
        type: types.AUTH_LOGOUT
    };
}
export function RefreshTokens(Tokens: types.IRefreshTokens): types.AuthActionsTypes {
    return {
        type: types.REFRESH_TOKENS,
        payload: Tokens
    };
}
export function UpdateTimezone(TimeZone: number): types.UpdateTimezone {
    return {
        type: types.UPDATE_TIMEZONE,
        payload: TimeZone
    };
}
export function UpdateIsValid(isValid: boolean): types.UpdateIsValid {
    return {
        type: types.UPDATE_ISVALID,
        payload: isValid
    };
}
export function UpdateNumberOfAccounts(): types.IUpdateNumberOfAccounts {
    return {
        type: types.UPDATE_NUMBER_OF_ACCOUNTS,
    };
}