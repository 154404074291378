import { YoonitResponse, YoonitResponseT2 } from "../Interfaces/Interfaces";
import store from "../redux/store";
import { push } from "connected-react-router";
import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { RefreshTokens, Logout } from "../redux/actions/AuthActions";


export async function ValidateResponse(response: YoonitResponse<any> | YoonitResponseT2<any, any>, t: Function) {
    if (!response)
        return false;
    if (!response.IsAuth) {
        toastr.error("", t('SessionNotAuthorized'));//printed as user language as when login it changes as user language
        store.dispatch(Logout());
        store.dispatch(push('/login'));
        return false;
    }

    if (response.IsAuth && response.IsTokenExpired && !Singleton.getInstance().inProgress) {
        Singleton.getInstance().inProgress = true;
        let dataObj = {
            Token: store.getState().auth.UserData.Token,
            RefreshToken: store.getState().auth.UserData.RefreshToken
        };
        await Axios.post('/api/auth/RefreshToken', dataObj)
            .then(res => {
                let refreshReducerParams = {
                    token: res.data.Token,
                    refreshToken: res.data.RefreshToken
                }
                store.dispatch(RefreshTokens(refreshReducerParams));
                Singleton.getInstance().inProgress = false;
                Singleton.getInstance().token = res.data.Token;
                if (Singleton.getInstance().token === res.data.Token && Singleton.getInstance().failedInRowCount === 2) {
                    LogoutUser(t);
                    ResetCount();
                    return false;
                }
                else if (Singleton.getInstance().token === res.data.Token) {//not changed means faulty 
                    IncrementCount();
                }
                else {
                    ResetCount(); //reset count when true again
                }
                return true;
            }).catch(e => {
                console.error('ValidateResponse', e);
                if (e.response) {
                    if (e.response.status === 401) {
                        toastr.error('', t('Unauthorized'));
                    }
                    else if (e.response.status === 403) {
                        toastr.error('', t('Forbidden'));
                    }
                }
                else {
                    toastr.error('', t('SessionEnded'));
                }
                store.dispatch(Logout()); //just need navigate as in sign in all logut and redux done
                store.dispatch(push('/login'));
                return false;
            });
    }
    return true;
}
//async function RefershToken(values: any) { //gtoDO when refresh token is fixed
//    await store.dispatch(RefreshTokens(values));
//    toastr.info("", "Session Renewed");
//    return true;
//}
export class Singleton {
    private static instance: Singleton;

    constructor() {
        if (Singleton.instance) {
            throw new Error("Error - use Singleton.getInstance()");
        }
        this.inProgress = false;
        this.token = '';
        this.failedInRowCount = 0;
    }
    static getInstance(): Singleton {
        Singleton.instance = Singleton.instance || new Singleton();
        return Singleton.instance;
    }
    inProgress: boolean;
    token: string;
    failedInRowCount: number;
}
export interface IHeaderParameters {
    Token: string | null;//null if external
}
function LogoutUser(t: Function) {
    toastr.error('', t('SessionEnded'));
    store.dispatch(Logout()); //clear notifcation with logout .. and other 
    store.dispatch(push('/login'));
}

function IncrementCount() {
    Singleton.getInstance().failedInRowCount++;
}

function ResetCount() {
    Singleton.getInstance().failedInRowCount = 0;
}

export async function GetAxiosResponse<T, T2>(
    BaseURL: string,
    Path: string,
    ObjectParameters: T,
    headerParameters: IHeaderParameters,
    SuccessCallback: Function,
    FailureCallback: Function,
    translate: Function,
    isExternal?: boolean,
    PopUpsNotAlowed?: boolean,
) {
    try {
        const res: any = await Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
            headers: {
                ...headerParameters,
                "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload"
            },
        });
        if (!isExternal)
            if (!ValidateResponse(res.data, translate)) {
                if (!PopUpsNotAlowed)
                    toastr.error("", translate('FailedAction'));
                //SuccessCallback(res);
            }
        SuccessCallback(res);
        return res;
    }
    catch (e) {
        console.error('GetAxiosResponse', e);
        RevertCall(e, translate, isExternal, PopUpsNotAlowed);
        FailureCallback(e);
        return null;
    }
}
function RevertCall(e: any, translate: Function, isExternal?: boolean, PopUpsNotAlowed?: boolean) {
    if (!isExternal
        && e.response
        && e.response.status === 401
        && e.response.data.IsAuth
        && e.response.data.IsTokenExpired
        && !Singleton.getInstance().inProgress) {//Expired case 401 and IsAuth true
        ValidateResponse(e.response.data, translate);
    } else if (e.response && (e.response.status === 500 || e.response.status === 404)) {
        if (!PopUpsNotAlowed)
            toastr.error('', translate('ErrorContactingServer'));
    }
    else if (e.response && (e.response.status === 403)) {
        if (!PopUpsNotAlowed)
            toastr.error('', translate('Forbidden'));
    }
    else if (e.response && (e.response.status === 401)) {
        if (!PopUpsNotAlowed)
            toastr.error('', translate('Unauthorized'));
        store.dispatch(Logout());
        store.dispatch(push('/login'));
    }
}
/**
 * PopUpsNotAlowed is to turn off popups
 * @param BaseURL
 * @param Path
 * @param ObjectParameters
 * @param headerParameters
 * @param SuccessCallback
 * @param FailureCallback
 * @param translate
 * @param isExternal
 * @param PopUpsNotAlowed
 */
export async function CallAxiosRequest<T, T2>(BaseURL: string,
    Path: string,
    ObjectParameters: T,
    headerParameters: IHeaderParameters,
    SuccessCallback: Function,
    FailureCallback: Function,
    translate: Function,
    isExternal?: boolean,
    PopUpsNotAlowed?: boolean,
) {
    Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
        headers: {
        ...headerParameters,
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload"
        },
        maxContentLength: 100000000,
        maxBodyLength: 100000000,
        //maxRedirects:10
    })
        .then(res => {
            if (!isExternal)
                if (!ValidateResponse(res.data, translate)) {
                    if (!PopUpsNotAlowed)
                        toastr.error("", translate('FailedAction'));
                    //SuccessCallback(res);//TODO check
                }
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error("CallAxiosRequest", e);
            RevertCall(e, translate, isExternal, PopUpsNotAlowed);
            FailureCallback(e);
        });
}
export async function CallAxiosRequestWithCancelToken<T, T2>(BaseURL: string,
    Path: string,
    ObjectParameters: T,
    headerParameters: IHeaderParameters,
    SuccessCallback: Function,
    FailureCallback: Function,
    translate: Function,
    CancelToken: any,
    isExternal?: boolean,
    PopUpsNotAlowed?: boolean,
) {
    Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
        headers: {
            ...headerParameters,
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
           
        }, cancelToken: CancelToken.token,
        maxContentLength: 100000000,
        maxBodyLength: 100000000,

    })
        .then(res => {
            if (!isExternal)
                if (!ValidateResponse(res.data, translate)) {
                    if (!PopUpsNotAlowed)
                        toastr.error("", translate('FailedAction'));
                    //SuccessCallback(res);//TODO check
                }
            SuccessCallback(res);
        }).catch((e: any) => {
            RevertCall(e, translate, isExternal, PopUpsNotAlowed);
            FailureCallback(e);
        });
}
export async function CallLocalRequest<T, T2>(
    Path: string,
    ObjectParameters: T,
    SuccessCallback: Function,
    FailureCallback: Function,
) {
    Axios.post<T2>(`${Path}`, ObjectParameters)
        .then(res => {
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error(e);
            FailureCallback(e);
        });
}
export async function CallLocalRequestGet<T, T2>(
    Path: string,
    ObjectParameters: T,
    SuccessCallback: Function,
    FailureCallback: Function,
) {
    Axios.get<T2>(`${Path}`, ObjectParameters)
        .then(res => {
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error(e);
            FailureCallback(e);
        });
}
export async function CallLocalRequestGetNoCache<T, T2>(
    Path: string,
    ObjectParameters: T,
    SuccessCallback: Function,
    FailureCallback: Function,
    noVersion?: boolean
) {
    let _path = noVersion ? Path : `${Path}?v=${new Date().getTime()}`;
    Axios.get<T2>(_path, {
        //headers: {
        //    'Cache-Control': 'no-cache',
        //    'Pragma': 'no-cache',
        //    'Expires': '0',
        //}
    })
        .then(res => {
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error(e);
            FailureCallback(e);
        });
}
export async function CallAsyncAxiosRequest<T, T2>(
    BaseURL: string,
    Path: string,
    ObjectParameters: T,
    headerParameters: IHeaderParameters,
    SuccessCallback: Function,
    FailureCallback: Function,
    translate: Function,
    isExternal?: boolean,
    PopUpsNotAlowed?: boolean
) {
    await Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
        headers: {
            ...headerParameters,
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
            maxContentLength: 100000000,
            maxBodyLength: 100000000,
        }
    })
        .then(res => {
            if (!isExternal)
                if (!ValidateResponse(res.data, translate)) {
                    if (!PopUpsNotAlowed)
                        toastr.error("", translate('FailedAction'));
                    //SuccessCallback(res);
                }
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error(e);
            RevertCall(e, translate, isExternal, PopUpsNotAlowed);
            FailureCallback(e);
        });
}
export async function CallAsyncAxiosRequestWithCancelToken<T, T2>(
    BaseURL: string,
    Path: string,
    ObjectParameters: T,
    headerParameters: IHeaderParameters,
    SuccessCallback: Function,
    FailureCallback: Function,
    translate: Function,
    isExternal?: boolean,
    PopUpsNotAlowed?: boolean,
    CancelToken?: any
) {
    await Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
        headers: {
            ...headerParameters,
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
            maxContentLength: 100000000,
            maxBodyLength: 100000000,
        }, cancelToken: CancelToken.token
    })
        .then(res => {
            if (!isExternal)
                if (!ValidateResponse(res.data, translate)) {
                    if (!PopUpsNotAlowed)
                        toastr.error("", translate('FailedAction'));
                    //SuccessCallback(res);
                }
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error(e);
            RevertCall(e, translate, isExternal, PopUpsNotAlowed);
            FailureCallback(e);
        });
}
export async function AxiosPost<T, T2>(
    { BaseURL,
        Path,
        ObjectParameters,
        headerParameters,
        SuccessCallback,
        FailureCallback,
        FinallyCallback,
        translate,
        isExternal,
        PopUpsNotAlowed
    }
        : {
            BaseURL: string,
            Path: string,
            ObjectParameters: T,
            headerParameters: IHeaderParameters,
            SuccessCallback: Function,
            FailureCallback: Function,
            FinallyCallback?: Function,
            translate: Function,
            isExternal?: boolean,
            PopUpsNotAlowed?: boolean
        }
) {
    Axios.post<YoonitResponse<T2>>(`${BaseURL}${Path}`, ObjectParameters, {
        headers: {
            ...headerParameters,
            "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
           
        },
        maxContentLength: 100000000,
        maxBodyLength: 100000000,
        //maxRedirects:10
    })
        .then(res => {
            if (!isExternal)
                if (!ValidateResponse(res.data, translate)) {
                    if (!PopUpsNotAlowed)
                        toastr.error("", translate('FailedAction'));
                    //SuccessCallback(res);//TODO check
                }
            SuccessCallback(res);
        }).catch((e: any) => {
            console.error("CallAxiosRequest", e);
            RevertCall(e, translate, isExternal, PopUpsNotAlowed);
            FailureCallback(e);
        }).finally(() => FinallyCallback?.());
}