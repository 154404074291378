import * as React from 'react';

declare var require: any
const { SpinnerComponent }: any = require('react-element-spinner');



//const loading = require("../Assets/imgs/loading.png") as string;
class Lazy extends React.Component<any> {
    render() {
        return (
            <SpinnerComponent loading={true} position="centered"  color="#00ad0f" />
        )
    }
};
export default Lazy;