import * as types from "../constants";

const initialState: any = true;//as true consider at first 
export default function reducer(state = initialState, actions: types.HandleUserConnectivity) {
    switch (actions.type) {
        case "HANDLE_USER_CONNECTIVITY":
            return actions.payload;
        default:
            return state;
    }
}
