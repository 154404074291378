import * as types from "../constants";

export default function reducer(state = [] as any, actions: any): types.HandleServerConnectivity | types.AddServerConnectivity {
    switch (actions.type) {
        case types.HANDLE_SERVER_CONNECTIVITY: {
            return {
                ...state,
                [actions.payload.ServerID]: actions.payload

            };
        }
        case types.ADD_SERVERS: {
            return actions.payload
        }
        default:
            return state;
    }
}