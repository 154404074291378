import * as types from "../constants";

export interface INotificationStore {
    Notifications: types.ActionMessage[],
    HasNew: boolean
}

const initialState: any = null;

export default function reducer(state = initialState, actions: types.SignalRTypes) {
    switch (actions.type) {
        case "ADD_SIGNALR":
            return actions.payload;
        case "REMOVE_SIGNALR":
            return initialState;
        case "CHECK_SIGNALR":
            return initialState;
        default:
            return state;
    }
}
