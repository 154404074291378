import * as types from "../constants";

export function AddSignalr(connection: any): types.ADDSIGNALR {
    return {
        type: types.ADD_SIGNALR,
        payload: connection
    };
}
export function RemoveSignalr(): types.REMOVESIGNALR {
    return {
        type: types.REMOVE_SIGNALR
    };
}
export function CheckSignalR(): types.CheckSignalR {
    return {
        type: types.CHECK_SIGNALR
    };
}