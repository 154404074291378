import * as types from "../constants";
import { GetCompanyDataResult } from "../../Interfaces/Interfaces";

const initialState: GetCompanyDataResult = new GetCompanyDataResult();

export default function reducer(state = initialState, actions: types.UpdateCompanyData) {
    switch (actions.type) {
        case "UPDATE_COMPANY_DATA":
            return { ...actions.payload };
        default:
            return state;
    }
}
