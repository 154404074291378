import * as React from 'react';
import ErrorBoundary from '../../AdvancedComponents/ErrorBoundary';

//removeCss TODO danger check 
export default (props: { children?: React.ReactNode, removeScroll?:boolean }) => (
    <ErrorBoundary>
        <div className="wrapper">

            <div className="d-flex w-100 signin height100Vh">
                <div className={props.removeScroll ? "mainExternal mainExternalNoScroll" : "mainExternal"} >
                    <div >

                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </ErrorBoundary>
);
