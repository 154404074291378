import * as types from "../constants";

const initialState: any = {};

export default function reducer(state = initialState, actions: types.IBManualSyncTimeStamp) {
    switch (actions.type) {
        case "UPDATE_IB_MANUAL_SYNC_TIMESTAMP": {
            return {
                ...state,
                ...actions.payload
            }
        }
        default:
            return state;
    }
}