import * as React from 'react';

export interface BrowserNotSuportedProps {
}

export interface BrowserNotSuportedState {
}
declare var require: any;
const unsupportedbrower = require("../src/Assets/imgs/notsuported.jpg") as string;
class BrowserNotSuported extends React.Component<BrowserNotSuportedProps, BrowserNotSuportedState> {
    render() {
        return (
            <img
                src={unsupportedbrower}
                className="sizeFull"
                alt="unsupported browser"
            />
        );
    }
}

export default BrowserNotSuported;