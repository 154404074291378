import * as types from "../constants";
import { LoadCompanyLogoResult } from "../../Interfaces/Interfaces";

const initialState: LoadCompanyLogoResult = new LoadCompanyLogoResult();

export default function reducer(state = initialState, actions: types.UpdateCompanySettings) {
    switch (actions.type) {
        case "UPDATE_COMPANY_SETTINGS":
            return { ...actions.payload };
        default:
            return state;
    }
}
