import * as types from "../redux/constants";
function timestampAction(action: any) {
    return {
        action: action.type,
        actionPayload: action.payload ? action.payload:"",//so dont crash
        time: Date.now()
    }
}

const storageMiddleware = ({ getState }: any) => (next: any) => async (action: any) => {
    const stampedAction = timestampAction(action);
    if ((action.type === types.AUTH_LOGIN /*&& action?.payload?.auth?.isAuth*/) // new state here or action payload
        || action.type === types.AUTH_LOGOUT) {
        localStorage.setItem('STORAGE-KEY', JSON.stringify(stampedAction));
    }
    next(action);

}
export default storageMiddleware;