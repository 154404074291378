import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import theme from "./themeReducer";
import auth from "./AuthReducer";
import { reducer as toastr } from "react-redux-toastr";
import language from './LanguageReducer';
import Notifications from './NotificationReducer';
import PushNotifications from './PushNotificationReducer';
import Items from './DashboardReducer';
import LanguageData from './LanguageOptionsReducer';
import CompanySettings from './CompanySettingsReducer';
import CompanyData from './CompanyDataReducer';
import SignalRConnection from './SignalRReducer';
import UserConnectivity from './UserConnectivityReducer';
import ServersConnectivity from './ServersConnectivityReducer';
import TableColumns from './ColumnTableReducer';
import TableFiltrationDict from './TableFiltrationReducer';
import IBManualSyncTimeStamp from './IBManualSyncTimeStampReducer';

const createRootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    theme,
    toastr,
    auth,
    language,
    Notifications,
    PushNotifications,
    Items,
    LanguageData,
    CompanySettings,
    CompanyData,
    SignalRConnection,
    UserConnectivity,
    ServersConnectivity,
    TableColumns,
    TableFiltrationDict,
    IBManualSyncTimeStamp
})

export default createRootReducer;