import * as types from "../constants";
import { toggleTheme } from "../../Services/ThemeService";

export function enableDarkTheme() {
    return enableTheme("addons-DarkTheme");
}
export function enableLightTheme() {
    return enableTheme("addons-LightTheme");
}
export function enableMixTheme() {
    return enableTheme("addons-MixTheme");
}
export function enableOrangeTheme() {
    return enableTheme("addons-OrangeTheme");
}
export function enableNavyTheme() {
    return enableTheme("addons-NavyTheme");
}
export function enableBlackRedTheme() {
    return enableTheme("addons-BlackRedTheme");
}
export function enableIGainTheme() {
    return enableTheme("addons-IGainTheme");
}
export function enableLDNTheme() {
    return enableTheme("addons-LDNTheme");
}
export function enableATFXTheme() {
    return enableTheme("addons-ATFXTheme");
}
export function enableACMTheme() {
    return enableTheme("addons-ACMTheme");
}
export function enableGMCTheme() {
    return enableTheme("addons-GMCTheme");
}
export function enableAvantTheme() {
    return enableTheme("addons-AvantTheme");
}
export function enablePrimexTheme() {
    return enableTheme("addons-PrimexTheme");
}
export function enableGivTradeTheme() {
    return enableTheme("addons-GivTradeTheme");
}
export function enableiQuotoTheme() {
    return enableTheme("addons-iQuotoTheme");
}
export function enablePureNorthTheme() {
    return enableTheme("addons-PureNorthTheme");
}
export function enableOrbitLight() {
    return enableTheme("addons-Orbit-Light");
}
export function enableOrbitDark() {
    return enableTheme("addons-Orbit-Dark");
}
export function enableTNFXTheme_OrangeDark() {
    return enableTheme("addons-TNFXTheme");
}
export function enableGold() {
    return enableTheme("addons-GoldTheme");
}
export function enableBrownTheme() {
    return enableTheme("addons-BrownTheme");
}
function enableTheme(name: any) {
    toggleTheme(name);
    return {
        type: types.THEME_TOGGLE,
        payload: name
    };
}

export function MenuToggleCollapse() {
    return {
        type: types.MENU_TOGGLE_COLLAPSE
    };
}
export function MenuSetCollapse(collapsed: boolean) {
    return {
        type: types.MENU_SET_COLLAPSE,
        payload: collapsed

    };
}