import DashboardIcon from '@material-ui/icons/Dashboard';
import LinkIcon from '@mui/icons-material/Link';
import 'antd/dist/antd.css';
import Menu from 'antd/es/menu';
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CheckIfCompanyIs, CheckIfPermAlllowedByName, DisplayZendDeskChat, GetPermissionDetailsByName, GetReportsPerms, OneOFReportsEnabled } from '../../../Helpers/DataHelpers';
import { translationType, UserPermissionOBjectClass } from '../../../Interfaces/Interfaces';
import ContactUs from '../../../Pages/ClientSettings/ContactUs';
import { Login, Logout } from '../../../redux/actions/AuthActions';
import { MenuSetCollapse, MenuToggleCollapse } from '../../../redux/actions/themeActions';
import { GetAdminData, GetClientData, ICompanyData, IPerms, IStore, IUserData } from '../../../redux/constants';
declare var require: any;

const $ = require("jquery");
const { SubMenu } = Menu;
const mapStateToProps = (state: IStore) => ({
    userData: state.auth.UserData,
    lang: state.language.value,
    state: state.sidebar,
    history: state.history,
    theme: state.theme,
    CompanyData: state.auth.CompanyData,
    AdminData: state.auth.AdminData,
    ClientData: state.auth.ClientData,
    UserPermissions: state.auth.UserPermissions
});
const dispatchProps = { push, Login, Logout, MenuToggleCollapse, MenuSetCollapse }
interface LeftSideBarProps {
    AdminData: GetAdminData;
    ClientData: GetClientData;
    path: any;
    PreviousPath: string;
    closedMenu: any;
    subtab: any;
    history: any;
    lang: translationType;
    userData: IUserData;
    theme: any;
    push: any;
    CompanyData: ICompanyData
    MenuSetCollapse: any;
    MenuToggleCollapse: any;
    UserPermissions: IPerms[];
    t: any;
    i18n: any;
}
interface LeftSideBarState {
    ContactUSMessage: string;
    SendMessageLoading: boolean;
    openContactUSPOPUP: boolean;
    defaultSelectedKeys: string;
    openKeys: string[];
    collapsed: boolean;
    selectedSetting: boolean;
    subtab: string;
    OpenContactUs: boolean;
    current: string;
    visible: boolean;
}
class LeftSideBar extends React.Component<LeftSideBarProps, LeftSideBarState> {
    // submenu keys of first level
    //without this in tsx 
    isElite: boolean = CheckIfCompanyIs("elite");
    isJKV: boolean = CheckIfCompanyIs("JKV");
    isOrbit: boolean = CheckIfCompanyIs("Orbit");
    isTNFX: boolean = CheckIfCompanyIs("TNFX");
    isIconFx: boolean = CheckIfCompanyIs("Icon");
    rootSubmenuKeys = [this.props.subtab];
    state = {
        ContactUSMessage: "",
        SendMessageLoading: false,
        openContactUSPOPUP: false,
        // Mobile
        current: 'mail',
        visible: false,
        collapsed: this.props.theme.MenuIsCollapsed,
        openKeys: (this.props.path
            && (this.props.path.toLowerCase().includes("/portalsettings")
                || this.props.path.toLowerCase().includes("clients/pools/")
                || this.props.path.toLowerCase().includes("trader/accounttypes/"))
        ) ? [] : [this.props.subtab],//@point here put [] if from sub menus //not empty or existing one 
        defaultSelectedKeys: '1000',
        selectedSetting: false,
        subtab: this.props.subtab,
        OpenContactUs: false,
    };
    componentDidMount() {
        this.rootSubmenuKeys = ['/Manage', '/IB', '/Portfolio', '/Analytics', '/Logs', '/MAM', '/IBAdmin', '/Trader', '/Clients', '/BackOffice', '/Users', '/OnBoarding', '/DynamicMargin', '/Bonus', '/Reports', '/LogsReport'];//add them ALL     

        if (this.props.path
            && (this.props.path.toLowerCase().includes("/portalsettings")
                || this.props.path.toLowerCase().includes("clients/pools/")
                || this.props.path.toLowerCase().includes("trader/accounttypes/"))
        ) {
            this.SetCollapsed(true);
            this.props.MenuSetCollapse(true);
            this.unselectItems();
        }
        else if (
            (this.props.PreviousPath)
            && (this.props.PreviousPath.toLowerCase().includes("/login")
                || this.props.PreviousPath.toLowerCase().includes("/portalsettings")
                || this.props.PreviousPath.toLowerCase().includes("clients/pools/")
                || this.props.PreviousPath.toLowerCase().includes("trader/accounttypes/"))
        ) {
            //opens in other tabs 
            this.SetCollapsed(false);
            this.props.MenuSetCollapse(false);
        }
        else if (!this.props.PreviousPath) { //reload from link and path not submenu
            this.SetCollapsed(false);
            this.props.MenuSetCollapse(false);
        }
        if (this.props.path) {
            // To get the substring BEFORE the nth occurence
            var tokens2 = this.props.path.split('/').slice(1, 2),
                result2 = tokens2.join('/'),
                sub = result2 !== undefined && result2 !== null && result2 !== '/' ? '/' + result2 : '/'; // this
            ;
            //in subTab so it closes opens
            if (this.props.path
                && (this.props.path.toLowerCase().includes("/portalsettings")
                    || this.props.path.toLowerCase().includes("clients/pools/")
                    || this.props.path.toLowerCase().includes("trader/accounttypes/"))
            ) {
                this.setState({ subtab: sub, openKeys: [] });
            }
            else {
                this.setState({ subtab: sub, openKeys: [sub] });
                this.scrollToSelectedPath();
            }
        }
        if (this.props.userData?.isAdmin) {
            DisplayZendDeskChat(true);
        } else {
            DisplayZendDeskChat(false);
        }
    }
    scrollToSelectedPath = () => {
        try {
            let selectedPathElement: any = document.getElementById(this.props.path);
            selectedPathElement?.scrollIntoView({ behavior: "smooth" });
        } catch (e) {
            console.error('scrollToSelectedPath', e);
        }
    }
    toggleCollapsed = () => {
        this.props.MenuToggleCollapse();
        this.setState({ //order matter 
            openKeys: [], defaultSelectedKeys: 'closeAll'
        });
        //this.unselectItems(); no need
        //this.forceUpdate();
        this.setState({
            collapsed: !this.state.collapsed,

        });
    };
    SetCollapsed = (collapsed: boolean) => {
        this.props.MenuToggleCollapse();
        this.setState({ //order matter 
            openKeys: [], defaultSelectedKeys: 'closeAll'
        });
        //this.unselectItems(); no need
        //this.forceUpdate();
        this.setState({ collapsed });
    };
    unselectItems = () => {
        $("#closeAll").trigger("click");
        this.setState({ selectedSetting: true });
        this.setState({
            openKeys: [],
        });

    }
    onOpenChange = (openKeys: any) => {
        const latestOpenKey = openKeys.find((key: any) => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    };
    closeALLMenus = () => {
        this.setState({
            openKeys: [],
        });
        this.setState({ selectedSetting: false });
    }
    unselectAll = () => {
        this.setState({
            openKeys: [],
        });

    }
    openContactusModal = () => {
        this.setState({ OpenContactUs: !this.state.OpenContactUs })
    }
    closeContact = () => {
        this.setState({ OpenContactUs: false })
    }
    navigatetoSettings = () => {
        this.props.push("/PortalSettings/CompanySettings");
        this.SetCollapsed(true);
        this.props.MenuSetCollapse(true);
        this.unselectItems();
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    openContactUS = () => {
        this.setState({ OpenContactUs: true });
    }
    closeContactUS = () => {
        this.setState({ OpenContactUs: false });
    }
    render() {
        var TraderUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Trader', this.props.UserPermissions);
        var ClientsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Clients', this.props.UserPermissions);
        var PartnersUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Partners', this.props.UserPermissions);
        var MAMUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('MAM', this.props.UserPermissions);
        var BackOfficeUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Back Office', this.props.UserPermissions);
        var BonusUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Bonus', this.props.UserPermissions);
        var OnBoardingUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('On Boarding', this.props.UserPermissions);
        var ReportsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Reports', this.props.UserPermissions);
        var NotesUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Notes', this.props.UserPermissions);
        var TasksUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Tasks', this.props.UserPermissions);
        var PortalSettingsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Portal Settings', this.props.UserPermissions);
        var DynamicMargin: UserPermissionOBjectClass = GetPermissionDetailsByName('Dynamic Margin', this.props.UserPermissions);
        let LogsPermission: UserPermissionOBjectClass = GetPermissionDetailsByName('Logs', this.props.UserPermissions);
        var ViewAccountTypes: boolean = CheckIfPermAlllowedByName('View Account Types', TraderUserPerms.Perms);
        var isA = this.props.userData.isAdmin;
        var isAdminBonus = isA && this.props.CompanyData.IsBonus;
        var isAdminIB = isA && this.props.CompanyData.IsIB;
        var isAdminMM = isA && this.props.CompanyData.IsMM;
        var isAdminCRM = isA && this.props.CompanyData.IsCRM;
        var isAdminDynamicMargin = isA && this.props.CompanyData.IsDynamicMargin;
        //Client
        //CRM
        var isClientCRMPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('CRM', this.props.UserPermissions);
        var isClientCRM = !isA && this.props.CompanyData.IsCRM && isClientCRMPermssion.Allowed;
        //IB
        var isClientIBPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('IB', this.props.UserPermissions);
        var isClientIB = !isA && this.props.CompanyData.IsIB && isClientIBPermssion.Allowed;
        var isPromotion: boolean = CheckIfPermAlllowedByName('Promotion', isClientIBPermssion.Perms);//if same name take from props name
        var isClientReporting: boolean = CheckIfPermAlllowedByName('Reporting', isClientIBPermssion.Perms);
        var isClientBreakDownReport: boolean = CheckIfPermAlllowedByName('Breakdown Report', isClientIBPermssion.Perms);
        var isClientAccessSubIB: boolean = CheckIfPermAlllowedByName('Access SubIB', isClientIBPermssion.Perms);
        //MAM
        var isClientMM = !isA && this.props.CompanyData.IsMM;//3 parents here
        //#region MAM parents 
        var isClientMoneyManager: UserPermissionOBjectClass = GetPermissionDetailsByName('Money Manager', this.props.UserPermissions);
        var isClientInvestorCopier: UserPermissionOBjectClass = GetPermissionDetailsByName('Investor/Copier', this.props.UserPermissions);
        var isClientSignalMAMPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Signal', this.props.UserPermissions);//copier
        //#endregion 
        var LeaderBoard: boolean = CheckIfPermAlllowedByName('LeaderBoard', isClientInvestorCopier.Perms);
        var isRequireKYC = this.isKYCRequired(isA);
        var isAccountExist = !isA && parseInt(String(this.props.ClientData.NmbrOfAcc), 10) > 0;
        var isHasKYC = !isA && this.props.ClientData.hasKYC;
        var isValid = !isA && (this.props.ClientData.IsValid ? true : false);//as may be null
        var isNotRequiredAndAccountExist = (!isRequireKYC && isAccountExist);
        var isRequiredKycSumbitedAndAccountExist = (isRequireKYC && isAccountExist && isHasKYC);
        var isRequiredKycAndSumbited = (isRequireKYC && isHasKYC);
        var AllowAddDemoAcc = !isA && this.props.ClientData?.AllowAddDemoAcc;
        let { BonusReportsPerm, CRMREports, IBReports, DLReports, BackofficeReports, MAMReports } = GetReportsPerms(ReportsUserPerms);
        let _OneOFReportsEnabled = OneOFReportsEnabled({ BonusReportsPerm, CRMREports, IBReports, DLReports, BackofficeReports, MAMReports });
        return (
            <>
                <div className="leftsidebarScreen">
                    <div className="h100vh-40  darkMenu2 continerMenu my-custom-scrollbar my-custom-scrollbar-primary border-rightGray">
                        <div className={this.state.collapsed ? "h100vh-120 upperMenu topbarBottom overflowhidden " : "h100vh-90 upperMenu topbarBottom "} >
                            <Menu
                                defaultSelectedKeys={[this.props.path]}
                                defaultOpenKeys={[this.state.subtab]}
                                mode="inline"
                                theme="dark"
                                openKeys={this.state.openKeys}
                                onOpenChange={this.onOpenChange}
                                inlineCollapsed={this.state.collapsed}
                            >
                                {(!isA && ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) && <Menu.Item key="/" className="DashboardClientMenu" onClick={this.closeALLMenus}>
                                    <Link to="/" className="titleMenu">
                                        <span className="titleMenu titleClienMenu">
                                            <span className={this.state.collapsed ? "dashboardIconClient ml-28" : "dashboardIconClient"}  >  <DashboardIcon className="DashboardClientIcon" /> </span>  <span className="DashboardMenu"> {this.props.t("Dashboard")} </span>
                                            {this.Pointer("/")}
                                        </span>
                                    </Link>
                                </Menu.Item>}

                                {((!isA && this.DisplayMyAccounts(isClientCRM, isRequireKYC, isValid)) && !this.isTNFX) &&
                                    this.RenderMyAccountsSubMenu(isClientCRM, isRequireKYC, isValid, AllowAddDemoAcc)
                                }
                                {((!isA && !this.isIconFx) && (!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))))) &&
                                    <Menu.Item key="/MyWallet" onClick={this.closeALLMenus} className="MyWalletSubMenu">
                                        <Link to="/MyWallet" className="titleMenu">

                                            <span className="titleMenu titleClienMenu">
                                                <i className={this.state.collapsed ? "MyWalletIcon fas fa-wallet mr-3 ml-19" : "MyWalletIcon fas fa-wallet mr-3"}></i> &nbsp;&nbsp;{this.props.t("MyWallet")}
                                                {this.Pointer("/MyWallet")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                {!isA && (this.DisplayOnbording(isRequireKYC)) && isClientCRM &&
                                    <SubMenu
                                        className="OnboardingSubMenu"
                                        key="/OnBoarding"
                                        title={
                                            <span>

                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "OnboardingIcon fas fa-layer-group mr-3 ml-19" : "OnboardingIcon fas fa-layer-group mr-3"}></i>  {this.props.t("OnBoarding")}
                                                </span>
                                            </span>

                                        }
                                    >

                                        {!isA && (this.DisplayOnbording(isRequireKYC)) &&
                                            //(applicationFormPerm) && !isA && hasKYCdminBonus &&
                                            <Menu.Item key="/OnBoarding/ApplicationForm" className="MenuItemsSub" >
                                                <Link to="/OnBoarding/ApplicationForm">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-file-signature mr-3 ml-19" : "fal fa-file-signature mr-3"}></i>      {this.props.t("Application")}
                                                        {this.Pointer("/OnBoarding/ApplicationForm")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }


                                    </SubMenu>
                                }
                                {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientIB)) &&
                                    (!this.isTNFX ? <SubMenu
                                        className="IBClientSubMenu"
                                        key="/IB"
                                        title={
                                            <span>
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "IBClientIcon fas fa-handshake mr-3 ml-19" : "IBClientIcon fas fa-handshake mr-3"}></i> {this.props.t("IB")}
                                                </span>
                                            </span>
                                        }
                                    >
                                        {this.RenderIBMenuItems(isClientIB, isClientAccessSubIB, isClientReporting, isClientBreakDownReport, isPromotion, true)}
                                    </SubMenu> : this.RenderIBMenuItems(isClientIB, isClientAccessSubIB, isClientReporting, isClientBreakDownReport, isPromotion, false))
                                }
                                {(!isA && (!isClientCRM && (isClientMM && isClientMoneyManager.Allowed)) || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) &&
                                    this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && < SubMenu
                                        className="PortfolioSubMenu"
                                        key="/Portfolio"
                                        title={
                                            <span>
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "PortfolioIcon fas fa-folder-open mr-3 ml-19" : "PortfolioIcon fas fa-folder-open  mr-3"}></i> &nbsp;{this.ReturnPortfolioName()}
                                                </span>
                                            </span>
                                        }
                                    >
                                        {(isClientMM && isClientMoneyManager.Allowed) &&
                                            <Menu.Item key="/Portfolio/Investors" className="MenuItemsSub">
                                                <Link to="/Portfolio/Investors">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-hand-holding-usd mr-3 ml-19" : "fal fa-hand-holding-usd  mr-3"}></i>
                                                        {this.props.t("Investors")}
                                                        {this.Pointer("/Portfolio/Investors")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isClientMM && isClientMoneyManager.Allowed) &&
                                            <Menu.Item key="/Portfolio/InvestorsTracker" className="MenuItemsSub">
                                                <Link to="/Portfolio/InvestorsTracker">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-search-location mr-3 ml-19" : "fal fa-search-location mr-3"}></i>
                                                        {this.props.t("InvestorsTracker")}
                                                        {this.Pointer("/Portfolio/InvestorsTracker")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isClientMM && isClientMoneyManager.Allowed) && <Menu.Item key="/Portfolio/PositionSummary" className="MenuItemsSub">
                                            <Link to="/Portfolio/PositionSummary">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-invoice mr-3 ml-19" : "fal fa-file-invoice mr-3"}></i>
                                                    {this.props.t("OpenPositionSummary")} {/*@TODO*/}
                                                    {this.Pointer("/Portfolio/PositionSummary")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        <Menu.Item key="/Portfolio/HistorySummary" className="MenuItemsSub">
                                            <Link to="/Portfolio/HistorySummary">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-alt mr-3 ml-19" : "fal fa-file-alt mr-3"}></i>
                                                    {this.props.t("TradeHistorySummary")}
                                                    {this.Pointer("/Portfolio/HistorySummary")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                        {(isClientMM && isClientMoneyManager.Allowed) &&
                                            <Menu.Item key="/Portfolio/PerformanceTracker" className="MenuItemsSub">
                                                <Link to="/Portfolio/PerformanceTracker">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-chart-area mr-3 ml-19" : "fal fa-chart-area mr-3"}></i>
                                                        {this.props.t("FeesPerformanceTracker")}
                                                        {this.Pointer("/Portfolio/PerformanceTracker")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isClientMM && isClientMoneyManager.Allowed) &&
                                            <Menu.Item key="/Portfolio/MonthlyFees" className="MenuItemsSub" >
                                                <Link to="/Portfolio/MonthlyFees">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-calendar-check mr-3 ml-19" : "fal fa-calendar-check mr-3"}></i>&nbsp;{this.props.t("MonthlyFees")}
                                                        {this.Pointer("/Portfolio/MonthlyFees")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                    </SubMenu>
                                }
                                {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientMM && isClientSignalMAMPerms.Allowed)) &&
                                    this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && <Menu.Item key="/Copiers" onClick={this.closeALLMenus} className="CopiersSubMenu">
                                        <Link to="/Copiers" className="titleMenu ">
                                            <span className="titleMenu titleClienMenu">
                                                <i className={this.state.collapsed ? "CopiersIcon fas fa-comment-dollar mr-3 ml-19" : "CopiersIcon fas fa-comment-dollar mr-3"}></i>&nbsp;{this.props.t("Copiers")}
                                                {this.Pointer("/Copiers")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                }
                                {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientMM && LeaderBoard)) &&
                                    <Menu.Item key="/LeaderBoard" onClick={this.closeALLMenus} className="LeaderBoardSubMenu">
                                        <Link to="/LeaderBoard" className="titleMenu ">
                                            <span className="titleMenu titleClienMenu">
                                                <i className={this.state.collapsed ? "LeaderBoardIcon fas fa-satellite-dish mr-3 ml-19" : "LeaderBoardIcon fas fa-satellite-dish mr-3"}></i>&nbsp;{this.props.t("LeaderBoard")}
                                                {this.Pointer("/LeaderBoard")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                }
                                {(!isA && ["plugit", "capstock"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && < Menu.Item key="/AnalystView" onClick={this.closeALLMenus} className="LeaderBoardSubMenu">
                                    <Link to="/AnalystView" className="titleMenu ">
                                        <span className="titleMenu titleClienMenu">
                                            <i className={this.state.collapsed ? "LeaderBoardIcon fad fa-chart-bar mr-3 ml-19" : "LeaderBoardIcon fad fa-chart-bar mr-3"}></i>&nbsp;{this.props.t("AnalystView")}
                                            {this.Pointer("/AnalystView")}
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {(!isA && ["plugit", "finzolo", "iqx"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && < Menu.Item key="/WebTerminal" onClick={this.closeALLMenus} className="LeaderBoardSubMenu">
                                    <Link to="/WebTerminal" className="titleMenu ">
                                        <span className="titleMenu titleClienMenu">
                                            <i className={this.state.collapsed ? "LeaderBoardIcon fas fa-terminal mr-3 ml-19" : "LeaderBoardIcon fas fa-terminal mr-3"}></i>&nbsp;{this.props.t("WebTerminal")}
                                            {this.Pointer("/WebTerminal")}
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {(!isA && ["plugit", "marketequity"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && < Menu.Item key="/WebTrader" onClick={this.closeALLMenus} className="LeaderBoardSubMenu">
                                    <Link to="/WebTrader" className="titleMenu ">
                                        <span className="titleMenu titleClienMenu">
                                            <i className={this.state.collapsed ? "LeaderBoardIcon fas fa-terminal mr-3 ml-19" : "LeaderBoardIcon fas fa-terminal mr-3"}></i>&nbsp;{this.props.t("WebTrader")}
                                            {this.Pointer("/WebTrader")}
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {!isA && (CheckIfCompanyIs("TiranForex") || CheckIfCompanyIs("PLUGIT")) && <Menu.Item key="/MarketCalendar" onClick={this.closeALLMenus} className="LeaderBoardSubMenu">
                                    <Link to="/MarketCalendar" className="titleMenu ">
                                        <span className="titleMenu titleClienMenu">
                                            <i className={this.state.collapsed ? "LeaderBoardIcon fas fa-terminal mr-3 ml-19" : "LeaderBoardIcon fas fa-terminal mr-3"}></i>&nbsp;{this.props.t("MarketCalendar")}
                                            {this.Pointer("/MarketCalendar")}
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && ((isClientMM && (isClientMoneyManager.Allowed || isClientInvestorCopier.Allowed || isClientSignalMAMPerms.Allowed)) || isClientCRM)) &&
                                    this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && <SubMenu
                                        className="AnalyticsSubMenu"
                                        key="/Analytics"
                                        title={
                                            <span>
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "AnalyticsIcon fas fa-chart-pie mr-3 ml-19" : "AnalyticsIcon fas fa-chart-pie mr-3"}></i>&nbsp;{this.props.t("Analytics")}
                                                </span>
                                            </span>
                                        }
                                    >
                                        <Menu.Item key="/Analytics/Overview" className="MenuItemsSub">
                                            <Link to="/Analytics/Overview">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-chart-line mr-3 ml-19" : "fal  fa-chart-line mr-3"}></i>
                                                    {this.props.t("Overview")}
                                                    {this.Pointer("/Analytics/Overview")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/Analytics/trades" className="MenuItemsSub">
                                            <Link to="/Analytics/trades">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-exchange-alt mr-3 ml-19" : "fal fa-exchange-alt mr-3"}></i>
                                                    {this.props.t("Trades")}
                                                    {this.Pointer("/Analytics/trades")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/Analytics/RiskMeasures" className="MenuItemsSub">
                                            <Link to="/Analytics/RiskMeasures">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-exclamation-triangle mr-3 ml-19" : "fal fa-exclamation-triangle mr-3"}></i>
                                                    {this.props.t("RiskMeasures")}
                                                    {this.Pointer("/Analytics/RiskMeasures")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="/Analytics/HistoricalPerformance" className="MenuItemsSub">
                                            <Link to="/Analytics/HistoricalPerformance">
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-history mr-3 ml-19" : "fal fa-history mr-3"}></i>&nbsp;{this.props.t("HistoricalPerformance")}
                                                    {this.Pointer("/Analytics/HistoricalPerformance")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    </SubMenu>
                                }
                                {(!isA && (this.checkIfAllowedForPureNorthLogs() || this.isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist))))) &&
                                    <SubMenu
                                        className="LogsSubMenu"
                                        key="/Logs"
                                        title={
                                            <span>
                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "LogsIcon fas fa-history mr-3 ml-19" : "LogsIcon fas fa-history mr-3"}></i>&nbsp;{this.props.t("Logs")}
                                                </span>
                                            </span>
                                        }
                                    > <Menu.Item key="/Logs/AccountLogs" className="MenuItemsSub">
                                            <Link to="/Logs/AccountLogs">

                                                <span className="titleMenu titleClienMenu">
                                                    <i className={this.state.collapsed ? "fal fa-exchange-alt mr-3 ml-19" : "fal fa-exchange-alt mr-3"}></i> {this.props.t("AccountLogs")}
                                                    {this.Pointer("/Logs/AccountLogs")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                        {(this.isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) &&
                                            <Menu.Item key="/Logs/TransactionsLogs" className="MenuItemsSub" >
                                                <Link to="/Logs/TransactionsLogs">
                                                    <span className="titleMenu titleClienMenu">
                                                        <i className={this.state.collapsed ? "fal fa-search-dollar mr-3 ml-19" : "fal fa-search-dollar mr-3"}></i>     {this.props.t("TransactionsLogs")}
                                                        {this.Pointer("/Logs/TransactionsLogs")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                    </SubMenu>
                                }
                                {(!isA && this.isElite) && <Menu.Item key="/Platforms" className="DashboardClientMenu" onClick={this.closeALLMenus}>
                                    <Link to="/Platforms" className="titleMenu">
                                        <span className="titleMenu titleClienMenu">
                                            <span className={this.state.collapsed ? "dashboardIconClient ml-28" : "dashboardIconClient"}  >  <LinkIcon className="DashboardClientIcon" /> </span>  <span className="DashboardMenu"> {this.props.t("Platforms")} </span>
                                            {this.Pointer("/Platforms")}
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {((!isA && this.DisplayMyAccounts(isClientCRM, isRequireKYC, isValid)) && this.isTNFX) &&
                                    this.RenderMyAccountsSubMenu(isClientCRM, isRequireKYC, isValid, AllowAddDemoAcc)
                                }
                                {isA &&
                                    (isAdminCRM || (isAdminMM) || isAdminIB || isAdminDynamicMargin) && <Menu.Item key="/" onClick={this.closeALLMenus} className="DashboardMenuTitle">
                                        <Link to="/" className="titleMenu">
                                            <span className="titleMenu titleClienMenu">
                                                <span className={this.state.collapsed ? "dashboardIcon ml-28" : "dashboardIcon"}  > <DashboardIcon className="dashboardIcon" />   </span>  <span className="DashboardMenu">  {this.props.t("Dashboard")}  </span>
                                                {this.Pointer("/")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                }
                                {(isA && (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && ClientsUserPerms.Allowed) &&
                                    <SubMenu
                                        className="ClientsSubMenu"
                                        key="/Clients"
                                        title={
                                            <span>

                                                <span className="titleMenu ClientsMenuTitle">
                                                    <i className={this.state.collapsed ? "fas fa-users clientsIcon mr-3 ml-28" : " fas fa-users clientsIcon"}></i>{this.props.t("Clients")}
                                                </span>
                                            </span>

                                        }
                                    >
                                        {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/ClientPage" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/ClientPage">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-user mr-3 ml-19" : "fal fa-user mr-3"}></i> &nbsp;&nbsp;{this.props.t("Clients")}
                                                    {this.Pointer("/Clients/ClientPage")}
                                                </span>

                                            </Link>
                                        </Menu.Item>}
                                        {(isA && (isAdminIB || (isAdminMM) || isAdminCRM)) && <Menu.Item key="/Clients/Wallet" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/Wallet">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-wallet mr-3 ml-19" : "fal fa-wallet mr-3"}></i>&nbsp;&nbsp;{this.props.t("Wallet")}
                                                    {this.Pointer("/Clients/Wallet")}
                                                </span>

                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/DeletedClients" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/DeletedClients" >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-user-minus mr-3 ml-19" : "fal fa-user-minus mr-3"}></i>&nbsp;{this.props.t("DeletedClients")}
                                                    {this.Pointer("/Clients/DeletedClients")}
                                                </span>


                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminCRM || isAdminIB) && <Menu.Item key="/Clients/Leads" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/Leads">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-user-check mr-3 ml-19" : "fal fa-user-check mr-3"}></i>   {this.props.t("Leads")}
                                                    {this.Pointer("/Clients/Leads")}

                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/Contact" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/Contact">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-address-book mr-3 ml-19" : "fal fa-address-book mr-3"}></i> &nbsp;{this.props.t("Contacts")}
                                                    {this.Pointer("/Clients/Contact")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/Pools" className="MenuItemsSub ClientsMenuTitle">
                                            <Link to="/Clients/Pools">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-funnel-dollar mr-3 ml-19" : "fal fa-funnel-dollar mr-3"}></i>&nbsp;{this.props.t("Pools")}
                                                    {this.Pointer("/Clients/Pools")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                    </SubMenu>
                                }
                                {isAdminCRM && isA && OnBoardingUserPerms.Allowed &&
                                    <SubMenu
                                        className="OnBoardingSubMenu"
                                        key="/OnBoarding"
                                        title={
                                            <span>
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-layer-group onboardingIcon mr-3 ml-28" : "fas fa-layer-group onboardingIcon"} ></i> {this.props.t("OnBoarding")}
                                                </span>
                                            </span>

                                        }
                                    >
                                        {isAdminCRM && <Menu.Item key="/OnBoarding/Application" className="MenuItemsSub OnBoardingMenuTitle">
                                            <Link to="/OnBoarding/Application">

                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-signature onboardingIcon mr-3 ml-28" : "fal fa-file-signature onboardingIcon mr-3"} ></i>   {this.props.t("Application")}
                                                </span>
                                                {this.Pointer("/OnBoarding/Application")}
                                            </Link>
                                        </Menu.Item>}

                                    </SubMenu>
                                }
                                {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && TraderUserPerms.Allowed && < SubMenu
                                    className="TraderSubMenu"
                                    key="/Trader"
                                    title={
                                        <span>
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fas fa-exchange-alt traderIcon mr-3 ml-28" : "fas fa-exchange-alt traderIcon"} ></i>{this.props.t("Trader")}
                                            </span>
                                        </span>
                                    }
                                >  <Menu.Item key="/Trader/TradingAccounts" className="MenuItemsSub TraderMenuTitle">
                                        <Link to="/Trader/TradingAccounts">
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-chart-line traderIcon mr-3 ml-28" : "fal fa-chart-line traderIcon mr-3"} ></i>&nbsp;{this.props.t("TradingAccounts")}
                                                {this.Pointer("/Trader/TradingAccounts")}
                                            </span>

                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/Trader/DemoAccounts" className="MenuItemsSub TraderMenuTitle">
                                        <Link to="/Trader/DemoAccounts">
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-random traderIcon mr-3 ml-28" : "fal fa-random traderIcon mr-3"} ></i>&nbsp;{this.props.t("DemoAccounts")}
                                                {this.Pointer("/Trader/DemoAccounts")}
                                            </span>

                                        </Link>
                                    </Menu.Item>
                                    {ViewAccountTypes && <Menu.Item key="/Trader/AccountTypes" className="MenuItemsSub TraderMenuTitle">
                                        <Link to="/Trader/AccountTypes">
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-users-cog traderIcon mr-3 ml-28" : "fal fa-users-cog traderIcon mr-3"} ></i> {this.props.t("AccountTypes")}
                                                {this.Pointer("/Trader/AccountTypes")}
                                            </span>

                                        </Link>
                                    </Menu.Item>}
                                </SubMenu>}
                                {(isAdminIB) && isA && PartnersUserPerms.Allowed &&
                                    <SubMenu
                                        className="IBAdminSubMenu"
                                        key="/IBAdmin"
                                        title={
                                            <span >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-handshake partnersIcon mr-3 ml-28" : "fas fa-handshake partnersIcon"} ></i> {this.props.t("Partners")}
                                                </span>
                                            </span>
                                        }
                                    >
                                        {(isAdminIB) && <Menu.Item key="/IBAdmin/IBAllocation" className="MenuItemsSub PartnersMenuTitle">
                                            <Link to="/IBAdmin/IBAllocation" >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-cogs mr-3 ml-19" : "fal fa-cogs mr-3"}></i>&nbsp;{this.props.t("IBAllocations")}
                                                    {this.Pointer("/IBAdmin/IBAllocation")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminIB) && <Menu.Item key="/IBAdmin/ProfileTemplates" className="MenuItemsSub PartnersMenuTitle">
                                            <Link to="/IBAdmin/ProfileTemplates">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-address-card mr-3 ml-19" : "fal fa-address-card mr-3"}></i>  {this.props.t("ProfileTemplates")}
                                                    {this.Pointer("/IBAdmin/ProfileTemplates")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminIB) &&

                                            <Menu.Item key="/IBAdmin/CampaignLinks" className="MenuItemsSub PartnersMenuTitle">
                                                <Link to="/IBAdmin/CampaignLinks">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-link mr-3 ml-19" : "fal fa-link mr-3"}></i> &nbsp;{this.props.t("CampaignLinks")}
                                                        {this.Pointer("/IBAdmin/CampaignLinks")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>}

                                    </SubMenu>
                                }

                                {(isAdminMM) && isA && MAMUserPerms.Allowed &&
                                    <SubMenu
                                        className="MAMSubMenu"
                                        key="/MAM"
                                        title={
                                            <span >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-users-cog MAMIcon mr-3 ml-28" : "fas fa-users-cog MAMIcon"} ></i>  MAM
                                                </span>
                                            </span>
                                        }
                                    >
                                        {(isAdminMM) &&
                                            <Menu.Item key="/MAM/MamAllocations" className="MenuItemsSub MAMMenuTitle">
                                                <Link to="/MAM/MamAllocations">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-hand-holding-usd mr-3 ml-19" : "fal fa-hand-holding-usd mr-3"}></i>
                                                        {this.props.t("MamAllocations")}
                                                        {this.Pointer("/MAM/MamAllocations")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isAdminMM) && <Menu.Item key="/MAM/SignalAllocations" className="MenuItemsSub MAMMenuTitle">
                                            <Link to="/MAM/SignalAllocations">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-comment-dollar mr-3 ml-19" : "fal fa-comment-dollar mr-3"}></i> {this.props.t("SignalAllocations")}
                                                    {this.Pointer("/MAM/SignalAllocations")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                    </SubMenu>
                                }
                                {(isA && (isAdminCRM || (isAdminMM) || isAdminIB) && BackOfficeUserPerms.Allowed) &&
                                    <SubMenu
                                        className="BackOfficeSubMenu"
                                        key="/BackOffice"
                                        title={
                                            <span>
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-laptop-code BackofficeIcon mr-3 ml-28" : "fas fa-laptop-code BackofficeIcon"} ></i> {this.props.t("BackOffice")}
                                                </span>
                                            </span>

                                        }
                                    >
                                        {(isAdminCRM || isAdminMM || isAdminIB) && <Menu.Item key="/BackOffice/OperationRequest" className="MenuItemsSub BackOfficeMenuTitle">
                                            <Link to="/BackOffice/OperationRequest">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-code mr-3 ml-19" : "fal fa-file-code mr-3"}></i>   &nbsp;{this.props.t("OperationRequests")}
                                                    {this.Pointer("/BackOffice/OperationRequest")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminCRM && OnBoardingUserPerms.Allowed) && <Menu.Item key="/BackOffice/ApplicationRequest" className="MenuItemsSub BackOfficeMenuTitle">
                                            <Link to="/BackOffice/ApplicationRequest">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-user-clock mr-3 ml-19" : "fal fa-user-clock mr-3"}></i>    {this.props.t("ApplicationRequest")}
                                                    {this.Pointer("/BackOffice/ApplicationRequest")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}

                                    </SubMenu>
                                }
                                {(isAdminBonus) && BonusUserPerms.Allowed && <SubMenu
                                    className="BonusSubMenu"
                                    key="/Bonus"
                                    title={
                                        <span>
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fas fa-gifts BonusIcon mr-3 ml-28" : "fas fa-gifts BonusIcon"} ></i>{this.props.t("Bonus")}
                                            </span>
                                        </span>

                                    }
                                > <Menu.Item key="/Bonus/Promotion" className="MenuItemsSub BonusMenuTitle">
                                        <Link to="/Bonus/Promotion">
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-bullhorn mr-3 ml-19" : "fal fa-bullhorn mr-3"}></i> {this.props.t("Promotion")}
                                                {this.Pointer("/Bonus/Promotion")}
                                            </span>

                                        </Link>
                                    </Menu.Item>
                                </SubMenu>}
                                {(isAdminDynamicMargin) && DynamicMargin.Allowed && < SubMenu
                                    className="DynamicMarginSubMenu"
                                    key="/DynamicMargin"
                                    title={
                                        <span>
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fas fa-balance-scale-left dynamicIcon mr-3 ml-28" : "fas fa-balance-scale-left dynamicIcon"} ></i> {this.props.t("DynamicMargin")}
                                            </span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="/DynamicMargin/SymbolProfile" className="MenuItemsSub DynamicMarginMenuTitle">
                                        <Link to="/DynamicMargin/SymbolProfile">
                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-coins mr-3 ml-19" : "fal fa-coins mr-3"}></i>
                                                &nbsp;{this.props.t("SymbolProfile")}
                                                {this.Pointer("/DynamicMargin/SymbolProfile")}
                                            </span>

                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/DynamicMargin/AccountProfile" className="MenuItemsSub DynamicMarginMenuTitle">
                                        <Link to="/DynamicMargin/AccountProfile">

                                            <span className="titleMenu">
                                                <i className={this.state.collapsed ? "fal fa-user-clock mr-3 ml-19" : "fal fa-user-clock mr-3"}></i>
                                                {this.props.t("AccountProfile")}
                                                {this.Pointer("/DynamicMargin/AccountProfile")}
                                            </span>

                                        </Link>
                                    </Menu.Item>
                                </SubMenu>}
                                {((isA && (isAdminCRM || (isAdminMM) || isAdminIB || isAdminBonus || isAdminDynamicMargin)) && ReportsUserPerms.Allowed && !!_OneOFReportsEnabled) &&
                                    <SubMenu
                                        className="ReportsSubMenu"
                                        key="/Reports"
                                        title={
                                            <span>
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-chalkboard-teacher reportsIcon mr-3 ml-28" : "fas fa-chalkboard-teacher reportsIcon"} ></i>
                                                    {this.props.t("Reports")}
                                                </span>
                                            </span>

                                        }
                                    >
                                        {((isAdminIB && IBReports) || (isAdminMM && MAMReports) || (isAdminCRM && CRMREports)) &&
                                            < Menu.Item key="/Reports/Transactions" className="MenuItemsSub ReportsMenuTitle">
                                                <Link to="/Reports/Transactions" >
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-search-dollar mr-3 ml-19" : "fal fa-search-dollar mr-3"}></i>&nbsp;&nbsp;{this.props.t("Wallet")}  {this.props.t("Transactions")}
                                                        {this.Pointer("/Reports/Transactions")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>}
                                        {(isAdminMM && MAMReports) && <Menu.Item key="/Reports/PerformanceFees" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/PerformanceFees" >

                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-money-check-alt mr-3 ml-19" : "fal fa-money-check-alt mr-3"}></i>&nbsp;{this.props.t("PerformanceFees")}
                                                    {this.Pointer("/Reports/PerformanceFees")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminMM && MAMReports) && <Menu.Item key="/Reports/SignalsPerformanceFees" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/SignalsPerformanceFees" >

                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-money-check-alt mr-3 ml-19" : "fal fa-money-check-alt mr-3"}></i>&nbsp;{this.props.t("SignalsPerformanceFees", { defaultValue: "Signals Performance Fees" })}
                                                    {this.Pointer("/Reports/SignalsPerformanceFees")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminIB && IBReports) && <Menu.Item key="/Reports/IBDetailedReport" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/IBDetailedReport" >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-invoice mr-3 ml-19" : "fal fa-file-invoice mr-3"}></i>&nbsp;&nbsp;&nbsp;{this.props.t("IBDetailedReport")}
                                                    {this.Pointer("/Reports/IBDetailedReport")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminIB && IBReports) && <Menu.Item key="/Reports/CPAReport" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/CPAReport" >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-donate mr-3 ml-19" : "fal fa-donate mr-3"}></i>&nbsp;&nbsp;{this.props.t("CPAReport")}
                                                    {this.Pointer("/Reports/CPAReport")}
                                                </span>
                                            </Link>

                                        </Menu.Item>}
                                        {(isAdminIB && IBReports) && <Menu.Item key="/Reports/BreakdownReport" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/BreakdownReport" >
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-invoice mr-3 ml-19" : "fal fa-file-invoice mr-3"}></i>&nbsp;&nbsp;&nbsp;{this.props.t("Breakdown")}
                                                    {this.Pointer("/Reports/BreakdownReport")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminIB && false) && <Menu.Item key="/Reports/ClicksConversion" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/ClicksConversion" >

                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-comment-dollar mr-3 ml-19" : "fal fa-comment-dollar mr-3"}></i>&nbsp;{this.props.t("ClicksConversion")}
                                                    {this.Pointer("/Reports/ClicksConversion")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminCRM && CRMREports) && <Menu.Item key="/Reports/DocumentsExpiration" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/DocumentsExpiration">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-calendar-times mr-3 ml-19" : "fal fa-calendar-times mr-3"}></i>&nbsp;&nbsp;{this.props.t("DocumentsExpiration")}
                                                    {this.Pointer("/Reports/DocumentsExpiration")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {((isAdminCRM || (isAdminMM) || isAdminIB) && CRMREports) && < Menu.Item key="/Reports/RegistrationLogs" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/RegistrationLogs">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-contract mr-3 ml-19" : "fal fa-file-contract mr-3"}></i>&nbsp;&nbsp;{this.props.t("RegistrationLogs")}
                                                    {this.Pointer("/Reports/RegistrationLogs")}

                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {((isAdminCRM || (isAdminMM) || isAdminIB) && BackofficeReports) && <Menu.Item key="/Reports/OperationLogs" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/OperationLogs">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-invoice mr-3 ml-19" : "fal fa-file-invoice mr-3"}></i>&nbsp;&nbsp;{this.props.t("OperationLogs")}
                                                    {this.Pointer("/Reports/OperationLogs")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminCRM && CRMREports) && <Menu.Item key="/Reports/closetrades" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/closetrades">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-compress-arrows-alt mr-3 ml-19" : "fal fa-compress-arrows-alt mr-3"}></i>&nbsp;{this.props.t('ClosedTrades')}
                                                    {this.Pointer("/Reports/closetrades")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminDynamicMargin && DLReports) &&
                                            <Menu.Item key="/Reports/DynamicReport" className="MenuItemsSub ReportsMenuTitle">
                                                <Link to="/Reports/DynamicReport">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-balance-scale-left mr-3 ml-19" : "fal fa-balance-scale-left mr-3"}></i> {this.props.t('DynamicMargin')}
                                                        {this.Pointer("/Reports/DynamicReport")}

                                                    </span>
                                                </Link>
                                            </Menu.Item>}
                                        {(isAdminBonus && BonusReportsPerm) &&
                                            <Menu.Item key="/Reports/BonusReport" className="MenuItemsSub ReportsMenuTitle">
                                                <Link to="/Reports/BonusReport">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-gifts mr-3 ml-19" : "fal fa-gifts mr-3"}></i>   {this.props.t('BonusReport')}
                                                        {this.Pointer("/Reports/BonusReport")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>}
                                        {(isAdminBonus && BonusReportsPerm) && <Menu.Item key="/Reports/BonusReferralReport" className="MenuItemsSub ReportsMenuTitle">
                                            <Link to="/Reports/BonusReferralReport">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-code mr-3 ml-19" : "fal fa-file-code mr-3"}></i>  &nbsp;&nbsp;{this.props.t('BonusReferralReport')}
                                                    {this.Pointer("/Reports/BonusReferralReport")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                    </SubMenu>
                                }
                                {(isA && (isAdminMM || isAdminCRM) && ReportsUserPerms.Allowed) && //all under MAM
                                    <SubMenu
                                        className="AnalyticsSubMenu"
                                        key="/Analytics"
                                        title={
                                            <span>
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fas fa-chart-pie analyticsIcon mr-3 ml-28" : "fas fa-chart-pie analyticsIcon"} ></i>{this.props.t("Analytics")}
                                                </span>
                                            </span>

                                        }
                                    >
                                        {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/Overview" className="MenuItemsSub AnalyticsMenuTitle">
                                            <Link to="/Analytics/Overview">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-chart-line mr-3 ml-19" : "fal fa-chart-line mr-3"}></i>&nbsp;{this.props.t("Overview")}
                                                    {this.Pointer("/Analytics/Overview")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/Trades" className="MenuItemsSub AnalyticsMenuTitle">
                                            <Link to="/Analytics/Trades">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-exchange-alt mr-3 ml-19" : "fal  fa-exchange-alt mr-3"}></i>&nbsp;{this.props.t("Trades")}
                                                    {this.Pointer("/Analytics/Trades")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/RiskMeasures" className="MenuItemsSub AnalyticsMenuTitle">
                                            <Link to="/Analytics/RiskMeasures">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-exclamation-triangle mr-3 ml-19" : "fal fa-exclamation-triangle mr-3"}></i>&nbsp;{this.props.t("RiskMeasures")}
                                                    {this.Pointer("/Analytics/RiskMeasures")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/HistoricalPerformance" className="MenuItemsSub AnalyticsMenuTitle">
                                            <Link to="/Analytics/HistoricalPerformance">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-history mr-3 ml-19" : "fal fa-history mr-3"}></i>&nbsp;{this.props.t('HistoricalPerformance')}
                                                    {this.Pointer("/Analytics/HistoricalPerformance")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                        {(isAdminMM) &&
                                            <Menu.Item key="/Analytics/InvestorsReport" className="MenuItemsSub AnalyticsMenuTitle">
                                                <Link to="/Analytics/InvestorsReport">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-hand-holding-usd mr-3 ml-19" : "fal fa-hand-holding-usd mr-3"}></i>  {this.props.t("Investors")}
                                                        {this.Pointer("/Analytics/InvestorsReport")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isAdminMM) &&
                                            <Menu.Item key="/Analytics/InvestorsTracker" className="MenuItemsSub AnalyticsMenuTitle">
                                                <Link to="/Analytics/InvestorsTracker">
                                                    <span className="titleMenu">
                                                        <i className={this.state.collapsed ? "fal fa-search-location mr-3 ml-19" : "fal fa-search-location  mr-3"}></i>   {this.props.t("InvestorsTracker")}
                                                        {this.Pointer("/Analytics/InvestorsTracker")}
                                                    </span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                        {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/TradeHistorySummary" className="MenuItemsSub AnalyticsMenuTitle">
                                            <Link to="/Analytics/TradeHistorySummary">
                                                <span className="titleMenu">
                                                    <i className={this.state.collapsed ? "fal fa-file-alt mr-3 ml-19" : "fal fa-file-alt  mr-3"}></i>    {this.props.t("TradeHistorySummary")}
                                                    {this.Pointer("/Analytics/TradeHistorySummary")}
                                                </span>
                                            </Link>
                                        </Menu.Item>}
                                    </SubMenu>
                                }
                                {(isA && LogsPermission.Allowed) &&
                                    <Menu.Item key="/LogsReport" onClick={this.closeALLMenus} className="LogsAdminMenuTitle">
                                        <Link to="/LogsReport" className="titleMenu">
                                            <span >
                                                <i className={this.state.collapsed ? "fas fa-history logsIcon mr-3 ml-28" : "fas fa-history logsIcon"} ></i>{this.props.t("Logs")}
                                                {this.Pointer("/LogsReport")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                }

                                {isA && NotesUserPerms.Allowed && <Menu.Item key="/Notes" onClick={this.closeALLMenus} className="NotesMenuTitle">
                                    <Link to="/Notes" className="titleMenu">
                                        <span >
                                            <i className={this.state.collapsed ? "fas fa-clipboard notesIcon mr-3 ml-28" : "fas fa-clipboard notesIcon"} ></i>{this.props.t("Notes")}
                                            {this.Pointer("/Notes")}
                                        </span>
                                    </Link>
                                </Menu.Item>
                                }
                                {isA && TasksUserPerms.Allowed && <Menu.Item key="/Tasks" onClick={this.closeALLMenus} className="TasksMenuTitle">
                                    <Link to="/Tasks" className="titleMenu">
                                        <span >
                                            <i className={this.state.collapsed ? "fas fa-tasks tasksIcon mr-3 ml-28" : "fas fa-tasks tasksIcon"} ></i>{this.props.t("Tasks")}
                                            {this.Pointer("/Tasks")}
                                        </span>
                                    </Link>
                                </Menu.Item>
                                }
                                <Menu.Item id="closeAll" key="closeAll" onClick={this.closeALLMenus} hidden>
                                    close All  for project setting to work
                                </Menu.Item>
                            </Menu>
                            <div className={this.state.collapsed ? "white-bg  white-c bottomMenu20 h34 pt5 mb-4" : "white-bg  white-c bottomMenu8 h34 pt5"} >
                                <Row md={12}>
                                    <Col md={10}>
                                        {!isA &&
                                            <div onClick={this.openContactUS}>
                                                {!this.state.collapsed && <span className={this.state.selectedSetting ? " TextOurGrey posFixed mt-8p cursor-pointer ContactusColor" : " posFixed text-Grey mt-8p cursor-pointer ContactusColor"}>
                                                    <i className={this.state.collapsed ? "fas fa-envelope-open-text portalIcons mr-3 ml-28" : "fas fa-envelope-open-text portalIcons"}></i> <span className="portalTitle"> {this.props.t("ContactUs")} </span> </span>}
                                                {this.state.collapsed && <span className={this.state.selectedSetting ? " TextOurGrey posFixed mt-8p cursor-pointer ContactusColor2" : " text-Grey posFixed mt-8p cursor-pointer ContactusColor2"}>
                                                    <i className={this.state.collapsed ? "fas fa-envelope-open-text portalIcons2 mr-3 ml-28" : "fas fa-envelope-open-text portalIcons2 mr-3"}></i>
                                                </span>}
                                            </div>
                                        }
                                        {isA && PortalSettingsUserPerms.Allowed &&
                                            <div onClick={this.navigatetoSettings}>
                                                {!this.state.collapsed && <span className={this.state.selectedSetting ? " TextOurGrey posFixed mt-8p cursor-pointer ContactusColor" : " posFixed text-Grey mt-8p cursor-pointer ContactusColor"}>
                                                    <i className={this.state.collapsed ? "fas fa-cog portalIcons mr-3 ml-28" : "fas fa-cog portalIcons"}></i> <span className="portalTitle"> {this.props.t("PortalSettings")} </span> </span>}
                                                {this.state.collapsed && <span className={this.state.selectedSetting ? " TextOurGrey posFixed mt-8p cursor-pointer ContactusColor2" : " text-Grey posFixed mt-8p cursor-pointer ContactusColor2"}>
                                                    <i className={this.state.collapsed ? "fas fa-cog portalIcons2 mr-3 ml-28" : "fas fa-cog portalIcons2 mr-3"}></i>
                                                </span>}
                                            </div>
                                        }
                                    </Col>
                                    {!isA &&
                                        <>
                                            {
                                                this.state.OpenContactUs &&
                                                <ContactUs
                                                    OpenContactUs={this.state.OpenContactUs}
                                                    closeContact={this.closeContact}
                                                />
                                            }
                                        </>
                                    }

                                    <Col md={3}>
                                        <div onClick={this.toggleCollapsed}>
                                            <i className={this.state.collapsed ? "fas fa-chevron-double-left maximizeIcon bottomMenu40 menuItemsToggle downMenu transform180 bottom-55 cursor-pointer mb-1 ml-4" : "fas fa-chevron-double-left maximizeIcon bottomMenu40  menuItemsToggle downMenu left230  cursor-pointer mb-1"}></i>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div >
                </div>
            </>
        );
    }
    Pointer = (id: string) => {
        return <div id={`${id}`} style={{ float: "left", clear: "both" }}></div>;
    }
    checkIfAllowedForPureNorthMAM = (isClientMM: boolean, isClientMoneyManager: UserPermissionOBjectClass) => {
        return (!CheckIfCompanyIs("PureNorth") || (isClientMM && isClientMoneyManager.Allowed));
    }
    checkIfAllowedForPureNorthLogs = () => {
        return CheckIfCompanyIs("PureNorth");
    }

    isKYCRequired = (isA: boolean) => {
        if (this.isElite) return false;
        if (this.isOrbit) return false;
        return !isA && this.props.ClientData.RequireKYC;
    }

    ReturnPortfolioName = () => {
        if (this.isElite || this.isIconFx) return this.props.t("Portfolio");//TODO check if need to add check if MAM
        return `${this.props.t("MAM")} ${this.props.t("Portfolio")}`;
    }
    DisplayOnbording = (isRequireKYC: boolean) => {
        if (this.isElite) return this.props.ClientData.RequireKYC;
        if (this.isOrbit) return this.props.ClientData.RequireKYC;
        return isRequireKYC;
    }

    DisplayMyAccounts = (isClientCRM: boolean, isRequireKYC: boolean, isValid: boolean) => {
        if (this.isOrbit) return (!isClientCRM || (isClientCRM && (!this.props.ClientData.RequireKYC || (this.props.ClientData.RequireKYC && isValid))))
        return (!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))));
    }

    RenderMyAccountsSubMenu = (isClientCRM: boolean, isRequireKYC: boolean, isValid: boolean, AllowAddDemoAcc: boolean): any => {
        return <SubMenu
            className="MyAccountsSubMenu"
            key="/Manage"
            title={<span>

                <span className="titleMenu titleClienMenu">
                    <i className={this.state.collapsed ? "MyAccountsIcon fas fa-user mr-3 ml-19" : "MyAccountsIcon fas fa-user mr-3"}></i>&nbsp;&nbsp;{this.props.t("MyAccounts")}
                </span>
            </span>}
        >

            {(!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid)))) /*(manageAccountsPerm)*/ &&

                <Menu.Item key="/Manage/MyAccounts" className="MenuItemsSub">
                    <Link to="/Manage/MyAccounts">
                        <span className="titleMenu titleClienMenu">
                            <i className={this.state.collapsed ? "fal fa-exchange-alt mr-3 ml-19" : "fal fa-exchange-alt mr-3"}></i>  {this.props.t("LiveAccount")}
                            {this.Pointer("/Manage/MyAccounts")}
                        </span>
                    </Link>
                </Menu.Item>}
            {(!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid)))) && AllowAddDemoAcc &&
                <Menu.Item key="/Manage/DemoAccounts" className="MenuItemsSub">
                    <Link to="/Manage/DemoAccounts">
                        <span className="titleMenu titleClienMenu">
                            <i className={this.state.collapsed ? "fal fa-random mr-3 ml-19" : "fal fa-random mr-3"}></i>  {this.props.t("DemoAccount")}
                            {this.Pointer("/Manage/DemoAccounts")}
                        </span>
                    </Link>
                </Menu.Item>}
        </SubMenu>;
    }

    RenderIBMenuItems = (isClientIB: boolean, isClientAccessSubIB: boolean, isClientReporting: boolean, isClientBreakDownReport: boolean, isPromotion: boolean, sub: any) => {
        return [
            <Menu.Item key="/IB/IBClients" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/IBClients">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-users mr-3 ml-19" : "fal fa-users mr-3"}></i>
                        {this.props.t("Clients")}
                        {this.Pointer("IB/IBClients")}
                    </span>
                </Link>
            </Menu.Item>,
            (isClientIB && !this.isTNFX) && <Menu.Item key="/IB/leads" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/leads">
                    <span className="titleMenu titleClienMenu">

                        <i className={this.state.collapsed ? "fal fa-user-check mr-3 ml-19" : "fal fa-user-check mr-3"}></i>{this.props.t("Leads")}
                        {this.Pointer("/IB/leads")}
                    </span>
                </Link>
            </Menu.Item>,
            isClientIB && <Menu.Item key="/IB/accountStatement" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/accountStatement">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-file-alt mr-3 ml-19" : "fal fa-file-alt mr-3"}></i>&nbsp;{this.props.t("AccountStatement")}
                        {this.Pointer("/IB/accountStatement")}
                    </span>
                </Link>
            </Menu.Item>,
            (isClientAccessSubIB && !this.isTNFX) &&
            <Menu.Item key="/IB/subIBStatement" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/subIBStatement">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-file-invoice-dollar mr-3 ml-19" : "fal fa-file-invoice-dollar  mr-3"}></i>&nbsp;{this.props.t("SubIBStatement")}
                        {this.Pointer("/IB/subIBStatement")}
                    </span>
                </Link>
            </Menu.Item>,
            isClientReporting && <Menu.Item key="/IB/CPAReport" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/CPAReport">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-donate mr-3 ml-19" : "fal fa-donate  mr-3"}></i>{this.props.t("CPAReport")}
                        {this.Pointer("/IB/CPAReport")}
                    </span>
                </Link>
            </Menu.Item>,
            isClientBreakDownReport && <Menu.Item key="/IB/BreakdownReport" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/BreakdownReport">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-chart-line mr-3 ml-19" : "fal fa-chart-line  mr-3"}></i>&nbsp;{this.props.t("BreakdownReport")}
                        {this.Pointer("/IB/BreakdownReport")}
                    </span>
                </Link>
            </Menu.Item>
            ,
            isClientIB && <Menu.Item key="/IB/IBDiagram" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/IBDiagram">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-project-diagram mr-3 ml-19" : "fal fa-project-diagram  mr-3"}></i> {this.props.t("IBDiagram")}
                        {this.Pointer("/IB/IBDiagram")}
                    </span>
                </Link>
            </Menu.Item>
            ,
            isClientIB && isPromotion && <Menu.Item key="/IB/Promotion" className={sub ? "MenuItemsSub" : "CopiersSubMenu"}>
                <Link to="/IB/Promotion">
                    <span className="titleMenu titleClienMenu">
                        <i className={this.state.collapsed ? "fal fa-bullhorn mr-3 ml-19" : "fal fa-bullhorn  mr-3"}></i> {this.props.t("IBPromotion", { defaultValue: "IB Promotion" })}
                        {this.Pointer("/IB/Promotion")}
                    </span>
                </Link>
            </Menu.Item>
        ]
    }
}


export default withTranslation()(connect(mapStateToProps, dispatchProps)(LeftSideBar) as any) as any;

