import { faChevronCircleLeft, faChevronDown, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Close } from '@material-ui/icons';
import { Divider } from 'antd';
import InputAnt from 'antd/es/input';
import { push } from "connected-react-router";
import * as React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Input, Label, Navbar } from "reactstrap";
import { GetClientData, ICompanyData, IStore, IUserData } from '../../../redux/constants';

const mapStateToProps = (state: IStore) => ({
    theme: state.theme,
    router: state.router,
    userData: state.auth.UserData,
    CompanyData: state.auth.CompanyData,
    ClientData: state.auth.ClientData,

});
const dispatchProps = { push }
interface FilterBarProps {
    body?: any;
    bodyList?: any;
    mobileBodyList?: any;
    handleClose?: any;
    theme?: any;
    filterFirstTable?: any;
    sidebar?: boolean;
    filterContact?: string;
    filterKeyWord?: string;
    t: any;
    noSearch?: boolean;
    scrollable?: boolean;
    id?: string;
    NumberOfCarouselItems?: number;
    columnFilter?: any;
}
interface FilterBarSettingsProps {
    body?: any;
    theme?: any;
    width100?: boolean;
    filterFirstTable?: any;
    filterKeyWord?: string;
    filterSettings?: string;
    ChangeCssLogo?: boolean;
    handleClear?: any;
    t: any;
}
class FilterB extends React.Component<FilterBarProps> {
    render() {
        return (
            <div>
                {this.props.body}
            </div>

        )
    }
};
export const FilterBar = withTranslation()(FilterB as any) as any;

interface FilterBarNoSearchProps {
    body?: any;
    theme?: any;
}

export class FilterBarNoSearch extends React.Component<FilterBarNoSearchProps> {
    render() {
        return (
            <div className="w100p hideMobile" >
                <div className={"filterBar "}>
                    <div className="divLeft ">
                        <FontAwesomeIcon icon={faFilter} className="ml-3 mt-2 AddTaskPlus2" />
                    </div>
                    <div className="w100p ">
                        <div className="divRight d-inline-flex mb-2">
                            {this.props.body}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
};
class FilterBarFr extends React.Component<FilterBarProps> {
    render() {
        return (
            <div className="d-inline-flex" >
                <InputAnt prefix={<FontAwesomeIcon icon={faSearch} className="mr-2 searchIcon" />}
                    placeholder={this.props.filterKeyWord ? this.props.t('FilterBy') + " " + this.props.filterKeyWord : this.props.t('FilterByKeyword')}
                    className={"SearchInputFilter"}
                    name="Search"
                    value={this.props.filterContact}
                    onChange={this.props.filterFirstTable}
                />
            </div>
        )
    }
};
export const FilterBarFront = withTranslation()(FilterBarFr as any) as any;
class FilterBarInPages extends React.Component<FilterBarProps> {
    render() {
        return (
            <div>
                <div className="d-flex" >
                    <div className=" MainFilterBar filterBar w100p">
                        <div className=" filterBarBackground">
                            <div className="divLeft hideFilter">
                                <FontAwesomeIcon icon={faFilter} className="ml-3 mt-2 AddTaskPlus2" />
                            </div>
                            <div className="d-flex">
                                <Input
                                    type="text"
                                    name="filterContact"
                                    id="filterInput"
                                    bsSize="lg"
                                    className="filterInput filterInputinPages"
                                    placeholder={this.props.filterKeyWord ? this.props.t('FilterBy') + " " + this.props.filterKeyWord : this.props.t('FilterByKeyword')}
                                    value={this.props.filterContact}
                                    onKeyUp={this.ReloadTable}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={this.props.scrollable ? "ml-auto p-2 d-inline-flex w80p" : "ml-auto p-2 d-inline-flex"}>
                        {this.props.scrollable ?
                            <>
                                <Carousel
                                    className="filterScroll carousel-inner paymentCarousel CarouselFilters m-auto"
                                    prefix="test"
                                    indicators={false}
                                    interval={null}
                                    prevIcon={<i className="fa fa-chevron-left iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}
                                    nextIcon={<i className="fa fa-chevron-right iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}>
                                    {this.props.bodyList?.length > 0
                                        && this.props.bodyList.map((elements: any) => {
                                            return (
                                                <Carousel.Item>
                                                    <div className=" d-inline-flex ">
                                                        {elements}
                                                    </div>
                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                            </>
                            :
                            <div>
                                {this.props.body}

                            </div>
                        }
                        {this.props.columnFilter}
                    </div>
                </div>
                <Divider className="mb-3 filterbarDivider" />
            </div>
        )
    }

    ReloadTable = (e: any) => {
        if (e.keyCode === 13 || (!e.target.value && e.keyCode === 8))
            this.props.filterFirstTable(e);
    }
};
export const FilterBarPages = withTranslation()(FilterBarInPages as any) as any;

const FilterBarWithSliders = (props: FilterBarProps) => {
    const ShowMobile = useMediaQuery('(max-width:480px)');
    const ReloadTable = (e: any) => {
        if (e.keyCode === 13 || (!e.target.value && e.keyCode === 8))
            props.filterFirstTable(e);
    };
    return (
        <div>

            <div className="d-flex" >
                <div className=" MainFilterBar filterBar w100p">
                    <div className=" filterBarBackground">
                        <div className="divLeft hideFilter">
                            <FontAwesomeIcon icon={faFilter} className="ml-3 mt-2 AddTaskPlus2" />
                        </div>
                        <div className="d-flex">
                            <Input
                                type="text"
                                name="filterContact"
                                id="filterInput"
                                bsSize="lg"
                                className="filterInput filterInputinPages"
                                placeholder={props.filterKeyWord ? props.t('FilterBy') + " " + props.filterKeyWord : props.t('FilterByKeyword')}
                                value={props.filterContact}
                                onKeyUp={ReloadTable}
                            />
                        </div>
                    </div>
                </div>
                <div className={props.scrollable ? "ml-auto p-2 d-inline-flex w80p" : "ml-auto p-2 d-inline-flex"}>
                    {props.scrollable ?
                        <>
                            <Carousel
                                hidden={ShowMobile}
                                //ShowMobile to re init
                                className="filterScroll carousel-inner paymentCarousel CarouselFilters m-auto"
                                prefix="test"
                                indicators={false}
                                interval={null}
                                prevIcon={<i className="fa fa-chevron-left iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}
                                nextIcon={<i className="fa fa-chevron-right iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}>
                                {props.bodyList?.length > 0
                                    && props.bodyList.map((elements: any) => {
                                        return (
                                            <Carousel.Item>
                                                <div className=" d-inline-flex ">
                                                    {elements}
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                            <Carousel
                                hidden={!ShowMobile}
                                className="filterScroll carousel-inner paymentCarousel CarouselFilters m-auto"
                                prefix="test"
                                indicators={false}
                                interval={null}
                                prevIcon={<i className="fa fa-chevron-left iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}
                                nextIcon={<i className="fa fa-chevron-right iconChevron mb-2 iconChevronFilter" style={{ color: "black" }}></i>}>
                                {props.mobileBodyList?.length > 0
                                    && props.mobileBodyList.map((elements: any) => {
                                        return (
                                            <Carousel.Item>
                                                <div className=" d-inline-flex ">
                                                    {elements}
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        </>
                        :
                        <div>
                            {props.body}

                        </div>
                    }
                    {props.columnFilter}
                </div>
            </div>
            <Divider className="mb-3 filterbarDivider" />
        </div>
    )
};
export const FilterBarWithSlider = withTranslation()(FilterBarWithSliders as any) as any;

export class FilterBarS extends React.Component<FilterBarSettingsProps> {
    render() {
        return (
            <>
                <div className="filterBar filterSidebar filterSettings d-inline-flex">
                    <div className="divLeft ">
                        <Label className="LabelCreate2" > <FontAwesomeIcon icon={faSearch} className={this.props.ChangeCssLogo ? "AccountIcon2 ml-2 mt-2 mb100" : "AccountIcon2 ml-2 mt-2"} /> </Label>
                    </div>
                    <div className="">
                        <Input
                            type="text"
                            name="filterContact"
                            id="filterInput"
                            bsSize="lg"
                            className="filterInput filterSettings"
                            placeholder={this.props.filterKeyWord ? this.props.t('Filter') + " " + this.props.filterKeyWord : this.props.t('Filter')}
                            value={this.props.filterSettings}
                            onChange={this.props.filterFirstTable}
                        />
                    </div>
                    <div className="d-inline-flex ">
                        <div className="divRight d-inline-flex mb-2">
                            {this.props.body}
                            <div className="d-inline-flex ml-2">
                                <div className="xHoverIcon">
                                    <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClear} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
};
export const FilterBarSettings = withTranslation()(FilterBarS as any) as any;






interface NavigationBarState {
    Navigation: string[];
    NavigationWithoutSub: string[];
}
interface NavigationBarProps {
    t: any;
    router: any;
    push: any;
    userData: IUserData;
    CompanyData: ICompanyData;
    ClientData: GetClientData;
}

class NavigationBarClass extends React.Component<NavigationBarProps, NavigationBarState> {
    state = {
        Navigation: [], NavigationWithoutSub: []
    }
    componentDidMount() {
        this.Navigation();
    }
    navigatetoPage = (link: any, index: number) => {
        if (index === 0) {
            this.props.push("/");
        }
        else {
            var links = '';
            for (let k = 1; k < index + 2; k++) {
                links += "/" + link[k];
            }
            this.props.push(links);
        }
    }
    Navigation = () => {
        let _HasDashboard: boolean = this.HasDashboard();
        let link = this.props.router.location.pathname;
        let links: string[] = [];
        if (_HasDashboard)
            links.push('Dashboard');
        links = links.concat(link.split('/').slice(1));
        this.setState({ Navigation: links });
        var linksNoSub: string[] = [];
        for (var k = 0; k < links.length - 1; k++) {
            linksNoSub.push(links[k])
        }
        const index = linksNoSub.indexOf(linksNoSub[1]);
        linksNoSub.splice(index, 1);
        this.setState({ NavigationWithoutSub: linksNoSub });

    }
    HasDashboard = () => {
        var isA = this.props.userData.isAdmin;
        var isClientCRM = !isA && this.props.CompanyData.IsCRM;
        var isAccountExist = !isA && parseInt(String(this.props.ClientData.NmbrOfAcc), 10) > 0;
        var isRequireKYC = !isA && this.props.ClientData.RequireKYC;
        var isNotRequiredAndAccountExist = (!isRequireKYC && isAccountExist);
        var isHasKYC = !isA && this.props.ClientData.hasKYC;
        var isRequiredKycSumbitedAndAccountExist = (isRequireKYC && isAccountExist && isHasKYC);
        var isAdminCRM = isA && this.props.CompanyData.IsCRM;
        var isAdminMM = isA && this.props.CompanyData.IsMM;
        var isAdminIB = isA && this.props.CompanyData.IsIB;
        if (!isA && ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) {
            return true;
        }
        if (isA && (isAdminCRM || (isAdminMM) || isAdminIB)) {
            return true;
        }
        return false;
    }
    render() {
        return (
            <div className="d-inline-flex ml-8">
                {this.state.NavigationWithoutSub &&
                    this.state.NavigationWithoutSub.map((item: any, index: number) => {
                        //const linkContent = this.state.Navigation;
                        //console.log({ item });
                        return (
                            <div key={index} className="navigationTextDiv cursor-pointer">
                                <div>
                                    <span onClick={() => this.navigatetoPage(this.state.Navigation, index)}
                                        className="navigationText cursor-pointer d-inline-flex mr-2" >
                                        {this.props.t(item)}
                                        <span className=" ml-2 mr-2  d-contents seperatorNavigation"> / </span> </span>

                                </div>
                            </div>
                        );
                    }
                    )}
            </div>
        );
    }
}
export const NavigationBar = withTranslation()(connect(mapStateToProps, dispatchProps)(NavigationBarClass));

interface TabBarBackClassProps {
    tabTitle: string;
    navigateTo: any;
    showAction?: boolean;
    onClickAction1?: any;
    onClickAction2?: any;
    onClickAction3?: any;
    onClickAction4?: any;
    onClickAction5?: any;
    Action1?: any;
    Action2?: any;
    Action3?: any;
    Action4?: any;
    Action5?: any;
    backTitle: any;
    actionTitle: any
}

export class TabBarBack extends React.Component<TabBarBackClassProps> {
    render() {
        return (
            <div className="borderbottom">
                <Button className="BackButton" onClick={this.props.navigateTo}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-2" /> < span className="LabelCreate2">{this.props.backTitle} </span>
                </Button>
                {this.props.showAction &&
                    <div className="divRight mr-2 ActionDiv" >
                        <DropdownButton id="ActionBtn" title={<span className="ManageText mb-2">{this.props.actionTitle} < FontAwesomeIcon icon={faChevronDown} className="cursor-pointer ml-2" /></span>}>
                            <Dropdown.Item className="AccountDrop ActionBtn" onSelect={this.props.onClickAction1}> {this.props.Action1} </Dropdown.Item>
                            <Dropdown.Item className="AccountDrop ActionBtn" onSelect={this.props.onClickAction2}> {this.props.Action2} </Dropdown.Item>
                            <Dropdown.Item className="AccountDrop ActionBtn" onSelect={this.props.onClickAction3}> {this.props.Action3} </Dropdown.Item>
                            <Dropdown.Item className="AccountDrop ActionBtn" onSelect={this.props.onClickAction4}> {this.props.Action4} </Dropdown.Item>
                            <Dropdown.Item className="AccountDrop ActionBtn" onSelect={this.props.onClickAction5}> {this.props.Action5} </Dropdown.Item>
                        </DropdownButton>
                    </div>
                }
            </div>
        );
    }
}

interface TabBarProps {
    tabTitle: string;
}

export class TabBar extends React.Component<TabBarProps> {
    render() {
        return (
            <Navbar expand className="h40 p-0 m-0 tabbar">
                <span className="tabTitle">
                    {this.props.tabTitle}
                </span>
            </Navbar>
        );
    }
}




interface SettingsBarProps {
    elements: any;
}

export class SettingsBar extends React.Component<SettingsBarProps> {
    render() {
        return (
            <div >
                <Navbar expand className="navSettingsbar h40 bbsettingsBar  PortalsettingsActionBar" >
                    {this.props.elements}
                </Navbar>
            </div>
        );
    }
}

