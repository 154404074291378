import * as types from "../constants";

const initialState: types.LanguageOption = {
    label: "English",
    value: "en",
    isDefault:true
};

export default function reducer(state = initialState, actions: types.LanguageActionsTypes): types.LanguageOption {
    switch (actions.type) {
        case "CHANGE_LANGUAGE":
            return {
                label: actions.payload.label,
                value: actions.payload.value
            };
        default:
            return state;
    }
}
