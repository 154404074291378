import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { Close } from '@material-ui/icons';
import { Divider } from 'antd';
import Tabs from "antd/es/tabs";
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { RemoveAllMessages, RemoveMessage } from '../redux/actions/NotificationActions';
import { ActionMessage, IStore } from '../redux/constants';
import { INotificationStore } from '../redux/reducers/NotificationReducer';
const { TabPane } = Tabs;

declare var require: any;
const moment: any = require('moment');
const dispatchProps = { push, RemoveMessage, RemoveAllMessages }
const mapStateToProps = (state: IStore) => ({
    userData: state.auth.UserData,
    lang: state.language.value,
    Notifications: state.Notifications,
    history: state.history,
    location: state.router.location,
    theme: state.theme
});

interface IndexProps {
    RemoveMessage: any;
    RemoveAllMessages: any;
    lang: string;
    userData: any;
    push: any;
    history: any;
    location: any;
    theme: any;
    title: string;
    header?: any;
    handleClose: any;
    isOpen: boolean;
    Notifications: INotificationStore;
    t: any;
}
class NotificationPage extends React.Component<IndexProps> {
    state = { isOpen: true };

    handleClose = () => {
        this.setState({ isOpen: false });
    }
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.handleClose();
        }
    }
    RemoveMessage = (index: number) => {
        this.props.RemoveMessage(index);
    }
    RemoveAllMessages = () => {
        this.props.RemoveAllMessages();
    }

    ReturnElapsedTime = (_date: any) => {
        if (!_date) return '-';
        //moment(Date).format("hh:mm:ss a")
        let _now = moment(new Date());//now
        let _timeOfExecution = moment(new Date(_date));

        let days = _now.diff(_timeOfExecution, 'days');
        let hours = _now.diff(_timeOfExecution, 'hours') < 24 ? _now.diff(_timeOfExecution, 'hours') : _now.diff(_timeOfExecution, 'hours') % 24;
        let minutes = _now.diff(_timeOfExecution, 'minutes') < 60 ? _now.diff(_timeOfExecution, 'minutes') : _now.diff(_timeOfExecution, 'minutes') % 60;

        if (String(days) === "0" && String(hours) === "0" && String(minutes) === "0") return "just now";
        return `${String(days) !== "0" ? days + " days " : ""}${String(hours) !== "0" ? hours + " hours and " : ""}${String(minutes) !== "0" ? minutes + " minutes ago " : ""} `
    }
    deleteAll = () => {
        this.props.RemoveAllMessages();
    }
    render() {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={"Model SemiModal"}
                    open={this.props.isOpen}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.isOpen}>
                        <div className={"ModelPaper4 m10mr10"}>
                            <div className="d-flex" >
                                <div className="mr-auto p-2"><h2 className="TitleModal"> {this.props.t("Notifications")} </h2></div>
                                <div className="ml-auto floatRight"> <Close className="closeButton mr-1 cursor-pointer" onClick={this.props.handleClose} /></div>
                            </div>
                            <Divider />
                            <Tabs className="mh100vhm70 NotificationTab ml-2 mt-3"
                                tabBarExtraContent={
                                    <div>
                                        <Button className="ml-2 primaryApp" onClick={this.deleteAll}>
                                            {this.props.t('DismissAll')}
                                            </Button>

                                    </div>
                                }
                                >

                                <TabPane
                                    tab={this.props.title}
                                    key={'notification'}
                                    
                                    className="mt45 TabEachUser addHeight">
                                    {
                                        <div className="BodyModal3">
                                            {this.props.Notifications.Notifications.map((item: ActionMessage, index: number) => {
                                                return (
                                                    <div key={index} className="mb-2 cursor-pointer">
                                                        <Row className="mb-3 backgroundHovered" onClick={() => this.RemoveMessage(index)}>
                                                            <Col md={11}>
                                                                <span className="font-16 t-bold LabelCreate2"> {item.messageTitle}: </span>

                                                                <span className="LabelCreate2 font-16 d-block">{item.messageContent}</span>
                                                                <span className="mr-2 colorGrey d-block opacity6 font-12">{this.ReturnElapsedTime(item.messageDate)}</span>

                                                            </Col>
                                                            <Col md={1} className="floatRight d-flex align-items-end flex-column mt-2 align-self-center" onClick={() => this.RemoveMessage(index)}>
                                                                <FontAwesomeIcon icon={faCircle} className="circleNotifications" />
                                                            </Col>
                                                        </Row>
                                                        <Divider className="mt-3 mb-3" />
                                                    </div>);
                                            })}
                                        </div>
                                    }
                                </TabPane>
                            </Tabs>
                        </div>
                    </Fade>

                </Modal>
            </div>
        );
    }
}
export default withTranslation()(connect(mapStateToProps, dispatchProps)(NotificationPage) as any) as any;
