import { faBars, faBell, faCheckCircle, faMoon, faQuestion, faServer, faSignOutAlt, faSlidersH, faStar, faSun, faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { Avatar, Badge, Divider } from 'antd';
import 'antd/dist/antd.css';
import Radio from 'antd/es/radio';
import { push } from "connected-react-router";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Collapse, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Nav, Navbar, Row, UncontrolledDropdown } from "reactstrap";
import { CallAxiosRequest } from "../../../Helpers/axiosHelpers";
import { LoadCompanyLogoResult, ServerData, YoonitRequest, UserPermissionOBjectClass, GetCompanyDataResult as IGetCompanyDataResult } from "../../../Interfaces/Interfaces";
import MyProfile from "../../../Pages/ClientSettings/MyProfile";
import MyProfileAdmin from "../../../Pages/ClientSettings/MyProfileAdmin";
import NotificationPage from "../../../Pages/NotificationPage";
import { Login, Logout } from '../../../redux/actions/AuthActions';
import { ChangeLanguage } from '../../../redux/actions/languageAction';
import { RemoveAllMessages, SetHasNewValue } from '../../../redux/actions/NotificationActions';
import { ClearPushNotifications } from '../../../redux/actions/PushNotificationActions';
import { enableDarkTheme, enableLightTheme, enableMixTheme, enableOrangeTheme, enableBlackRedTheme, enableNavyTheme, enableIGainTheme, enableLDNTheme, enableATFXTheme, enableACMTheme, enableAvantTheme, enablePrimexTheme, enableGMCTheme, enableGivTradeTheme, enableiQuotoTheme, enablePureNorthTheme, enableOrbitLight, enableOrbitDark, enableTNFXTheme_OrangeDark, enableGold, enableBrownTheme } from '../../../redux/actions/themeActions';
import * as ReduxTypes from "../../../redux/constants";
import { IUserData } from '../../../redux/constants';
import { INotificationStore } from "../../../redux/reducers/NotificationReducer";
import { NavigationBar } from "../Bars/Bars";
import LeftSideBarMobile from "./LeftsidebarMobile";
import { CheckIfCompanyIs, DisplayZendDeskChat, GetPermissionDetailsByName } from '../../../Helpers/DataHelpers';
import { BasicSelectOption } from '../../../Interfaces/IApplicationForm';
declare var window: any;
const $ = require("jquery");

export interface TopBarProps {
    LanguageData: ReduxTypes.ILanguageData;
    Logout: any;
    Login: any;
    ChangeLanguage: any;
    enableDarkTheme: any;
    enableLightTheme: any;
    enableMixTheme: any;
    enableOrangeTheme: any;
    enableBlackRedTheme: any;
    enableNavyTheme: any;
    enableIGainTheme: any;
    enableLDNTheme: any;
    enableATFXTheme: any;
    enablePrimexTheme: any;
    enableACMTheme: any;
    enableAvantTheme: any;
    enableGMCTheme: any;
    enableGivTradeTheme: any;
    enableiQuotoTheme: any;
    enablePureNorthTheme: any;
    enableOrbitLight: any;
    enableOrbitDark: any;
    enableTNFXTheme_OrangeDark: any;
    enableGold: any;
    enableBrownTheme: any;
    history: any;
    LangselectedOption: ReduxTypes.LanguageOption;
    userData: IUserData;
    Servers: ServerData[];
    ServersConnectivity: any;
    lang: string;
    theme: any;
    router: any;
    AdminData: ReduxTypes.GetAdminData;
    ClientData: ReduxTypes.GetClientData;
    CompanyData: ReduxTypes.ICompanyData;
    Notifications: INotificationStore;
    SetHasNewValue: any;
    CompanySettings: LoadCompanyLogoResult;
    CompanyDataProps: IGetCompanyDataResult;
    i18n: any;
    t: any;
    ClearPushNotifications: any;
    RemoveAllMessages: any;
    ActionURL: any;
    UserPermissions: ReduxTypes.IPerms[]
}
export interface TopBarState {
}

const mapStateToProps = (state: ReduxTypes.IStore) => ({
    LanguageData: state.LanguageData,
    AdminData: state.auth.AdminData,
    ClientData: state.auth.ClientData,
    userData: state.auth.UserData,
    history: state.history,
    LangselectedOption: state.language,
    lang: state.language.value,
    CompanyData: state.auth.CompanyData,
    URL: state.auth.UserData.yoonitGetURL,
    ActionURL: state.auth.UserData.yoonitActionURL,
    theme: state.theme,
    router: state.router,
    Notifications: state.Notifications,
    CompanySettings: state.CompanySettings,
    ServersConnectivity: state.ServersConnectivity,
    Servers: state.auth.Servers,
    UserPermissions: state.auth.UserPermissions,
    CompanyDataProps: state.CompanyData
});
const dispatchProps = { push, Login, Logout, ChangeLanguage, enableLightTheme, enableDarkTheme, enableMixTheme, enableBlackRedTheme, enableOrangeTheme, enableNavyTheme, enableIGainTheme, enableLDNTheme, enableATFXTheme, enableACMTheme, enableGMCTheme, enableAvantTheme, enablePrimexTheme, enableGivTradeTheme, SetHasNewValue, enableiQuotoTheme, enablePureNorthTheme, enableOrbitLight, enableOrbitDark, enableTNFXTheme_OrangeDark, enableGold, enableBrownTheme, ClearPushNotifications, RemoveAllMessages }

document.addEventListener(
    'touchmove',
    function (e) {
        e.preventDefault();
    },
    false
);
class TopBar extends React.Component<TopBarProps, TopBarState> {
    state = {
        value: 1, username: "", open: false, blacRedTheme: false, darkTheme: false, lightTheme: false, mixTheme: false, orangeTheme: false, navyTheme: false, iGainTheme: false, ldnTheme: false, openProfile: false, OpenMail: false, openPassword: false, openLangauge: false, openLinks: false, openNotification: false, openProfileMainAdmin: false, openProfileNotification: false, visible: false, atfxTheme: false, acmTheme: false, avantTheme: false, primexTheme: false, gmcTheme: false, givTradeTheme: false, iquotaTheme: false,
        pureNorthTheme: false,
        enableOrbitLight: false,
        enableOrbitDark: false,
        enableTNFXTheme_OrangeDark: false,
        enableGold: false,
        enableBrownTheme: false,
        LightLogo: '',
        SecondaryIcon: '',
        DarkLogo: '',
        IsCircular: false,
        website: '',
        ThemeChecked: "",
        languageChecked: "",
        cdnLink: ""
    };
    componentDidMount() {
        var firstName;
        var lastName;
        var name;
        this.setState({
            ThemeChecked: this.props.theme.currentTheme,
            languageChecked: this.props.i18n.language
        })
        document.documentElement.setAttribute('lang', this.props.i18n.language);
        this.setState({
            LightLogo: this.props.CompanySettings.LightLogo,
            SecondaryIcon: this.props.CompanySettings.SecondaryIcon,
            DarkLogo: this.props.CompanySettings.DarkLogo,
            IsCircular: this.props.CompanySettings.IsCircular,
            website: this.props.CompanySettings.CompanySite,
            cdnLink: this.props.CompanyDataProps.CDNURL
        });
        if (this.props.userData.isAdmin) {
            firstName = this.props.AdminData.FirstName ? this.props.AdminData.FirstName.charAt(0) : '';
            lastName = this.props.AdminData.LastName ? this.props.AdminData.LastName.charAt(0) : '';
            name = firstName + lastName;
            this.setState({ username: name.toUpperCase() })
        }
        else {
            firstName = this.props.ClientData.FirstName ? this.props.ClientData.FirstName.charAt(0) : '';
            lastName = this.props.ClientData.LastName ? this.props.ClientData.LastName.charAt(0) : '';
            name = firstName + lastName;
            this.setState({ username: name.toUpperCase() })
        }
        document.addEventListener("keydown", this.escFunction, false);
        this.props.i18n.changeLanguage(this.props.LangselectedOption.value);
        document.documentElement.setAttribute('lang', this.props.i18n.language);
        if (this.props.i18n.language === "ar") {
            document.documentElement.setAttribute('dir', "rtl");
        }
        else {
            document.documentElement.setAttribute('dir', "ltr");
        }
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    escFunction = (event: any) => {
        if (event.keyCode === 27) {
            this.closeTheme();
        }
    }
    AddAuthenticationLog = (Login?: boolean) => {
        let Params: YoonitRequest<string> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            UserName: this.props.userData.isAdmin ? this.props.AdminData.Email : this.props.ClientData.Email,
            ClientName: this.props.userData.isAdmin ?
                `${this.props.AdminData.FirstName ? this.props.AdminData.FirstName : ""} ${this.props.AdminData.LastName ? this.props.AdminData.LastName : ""}`
                : `${this.props.ClientData.FirstName ? this.props.ClientData.FirstName : ""} ${this.props.ClientData.LastName ? this.props.ClientData.LastName : ""}`
            ,
            ObjData: Login ? "Login" : "Logout",
        };
        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `Trader/AddAuthenticationLog`,
            Params,
            { Token: this.props.userData.Token, },
            () => { },
            () => { },
            this.props.t
        );

    }
    onLogout = (e: any) => {

        this.props.ClearPushNotifications();
        this.props.RemoveAllMessages();
        this.props.history.push('/');
        this.props.Logout();//must be last
        //localStorage.clear();
        window.location.reload();
    }

    onLanguageChange = (e: any) => {
        //if (e.target.name === "APIType" && String(e.target.value) !== "3") {
        //    this.GenerateBonusToken();
        //}
        this.setState({
            [e.target.name]: e.target.value
        } as any);
        this.props.ChangeLanguage(this.props.LanguageData.Dict[e.target.value]);
        this.props.i18n.changeLanguage(e.target.value);
        document.documentElement.setAttribute('lang', e.target.value);
        if (e.target.value === "ar") {
            document.documentElement.setAttribute('dir', "rtl");
        }
        else {
            document.documentElement.setAttribute('dir', "ltr");
        }
    }

    enableDarkTheme = () => {
        this.setState({
            darkTheme: true,
            lightTheme: true,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableDarkTheme();
        //$(".DarkThemeIcon").on('click', function () {
        //    $('body').fadeOut(1000);
        //});


    }

    enableLightTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: true,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableLightTheme();
        //$(".LightThemeIcon").on('click', function () {
        //    $('body').fadeIn(300);
        //});

    }
    enableMixTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: true,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableMixTheme();
    }
    enableOrangeTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: true,
            blacRedTheme: false,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableOrangeTheme();
    }
    enableBlackRedTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: true,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableBlackRedTheme();
    }
    enableNavyTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: true,
            ldnTheme: false,
            iGainTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableNavyTheme();
    }
    enableIGainTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            ldnTheme: false,
            iGainTheme: true,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableIGainTheme();
    }
    enableLDNTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: true,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableLDNTheme();
    }
    enableATFXTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: true,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableATFXTheme();
    }
    enableACMTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: true,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableACMTheme();
    }
    enableGMCTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: true,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableGMCTheme();
    }
    enableAvantTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: true,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableAvantTheme();
    }
    enablePrimexTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: true,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enablePrimexTheme();
    }
    enableGivTradeTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: true,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableGivTradeTheme();
    }
    enablePureNorthTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: true,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enablePureNorthTheme();
    }
    enableIQuotoTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: true,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableiQuotoTheme();
    }
    enableOrbitLight = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: true,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableOrbitLight();
    }
    enableOrbitDark = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: false,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: true,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableOrbitDark();
    }
    enableTNFXTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: true,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: true,
            enableGold: false,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableTNFXTheme_OrangeDark();
    }
    enableGoldTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: true,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: true,
            enableBrownTheme: false,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableGold();
    }
    enableBrownTheme = () => {
        this.setState({
            darkTheme: false,
            lightTheme: false,
            mixTheme: false,
            orangeTheme: false,
            blacRedTheme: false,
            navyTheme: false,
            iGainTheme: true,
            ldnTheme: true,
            atfxTheme: false,
            acmTheme: false,
            avantTheme: false,
            primexTheme: false,
            gmcTheme: false,
            givTradeTheme: false,
            iquotaTheme: false,
            pureNorthTheme: false,
            enableOrbitLight: false,
            enableOrbitDark: false,
            enableTNFXTheme_OrangeDark: false,
            enableGold: false,
            enableBrownTheme: true,
        });
        $('html').addClass("blurBackground").delay(2000).queue(function () {
            $("html").removeClass("blurBackground").dequeue();
        });
        this.props.enableBrownTheme();
    }
    openProfileMainAdmin = () => {
        this.setState({ openProfileMainAdmin: true })
    }
    openProfileMain = () => {
        this.setState({ openProfile: true })
    }
    closeProfileMainAdmin = () => {
        this.setState({ openProfileMainAdmin: false })
    }
    closeProfileMain = () => {
        this.setState({ openProfile: false })
    }
    handleOpen = () => {
        this.setState({ open: !this.state.open })
    }
    closeTheme = () => {
        this.setState({ open: false })
    }
    openNotification = () => {
        this.props.SetHasNewValue(false);
        this.setState({ openNotification: true })
    }
    closeNotification = () => {
        this.setState({ openNotification: false })
    }
    openManual = (value: any) => {
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = `${this.props.CompanyDataProps.HandBookURL ? this.props.CompanyDataProps.HandBookURL : "https://yoonit-handbook.azurewebsites.net/"}login?Data=${encodeURIComponent(value)}&&lng=${this.props.LangselectedOption.value}`;
        a.click();
    }
    radioBtnsOnChange = (e: any) => {
        //if (e.target.name === "APIType" && String(e.target.value) !== "3") {
        //    this.GenerateBonusToken();
        //}
        this.setState({
            [e.target.name]: e.target.value
        } as any);
        if (e.target.value === "addons-DarkTheme") {
            this.enableDarkTheme();
        }
        else if (e.target.value === "addons-LightTheme") {
            this.enableLightTheme();
        }
        else if (e.target.value === "addons-MixTheme") {
            this.enableMixTheme();
        }
        else if (e.target.value === "addons-NavyTheme") {
            this.enableNavyTheme();
        }
        else if (e.target.value === "addons-OrangeTheme") {
            this.enableOrangeTheme();
        }
        else if (e.target.value === "addons-BlackRedTheme") {
            this.enableBlackRedTheme();
        }
        else if (e.target.value === "addons-IGainTheme") {
            this.enableIGainTheme()
        }
        else if (e.target.value === "addons-LDNTheme") {
            this.enableLDNTheme()
        }
        else if (e.target.value === "addons-TNFXTheme") {
            this.enableTNFXTheme()
        }
        else if (e.target.value === "addons-ATFXTheme") {
            this.enableATFXTheme()
        }
        else if (e.target.value === "addons-ACMTheme") {
            this.enableACMTheme()
        }
        else if (e.target.value === "addons-GMCTheme") {
            this.enableGMCTheme()
        }
        else if (e.target.value === "addons-AvantTheme") {
            this.enableAvantTheme()
        }
        else if (e.target.value === "addons-PrimexTheme") {
            this.enablePrimexTheme()
        }
        else if (e.target.value === "addons-GivTradeTheme") {
            this.enableGivTradeTheme()
        }
        else if (e.target.value === "addons-iQuotoTheme") {
            this.enableIQuotoTheme()
        }
        else if (e.target.value === "addons-PureNorthTheme") {
            this.enablePureNorthTheme()
        }
        else if (e.target.value === "addons-Orbit-Light") {
            this.enableOrbitLight()
        }
        else if (e.target.value === "addons-Orbit-Dark") {
            this.enableOrbitDark()
        }
        else if (e.target.value === "addons-GoldTheme") {
            this.enableGoldTheme()
        }
        else if (e.target.value === "addons-BrownTheme") {
            this.enableBrownTheme()
        }
    }
    CheckIfThereIfAllServersConnected = (MAMOrDynamic: boolean, isAnyOtherthanDLTrue: boolean) => {
        if (!this.props.ServersConnectivity || this.props.ServersConnectivity.length === 0) return false;
        for (let serverKey in this.props.ServersConnectivity) {
            if (!this.props.ServersConnectivity[serverKey]?.IsConnected
                && this.IsNotHiddenWebAPI(serverKey, MAMOrDynamic)
                && this.IsNotHiddenManager(this.props.ServersConnectivity[serverKey], isAnyOtherthanDLTrue)
            )
                return false;
        }
        return true;
    }
    IsNotHiddenWebAPI = (serverKey: string, MAMOrDynamic: boolean) => {
        return (!serverKey?.includes('WebAPI') || MAMOrDynamic);
    }
    IsNotHiddenManager = (server: any, isAnyOtherthanDLTrue: boolean) => {
        //at login start true or false only so no need to come here 
        return (server?.ID?.includes('WebAPI') || server?.ServerType === "MT4" || isAnyOtherthanDLTrue);
    }
    render() {
        var isA = this.props.userData.isAdmin;
        var isAdminMM = isA && this.props.CompanyData.IsMM;
        var isAdminDynamicMargin = isA && this.props.CompanyData.IsDynamicMargin;
        var MAMUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('MAM', this.props.UserPermissions);
        var DynamicMargin: UserPermissionOBjectClass = GetPermissionDetailsByName('Dynamic Margin', this.props.UserPermissions);
        var isMAMAllowed = (isAdminMM) && isA && MAMUserPerms.Allowed;//for WebPAI
        var isDynamicMarginAllowed = (isAdminDynamicMargin) && DynamicMargin.Allowed;
        var isAdminIB = isA && this.props.CompanyData.IsIB;
        var isAdminBonus = isA && this.props.CompanyData.IsBonus;
        var isAdminCRM = isA && this.props.CompanyData.IsCRM;
        var SupportCenter: UserPermissionOBjectClass = GetPermissionDetailsByName('Support Center', this.props.UserPermissions);
        var isAnyOtherthanDLTrue = (isAdminCRM || isAdminIB || isAdminBonus || isMAMAllowed);
        return (
            <div className="sticky-top bg-dark-yoonit h48 zIndex100 ">
                <Navbar expand className=" sticky-top h48 darkMenu topbarBottom">
                    <div className="mobilMenuLeftsidebar">
                        <FontAwesomeIcon icon={faBars} className="Menuicon cursor-pointer" onClick={this.showDrawer} />
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={this.props.theme.MenuIsCollapsed ? "sideSide sidebarColor mb-1 ml4" : "sideSide sidebarColor mb-1 w176-ml5 h40 d-flex align-items-center"}
                        href={this.state.website === "" ? "#" : this.state.website}
                    >

                        {!!this.state.SecondaryIcon && <img
                            src={this.state.SecondaryIcon || ""}
                            alt="Logo"
                            className={this.props.theme.MenuIsCollapsed ? " mr-3 YLogo mt-1" : " mr-3 YLogo  mt-1"}
                        />}
                        {(!this.props.theme.MenuIsCollapsed && !!this.ReturnLogo())
                            && < img
                                src={this.ReturnLogo()}
                                alt="logo"
                                className={this.ReturnLogoClassName()}
                            />
                        }
                    </a>

                    {this.state.visible &&
                        <LeftSideBarMobile visible={this.state.visible} onClose={this.onClose} />
                    }
                    <div className="hideMobile">
                        <NavigationBar />
                    </div>
                    <Collapse navbar>
                        <Nav className="ml-auto" navbar>

                            {this.state.openNotification &&

                                <NotificationPage
                                    title={this.props.t('Session')}
                                    isOpen={this.state.openNotification}
                                    handleClose={this.closeNotification} />
                            }
                            {this.props.userData.isAdmin &&
                                <UncontrolledDropdown nav inNavbar id="nameUser" className="align-self-center mt-1">
                                    <span className="d-sm-inline-block hoverIcon">
                                        <DropdownToggle nav caret className="mt2">
                                            <span className=" cursor-default grayColor  ">
                                                <Tooltip title={this.props.t('Servers')} placement="left" className=" hoverIcon">
                                                    <Badge offset={[-20, 15]} count={<FontAwesomeIcon icon={this.CheckIfThereIfAllServersConnected(isMAMAllowed || isDynamicMarginAllowed, isAnyOtherthanDLTrue) ? faCheckCircle : faTimesCircle} className={this.CheckIfThereIfAllServersConnected(isMAMAllowed || isDynamicMarginAllowed, isAnyOtherthanDLTrue) ? "connected" : "notConnected"} />}>
                                                        <button className="TopbarIconHover">
                                                            <FontAwesomeIcon icon={faServer} className="Server TopbarIcon cursor-pointer hoverIcon" />
                                                        </button>
                                                    </Badge>
                                                </Tooltip>
                                            </span>
                                        </DropdownToggle>
                                    </span>

                                    <DropdownMenu right className="align-self-center ThemeDropDownMenu ServerMenu">
                                        <Row md={12}>
                                            {this.props.Servers && this.props.ServersConnectivity &&
                                                this.props.Servers.map((item: ServerData) => {
                                                    return (
                                                        <React.Fragment key={`${item?.ServerName}${item.ServerID}`}>
                                                            {(item.ServerType === "MT4" || isAnyOtherthanDLTrue) && <DropdownItem className="ThemeDropDown ServerDrop">
                                                                <Label className={this.props.ServersConnectivity[`${item.ServerID}`]?.IsConnected ? "LabelCreate2 wrapText" : "notConnectedServer wrapText"}>   <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                                                    <span className="t-bold"> {item.ServerName} - {item.ServerType} -{item.ServerType === "MT5" ? `${this.props.t('Manager')} -` : ""}
                                                                    </span>
                                                                    {this.props.ServersConnectivity[`${item.ServerID}`]?.IsConnected ? this.props.t('Connected') : this.props.t('Disconnected')}
                                                                </Label>
                                                            </DropdownItem>}
                                                            {/* mt5 have 2 types*/}
                                                            {item.ServerType === "MT5" && (isMAMAllowed || isDynamicMarginAllowed) && < DropdownItem className="ThemeDropDown ServerDrop">
                                                                <Label className={this.props.ServersConnectivity[`${item.ServerID}WebAPI`]?.IsConnected ? "LabelCreate2 wrapText" : "notConnectedServer wrapText"}>   <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /><span className="t-bold"> {item.ServerName} - {item.ServerType} - {this.props.t('WebAPI')} </span> - {this.props.ServersConnectivity[`${item.ServerID}WebAPI`]?.IsConnected ? this.props.t('Connected') : this.props.t('Disconnected')} </Label>
                                                            </DropdownItem>}
                                                        </React.Fragment>
                                                    )
                                                })}
                                        </Row>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            }
                            {this.props.userData.isAdmin &&
                                <div className="mt-3 hoverIcon ml-2" onClick={this.openNotification}>
                                    {this.props.Notifications.HasNew &&
                                        <Badge dot>
                                            <button className="TopbarIconHover">   <FontAwesomeIcon icon={faBell} className="TopbarIcon cursor-pointer hoverIcon" onClick={this.openNotification} /> </button>
                                        </Badge>
                                    }

                                    {!this.props.Notifications.HasNew &&
                                        <button className="TopbarIconHover">      <FontAwesomeIcon icon={faBell} className="TopbarIcon cursor-pointer hoverIcon" onClick={this.openNotification} /> </button>
                                    }

                                </div>
                            }
                            {(this.props.userData.isAdmin && SupportCenter.Allowed) &&
                                <div className="mt-3 ml-2 hoverIcon" >
                                    <Tooltip title={this.props.t('Support')} placement="bottom" className=" hoverIcon">
                                        <button className="TopbarIconHover">
                                            <FontAwesomeIcon icon={faQuestion} className="TopbarIcon cursor-pointer hoverIcon" onClick={() => this.openManual(this.props.userData.Token)} />
                                        </button>
                                    </Tooltip>
                                </div>
                            }
                            {!this.props.userData.isAdmin &&
                                <MyProfile
                                    openProfile={this.state.openProfile}
                                    closeProfile={this.closeProfileMain}
                                />}
                            {this.props.userData.isAdmin &&
                                <MyProfileAdmin
                                    openProfile={this.state.openProfileMainAdmin}
                                    closeProfile={this.closeProfileMainAdmin}
                                />}
                            {!CheckIfCompanyIs("XAMarkets") && <UncontrolledDropdown nav inNavbar id="nameUser" className="align-self-center mt-1">
                                <span className="d-sm-inline-block hoverIcon">
                                    <DropdownToggle nav caret className="mt2">
                                        <span className=" cursor-default grayColor  ">
                                            <button className="TopbarIconHover">
                                                <FontAwesomeIcon icon={faSlidersH} className="TopbarIcon cursor-pointer hoverIcon" />
                                            </button>
                                        </span>
                                    </DropdownToggle>
                                </span>

                                <DropdownMenu right className="align-self-center ThemeDropDownMenu">
                                    <Row md={12}>
                                        <DropdownItem className="ThemeDropDown">
                                            {(this.props.userData.isAdmin || !CheckIfCompanyIs("PRIMEX")) && <FormGroup>
                                                <Label className="text-white  d-block wrapText" > {this.props.t('ChooseATheme')}:</Label>
                                                <Radio.Group
                                                    value={this.state.ThemeChecked}
                                                    onChange={this.radioBtnsOnChange}
                                                    name="ThemeChecked">
                                                    <Radio
                                                        value={this.ReturnLightTheme()}
                                                        className="RadioLabel d-block mt-2 mb-2">
                                                        <span className="LabelCreate2"><FontAwesomeIcon icon={faSun} className="mr-1 ml-1" />{this.props.t('Light')}</span>
                                                    </Radio>
                                                    <Radio
                                                        value={this.ReturnDarkTheme()}
                                                        className="RadioLabel d-block mt-2 mb-2">
                                                        <span className="LabelCreate2"><FontAwesomeIcon icon={faMoon} className="mr-1 ml-1" />{this.props.t('Dark')}</span>
                                                    </Radio>
                                                    {this.checkIfOtherThemesAllowed() && <Radio
                                                        value="addons-MixTheme"
                                                        className="RadioLabel d-block mt-2 mb-2">
                                                        <span className="LabelCreate2"><FontAwesomeIcon icon={faStar} className="mr-1 ml-1" />{this.props.t('Fusion')}</span>
                                                    </Radio>}
                                                </Radio.Group>
                                            </FormGroup>}
                                            {this.checkIfOtherThemesAllowed() && <> <Divider className="mt-3 mb-3" />
                                                <FormGroup>
                                                    <Label className="text-white  d-block wrapText" > {this.props.t('CustomThemes')}: </Label>
                                                    <Radio.Group
                                                        value={this.state.ThemeChecked}
                                                        onChange={this.radioBtnsOnChange}
                                                        name="ThemeChecked">
                                                        {(this.props.userData.isAdmin || !CheckIfCompanyIs("PRIMEX")) && <>

                                                            {!CheckIfCompanyIs("XAMarkets") && <Radio
                                                                value="addons-NavyTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Navy</span>
                                                            </Radio>}
                                                            {!CheckIfCompanyIs("XAMarkets") && <>
                                                                <Radio
                                                                    value="addons-OrangeTheme"
                                                                    className="RadioLabel d-block mt-2 mb-2">
                                                                    <span className="LabelCreate2">Orange</span>
                                                                </Radio>
                                                                <Radio
                                                                    value="addons-BlackRedTheme"
                                                                    className="RadioLabel d-block mt-2 mb-2">
                                                                    <span className="LabelCreate2">Dark Red</span>
                                                                </Radio>
                                                                <Radio
                                                                    value="addons-IGainTheme"
                                                                    className="RadioLabel d-block mt-2 mb-2">
                                                                    <span className="LabelCreate2">Green Grey </span>
                                                                </Radio>
                                                                <Radio
                                                                    value="addons-ATFXTheme"
                                                                    className="RadioLabel d-block mt-2 mb-2">
                                                                    <span className="LabelCreate2">Orange White </span>
                                                                </Radio>
                                                            </>}
                                                            <Radio
                                                                value="addons-LDNTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Orange Black </span>
                                                            </Radio>
                                                            {!CheckIfCompanyIs("XAMarkets") && <Radio
                                                                value="addons-TNFXTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Orange Dark </span>
                                                            </Radio>}
                                                        </>}
                                                        {CheckIfCompanyIs("PRIMEX") && <Radio
                                                            value="addons-PrimexTheme"
                                                            className="RadioLabel d-block mt-2 mb-2">
                                                            <span className="LabelCreate2">PrimeX Theme</span>
                                                        </Radio>}
                                                        {((this.props.userData.isAdmin || !CheckIfCompanyIs("PRIMEX")) && !CheckIfCompanyIs("XAMarkets")) && <>
                                                            <Radio
                                                                value="addons-ACMTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Green Black </span>
                                                            </Radio>
                                                            <Radio
                                                                value="addons-AvantTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Grey </span>
                                                            </Radio>
                                                            {(CheckIfCompanyIs("GMC") || CheckIfCompanyIs("plugit")) && <Radio
                                                                value="addons-GMCTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">GMC-Theme </span>
                                                            </Radio>}
                                                            {(CheckIfCompanyIs("GIV") || CheckIfCompanyIs("plugit")) && <Radio
                                                                value="addons-GivTradeTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Giv-Theme</span>
                                                            </Radio>}
                                                            {(CheckIfCompanyIs("iQuoto") || CheckIfCompanyIs("plugit")) && <Radio
                                                                value="addons-iQuotoTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">iQuoto-Theme</span>
                                                            </Radio>}

                                                            {(CheckIfCompanyIs("PureNorth") || CheckIfCompanyIs("plugit")) && <Radio
                                                                value="addons-PureNorthTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Blue-Theme</span>
                                                            </Radio>}
                                                            <Radio
                                                                value="addons-GoldTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Gold</span>
                                                            </Radio>
                                                            <Radio
                                                                value="addons-BrownTheme"
                                                                className="RadioLabel d-block mt-2 mb-2">
                                                                <span className="LabelCreate2">Brown</span>
                                                            </Radio>
                                                        </>}
                                                    </Radio.Group>
                                                </FormGroup></>}
                                            <Divider className="mt-3 mb-3" />
                                            <FormGroup>
                                                <Label className="text-white d-block wrapText" > {this.props.t('ChooseALanguage')}:</Label>
                                                {this.RenderLanguages()}
                                            </FormGroup>
                                        </DropdownItem>

                                    </Row>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            <UncontrolledDropdown nav inNavbar id="nameUser">
                                <span className="d-sm-inline-block hoverIcon">
                                    <DropdownToggle nav caret className="mt2">
                                        <span className=" cursor-default grayColor  ">
                                            <Avatar className="avatar avatarTopBar">{this.state.username}</Avatar>
                                        </span>
                                    </DropdownToggle>
                                </span>
                                {!isA &&
                                    <DropdownMenu right className="ProfileDropdwon">
                                        <div className="text-center mt-3 mb-3">
                                            <Avatar className="avatar2 mb-2">{this.state.username}</Avatar>

                                            <p className="nameProfile text-primary d-block">  {this.props.ClientData.FirstName}  {this.props.ClientData.LastName} </p>
                                            <Label className="LabelCreate2 wrapText  d-block"> {this.props.ClientData.Email} </Label>
                                        </div>
                                        <div className=" text-center justify-content-center">
                                            <Button className=" ButtonsModal AddBtn mt-2 mr-2 d-inline-flex" onClick={this.openProfileMain}> <FontAwesomeIcon icon={faUserCircle} className="mr-2 mt-1" /> {this.props.t('Profile')}
                                            </Button>
                                            <Link to={"/login"}>
                                                <Button className=" ButtonsModal AddBtn mt-2 mr-2  d-inline-flex" onClick={() => {
                                                    this.props.Logout();
                                                    DisplayZendDeskChat(false);
                                                    this.AddAuthenticationLog();
                                                }}> <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 mt-1" /> {this.props.t('Logout')}
                                                </Button>

                                            </Link>
                                        </div>
                                    </DropdownMenu>
                                }
                                {isA &&
                                    <DropdownMenu right className="ProfileDropdwon">
                                        <div className="text-center mt-3 mb-3">
                                            <Avatar className="avatar2 mb-2">{this.state.username}</Avatar>

                                            <p className="nameProfile text-primary d-block font-24">  {this.props.AdminData.FirstName}  {this.props.AdminData.LastName} </p>
                                            <Label className="LabelCreate2 wrapText  d-block"> {this.props.AdminData.Email}  </Label>
                                        </div>
                                        <div className=" text-center justify-content-center">
                                            <Button className=" ButtonsModal AddBtn mt-2 mr-2 d-inline-flex" onClick={this.openProfileMainAdmin}> <FontAwesomeIcon icon={faUserCircle} className="mr-2 mt-1" /> {this.props.t('Profile')}
                                            </Button>
                                            <Link to={"/login"}>
                                                <Button className=" ButtonsModal AddBtn mt-2 mr-2  d-inline-flex" onClick={() => {
                                                    this.props.Logout();
                                                    DisplayZendDeskChat(false);
                                                    this.AddAuthenticationLog();
                                                }}> <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 mt-1" /> {this.props.t('Logout')}
                                                </Button>

                                            </Link>
                                        </div>
                                    </DropdownMenu>
                                }
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar >
            </div >
        );
    }

    RenderLanguages = () => {
        return <Radio.Group
            value={this.props.i18n.language} //as it changes from other pages 
            onChange={this.onLanguageChange}
            name="languageChecked">
            {this.props.LanguageData.Options
                && this.props.LanguageData.Options.map((lang: BasicSelectOption) => {
                    return <Radio
                        key={lang.value}
                        value={lang.value}
                        className="RadioLabel d-block mt-2 mb-2">
                        <span className="LabelCreate2 ml-1">{lang.label}</span>
                    </Radio>
                })}
        </Radio.Group>;
    }

    ReturnLogo = (): string | undefined => {
        return (
            this.props.theme.currentTheme === "addons-LightTheme"
            || this.props.theme.currentTheme === "addons-OrangeTheme"
            || this.props.theme.currentTheme === "addons-PrimexTheme"
            || this.props.theme.currentTheme === "addons-BrownTheme"
        ) ? this.state.LightLogo : this.state.DarkLogo;
    }

    ReturnLightTheme = (): any => {
        if (CheckIfCompanyIs("orbit")) return "addons-Orbit-Light";
        if (CheckIfCompanyIs("Malfex")) return "addons-BrownTheme";
        return "addons-LightTheme";
    }

    ReturnDarkTheme = (): any => {
        if (CheckIfCompanyIs("orbit")) return "addons-Orbit-Dark";
        return this.props.CompanyDataProps.CompanyCode?.toLowerCase() === "ldn" ? "addons-LDNTheme" : "addons-DarkTheme";
    }

    checkIfOtherThemesAllowed = () => {
        if (CheckIfCompanyIs("XAMarkets")) return false;
        if (CheckIfCompanyIs("Malfex")) return false;
        return this.props.CompanyDataProps.CompanyCode?.toLowerCase() !== "ldn" && !CheckIfCompanyIs("orbit");
    }

    ReturnLogoClassName = (): string => {
        if (CheckIfCompanyIs('LimitMarkets')) return "imagelogoLimitMarkets hideMobile";
        return `${this.state.IsCircular ? "logoTop hideMobile" : CheckIfCompanyIs('Maleyat') ? "imagelogoGold hideMobile" : "imagelogo hideMobile"}`;
    }
}

export default withTranslation()(connect(mapStateToProps, dispatchProps)(TopBar));
