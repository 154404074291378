import * as types from "../constants";

export interface INotificationStore {
    Notifications: types.ActionMessage[],
    HasNew: boolean
}

const initialState: INotificationStore = {
    Notifications: [],
    HasNew: false
};

export default function reducer(state = initialState, actions: types.NotificationActionsTypes) {
    switch (actions.type) {
        case "ADD_NEW_MESSAGE":
            return {
                ...state,
                Notifications: [actions.payload, ...state.Notifications],
                HasNew: true
            };
        case "SET_HAS_NEW_VALUE":
            return {
                ...state,
                HasNew: actions.payload
            };
        case "REMOVE_MESSAGE":
            return {
                ...state,
                Notifications: [
                    ...state.Notifications.slice(0, actions.payload),
                    ...state.Notifications.slice(actions.payload + 1)
                ],
            };
        case "REMOVE_ALL_MESSAGES":
            return initialState;
        default:
            return state;
    }
}
