import * as types from "../constants";

export function HandleServerConnectivity(server: types.ServerConnection): types.HandleServerConnectivity {
    return {
        type: types.HANDLE_SERVER_CONNECTIVITY,
        payload: server
    };
}
export function ADDSERVERS(servers: any): types.AddServerConnectivity {
    return {
        type: types.ADD_SERVERS,
        payload: servers
    };
}