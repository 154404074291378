import DashboardIcon from '@material-ui/icons/Dashboard';
import { Divider, Drawer } from 'antd';
import Menu from 'antd/es/menu';
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { CheckIfCompanyIs, CheckIfPermAlllowedByName, DisplayZendDeskChat, GetPermissionDetailsByName, GetReportsPerms, OneOFReportsEnabled } from '../../../Helpers/DataHelpers';
import { GetCompanyDataResult, LoadCompanyLogoResult, translationType, UserPermissionOBjectClass } from '../../../Interfaces/Interfaces';
import ContactUs from '../../../Pages/ClientSettings/ContactUs';
import { Login, Logout } from '../../../redux/actions/AuthActions';
import { MenuToggleCollapse } from '../../../redux/actions/themeActions';
import { GetAdminData, GetClientData, ICompanyData, IPerms, IStore, IUserData } from '../../../redux/constants';
const MenuItemGroup = Menu.ItemGroup;

const { SubMenu } = Menu;
const mapStateToProps = (state: IStore) => ({
    userData: state.auth.UserData,
    lang: state.language.value,
    state: state.sidebar,
    history: state.history,
    theme: state.theme,
    CompanyData: state.auth.CompanyData,
    AdminData: state.auth.AdminData,
    ClientData: state.auth.ClientData,
    UserPermissions: state.auth.UserPermissions,
    CompanySettings: state.CompanySettings,
    CompanyDataProps: state.CompanyData,//not auth, CompanyData
});
const dispatchProps = { push, Login, Logout, MenuToggleCollapse }
export interface LeftSideBarMobileProps {
    CompanyDataProps: GetCompanyDataResult;
    AdminData: GetAdminData;
    ClientData: GetClientData;
    lang: translationType;
    userData: IUserData;
    theme: any;
    push: any;
    CompanyData: ICompanyData
    UserPermissions: IPerms[];
    visible: boolean;
    onClose: any;
    CompanySettings: LoadCompanyLogoResult;
    t: any;
}
export interface LeftSideBarMobileState {
    SecondaryIcon: string | null;
    defaultSelectedKeys: string;
    collapsed: boolean;
    selectedSetting: boolean;
    OpenContactUs: boolean;
    current: string;
    visible: boolean;
    LightLogo: string | null;
    DarkLogo: string | null;
    IsCircular: boolean;
    cdnLink: string;
}
class LeftSideBarMobile extends React.Component<LeftSideBarMobileProps, LeftSideBarMobileState> {
    // submenu keys of first level
    //without this in tsx 
    isElite: boolean = CheckIfCompanyIs("elite");
    isJKV: boolean = CheckIfCompanyIs("JKV");
    isOrbit: boolean = CheckIfCompanyIs("Orbit");
    isTNFX: boolean = CheckIfCompanyIs("TNFX");
    isIconFx: boolean = CheckIfCompanyIs("Icon");
    state = {
        // Mobile
        SecondaryIcon: '',
        cdnLink: "",
        current: 'mail',
        visible: false,
        collapsed: this.props.theme.MenuIsCollapsed,
        defaultSelectedKeys: '1000',
        selectedSetting: false,
        OpenContactUs: false,
        LightLogo: '',
        DarkLogo: '',
        IsCircular: false, website: ''
    };
    componentDidMount() {
        this.setState({
            cdnLink: this.props.CompanyDataProps.CDNURL
        });
        this.setState({
            LightLogo: this.props.CompanySettings ? this.props.CompanySettings.LightLogo : null,
            SecondaryIcon: this.props.CompanySettings ? this.props.CompanySettings.SecondaryIcon : null,
            DarkLogo: this.props.CompanySettings ? this.props.CompanySettings.DarkLogo : null,
            IsCircular: this.props.CompanySettings ? this.props.CompanySettings.IsCircular : false
        });
        if (this.props.userData?.isAdmin) {
            DisplayZendDeskChat(true);
        } else {
            DisplayZendDeskChat(false);
        }
    }


    openContactusModal = () => {
        this.setState({ OpenContactUs: !this.state.OpenContactUs })
    }
    closeContact = () => {
        this.setState({ OpenContactUs: false })
    }
    navigatetoSettings = () => {
        this.props.push("/PortalSettings/CompanySettings");
    }
    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };
    ReturnLogo = (): string | undefined => {
        return (this.props.theme.currentTheme === "addons-LightTheme" || this.props.theme.currentTheme === "addons-OrangeTheme" || this.props.theme.currentTheme === "addons-PrimexTheme") ? this.state.LightLogo : this.state.DarkLogo;
    }
    render() {
        var TraderUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Trader', this.props.UserPermissions);
        var ClientsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Clients', this.props.UserPermissions);
        var PartnersUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Partners', this.props.UserPermissions);
        var MAMUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('MAM', this.props.UserPermissions);
        var BackOfficeUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Back Office', this.props.UserPermissions);
        var BonusUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Bonus', this.props.UserPermissions);
        var OnBoardingUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('On Boarding', this.props.UserPermissions);
        var ReportsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Reports', this.props.UserPermissions);
        var NotesUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Notes', this.props.UserPermissions);
        var TasksUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Tasks', this.props.UserPermissions);
        var PortalSettingsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Portal Settings', this.props.UserPermissions);
        var DynamicMargin: UserPermissionOBjectClass = GetPermissionDetailsByName('Dynamic Margin', this.props.UserPermissions);
        let LogsPermission: UserPermissionOBjectClass = GetPermissionDetailsByName('Logs', this.props.UserPermissions);
        var ViewAccountTypes: boolean = CheckIfPermAlllowedByName('View Account Types', TraderUserPerms.Perms);
        var isA = this.props.userData.isAdmin;
        var isAdminBonus = isA && this.props.CompanyData.IsBonus;
        var isAdminIB = isA && this.props.CompanyData.IsIB;
        var isAdminMM = isA && this.props.CompanyData.IsMM;
        var isAdminCRM = isA && this.props.CompanyData.IsCRM;
        var isAdminDynamicMargin = isA && this.props.CompanyData.IsDynamicMargin;
        //Client
        //CRM
        var isClientCRMPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('CRM', this.props.UserPermissions);
        var isClientCRM = !isA && this.props.CompanyData.IsCRM && isClientCRMPermssion.Allowed;
        //IB
        var isClientIBPermssion: UserPermissionOBjectClass = GetPermissionDetailsByName('IB', this.props.UserPermissions);
        var isClientIB = !isA && this.props.CompanyData.IsIB && isClientIBPermssion.Allowed;
        var isPromotion: boolean = CheckIfPermAlllowedByName('Promotion', isClientIBPermssion.Perms);//if same name take from props name
        var isClientReporting: boolean = CheckIfPermAlllowedByName('Reporting', isClientIBPermssion.Perms);
        var isClientBreakDownReport: boolean = CheckIfPermAlllowedByName('Breakdown Report', isClientIBPermssion.Perms);
        var isClientAccessSubIB: boolean = CheckIfPermAlllowedByName('Access SubIB', isClientIBPermssion.Perms);
        //MAM
        var isClientMM = !isA && this.props.CompanyData.IsMM;//3 parents here
        //#region MAM parents 
        var isClientMoneyManager: UserPermissionOBjectClass = GetPermissionDetailsByName('Money Manager', this.props.UserPermissions);
        var isClientInvestorCopier: UserPermissionOBjectClass = GetPermissionDetailsByName('Investor/Copier', this.props.UserPermissions);
        var isClientSignalMAMPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Signal', this.props.UserPermissions);//copier
        //#endregion 
        var LeaderBoard: boolean = CheckIfPermAlllowedByName('LeaderBoard', isClientInvestorCopier.Perms);
        var isRequireKYC = this.isKYCRequired(isA);
        var isAccountExist = !isA && parseInt(String(this.props.ClientData.NmbrOfAcc), 10) > 0;
        var isHasKYC = !isA && this.props.ClientData.hasKYC;
        var isValid = !isA && (this.props.ClientData.IsValid ? true : false);//as may be null
        var isNotRequiredAndAccountExist = (!isRequireKYC && isAccountExist);
        var isRequiredKycSumbitedAndAccountExist = (isRequireKYC && isAccountExist && isHasKYC);
        var isRequiredKycAndSumbited = (isRequireKYC && isHasKYC);
        var AllowAddDemoAcc = !isA && this.props.ClientData?.AllowAddDemoAcc;
        let { BonusReportsPerm, CRMREports, IBReports, DLReports, BackofficeReports, MAMReports } = GetReportsPerms(ReportsUserPerms);
        let _OneOFReportsEnabled = OneOFReportsEnabled({ BonusReportsPerm, CRMREports, IBReports, DLReports, BackofficeReports, MAMReports });
        return (
            <>
                <div className="mobilMenuLeftSideBarMobile">
                    <nav className="menuBar">
                        <div className="menuCon">
                            <Drawer
                                placement="left"
                                closable={false}
                                className="MobileDrawer"
                                onClose={this.props.onClose}
                                visible={this.props.visible}
                            >
                                <Menu mode="inline">
                                    <div className=" d-contents ml-4" >
                                        {!!this.state.SecondaryIcon && <img
                                            src={this.state.SecondaryIcon || ""}
                                            alt="SecondaryIcon"
                                            className=" mr-3 YLogo"
                                        />}
                                        {!!this.ReturnLogo() && <img
                                            src={this.ReturnLogo()}
                                            alt="logo"
                                            className={this.state.IsCircular ? "circularLogo" : " h30"}
                                        />}
                                    </div>
                                    {(!isA && ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) && <Menu.Item key="main" >
                                        <Link to="/" className="titleMobileMenu">

                                            <span className="titleMenu titleClienMenu">
                                                <span className="dashboardIconClient"  >    <DashboardIcon className="dashboardIconClient" /> </span> {this.props.t("Dashboard")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}

                                    {((!isA && this.DisplayMyAccounts(isClientCRM, isRequireKYC, isValid)) && !this.isTNFX) &&
                                        this.RenderMyAccountsSubMenu(isClientCRM, isRequireKYC, isValid, AllowAddDemoAcc)
                                    }
                                    {((!isA && !this.isIconFx) && (!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))))) && <Menu.Item key="/MyWallet">
                                        <Link to="/MyWallet" className="titleMobileMenu">
                                            <span >
                                                <i className="fas fa-wallet MyWalletIcon mr-3"></i>   {this.props.t("MyWallet")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                    {!isA && this.DisplayOnbording(isRequireKYC) && isClientCRM &&
                                        <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-layer-group OnboardingIcon mr-3"></i>    {this.props.t("OnBoarding")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {
                                                    !isA && this.DisplayOnbording(isRequireKYC) &&
                                                    //(applicationFormPerm) && !isA && hasKYCdminBonus &&
                                                    <Menu.Item key="/OnBoarding/ApplicationForm" className="MenuItemsSub" >
                                                        <Link to="/OnBoarding/ApplicationForm">

                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-file-signature mr-3"></i>   {this.props.t("Application")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }

                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientIB)) &&
                                        <SubMenu title={
                                            <span>

                                                <span className="titleMobileMenu">
                                                    <i className="fas fa-handshake IBClientIcon mr-3"></i> {this.props.t("IB")}
                                                </span>
                                            </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {true &&
                                                    <Menu.Item key="/IB/IBClients" className="MenuItemsSub">
                                                        <Link to="/IB/IBClients">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-users mr-3"></i>   {this.props.t("Clients")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isClientIB && !this.isTNFX) && <Menu.Item key="/IB/leads" className="MenuItemsSub" >
                                                    <Link to="/IB/leads">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user-check mr-3"></i>{this.props.t("Leads")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {isClientIB && <Menu.Item key="/IB/accountStatement" className="MenuItemsSub" >
                                                    <Link to="/IB/accountStatement" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-alt mr-3"></i>   {this.props.t("AccountStatement")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isClientAccessSubIB && !this.isTNFX) &&
                                                    <Menu.Item key="/IB/subIBStatement" className="MenuItemsSub">
                                                        <Link to="/IB/subIBStatement">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-file-invoice-dollar mr-3"></i> {this.props.t("SubIBStatement")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {isClientReporting && <Menu.Item key="/IB/CPAReport" className="MenuItemsSub">
                                                    <Link to="/IB/CPAReport">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-donate mr-3"></i>   {this.props.t("CPAReport")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {isClientBreakDownReport && <Menu.Item key="/IB/BreakdownReport" className="MenuItemsSub">
                                                    <Link to="/IB/BreakdownReport">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-chart-line mr-3"></i>
                                                            {this.props.t("BreakdownReport")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {isClientIB && < Menu.Item key="/IB/IBDiagram" className="MenuItemsSub">
                                                    <Link to="/IB/IBDiagram">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-project-diagram mr-3"></i>   {this.props.t("IBDiagram")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {isClientIB && isPromotion && < Menu.Item key="/IB/Promotion" className="MenuItemsSub">
                                                    <Link to="/IB/Promotion">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-bullhorn mr-3"></i>{this.props.t("IB")} {this.props.t("Promotion")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(!isA && (!isClientCRM && (isClientMM && isClientMoneyManager.Allowed)) || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) &&
                                        this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-folder-open PortfolioIcon mr-3"></i>  {this.ReturnPortfolioName()}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isClientMM && isClientMoneyManager.Allowed) &&
                                                    <Menu.Item key="/Portfolio/Investors" className="MenuItemsSub">
                                                        <Link to="/Portfolio/Investors">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-hand-holding-usd mr-3"></i>   {this.props.t("Investors")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isClientMM && isClientMoneyManager.Allowed) &&
                                                    <Menu.Item key="/Portfolio/InvestorsTracker" className="MenuItemsSub">
                                                        <Link to="/Portfolio/InvestorsTracker">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-search-location mr-3"></i> {this.props.t("InvestorsTracker")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isClientMM && isClientMoneyManager.Allowed) &&
                                                    <Menu.Item key="/Portfolio/PositionSummary" className="MenuItemsSub">
                                                        <Link to="/Portfolio/PositionSummary">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-file-invoice mr-3"></i>   {this.props.t("OpenPositionSummary")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>}
                                                <Menu.Item key="/Portfolio/HistorySummary" className="MenuItemsSub">
                                                    <Link to="/Portfolio/HistorySummary">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-alt mr-3"></i>     {this.props.t("TradeHistorySummary")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>

                                                {(isClientMM && isClientMoneyManager.Allowed) &&
                                                    <Menu.Item key="/Portfolio/PerformanceTracker" className="MenuItemsSub">
                                                        <Link to="/Portfolio/PerformanceTracker">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-chart-area mr-3"></i>  {this.props.t("FeesPerformanceTracker")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isClientMM && isClientMoneyManager.Allowed) &&
                                                    <Menu.Item key="/Portfolio/MonthlyFees" className="MenuItemsSub" >
                                                        <Link to="/Portfolio/MonthlyFees">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-calendar-check mr-3"></i>{this.props.t("MonthlyFees")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientMM && isClientSignalMAMPerms.Allowed)) &&
                                        this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && <Menu.Item key="/Copiers" >
                                            <Link to="/Copiers" className="titleMobileMenu ">

                                                <span className="titleMobileMenu">
                                                    <i className="fas fa-comment-dollar CopiersIcon mr-3"></i>  {this.props.t("Copiers")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && (isClientMM && LeaderBoard)) &&
                                        <Menu.Item key="/LeaderBoard" >
                                            <Link to="/LeaderBoard" className="titleMobileMenu ">

                                                <span className="titleMobileMenu">
                                                    <i className="fas fa-satellite-dish LeaderBoardIcon mr-3"></i> {this.props.t("LeaderBoard")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {(!isA && ["plugit", "capstock"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && <Menu.Item key="/AnalystView" >
                                        <Link to="/AnalystView" className="titleMobileMenu ">
                                            <span className="titleMobileMenu">
                                                <i className="fad fa-chart-bar LeaderBoardIcon mr-3"></i> {this.props.t("AnalystView")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                    {(!isA && ["plugit", "finzolo"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && <Menu.Item key="/WebTerminal" >
                                        <Link to="/WebTerminal" className="titleMobileMenu ">
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-terminal LeaderBoardIcon mr-3"></i> {this.props.t("WebTerminal")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                    {(!isA && ["plugit", "marketequity"].includes(this.props.CompanyData?.CompanyName?.toLowerCase())) && <Menu.Item key="/WebTrader" >
                                        <Link to="/WebTrader" className="titleMobileMenu ">
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-terminal LeaderBoardIcon mr-3"></i> {this.props.t("WebTrader")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                    {!isA && (CheckIfCompanyIs("TiranForex") || CheckIfCompanyIs("PLUGIT")) && <Menu.Item key="/MarketCalendar" >
                                        <Link to="/MarketCalendar" className="titleMobileMenu ">
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-terminal LeaderBoardIcon mr-3"></i> {this.props.t("MarketCalendar")}
                                            </span>
                                        </Link>
                                    </Menu.Item>}
                                    {(!isA && (!isClientCRM || (isClientCRM && (!isRequireKYC || isRequiredKycAndSumbited))) && ((isClientMM && (isClientMoneyManager.Allowed || isClientInvestorCopier.Allowed || isClientSignalMAMPerms.Allowed)) || isClientCRM)) &&
                                        this.checkIfAllowedForPureNorthMAM(isClientMM, isClientMoneyManager) && <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-chart-pie AnalyticsIcon mr-3"></i>  {this.props.t("Analytics")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {true && <Menu.Item key="/Analytics/Overview" className="MenuItemsSub">
                                                    <Link to="/Analytics/Overview">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-chart-line mr-3"></i> {this.props.t("Overview")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {!isA && <Menu.Item key="/Analytics/trades" className="MenuItemsSub">
                                                    <Link to="/Analytics/trades">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-exchange-alt mr-3"></i>  {this.props.t("Trades")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {true && <Menu.Item key="/Analytics/RiskMeasures" className="MenuItemsSub">
                                                    <Link to="/Analytics/RiskMeasures">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-exclamation-triangle mr-3"></i> {this.props.t("RiskMeasures")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {true && <Menu.Item key="/Analytics/HistoricalPerformance" className="MenuItemsSub">
                                                    <Link to="/Analytics/HistoricalPerformance">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-history mr-3"></i>   {this.props.t("HistoricalPerformance")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}

                                            </MenuItemGroup>
                                        </SubMenu>
                                    }

                                    {(!isA && (this.checkIfAllowedForPureNorthLogs() || this.isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist))))) &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-history LogsIcon mr-3" ></i> {this.props.t("Logs")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                <Menu.Item key="/Logs/AccountLogs" className="MenuItemsSub">
                                                    <Link to="/Logs/AccountLogs">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-exchange-alt mr-3"></i>  {this.props.t("AccountLogs")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>
                                                {(this.isJKV || ((!isClientCRM && isAccountExist) || (isClientCRM && (isNotRequiredAndAccountExist || isRequiredKycSumbitedAndAccountExist)))) &&
                                                    <Menu.Item key="/Logs/TransactionsLogs" className="MenuItemsSub" >
                                                        <Link to="/Logs/TransactionsLogs">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-search-dollar mr-3"></i>    {this.props.t("TransactionsLogs")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }

                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(!isA && this.isElite) &&
                                        <Menu.Item key="/Platforms" >
                                            <Link to="/Platforms" className="titleMobileMenu">
                                                <span className="titleMenu titleClienMenu">
                                                    <span className="dashboardIconClient"  >    <DashboardIcon className="dashboardIconClient" /> </span> {this.props.t("Platforms")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {((!isA && this.DisplayMyAccounts(isClientCRM, isRequireKYC, isValid)) && this.isTNFX) &&
                                        this.RenderMyAccountsSubMenu(isClientCRM, isRequireKYC, isValid, AllowAddDemoAcc)
                                    }
                                    {isA &&
                                        (isAdminCRM || (isAdminMM) || isAdminIB) &&
                                        <Menu.Item key="/" >
                                            <Link to="/" className="titleMobileMenu">
                                                <span className="titleMenu titleClienMenu">
                                                    <span className="dashboardIconClient"  >    <DashboardIcon className="dashboardIconClient" /> </span> {this.props.t("Dashboard")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {(isA && (isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB)) && ClientsUserPerms.Allowed &&
                                        <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-users clientsIcon mr-3"></i>{this.props.t("Clients")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/ClientPage" className="MenuItemsSub">
                                                    <Link to="/Clients/ClientPage">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user mr-3"></i>  {this.props.t("Clients")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}
                                                {(isA && (isAdminCRM || (isAdminMM) || isAdminIB)) && <Menu.Item key="/Clients/Wallet" className="MenuItemsSub">
                                                    <Link to="/Clients/Wallet">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-wallet mr-3"></i>    {this.props.t("Wallet")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/DeletedClients" className="MenuItemsSub">
                                                    <Link to="/Clients/DeletedClients" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user-minus mr-3"></i>  {this.props.t("DeletedClients")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminCRM || isAdminIB) && <Menu.Item key="/Clients/Leads" className="MenuItemsSub">
                                                    <Link to="/Clients/Leads">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user-check mr-3"></i>    {this.props.t("Leads")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/Contact" className="MenuItemsSub">
                                                    <Link to="/Clients/Contact">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-address-book mr-3"></i>  {this.props.t("Contacts")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && <Menu.Item key="/Clients/Pools" className="MenuItemsSub">
                                                    <Link to="/Clients/Pools">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-funnel-dollar mr-3"></i>  {this.props.t("Pools")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {isAdminCRM && isA && OnBoardingUserPerms.Allowed &&
                                        <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-layer-group onboardingIcon mr-3"></i>  {this.props.t("OnBoarding")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {isAdminCRM && <Menu.Item key="/OnBoarding/Application" className="MenuItemsSub">
                                                    <Link to="/OnBoarding/Application">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-signature mr-3"></i>     {this.props.t("Application")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}

                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isAdminBonus || isAdminCRM || (isAdminMM) || isAdminIB) && TraderUserPerms.Allowed &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-exchange-alt traderIcon mr-3"></i>    {this.props.t("Trader")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                <Menu.Item key="/Trader/TradingAccounts" className="MenuItemsSub">
                                                    <Link to="/Trader/TradingAccounts">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-chart-line mr-3"></i>   {this.props.t("TradingAccounts")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/Trader/DemoAccounts" className="MenuItemsSub">
                                                    <Link to="/Trader/DemoAccounts">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-random mr-3"></i>   {this.props.t("DemoAccounts")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>
                                                {ViewAccountTypes && <Menu.Item key="/Trader/AccountTypes" className="MenuItemsSub">
                                                    <Link to="/Trader/AccountTypes">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-users-cog mr-3"></i>    {this.props.t("AccountTypes")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}

                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isAdminIB) && isA && PartnersUserPerms.Allowed &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-handshake partnersIcon mr-3"></i>    {this.props.t("Partners")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isAdminIB) && <Menu.Item key="/IBAdmin/IBAllocation" className="MenuItemsSub">
                                                    <Link to="/IBAdmin/IBAllocation" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-cogs mr-3"></i>   {this.props.t("IBAllocations")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminIB) && <Menu.Item key="/IBAdmin/ProfileTemplates" className="MenuItemsSub">
                                                    <Link to="/IBAdmin/ProfileTemplates">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-address-book mr-3"></i>   {this.props.t("ProfileTemplates")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminIB) && <Menu.Item key="/IBAdmin/CapaignLinks" className="MenuItemsSub">
                                                    <Link to="/IBAdmin/CampaignLinks">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-link mr-3"></i>  {this.props.t("CampaignLinks")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isAdminMM) && isA && MAMUserPerms.Allowed &&
                                        <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-users-cog MAMIcon mr-3"></i>   MAM
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isAdminMM) && <Menu.Item key="/MAM/MAMAllocations" className="MenuItemsSub">
                                                    <Link to="/MAM/MAMAllocations">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-hand-holding-usd mr-3"></i>
                                                            {this.props.t("MamAllocations")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM) && <Menu.Item key="/MAM/SignalAllocations" className="MenuItemsSub">
                                                    <Link to="/MAM/SignalAllocations">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-comment-dollar mr-3"></i>  {this.props.t("SignalAllocations")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isA && (isAdminCRM || (isAdminMM) || isAdminIB) && BackOfficeUserPerms.Allowed) &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-laptop-code BackofficeIcon mr-3"></i>
                                                {this.props.t("BackOffice")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isAdminCRM || isAdminMM || isAdminIB) && <Menu.Item key="/BackOffice/OperationRequest" className="MenuItemsSub">
                                                    <Link to="/BackOffice/OperationRequest">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-code mr-3"></i> {this.props.t("OperationRequests")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminCRM && OnBoardingUserPerms.Allowed) && <Menu.Item key="/BackOffice/ApplicationRequest" className="MenuItemsSub">
                                                    <Link to="/BackOffice/ApplicationRequest">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user-clock mr-3"></i>    {this.props.t("ApplicationRequest")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isAdminBonus) && BonusUserPerms.Allowed &&
                                        <SubMenu title={<span>
                                            <span className="titleMobileMenu">
                                                <i className="fas fa-gift BonusIcon mr-3"></i>    {this.props.t("Bonus")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                <Menu.Item key="/Bonus/Promotion" className="MenuItemsSub">
                                                    <Link to="/Bonus/Promotion">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-bullhorn mr-3"></i>   {this.props.t("Promotion")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isAdminDynamicMargin) && DynamicMargin.Allowed &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-balance-scale-left dynamicIcon mr-3"></i>  {this.props.t("DynamicMargin")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                <Menu.Item key="/DynamicMargin/SymbolProfile" className="MenuItemsSub">
                                                    <Link to="/DynamicMargin/SymbolProfile">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-coins mr-3"></i> {this.props.t("SymbolProfile")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key="/DynamicMargin/AccountProfile" className="MenuItemsSub">
                                                    <Link to="/DynamicMargin/AccountProfile">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-user-clock mr-3"></i> {this.props.t("AccountProfile")}
                                                        </span>

                                                    </Link>
                                                </Menu.Item>
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {((isA && (isAdminCRM || (isAdminMM) || isAdminIB || isAdminBonus || isAdminDynamicMargin)) && ReportsUserPerms.Allowed && !!_OneOFReportsEnabled) &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-chalkboard-teacher reportsIcon mr-3"></i>     {this.props.t("Reports")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {((isAdminIB && IBReports) || (isAdminMM && MAMReports) || (isAdminCRM && CRMREports)) &&
                                                    <Menu.Item key="/Reports/Transactions" className="MenuItemsSub">
                                                        <Link to="/Reports/Transactions" >
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-search-dollar mr-3"></i>      {this.props.t("Wallet")}  {this.props.t("Transactions")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>}
                                                {(isAdminMM && MAMReports) && <Menu.Item key="/Reports/PerformanceFees" className="MenuItemsSub">
                                                    <Link to="/Reports/PerformanceFees" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-money-check-alt mr-3"></i> {this.props.t("PerformanceFees")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM && MAMReports) && <Menu.Item key="/Reports/SignalsPerformanceFees" className="MenuItemsSub">
                                                    <Link to="/Reports/SignalsPerformanceFees" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-money-check-alt mr-3"></i> {this.props.t("SignalsPerformanceFees", { defaultValue: "Signals Performance Fees" })}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminIB && IBReports) && <Menu.Item key="/Reports/IBDetailedReport" className="MenuItemsSub">
                                                    <Link to="/Reports/IBDetailedReport" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-invoice mr-3"></i>   {this.props.t("IBDetailedReport")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminIB && IBReports) && <Menu.Item key="/Reports/CPAReport" className="MenuItemsSub">
                                                    <Link to="/Reports/CPAReport" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-donate mr-3"></i>      {this.props.t("CPAReport")}
                                                        </span>
                                                    </Link>

                                                </Menu.Item>}
                                                {(isAdminIB && IBReports) && <Menu.Item key="/Reports/BreakdownReport" className="MenuItemsSub">
                                                    <Link to="/Reports/BreakdownReport" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-donate mr-3"></i>      {this.props.t("Breakdown")}
                                                        </span>
                                                    </Link>

                                                </Menu.Item>}
                                                {!isAdminBonus && <Menu.Item key="/Reports/ClicksConversion" className="MenuItemsSub">
                                                    <Link to="/Reports/ClicksConversion" >
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-comment-dollar mr-3"></i>     {this.props.t("ClicksConversion")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminCRM && CRMREports) && <Menu.Item key="/Reports/DocumentsExpiration" className="MenuItemsSub">
                                                    <Link to="/Reports/DocumentsExpiration">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-calendar-times mr-3"></i>  {this.props.t("DocumentsExpiration")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {((isAdminCRM || (isAdminMM) || isAdminIB) && CRMREports) && < Menu.Item key="/Reports/RegistrationLogs" className="MenuItemsSub">
                                                    <Link to="/Reports/RegistrationLogs">

                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-contract mr-3"></i>  {this.props.t("RegistrationLogs")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {((isAdminCRM || (isAdminMM) || isAdminIB) && BackofficeReports) && <Menu.Item key="/Reports/OperationLogs" className="MenuItemsSub">
                                                    <Link to="/Reports/OperationLogs">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-invoice mr-3"></i>     {this.props.t("OperationLogs")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminCRM && CRMREports) && <Menu.Item key="/Reports/closetrades" className="MenuItemsSub">
                                                    <Link to="/Reports/closetrades">
                                                        <span >
                                                            <i className="fal fa-compress-arrows-alt mr-3"></i>     {this.props.t('ClosedTrades')}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminDynamicMargin && DLReports) &&
                                                    <Menu.Item key="/Reports/DynamicReport" className="MenuItemsSub">
                                                        <Link to="/Reports/DynamicReport">
                                                            <span >
                                                                <i className="fal fa-balance-scale-left mr-3"></i>  {this.props.t('DynamicMarginReport')}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>}
                                                {(isAdminBonus && BonusReportsPerm) &&
                                                    <Menu.Item key="/Reports/BonusReport" className="MenuItemsSub">
                                                        <Link to="/Reports/BonusReport">
                                                            <span >
                                                                <i className="fal fa-gifts mr-3"></i>  {this.props.t('BonusReport')}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>}
                                                {(isAdminBonus && BonusReportsPerm) && <Menu.Item key="/Reports/BonusReferralReport" className="MenuItemsSub">
                                                    <Link to="/Reports/BonusReferralReport">
                                                        <span >
                                                            <i className="fal fa-file-code mr-3"></i>  {this.props.t('BonusReferralReport')}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isA && (isAdminMM || isAdminCRM) && ReportsUserPerms.Allowed) &&
                                        <SubMenu title={<span>

                                            <span className="titleMobileMenu">
                                                <i className="fas fa-chart-pie analyticsIcon mr-3"></i>    {this.props.t("Analytics")}
                                            </span>
                                        </span>} className="mobileSubMenu">
                                            <MenuItemGroup className="MobileMenu">
                                                {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/Overview" className="MenuItemsSub">
                                                    <Link to="/Analytics/Overview">
                                                        <i className="fal fa-chart-line mr-3"></i>
                                                        {this.props.t("Overview")}
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/Trades" className="MenuItemsSub">
                                                    <Link to="/Analytics/Trades">
                                                        <i className="fal fa-exchange-alt mr-3"></i>
                                                        {this.props.t("Trades")}
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/RiskMeasures" className="MenuItemsSub">
                                                    <Link to="/Analytics/RiskMeasures">
                                                        <i className="fal fa-exclamation-triangle mr-3"></i>
                                                        {this.props.t("RiskMeasures")}
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/HistoricalPerformance" className="MenuItemsSub">
                                                    <Link to="/Analytics/HistoricalPerformance">
                                                        <span >
                                                            <i className="fal fa-history mr-3"></i>    {this.props.t('HistoricalPerformance')}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                                {(isAdminMM) &&
                                                    <Menu.Item key="/Analytics/InvestorsReport" className="MenuItemsSub">
                                                        <Link to="/Analytics/InvestorsReport">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-hand-holding-usd  mr-3"></i>  {this.props.t("Investors")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isAdminMM) &&
                                                    <Menu.Item key="/Analytics/InvestorsTracker" className="MenuItemsSub">
                                                        <Link to="/Analytics/InvestorsTracker">
                                                            <span className="titleMobileMenu">
                                                                <i className="fal fa-search-location mr-3"></i> {this.props.t("InvestorsTracker")}
                                                            </span>
                                                        </Link>
                                                    </Menu.Item>
                                                }
                                                {(isAdminMM || isAdminCRM) && <Menu.Item key="/Analytics/TradeHistorySummary" className="MenuItemsSub">
                                                    <Link to="/Analytics/TradeHistorySummary">
                                                        <span className="titleMobileMenu">
                                                            <i className="fal fa-file-alt mr-3"></i>     {this.props.t("TradeHistorySummary")}
                                                        </span>
                                                    </Link>
                                                </Menu.Item>}
                                            </MenuItemGroup>
                                        </SubMenu>
                                    }
                                    {(isA && LogsPermission.Allowed) &&
                                        <Menu.Item key="/LogsReport" >
                                            <Link to="/LogsReport" className="titleMobileMenu">
                                                <span className="titleMobileMenu">
                                                    <i className="fas fa-history logsIcon mr-3"></i>      {this.props.t("Logs")}
                                                </span>
                                            </Link>
                                        </Menu.Item>
                                    }
                                    {isA && NotesUserPerms.Allowed && <Menu.Item key="/Notes" >
                                        <Link to="/Notes" className="titleMobileMenu">

                                            <span >
                                                <i className="fas fa-clipboard notesIcon mr-3"></i>   {this.props.t("Notes")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    }
                                    {isA && TasksUserPerms.Allowed && <Menu.Item key="/Tasks" >
                                        <Link to="/Tasks" className="titleMobileMenu">
                                            <span >
                                                <i className="fas fa-tasks tasksIcon mr-3"></i>  {this.props.t("Tasks")}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    }
                                    <Divider className="mt30 mb-3" />
                                    {isA && PortalSettingsUserPerms.Allowed &&
                                        <div onClick={this.navigatetoSettings} className="mb-3 ml-3">
                                            <span className="text-secondary cursor-pointer ContactusColor" >     <i className="fas fa-cog mr-3"></i> {this.props.t("PortalSettings")}</span>
                                        </div>
                                    }
                                    {!isA &&
                                        <div onClick={this.openContactusModal} className="mb-3 ml-3">
                                            <span className="text-secondary cursor-pointer ContactusColor" >     <i className="fas fa-envelope-open-text mr-3"></i> {this.props.t("ContactUs")}</span>
                                        </div>
                                    }
                                </Menu>
                                {!isA &&
                                    <>
                                        {this.state.OpenContactUs &&
                                            <ContactUs
                                                OpenContactUs={this.state.OpenContactUs}
                                                closeContact={this.closeContact}
                                            />
                                        }
                                    </>
                                }
                            </Drawer>

                        </div>
                    </nav>
                </div>
            </>
        );
    }

    checkIfAllowedForPureNorthMAM = (isClientMM: boolean, isClientMoneyManager: UserPermissionOBjectClass) => {
        return (!CheckIfCompanyIs("PureNorth") || (isClientMM && isClientMoneyManager.Allowed));
    }
    checkIfAllowedForPureNorthLogs = () => {
        return CheckIfCompanyIs("PureNorth");
    }
    isKYCRequired = (isA: boolean) => {
        if (this.isElite) return false;
        if (this.isOrbit) return false;
        return !isA && this.props.ClientData.RequireKYC;
    }
    ReturnPortfolioName = () => {
        if (this.isElite || this.isIconFx) return this.props.t("Portfolio");//TODO check if need to add check if MAM
        return `${this.props.t("MAM")} ${this.props.t("Portfolio")}`;
    }

    DisplayOnbording = (isRequireKYC: boolean) => {
        if (this.isElite) return this.props.ClientData.RequireKYC;
        if (this.isOrbit) return this.props.ClientData.RequireKYC;
        return isRequireKYC;
    }

    DisplayMyAccounts = (isClientCRM: boolean, isRequireKYC: boolean, isValid: boolean) => {
        if (this.isOrbit) return (!isClientCRM || (isClientCRM && (!this.props.ClientData.RequireKYC || (this.props.ClientData.RequireKYC && isValid))))
        return (!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid))));
    }

    RenderMyAccountsSubMenu = (isClientCRM: boolean, isRequireKYC: boolean, isValid: boolean, AllowAddDemoAcc: boolean): any => {
        return <SubMenu title={<span>
            <span className="titleMobileMenu">
                <i className="fas fa-user MyAccountsIcon mr-3"></i>   {this.props.t("MyAccounts")}
            </span>
        </span>} className="mobileSubMenu">
            <MenuItemGroup className="MobileMenu">
                {(!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid)))) /*(manageAccountsPerm)*/ &&
                    <Menu.Item key="/Manage/MyAccounts" className="MenuItemsSub">
                        <Link to="/Manage/MyAccounts">
                            <span className="titleMobileMenu">
                                <i className="fal fa-exchange-alt mr-3"></i>    {this.props.t("TradingAccounts")}
                            </span>
                        </Link>
                    </Menu.Item>}
                {(!isClientCRM || (isClientCRM && (!isRequireKYC || (isRequireKYC && isValid)))) && AllowAddDemoAcc &&
                    <Menu.Item key="/Manage/DemoAccounts" className="MenuItemsSub">
                        <Link to="/Manage/DemoAccounts">
                            <span className="titleMobileMenu">
                                <i className="fal fa-random mr-3"></i>  {this.props.t("DemoAccount")}
                            </span>
                        </Link>
                    </Menu.Item>}
            </MenuItemGroup>
        </SubMenu>;
    }
}


export default withTranslation()(connect(mapStateToProps, dispatchProps)(LeftSideBarMobile) as any) as any;

