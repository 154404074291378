import { faAlignLeft, faChevronDown, faCode, faCog, faDownload, faExpandAlt, faEye, faFileImport, faFilter, faImage, faRedo, faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventHandlerPayload, LiveChatWidget } from '@livechat/widget-react';
import { Tooltip } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from "@material-ui/core/Box";
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import TextField from '@mui/material/TextField';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Badge, DatePicker, Divider, Form, Input as AntInput, Radio, Skeleton, Switch, Tag, TimePicker } from 'antd';
import 'antd/dist/antd.css';
import { default as ButtonANTD } from "antd/es/button";
import Checkbox from 'antd/es/checkbox';
import Icon from 'antd/es/icon';
import InputAnt from 'antd/es/input';
import Tabs from "antd/es/tabs";
import { AxiosResponse } from 'axios';
import { push } from "connected-react-router";
import { cloneDeep } from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import * as React from 'react';
import { Eye } from "react-feather";
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { default as Select } from 'react-select';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, Table, UncontrolledDropdown } from "reactstrap";
import { InputType } from 'reactstrap/lib/Input';
import { CallAxiosRequest } from '../../../Helpers/axiosHelpers';
import { CheckIfCompanyIs, CheckIfIsExternalPage, CheckIFTrenchesIntervalIsValid, ConvertIntoDictionaryWithSpecificValue, DAY_DICTIONARY, DAY_OPTIONS, EPermissionValue, GetDayPostBy, GetDayPriorBy, getImagesURL_IGain_LDN, getImagesURL_LDN, LowerCaseExists, NumberExists, ReturnAccSecType, ReturnCountryCode, ReturnImageName, ReturnPlaceHolderStatement, ReturnSecuirtyTypeTitle, ReturnSymbolSecType, ReturnValuesForMultiSelect, SpecialCharacterExists, UpperCaseExists, ValidateExpLimit, ValidateInput, ValidationTXT } from '../../../Helpers/DataHelpers';
import { BasicSelectOption } from '../../../Interfaces/IApplicationForm';
import { GetCompanyDataResult, GetUserRolesPermsList, IMarginTiles, LoadCompanyLogoResult, PermsLicList, RolePermsLic, TierClass, translationType, UserRolesList, YoonitResponse } from '../../../Interfaces/Interfaces';
import { GetAdminData, GetClientData, ICompanyData, IStore, IUserData } from '../../../redux/constants';
import store from '../../../redux/store';

declare var require: any;
const $ = require("jquery");
const moment = require("moment");
const { TabPane } = Tabs;
const { SpinnerComponent }: any = require('react-element-spinner');
const { RangePicker } = DatePicker;

interface DateIndexProps {
    onChange: any;
    label: any;
}
export class DateRangePicker extends React.Component<DateIndexProps> {
    render() {
        return (
            <div className="d-inline-flex ">
                <FormGroup>
                    {this.props.label}
                    <RangePicker
                        onChange={this.props.onChange}
                        showToday={true}
                        className="rangePicker"
                    />
                </FormGroup>
            </div>

        )
    }
};

interface ExpIndexProps {
    exportBtnOnclick: any;

}

export class ExportBtn extends React.Component<ExpIndexProps> {
    render() {
        return (
            <div className="d-inline-flex cursor-pointer padding-0-10">
                <span onClick={this.props.exportBtnOnclick}><span className="ExportIcon"> <Icon type="file" /> </span> Export to File </span>
            </div>
        )
    }
};

interface FilterProps {
    filterbar?: any;
    buttonClick?: any;
    filterFirstTable?: any;
    filterKeyWord?: any;
    FilterBy?: any;
    showSeatch?: boolean;
}

export class FiltrationButton extends React.Component<FilterProps> {
    render() {
        return (
            <div className="d-inline-flex divRight">
                {this.props.showSeatch &&
                    <InputAnt prefix={<FontAwesomeIcon icon={faSearch} className="mr-2 searchIcon" />}
                        placeholder={this.props.FilterBy + " " + this.props.filterKeyWord}
                        className={"SearchInputFilter"}
                        name="Search"
                        onKeyUp={this.ReloadTable}
                    />
                }
                <UncontrolledDropdown nav inNavbar id="nameUser" className="d-inline-flex divRight ">
                    <span className="d-sm-inline-block hoverIcon">
                        <DropdownToggle nav care className="filterDropDown">
                            <Button className=" ButtonsModal AddBtn ml-2 divRight" >  <FontAwesomeIcon icon={faFilter} className="cursor-pointer mr-2" /> <FontAwesomeIcon icon={faChevronDown} className="cursor-pointer mr-2" /> </Button>
                        </DropdownToggle>
                    </span>

                    <DropdownMenu right className="align-self-center ThemeDropDownMenu2">
                        <Row md={12}>
                            <DropdownItem className="ThemeDropDown" toggle={false}>
                                <FormGroup>
                                    {this.props.filterbar}
                                </FormGroup>
                            </DropdownItem>

                        </Row>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>

        )
    }

    ReloadTable = (e: any) => {
        if (e.keyCode === 13 || (!e.target.value && e.keyCode === 8))
            this.props.filterFirstTable(e);
    }
};
interface FiltrationButtonPagesProps {
    filterOn?: any;
    onClick?: any;
}
export class FiltrationButtonPages extends React.Component<FiltrationButtonPagesProps> {
    render() {
        return (
            <div className="d-inline-flex divRight hideFilter">
                <Button className={this.props.filterOn ? " ButtonsClicked AddBtn ml-2 divRight" : " ButtonsModal AddBtn ml-2 divRight"} onClick={this.props.onClick}>
                    <FontAwesomeIcon icon={faFilter} className="cursor-pointer mr-2" />
                    <FontAwesomeIcon icon={faChevronDown} className="cursor-pointer mr-2" />
                </Button>
            </div>

        )
    }
};
interface ColumnsButtonsProps {
    BtnOn?: any;
    openModal?: any;
    theme?: any;
}
export class ColumnsButtons extends React.Component<ColumnsButtonsProps> {
    state = {
        openModal: false
    }
    handleModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }
    render() {
        return (
            <div className="d-inline-flex divRight hideFilter text-center">
                <Button className={this.props.BtnOn ? " ButtonsClicked AddBtn ml-2 divRight " : " ButtonsModal AddBtn ml-2 divRight"} onClick={this.handleModal}>
                    <FontAwesomeIcon icon={faCog} className="cursor-pointer text-center" />
                </Button>
                {this.state.openModal &&
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={"Model"}
                        open={this.state.openModal}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.openModal}>
                            <div className={"ModelPaper2 ModelColumns m10mr10"}>
                                <div className="d-flex" >
                                    <div className="mr-auto p-2 mt-3"><h2 className="TitleModal">Edit Columns </h2></div>
                                    <div className="ml-auto floatRight">
                                        <Close className="closeButton mr-1 cursor-pointer" onClick={this.handleModal} /></div>
                                </div>

                                <div className="BodyModal3">
                                    <div className="overflowxHidden">
                                        <Label className="LabelCreate2" > Customize your table by choosing columns from the list below. </Label>
                                        <Row className=" m10">
                                            <Col md={8}>
                                                <Label className="ml-10 LabelCreate2 d-contents font-14 mr-2" > Column 1 </Label>
                                            </Col>
                                            <Col md={4}>
                                                <Switch checkedChildren="ON"
                                                    unCheckedChildren="OFF"
                                                    className="d-contents "
                                                />
                                            </Col>
                                        </Row>
                                        <Row className=" m10">
                                            <Col md={8}>
                                                <Label className="ml-10 LabelCreate2 d-contents font-14 mr-2" > Column 2 </Label>
                                            </Col>
                                            <Col md={4}>
                                                <Switch checkedChildren="ON"
                                                    unCheckedChildren="OFF"
                                                    className="d-contents "
                                                />
                                            </Col>
                                        </Row>
                                        <Row className=" m10">
                                            <Col md={8}>
                                                <Label className="ml-10 LabelCreate2 d-contents font-14 mr-2" > Column 3 </Label>
                                            </Col>
                                            <Col md={4}>
                                                <Switch checkedChildren="ON"
                                                    unCheckedChildren="OFF"
                                                    className="d-contents "
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="ButtonsBottom">
                                    <div className="btnModal">
                                        <div className="floatRight">
                                            <Button className="mr-2 ButtonsModal mt-2" > Save </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>

                    </Modal>
                }
            </div>

        )
    }
};

interface FilterdateProps {
    monthSelectOptions: any;
    monthSelectValue: any;
    monthSelectonChange: any;
    inputDateValue: any;
    isMulti: boolean;
    isNotMultiIsSearchable?: boolean;
    yearChange: any;
    inputPlaceholder?: any;
    yearBlur?: any;
    placeholderMonth?: any
}

export class FiltrationDate extends React.Component<FilterdateProps> {
    render() {
        return (
            <div className="d-inline-flex" >
                <div>
                    <Input
                        type="number"
                        bsSize="lg"
                        className="ml-3 mb-3 w100 p10 FilterInput"
                        value={this.props.inputDateValue}
                        maxLength={4}
                        onChange={this.props.yearChange}
                        onBlur={this.props.yearBlur ? this.props.yearBlur : () => { }}
                        placeholder={this.props.inputPlaceholder}
                    />
                </div>
                <div >
                    {!this.props.isMulti &&
                        <Select
                            className={"react-select-container w150 MediumFilterSelect"}
                            classNamePrefix={"react-select cursor-pointer MediumFilterSelect  "}
                            options={this.props.monthSelectOptions}
                            isSearchable={!this.props.isNotMultiIsSearchable ? false : true}
                            value={this.props.monthSelectValue}
                            onChange={this.props.monthSelectonChange}
                            placeholder={this.props.monthSelectValue}
                            isMulti={this.props.isMulti}

                        />
                    }
                    {this.props.isMulti &&
                        <FilterMultiSelect
                            value={this.props.monthSelectValue}
                            options={this.props.monthSelectOptions}
                            onChange={this.props.monthSelectonChange}
                            placeholder={this.props.placeholderMonth}
                            inpages={true}
                        />

                    }
                </div>
            </div>
        );
    }
}


interface OutlineBtnProps {
    type?: any;
    class?: any;
    id?: any;
    onClick?: any;
    btnText?: any;
}

export class OutlineBtn extends React.Component<OutlineBtnProps> {
    render() {
        return (
            <div className="input-group" >
                <Button type={this.props.type} className={this.props.class} id={this.props.id} onClick={this.props.onClick} > {this.props.btnText} </Button>
            </div >
        )
    }
};

interface PerIndexProps {
    perSelectOptions: any;
    perSelectValue: any;
    perSelectonChange: any;

}

export class PerTimeSelect extends React.Component<PerIndexProps> {
    render() {
        return (
            <div className="d-inline-flex">
                <Select
                    className="react-select-container perSelect"
                    classNamePrefix="react-select cursor-pointer perSelect  "
                    options={this.props.perSelectOptions}
                    isSearchable={true}
                    value={this.props.perSelectValue}
                    onChange={this.props.perSelectonChange}
                />
            </div>
        );
    }
}

interface RefreshIndexProps {
    refreshBtnOnclick: any;
    refreshText: any;

}

export class RefreshBtn extends React.Component<RefreshIndexProps> {
    render() {
        return (
            <div className="d-inline-flex cursor-pointer padding-0-10">
                <span className="getText mr-2 mt-1" onClick={this.props.refreshBtnOnclick} ><span className="GetIcon "> <Icon type="undo" />  {this.props.refreshText}   </span> </span>
            </div>
        )
    }
};


interface SelectIndexProps {
    serverSelectOptions: any;
    serverSelectValue: any;
    serverSelectonChange: any;

}

export class ServerSelect extends React.Component<SelectIndexProps> {
    render() {
        return (
            <div className="d-block">
                <Select
                    className={"react-select-container w150 MediumFilterSelect"}
                    classNamePrefix={"react-select cursor-pointer MediumFilterSelect  "}
                    options={this.props.serverSelectOptions}
                    isSearchable={true}
                    value={this.props.serverSelectValue}
                    onChange={this.props.serverSelectonChange}
                />
            </div>
        );
    }
}


interface ServerProps {
    serverSelectedValue: any;
    serverSelectOptions: any;
    ServerChange: any;
    id?: string;
    placeholder?: any;
    inpages?: boolean;
}


export class ServerMultiSelect extends React.Component<ServerProps> {

    render() {
        return (
            <FilterMultiSelect
                value={this.props.serverSelectedValue}
                options={this.props.serverSelectOptions}
                onChange={this.props.ServerChange}
                placeholder={this.props.placeholder}
                id={this.props.id}
                inpages={this.props.inpages}
            />
        );
    }
}



interface IBIndexProps {
    yearValue: any;
    yearChange: any;
    monthValue: any;
    monthChange: any;
    LabelCreate2: any;
    onEventDate: any;
}

interface ExcelType {
    onClick?: any;
    csvData?: any;
    fileName?: any;
    title?: any;
    mainTitle?: any;
    loading?: boolean;
    isImport?: boolean;
}


export const ExcelBtn = ({ csvData, fileName, onClick, title, mainTitle, loading, isImport }: ExcelType) => {
    const exportToCSV = (csvData: any, fileName: any, onClick: any, title: any, lang: any) => {
        if (onClick !== undefined) {
            onClick();
        }
    }
    return (

        <ButtonANTD
            loading={(loading === undefined || loading === null) ? false : loading}
            className=" ButtonsModal AddBtn ml-2 divRight" onClick={(e: any) => exportToCSV(csvData, fileName, onClick, title, mainTitle)}>
            <FontAwesomeIcon icon={isImport ? faFileImport : faDownload} className="cursor-pointer mr-2" />
            {mainTitle} {title}
        </ButtonANTD>
    )
}


interface PassIndexProps {
    value?: any;
    label?: any;
    name?: any;
    id?: any;
    disabled?: any;
    placeholder?: any;
    onChange?: any;

}

export class PasswordComp extends React.Component<PassIndexProps> {
    state = {
        hidden: true, password: ''
    };
    toggleShow = () => {

        this.setState({ hidden: !this.state.hidden });
    }
    componentDidMount() {
        if (this.props.value) {
            this.setState({ password: this.props.value });
        }
    }
    render() {
        return (
            <Col md={12}>
                <Label className="LabelCreate2">
                    {this.props.label}
                    <b className="iconNes">*</b>
                </Label>
                <div className="input-group">
                    <Input
                        name={this.props.name}
                        type={this.state.hidden ? "password" : "text"}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        autoComplete="password"
                    />
                    <Button
                        className="eyeButton"
                        onClick={this.toggleShow} >
                        <span>
                            <Eye size={14} className="passwordEye" />
                        </span>
                    </Button>
                </div>
            </Col>
        )
    }
};

interface FiltrationDateAnalyticsIndexProps {
    showDateInput?: any;
    inputDateValue?: any;
    yearChange?: any;
    inputPlaceholder?: any;
    selectclass?: any;
    classPrefix?: any;
    monthSelectOptions: any;
    isMulti?: any;
    monthSelectValue?: any;
    monthSelectonChange?: any;
    getbtnClassName?: any;
    onclickGetBtn?: any;
}

export class FiltrationDateAnalytics extends React.Component<FiltrationDateAnalyticsIndexProps> {
    state = {
    }
    render() {
        return (
            <div >
                {this.props.showDateInput &&
                    <Input
                        type="text"
                        bsSize="lg"
                        className="FilterInput"
                        value={this.props.inputDateValue}
                        onChange={this.props.yearChange}
                        placeholder={this.props.inputPlaceholder}
                    />
                }
                <Select
                    className={this.props.selectclass}
                    classNamePrefix={this.props.classPrefix}
                    options={this.props.monthSelectOptions}
                    isSearchable={true}
                    isMulti={this.props.isMulti}
                    value={this.props.monthSelectValue}
                    placeholder={this.props.monthSelectValue}
                    onChange={this.props.monthSelectonChange} />
            </div>
        );
    }
}


interface ServerIndexProps {
    serverSelectOptions: any;
    serverSelectValue: any;
    serverSelectonChange: any;
    loginSelectOptions: any;
    loginSelectValue: any;
    loginSelectonChange: any;
    placeholder?: any;
    hide?: any;

}

export class ServerLogin extends React.Component<ServerIndexProps> {

    render() {
        return (
            <div className="d-inline-flex">
                <Select
                    className={"react-select-container w150 MediumFilterSelect"}
                    classNamePrefix={"react-select cursor-pointer MediumFilterSelect  "}
                    isSearchable={true}
                    value={this.props.serverSelectValue}
                    onChange={this.props.serverSelectonChange}
                    options={this.props.serverSelectOptions}
                />
                {!this.props.hide && <Select
                    className={"react-select-container w150 MediumFilterSelect"}
                    classNamePrefix={"react-select cursor-pointer MediumFilterSelect  "}
                    options={this.props.loginSelectOptions}
                    isSearchable={true}
                    value={this.props.loginSelectValue}
                    placeholder={this.props.placeholder}
                    onChange={this.props.loginSelectonChange}
                />}
            </div>

        );
    }
}

interface DisIndexProps {
    title: any;
    result: any | null;
}

export class DisplayFields extends React.Component<DisIndexProps> {

    render() {
        return (
            <Row md={12} className="dataApplicationform">
                <Col md={6} className="reviewLabels LabelCreate2">{this.props.title}</Col>
                <Col md={6} className="resultLabels"> {this.props.result ? this.props.result : ""} </Col>
            </Row>
        );
    }
}



interface FinishIndexProps {
    title?: any;
    result?: any;
    showEdit?: any;
    onEditClick?: any;
    editText?: any;
}

export class FinishReviewDisplay extends React.Component<FinishIndexProps> {

    render() {
        return (
            <div>

                <p className="d-block resultFinish"> {this.props.title}: &nbsp;&nbsp;{(this.props.result && this.props.result !== "choose" && this.props.result !== "Choose" && this.props.result !== "-1" && this.props.result !== "No Servers") ? this.props.result : "--"} </p>
                {this.props.showEdit &&
                    <button className="navigate EditButton" onClick={this.props.onEditClick}> {this.props.editText} </button>
                }

            </div>
        );
    }
}

interface InuptProps {
    for?: any;
    LabelClass?: any;
    labelContent?: any;
    value: any;
    onChange: any;
    type: any;
    name: any;
    placeholder?: any;
    id: any;
    className?: any;
    rows?: any;
    autocomplete?: any;
}


export class InputWithLabel extends React.Component<InuptProps> {
    render() {
        return (<div>
            <Label htmlFor={this.props.for} className={this.props.LabelClass} >{this.props.labelContent}</Label>
            <Input
                value={this.props.value}
                onChange={this.props.onChange}
                type={this.props.type}
                name={this.props.name}
                placeholder={this.props.placeholder}
                id={this.props.id}
                bsSize="lg"
                className={this.props.className}
                rows={this.props.rows}
                autoComplete={this.props.autocomplete}
            />
        </div>);
    }
}

interface InternalLeftsidebarLabelsProps {
    title: any;
    answer: any;
    isLoading?: any;
}

export class InternalLeftsidebarLabels extends React.Component<InternalLeftsidebarLabelsProps> {
    render() {
        return (
            <Row>
                <Col md={12} className="mb-2">
                    <FormGroup className="mb-4">
                        <Label for="lastName" className=" d-block titleLeftsidebar" >{this.props.title}&nbsp;&nbsp; </Label>
                        <SkeletonWrapper
                            ClassName="w100"
                            loading={this.props.isLoading ? true : false}>
                            <Label for="lastNameText" className="answerLefsidebar wrapText" >
                                {String(this.props.answer) !== "-1" ? this.props.answer : "None-Provided"}
                            </Label>
                        </SkeletonWrapper>
                    </FormGroup>
                </Col >
            </Row >
        )
    }
};

interface InternalLeftsidebarLabelsProps {
    title: any;
    answer: any;
    isLoading?: any;
    LabelClass?: any;
}

export class InternalLeftsidebarLabelsNewDesign extends React.Component<InternalLeftsidebarLabelsProps> {
    render() {
        return (
            <FormGroup className="mb-4">
                <Label for="lastName" className=" d-block titleLeftsidebar" >{this.props.title}&nbsp;&nbsp; </Label>
                <SkeletonWrapper
                    ClassName="w100"
                    loading={this.props.isLoading ? true : false}>
                    <Label for="lastNameText" className={this.props.LabelClass || `answerLefsidebar wrapText`} >
                        {String(this.props.answer) !== "-1" ? this.props.answer : "None-Provided"}
                    </Label>
                </SkeletonWrapper>
            </FormGroup>
        )
    }
};

export class NoDataInTable extends React.Component {

    render() {
        return (
            <div className="insideMain padingf2em " >
                <Col sm="12" md="12" lg="12" className="mx-auto d-table h-100 mt100">
                    <div className=" text-center p10" >
                        <img src={`${getImagesURL_IGain_LDN(store?.getState()?.CompanyData?.BannerCDNURL, store?.getState()?.auth?.CompanyData?.CompanyName, store?.getState()?.CompanyData?.CDNURL)}${ReturnImageName()}`} alt="" className="mt-3 h400" />
                        <div className="d-block mt-3 text-center m10">
                            <Label className="text-Grey font-32 t-bold d-block mb-2"> No Data </Label>
                        </div>
                    </div>
                </Col>
            </div >
        )
    }
};

export class NoDataToDisplay extends React.Component {
    render() {//TODO need translation
        return (
            <div className="insideMain padingf2em " >
                <Col sm="12" md="12" lg="12" className="mx-auto d-table h-100 mt100 ">
                    <div className=" text-center p10" >
                        <img src={`${getImagesURL_IGain_LDN(store?.getState()?.CompanyData?.BannerCDNURL, store?.getState()?.auth?.CompanyData?.CompanyName, store?.getState()?.CompanyData?.CDNURL)}${ReturnImageName()}`} alt="" className="mt-3 h400" />
                        <div className="d-block mt-3 text-center m10">
                            <Label className="text-Grey font-32 t-bold d-block mb-2"> No Data </Label>
                        </div>
                    </div>
                </Col>
            </div >
        )
    }
};

export const FormValidation = (props: { children?: React.ReactNode, validate: boolean, title: string }) => (
    <Form.Item
        label={props.title}
        hasFeedback={false}
        validateStatus="error"
        help={props.validate ? "" : "Fill Missing Fields"} >
        {props.children}
    </Form.Item>
);
export const BasicValidation = (props: { children?: React.ReactNode, validate: boolean, title: string }) => (
    <Label for="Login" className="LabelCreate2 d-block font-14" >
        {props.title}
        {(!props.validate) && <Label className="d-contents t-bold font-14 text-danger mb-0" >*</Label>}
    </Label>
);
interface InputOnFocusProps {
    placeholder: any;
    onChange?: any;
    value?: any;
    name: string;
    Compliance?: boolean;
    type?: InputType;
    disabled?: boolean;
}

export class InputFormWithFocus extends React.Component<InputOnFocusProps> {

    render() {
        return (
            <div>

                <Label className={`LabelCreate2 d-contents font-14 ml-2  ${this.props.Compliance !== undefined && !this.props.Compliance ? "text-danger" : "colorGrey"}`} >{true ?
                    `${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}` : " "} </Label>

                <Input
                    type={this.props.type ? this.props.type : "text"}
                    bsSize="lg"
                    className="DarkMode"
                    placeholder={`${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    name={this.props.name}
                    disabled={this.props.disabled ? true : false}

                />
            </div>
        )
    }
};
interface InputComponentProps {
    title?: any;
    placeholder?: any;
    onChange?: any;
    onBlur?: any;
    value?: any;
    name: string;
    Compliance?: boolean;
    type?: InputType;
    disabled?: boolean;
    rows?: any;
}

export class InputComponent extends React.Component<InputComponentProps> {
    render() {
        return (
            <div>

                <Label className="LabelCreate2 font-16" >{this.props.title || this.props.placeholder} </Label>
                {(!this.props.Compliance) && <Label className="d-contents t-bold font-14 text-danger" >*</Label>}
                <Input
                    type={this.props.type ? this.props.type : "text"}
                    bsSize="lg"
                    rows={this.props.rows}
                    placeholder={this.props.title ? this.props.placeholder : ""}
                    className="DarkMode"
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    value={this.props.value}
                    name={this.props.name}
                    disabled={this.props.disabled ? true : false}

                />
            </div>
        )
    }
};
export class PasswordComponent extends React.Component<InputComponentProps> {
    render() {
        return (
            <div>

                {this.props.placeholder && <Label className="LabelCreate2 font-16" >{this.props.placeholder} </Label>}
                {(!this.props.Compliance) && <Label className="d-contents t-bold font-14 text-danger" >*</Label>}
                <AntInput.Password
                    autoComplete="new-password"
                    type="password"
                    placeholder={""}
                    className="DarkMode"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    name={this.props.name}
                />
            </div>
        )
    }
};
interface RadioLabelWithCompComplianceProps {
    label?: any;
    Compliance?: boolean;
}
export class RadioLabelWithCompCompliance extends React.Component<RadioLabelWithCompComplianceProps> {
    render() {
        return (
            <Label className={`LabelCreate2 d-block font-14 ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`} >
                {`${this.props.label}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`} </Label>
        )
    }
};
interface CheckAllHeaderProps {
    t: any;
    title: string;
    tableID: string;
    checkboxesClass: string;
}
export class CheckAllHeader extends React.Component<CheckAllHeaderProps> {
    state = {
        checkALL: false,
        asc: false,
    }
    checkBoxOnChange = (e: any) => {
        let _checked = e.target.checked;
        let _ref = this;
        this.setState({ checkALL: _checked } as any);
        $(`.${_ref.props.checkboxesClass}`).prop('checked', _checked).change();
    };
    SortFirstColumn = () => {
        $(`#${this.props.tableID}`).DataTable().order([0, this.state.asc ? 'asc' : 'desc']).draw();
        this.setState({ asc: !this.state.asc });
    }
    render() {
        return (
            <><Checkbox
                name="checkALL"
                checked={this.state.checkALL}
                onChange={this.checkBoxOnChange} />
                <span
                    style={{ marginLeft: "5px" }}
                    onClick={this.SortFirstColumn}>
                    {this.props.t(this.props.title)}
                </span>
            </>
        )
    }
};
interface SelectWithFocusProps {
    placeholder: any;
    onChange?: any;
    value?: any;
    options?: any;
    isSearchable?: any
    classNamePrefix: string;
    Compliance?: boolean;
    t: Function;
}
export class SelectWithFocus extends React.Component<SelectWithFocusProps> {
    render() {
        return (
            <div>
                <Label className={`LabelCreate2 d-contents font-14 ml-2 ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`} >{true ? `${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}` : " "} </Label>
                {!(true) && <Input
                    type="text"
                    bsSize="lg"
                    placeholder={`${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`}
                    className={`noBorderInputs ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`}

                />}
                {((this.props.value && this.props.value.value && String(this.props.value.value) !== '-1')) &&
                    <Select
                        isSearchable={this.props.isSearchable}
                        classNamePrefix={this.props.classNamePrefix}
                        className="react-select-container MedSelect DarkMode"
                        placeholder={""}
                        options={this.props.options}
                        onChange={this.props.onChange}
                        value={this.props.value}
                    />}
            </div>
        )
    }
};

export class SelectWithFocusNewDesgin extends React.Component<SelectWithFocusProps> {
    //#region Open/Close menu-React
    box: any = React.createRef();
    state = {
        menuIsOpen: false,
        menuDivIsOpen: false,
    }
    Toggle = (str: string) => {
        //console.log(str)
        this.setState({ menuDivIsOpen: !this.state.menuDivIsOpen })
    }
    closeAll = (str: string) => {
        this.setState({ menuDivIsOpen: false, menuIsOpen: false })
    }
    OpenMenu = (str: string) => {
        this.setState({ menuIsOpen: true })
    }
    CloseMenu = (str: string) => {
        this.setState({ menuIsOpen: false })
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }
    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }
    handleOutsideClick = (event: any) => {
        if (this.box && !this.box?.current?.contains(event.target)) {
            this.closeAll('you just clicked outside of box!')
        }
    }
    //#endregion
    render() {
        return (
            <div
                ref={this.box}
                onMouseDown={() => this.closeAll("onMouseDown")}
                onBlur={() => this.closeAll("onBlur")}
            >
                <Label
                    onClick={() => this.closeAll("onClick")}
                    className={`LabelCreate2 d-contents font-14 ml-2 ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`} >{true ? `${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}` : " "} </Label>
                {false && <Input //not need any more
                    type="text"
                    bsSize="lg"
                    placeholder={`${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`}
                    className={`noBorderInputs ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`}

                />}
                {/*((this.props.value && this.props.value.value && String(this.props.value.value) !== '-1'))*/ true &&
                    <div
                        onClick={() => this.Toggle("Click")}
                        onBlur={() => this.closeAll("onBlur")}
                    >
                        <Select
                            getOptionLabel={(option: any) => this.props.t(option.label)}
                            isSearchable={this.props.isSearchable}
                            classNamePrefix={this.props.classNamePrefix}
                            className="react-select cursor-pointer LargeSelect DarkMode "
                            placeholder={""}
                            options={this.props.options}
                            onChange={this.props.onChange}
                            value={this.props.value}
                            onFocus={() => this.OpenMenu("foucs")}
                            onBlur={() => this.CloseMenu("onBlur")}
                            menuIsOpen={this.state.menuIsOpen || this.state.menuDivIsOpen}
                            onMenuOpen={() => this.OpenMenu("onMenuOpen")}
                            onMenuClose={() => this.CloseMenu("onMenuClose")}
                        /></div>}
            </div>
        )
    }
};
interface SelectComponentProps {
    onChange?: any;
    value?: any;
    options?: any;
    isSearchable?: any
    label?: any;
    compliance?: any;
    isLoading?: boolean;
    isDisabled?: boolean;
    getOptionLabel?: any;
}
export class SelectComponent extends React.Component<SelectComponentProps> {
    render() {
        return (
            <div>
                <Label className="LabelCreate2 font-16" >{this.props.label}</Label>
                {(!this.props.compliance) && <Label className="d-contents t-bold font-14 text-danger" >*</Label>}
                <Select
                    isDisabled={!!this.props.isDisabled}
                    isLoading={this.props.isLoading ? true : false}
                    isSearchable={this.props.isSearchable}
                    className="react-select-container MedSelect DarkMode"
                    classNamePrefix="react-select cursor-pointer LargeSelect DarkMode "
                    placeholder={this.props.label}
                    options={this.props.options}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    getOptionLabel={(option: any) => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label)}
                />
            </div>
        )
    }
};

interface SelectMultiWithFocusProps {
    placeholder: any;
    onChange: any;
    value: any;
    options: any;
    isSearchable?: any
    classNamePrefix: string;
    Compliance?: boolean;
    t: Function;
}
export class SelectMultiWithFocus extends React.Component<SelectMultiWithFocusProps> {

    render() {
        return (
            <div>
                <Label className={`LabelCreate2 d-contents font-14 ml-2 ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`} >{((this.props.value && this.props.value.length > 0)) ? `${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}` : " "} </Label>
                {!((this.props.value && this.props.value.length > 0)) && <Input
                    type="text"
                    bsSize="lg"
                    placeholder={`${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`}
                    className={`noBorderInputs ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`}

                />}
                {((this.props.value && this.props.value.length > 0)) &&
                    <Select
                        isSearchable={this.props.isSearchable}
                        classNamePrefix={this.props.classNamePrefix}
                        placeholder={""}
                        className="react-select-container LargeSelect DarkMode AsyncMultiSelect"
                        options={this.props.options}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        isMulti={true}
                    />}
            </div>
        )
    }
}
export class SelectMultiWithFocusNewDesgin extends React.Component<SelectMultiWithFocusProps> {
    //#region Open/Close menu-React
    box: any = React.createRef();
    state = {
        menuIsOpen: false,
        menuDivIsOpen: false,
    }
    Toggle = (str: string) => {
        //console.log(str)
        this.setState({ menuDivIsOpen: !this.state.menuDivIsOpen })
    }
    closeAll = (str: string) => {
        this.setState({ menuDivIsOpen: false, menuIsOpen: false })
    }
    OpenMenu = (str: string) => {
        this.setState({ menuIsOpen: true })
    }
    CloseMenu = (str: string) => {
        this.setState({ menuIsOpen: false })
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }
    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }
    handleOutsideClick = (event: any) => {
        if (this.box && !this.box?.current?.contains(event.target)) {
            this.closeAll('you just clicked outside of box!')
        }
    }
    //#endregion
    render() {
        return (
            <div
                ref={this.box}
                onMouseDown={() => this.closeAll("onMouseDown")}
                onBlur={() => this.closeAll("onBlur")}
            >
                <Label
                    onClick={() => this.closeAll("onClick")}
                    className={`LabelCreate2 d-contents font-14 ml-2 ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`} >{((this.props.value && this.props.value.length > 0)) ? `${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}` : " "} </Label>
                {false && <Input
                    type="text"
                    bsSize="lg"
                    placeholder={`${this.props.placeholder}${this.props.Compliance !== undefined && !this.props.Compliance ? "*" : ""}`}
                    className={`noBorderInputs ${this.props.Compliance !== undefined && !this.props.Compliance ? " text-danger" : "colorGrey"}`}

                />}
                {true &&
                    <div
                        onClick={() => this.Toggle("Click")}
                        onBlur={() => this.closeAll("onBlur")}
                    >
                        <Select
                            isSearchable={this.props.isSearchable}
                            classNamePrefix={this.props.classNamePrefix}
                            placeholder={this.props.placeholder ? this.props.placeholder : "Select"}
                            className="react-select-container LargeSelect DarkMode AsyncMultiSelect"
                            options={this.props.options}
                            onChange={this.props.onChange}
                            value={this.props.value}
                            isMulti={true}
                            onFocus={() => this.OpenMenu("foucs")}
                            onBlur={() => this.CloseMenu("onBlur")}
                            menuIsOpen={this.state.menuIsOpen || this.state.menuDivIsOpen}
                            onMenuOpen={() => this.OpenMenu("onMenuOpen")}
                            onMenuClose={() => this.CloseMenu("onMenuClose")}
                        /></div>}
            </div>
        )
    }
}
export function Translation({ children }: any) {
    const [t, i18n] = useTranslation();
    return children({ t, i18n });
}
interface FilterMultiSelectProps {
    placeholder: string;
    value: any[];
    options: any[];
    onChange: any;
    id?: any;
    inpages?: any;
    t?: any;
    keyLabel?: string;
}
export class FilterMultiSelect extends React.Component<FilterMultiSelectProps> {
    render() {
        return (
            <MultiSelect
                id={this.props.id}
                filter={true}
                itemTemplate={(option: any) => this.ReturnTranslatedOption(option, this.props.keyLabel)}
                className={this.props.inpages ? "ShowSearch2 d-block ShowSearch NoBordersFilter multiselect-custom" : "d-block ShowSearch multiselect-custom mb-2"}
                value={this.props.value}
                options={this.props.options}
                onChange={this.props.onChange}
                placeholder={ReturnPlaceHolderStatement(this.props.placeholder, this.props.value, this.props.options)}
                maxSelectedLabels={0}
                fixedPlaceholder
            />
        )
    }
    ReturnTranslatedOption = (option: any, keyLabel?: string) => {
        let _option: any = { ...option };
        if (keyLabel !== "CountryCode")
            return this.props.t ? <div>{this.props.t(this.props.keyLabel ? _option[this.props.keyLabel] : _option.label)}</div> : <div>{this.props.keyLabel ? ReturnCountryCode(_option[this.props.keyLabel]) : _option.label}</div>;
        else
            return this.props.t ? <div>{this.props.t(this.props.keyLabel ? ReturnCountryCode(_option[this.props.keyLabel]) : _option.label)}</div> : <div>{this.props.keyLabel ? ReturnCountryCode(_option[this.props.keyLabel]) : _option.label}</div>;
    }
};
interface PassowrdValidationProps {
    Password: string;
    t: Function;
    length?: number;
}
export class PassowrdValidation extends React.Component<PassowrdValidationProps> {
    length = this.props.length ? (this.props.length - 1) : 7;
    render() {
        if (this.props.Password && this.props.Password !== "" &&
            (!UpperCaseExists(this.props.Password) ||
                !LowerCaseExists(this.props.Password) ||
                !NumberExists(this.props.Password) ||
                !SpecialCharacterExists(this.props.Password) ||
                this.props.Password.length <= this.length)
        ) {
            return (
                <div className="mt-2">
                    <Tag className={UpperCaseExists(this.props.Password) ? "tagSucess" : "tagError"}>  {this.props.t('UpperCase')} </Tag>
                    <Tag className={LowerCaseExists(this.props.Password) ? "tagSucess" : "tagError"}>  {this.props.t('LowerCase')} </Tag>
                    <Tag className={NumberExists(this.props.Password) ? "tagSucess" : "tagError"}>{this.props.t('Numbers')} </Tag>
                    <Tag className={SpecialCharacterExists(this.props.Password) ? "tagSucess" : "tagError"}> {this.props.t('SpecialCharacter')} </Tag>
                    <Tag className={this.props.Password.length > this.length ? "tagSucess" : "tagError"}> {this.length ? (this.length + 1) : ""} {this.props.t(this.length ? `Characters` : '8Characters')} </Tag>
                </div>)
        }
        else return <div></div>
    }
}

interface IncludeExcludeDropDownProps {
    placeholder: string;
    options: any[];
    includedValue: any[];
    includedOnChange: any;
    excludedValue: any[];
    excludedOnChange: any;

}
class IncludeExcludeDropDownStates {
    IsExcluded: boolean = false;
    excludeOptions: any[] = [];
    includeOptions: any[] = [];
}
export class IncludeExcludeDropDown extends React.Component<IncludeExcludeDropDownProps, IncludeExcludeDropDownStates> {
    state = new IncludeExcludeDropDownStates();
    componentDidMount() {
        this.setState({
            includeOptions: this.ReturnExcludeOptions(this.props.excludedValue),
            //excludeOptions: this.ReturnExcludeOptions(this.props.includedValue),//so can select values
        });
    }
    componentDidUpdate(prevprops: IncludeExcludeDropDownProps) {
        if (prevprops.options !== this.props.options) {
            this.setState({
                excludeOptions: this.ReturnExcludeOptions(this.props.includedValue),
                includeOptions: this.ReturnExcludeOptions(this.props.excludedValue)
            })
        }
    }
    ReturnExcludeOptions = (list: any) => {
        try {
            let _options: any[] = [];
            let _includedValue: any = [...list];
            _includedValue = _includedValue ? ReturnValuesForMultiSelect(_includedValue).toString() : "";
            for (let option of this.props.options) {
                if (!_includedValue.includes(option?.value)) {
                    //console.log('Accpeted:', option?.label)
                    _options.push({ ...option });
                }
            }
            return _options;
        } catch (e) {
            console.error(e);
            return this.props.options;
        }
    }
    RadioOnChange = (e: any) => {
        this.setState({ IsExcluded: e.target.value === 'exclude' })
        if (e.target.value === 'exclude')//on didmount too for included in clude triger radio change
            this.setState({ excludeOptions: this.ReturnExcludeOptions(this.props.includedValue) })
        else
            this.setState({ includeOptions: this.ReturnExcludeOptions(this.props.excludedValue) })
    };
    render() {
        return (
            <>
                <Badge
                    color={this.state.IsExcluded ? 'volcano' : 'blue'}
                    dot
                    className="float-right" >
                    {this.state.IsExcluded ? 'Exclude' : 'Include'}
                </Badge>
                {!this.state.IsExcluded && <Select
                    className="react-select-container LargeSelect AsyncMultiSelect"
                    classNamePrefix="react-select cursor-pointer LargeSelect AsyncMultiSelect "
                    isSearchable={true}
                    isMulti={true}
                    options={this.state.includeOptions}
                    value={this.props.includedValue}
                    onChange={this.props.includedOnChange}
                />}
                {this.state.IsExcluded && <Select
                    className="react-select-container LargeSelect AsyncMultiSelect"
                    classNamePrefix="react-select cursor-pointer LargeSelect AsyncMultiSelect "
                    isSearchable={true}
                    isMulti={true}
                    options={this.state.excludeOptions}
                    value={this.props.excludedValue}
                    onChange={this.props.excludedOnChange}
                />}
                <Radio.Group
                    value={this.state.IsExcluded ? 'exclude' : 'include'}
                    onChange={this.RadioOnChange}
                    name="ThemeChecked">
                    <Radio
                        value="include"// don't translate value
                        className="RadioLabel  mt-2 mb-2">
                        <span className="LabelCreate2">Include</span>
                    </Radio>
                    <Radio
                        value="exclude"
                        className="RadioLabel  mt-2 mb-2">
                        <span className="LabelCreate2">Exclude</span>
                    </Radio>
                </Radio.Group>
            </>
        )
    }
};

export class IncludeDropDown extends React.Component<IncludeExcludeDropDownProps, IncludeExcludeDropDownStates> {
    state = new IncludeExcludeDropDownStates();
    componentDidMount() {
        this.setState({
            includeOptions: this.ReturnExcludeOptions(this.props.excludedValue),
            //excludeOptions: this.ReturnExcludeOptions(this.props.includedValue),//so can select values
        });
    }
    componentDidUpdate(prevprops: IncludeExcludeDropDownProps) {
        if (prevprops.options !== this.props.options) {
            this.setState({
                excludeOptions: this.ReturnExcludeOptions(this.props.includedValue),
                includeOptions: this.ReturnExcludeOptions(this.props.excludedValue)
            })
        }
    }
    ReturnExcludeOptions = (list: any) => {
        try {
            let _options: any[] = [];
            let _includedValue: any = [...list];
            _includedValue = _includedValue ? ReturnValuesForMultiSelect(_includedValue).toString() : "";
            for (let option of this.props.options) {
                if (!_includedValue.includes(option?.value)) {
                    //console.log('Accpeted:', option?.label)
                    _options.push({ ...option });
                }
            }
            return _options;
        } catch (e) {
            console.error(e);
            return this.props.options;
        }
    }
    RadioOnChange = (e: any) => {
        this.setState({ IsExcluded: e.target.value === 'exclude' })
        if (e.target.value === 'exclude')//on didmount too for included in clude triger radio change
            this.setState({ excludeOptions: this.ReturnExcludeOptions(this.props.includedValue) })
        else
            this.setState({ includeOptions: this.ReturnExcludeOptions(this.props.excludedValue) })
    };
    render() {
        return (
            <Select
                className="react-select-container LargeSelect AsyncMultiSelect"
                classNamePrefix="react-select cursor-pointer LargeSelect AsyncMultiSelect "
                isSearchable={true}
                isMulti={true}
                options={this.state.includeOptions}
                value={this.props.includedValue}
                onChange={this.props.includedOnChange}
            />
        )
    }
};
interface ColumnMultiSelectProps {
    placeholder: string;
    value: any[];
    //options: any[];
    onChange: any;
    id: any;
    inpages?: any;
    Titles: any;
    t: any;
}
export class ColumnFilterMultiSelect extends React.Component<ColumnMultiSelectProps> {
    componentDidMount() {// elment not exist yet
        this.SetSelectedColumns(this.props.value, this.props.id);
    }
    componentDidUpdate(prevProps: ColumnMultiSelectProps) {
        if (prevProps.value !== this.props.value) {//as from parent trigger by code //TODO check if not needed
            this.SetSelectedColumns(this.props.value, this.props.id);
        }
    }
    tableColumnsOnChange = (e: any) => {
        this.props.onChange(e);
        if (e.value) {//If none selected will take orginal
            //console.log(this.props.id);
            this.SetSelectedColumns(e.value, this.props.id);
        }
    }
    SetSelectedColumns(list: any, id: any) {
        let ColumnPosDic = this.ConvertTitlesToColumnPosDic(this.props.Titles);
        //console.log({ ColumnPosDic, id, list });
        for (let key in ColumnPosDic) {
            if (ColumnPosDic.hasOwnProperty(key)) {//all false then set others by list[i]>> key true
                try {
                    if (!list.includes(key)) {
                        //console.log({ key }, "Not found ")
                        $(`#${id}`).DataTable()?.column(ColumnPosDic[key])?.visible(false);
                    } else {
                        //console.log({ key }, " found ") //any missing column would make problem 
                        $(`#${id}`).DataTable()?.column(ColumnPosDic[key])?.visible(true);
                    }
                } catch (e) {
                    console.log(e, `#${id}`, key)
                }
            }
        }
    }
    ConvertTitlesToColumnOptions = (list: any[]) => {
        if (!list || list.length === 0) return [];
        let _options: any[] = [];
        for (let titleIndex in list) {
            _options.push({ value: list[titleIndex], label: this.props.t(list[titleIndex]), Index: titleIndex })
        }
        return _options;
    }
    ConvertTitlesToColumnPosDic = (list: any[]) => {
        let _newDict: any = [...list].reduce((obj: any, item: any, index: number) => {
            obj[item] = index
            return obj
        }, {});
        return _newDict;
    }
    render() {
        return (
            <FilterMultiSelect
                inpages={true}
                value={this.props.value}
                options={this.ConvertTitlesToColumnOptions(this.props.Titles)}
                onChange={this.tableColumnsOnChange}
                placeholder={'Columns'}
            />
        )
    }
};

export class InputPostiveNumber extends React.Component<any> {
    timer: any;
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        this.timer = null;
    }
    render() {
        return (
            <Input
                {...this.props}
                onKeyUp={(e: any) => {
                    e.persist();
                    clearTimeout(this.timer);
                    this.timer = setTimeout(function () {
                        //console.log('Input Value:', e.target.value);
                        e.target.value = Math.abs(e.target.value); return e;
                    }, 2000);//every 2 seconds clear 
                }}
                onBlurCapture={(e: any) => {
                    e.target.value = Math.abs(e.target.value); return e;
                }}
            />);
    }
}
interface DatePickerRangeComponentProps {
    EndDate: Date;
    StartDate: Date;
    StartDateOnChange: any;
    EndDateOnChange: any;
    t: Function;
}
export class DatePickerRangeComponent extends React.Component<DatePickerRangeComponentProps> {
    componentDidUpdate(prevProps: any) {
        if (prevProps.EndDate !== this.props.EndDate) {
            this.setState({ Start: false }, () => this.setState({ Start: true }))
        }
        if (prevProps.StartDate !== this.props.StartDate) {
            this.setState({ End: false }, () => this.setState({ End: true }))
        }
    }
    state = {
        Start: true,
        End: true,
    }
    render() {
        return (
            <>
                {this.state.Start && <div> <DatePickerComponent
                    key={0}
                    placeholder={this.props.t("StartDate")}
                    className="filterDate"
                    max={this.props.EndDate}
                    value={this.props.StartDate}
                    change={this.props.StartDateOnChange}
                /></div>}
                {this.state.End && <div> <DatePickerComponent
                    key={1}
                    placeholder={this.props.t("EndDate")}
                    className="filterDate"
                    min={this.props.StartDate}
                    value={this.props.EndDate}
                    change={this.props.EndDateOnChange}
                /></div>}
            </>);
    }
}
interface MultiSelectDropdownSelectAllProps {
    placeholder: string;
    Title?: string;
    value: any[];
    options: any[];
    onChange: any;
    isDisabled?: boolean;
}
export const MultiSelectDropdownSelectAll = (props: MultiSelectDropdownSelectAllProps | any) => {
    const valueRef = React.useRef(props.value);
    valueRef.current = props.value;
    let _newtitle = props.placeholder && !props.placeholder?.toLowerCase().includes("choose") ? props.placeholder : "";
    const selectAllOption = {
        value: "<SELECT_ALL>",
        label: `All ${props.Title ? props.Title : _newtitle}`
    };
    const isSelectAllSelected = () => valueRef?.current && (valueRef?.current?.length === props?.options?.length) && props?.options?.length > 0;
    const isOptionSelected = (option: any) =>
        valueRef?.current?.some?.(({ value }: any) => value === option?.value) ||
        isSelectAllSelected();

    const getOptions = () => props.options.length > 0 ? [selectAllOption, ...props.options] : [...props.options];

    const getValue = () => isSelectAllSelected() ? [selectAllOption] : props.value;

    const onChange = (newValue: any, actionMeta: any) => {
        const { action, option, removedValue } = actionMeta;

        if (action === "select-option" && option.value === selectAllOption.value) {
            props.onChange(props.options, actionMeta);
        } else if (
            (action === "deselect-option" &&
                option.value === selectAllOption.value) ||
            (action === "remove-value" &&
                removedValue.value === selectAllOption.value)
        ) {
            props.onChange([], actionMeta);
        } else if (
            actionMeta.action === "deselect-option" &&
            isSelectAllSelected()
        ) {
            props.onChange(
                props.options.filter(({ value }: any) => value !== option.value),
                actionMeta
            );
        } else {
            props.onChange(newValue || [], actionMeta);
        }
    };
    const customFilter = (option: any, searchText: any) => {
        if (
            option?.data?.label?.toLowerCase()?.includes?.(searchText?.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <Select
            isLoading={props.isLoading ? true : false}
            placeholder={props.placeholder}
            className="react-select-container LargeSelect AsyncMultiSelect"
            classNamePrefix="react-select cursor-pointer LargeSelect AsyncMultiSelect "
            isOptionSelected={isOptionSelected}
            options={getOptions()}
            value={getValue()}
            onChange={onChange}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isMulti
            isSearchable
            filterOption={customFilter}
            isDisabled={!!props.isDisabled}
            getOptionLabel={(option: any) => props.getOptionLabel ? props.getOptionLabel(option) : option.label}
        />
    );
};
//#region User License
interface UserLicenseProps {
    UserData: IUserData;
    CompanyData: ICompanyData;
    AdminData: GetAdminData;
    ContactID: string;
    URL: string;
    t: Function;
    clientPermsDic: any;
    clientPermsDicNames: any;
    clientPermsDropDownOptions: any;
}
interface UserLicenseStates {
    isLoading: boolean;
    path: string;
    clientName: string;
    RolePermission: RolePermsLic[];
    RoleLicense: RolePermsLic[];
    key: string;
    ContactID: string;
    isAdmin: boolean;
}
export class UserLicensePermissionDetails extends React.Component<UserLicenseProps, UserLicenseStates> {
    state = {
        isLoading: false,
        path: "",
        clientName: "",
        RolePermission: [],
        RoleLicense: [],
        key: "1",
        isAdmin: false,
        ContactID: "",
    }
    GetUserRolesPermsForUser = (ContactID: string) => {
        let GetClientDropDownListsParams: any = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.UserData.Token,
            AUID: this.props.AdminData.AUID,
            ObjData: {
                CompanyID: String(this.props.CompanyData.CompanyID),
                ContactID: ContactID,
                isAdmin: false,
            }
        };
        this.setState({ isLoading: true });
        CallAxiosRequest<any, GetUserRolesPermsList>(
            this.props.URL,
            `Trader/GetUserRolesPerms`,
            GetClientDropDownListsParams,
            { Token: this.props.UserData.Token },
            (res: AxiosResponse<YoonitResponse<GetUserRolesPermsList>> | any) => {
                if (res.data.LiveRes) {
                    let _permmissions = this.CastToRolePermsLic(res.data.LiveRes.PermsLicListResult);//Casting here so if chnage clss or add new be aware 
                    this.setState({ RolePermission: _permmissions });
                    let _distinctIc: RolePermsLic[] = _permmissions.filter((thing: RolePermsLic, i: number, arr: any) => {
                        return arr.indexOf(arr.find((t: RolePermsLic) => t.LicID === thing.LicID)) === i;
                    });
                    _distinctIc = _distinctIc.sort((a: RolePermsLic, b: RolePermsLic) => (!a.LicOrder ? 1 : !b.LicOrder ? -1 : a.LicOrder - b.LicOrder) || (!a.PermID ? 1 : !b.PermOrder ? -1 : a.PermID - b.PermOrder));//sort by 2 fields
                    this.setState({ RoleLicense: _distinctIc })
                }
                this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t
        );
    }
    componentDidMount() {
        this.GetUserRolesPermsForUser(this.props.ContactID);
    }
    render() {
        return (
            <div>
                <SpinnerComponent loading={this.state.isLoading} position="inline" color="#00ad0f" />
                <div className={this.state.isLoading ? "blurBackground " : ""}>
                    <div>
                        <div >
                            {this.state.RoleLicense
                                && this.state.RoleLicense.map((Lic: RolePermsLic) => {
                                    return (
                                        <>
                                            <Label className="d-block LabelCreate2  font-18"> {this.props.t(Lic.LicName)} </Label>
                                            < Row className="ml-0">
                                                {this.state.RolePermission
                                                    && this.state.RolePermission.map((parentItem: RolePermsLic) => {
                                                        return (
                                                            String(parentItem.LicID) === String(Lic.LicID)
                                                            && String(parentItem.PermID) !== "0"
                                                            && String(parentItem.ParentPermID) === "0" &&
                                                            <>
                                                                {this.ReturnLevel1Perm(parentItem)}
                                                                {String(parentItem.LicName) === String(Lic.LicName)
                                                                    && parentItem.PermName !== null
                                                                    && this.checkifHaveChildren(parentItem.PermID, parentItem.PermName)
                                                                    && this.ReturnLevel2Perm(parentItem, Lic.LicName)}
                                                            </>
                                                        )
                                                    })}</Row>
                                            <Divider className="mt-3 mb-3" />
                                        </>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    //#region each User client part 
    CastToRolePermsLic = (list: PermsLicList[]) => {
        let newList: any[] = [...list];
        newList = newList.map((item: PermsLicList | any) => { //PermsLicList not getting same as class 
            return {
                LicID: item.LicID,
                PermOrder: item.PermOrder,
                LicName: item.LicName,
                PermID: item.PermID,
                ParentPermID: item.ParentPermID,
                PermName: item.PermName,
                PermValID: item.PermIsChecked,
                LicOrder: item.LicOrder,
            };
        })
        newList = newList.sort((a: UserRolesList, b: UserRolesList) => (a.PermOrder > b.PermOrder) ? 1 : -1);
        return newList;
    }
    returnselectedPerm = (item: any) => {
        let _selectedPerm: any = this.props.clientPermsDic[item.PermValID ? item.PermValID : EPermissionValue.Deny];
        try {
            _selectedPerm = this.props.clientPermsDic[item.PermValID ? item.PermValID : (this.props.clientPermsDicNames["Deny"]).Data.PermValID];
        } catch (e) {
            console.error(e);
        }
        return _selectedPerm;
    }
    returnChildernValues = (list: RolePermsLic[], permItem: RolePermsLic, MainItem: string) => {
        let _newList: any[] = cloneDeep(list); // as don't want to change main list 
        _newList = _newList.filter((element: RolePermsLic) => element.ParentPermID === permItem.PermID && (this.returnselectedPerm(element) ? this.returnselectedPerm(element).label === "Allow" : false))
            .map((child: RolePermsLic, i: number) => {
                return (
                    String(child.LicName) === String(MainItem)
                    && String(child.ParentPermID) !== "0"
                    && String(child.PermID) !== "0"
                    && String(child.ParentPermID) === String(permItem.PermID) &&
                    MainItem === child.LicName &&
                    child.PermName !== null &&
                    child.PermName  //not  ? : as or use ? : null as "" empty string is considerd selected ( filter will solve issue)
                );
            })
        return _newList;
    }
    checkifHaveChildren = (ParentPermID: any, ParentPerName: string) => {
        let _list = cloneDeep(this.state.RolePermission);
        let _newList: any[] = _list.filter((element: RolePermsLic) => +(element.ParentPermID) === +(ParentPermID));
        return _newList.length > 0;
    }
    ReturnLevel1Perm = (parentItem: RolePermsLic) => {
        return <React.Fragment>
            <Col md={12} className="pl-0">
                <FormGroup>
                    <Label className=" LabelCreate2 d-contents font14 mr-2" >
                        <p className="resultFinish d-inline-flex t-bold">  {this.props.t('Access')}: </p>
                        {parentItem.PermValID === 1 ? `: ${this.props.t('Allowed')}` : `: ${this.props.t('Denied')}`} </Label>
                </FormGroup>
            </Col>
        </React.Fragment>;
    }
    ReturnLevel3Perm = (parentItem: RolePermsLic, Lic: string) => {
        return <React.Fragment>
            <Col md={12} className="pl-0">
                <FormGroup>
                    <p className="d-block resultFinish">  <span className="t-bold">{this.props.t('AllocationRules')}:</span>
                        <Label className="LabelCreate2 ml-2">  {this.returnChildernValues(this.state.RolePermission, parentItem, Lic).toString()} </Label>
                    </p>
                </FormGroup>
            </Col>
        </React.Fragment>;
    }
    returnChildernOptions = (list: RolePermsLic[], permItem: RolePermsLic, MainItem: string) => {
        let _newList: any[];
        _newList = list.filter((element: RolePermsLic) => element.ParentPermID === permItem.PermID)
            .map((child: RolePermsLic, i: number) => {
                return (
                    String(child.LicName) === String(MainItem)
                    && String(child.ParentPermID) !== "0"
                    && String(child.PermID) !== "0"
                    && String(child.ParentPermID) === String(permItem.PermID) &&
                    MainItem === child.LicName &&
                    child.PermName !== null &&
                    { value: child.PermID, label: child.PermName, Data: child }//use Data field as can't slect based on object it is not comparabale  
                );
            })
        return _newList;
    }
    ReturnLevel2Perm = (permItem: RolePermsLic, MainItem: any) => {
        return (
            this.state.RolePermission
                .filter((element: RolePermsLic) => element.ParentPermID === permItem.PermID)
                .map((child: RolePermsLic, i: number) => {
                    return (
                        String(child.LicName) === String(MainItem)
                        && String(child.ParentPermID) !== "0"
                        && String(child.PermID) !== "0"
                        && String(child.ParentPermID) === String(permItem.PermID) &&
                        MainItem === child.LicName &&
                        child.PermName !== null && <>
                            <React.Fragment>
                                <Col md={6} className="mb-3 pl-0">
                                    <p className="titleContact" >  {this.props.t(child.PermName)}: </p>
                                    <Label className="LabelCreate2">  {this.props.t(this.returnselectedPerm(child)?.label?.toLowerCase() === "allow" ? "Allowed" : "Denied")} </Label>
                                </Col>
                            </React.Fragment>
                            {String(child.LicName) === String(MainItem)
                                && child.PermName !== null
                                && this.checkifHaveChildren(child.PermID, child.PermName)
                                && this.ReturnLevel3Perm(child, MainItem)}
                        </>);
                }))
    }
    //#endregion
};
//#endregion
//#region email Layout
export const RenderTutorialModal = (props: any) => {
    return <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
        className={"Model FullSreenModal"}
        open={props.openTutorial}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}>
        <Fade in={props.openTutorial}>
            <div className={"ModelPaper m10mr10 FullSreenModal"}>
                <div className="d-flex">
                    <div className="mr-auto p-2"></div>
                    <div className="ml-auto floatRight">
                        <Close className="closeButton mr-1 cursor-pointer"
                            onClick={props.closeTutorial} /></div>
                </div>
                <div className="insideAccounts">
                    <div className="text-center">
                        <Label className="LabelCreate font-42 text-center"> {props.t('TemplateEditingTutorial')} </Label>
                    </div>
                    <Tabs tabPosition={"bottom"} className="TabPools">
                        <TabPane key="1" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faCode} className="textGrey" /></span>}>
                            <div>
                                <div className="container">
                                    <div className="row py-5 align-items-center">
                                        <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                            <div className=" text-center p10">
                                                <img src={`${getImagesURL_LDN(props.CompanyData, props.CompanyName, props.cdnLink)}code.png`} alt="" className="mt-3 mb50" />
                                                <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                    <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('CodeView')} </Label>
                                                    <Label className="text-Grey font-24 d-block m-0"> {props.t("CodeViewMessage")}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        {!props.isCode &&
                            <>
                                <TabPane key="2" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faImage} className="textGrey" /></span>}>
                                    <div>
                                        <div className="container">
                                            <div className="row py-5 align-items-center">
                                                <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                                    <div className=" text-center p10">
                                                        <img src={`${`${getImagesURL_LDN(props.CompanyData, props.CompanyName, props.cdnLink)}add-image.png`}`} alt="" className="mt-3 mb50" />
                                                        <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                            <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('AddImage')} </Label>
                                                            <Label className="text-Grey font-24 d-block m-0"> {props.t("AddImageMessage")}</Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane key="3" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faEye} className="textGrey" /></span>}>
                                    <div>
                                        <div className="container">
                                            <div className="row py-5 align-items-center">
                                                <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                                    <div className=" text-center p10">
                                                        <img src={`${`${getImagesURL_LDN(props.CompanyData, props.CompanyName, props.cdnLink)}preview.png`}`} alt="" className="mt-3 mb50" />
                                                        <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                            <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('Preview')} </Label>
                                                            <Label className="text-Grey font-24 d-block m-0"> {props.t("PreviewMessage")}</Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane key="4" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faAlignLeft} className="textGrey" /></span>}>
                                    <div>
                                        <div className="container">
                                            <div className="row py-5 align-items-center">
                                                <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                                    <div className=" text-center p10">
                                                        <img src={`${`${getImagesURL_LDN(props.CompanyData, props.CompanyName, props.cdnLink)}alignment.png`}`} alt="" className="mt-3 mb50" />
                                                        <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                            <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('TextAlignment')} </Label>
                                                            <Label className="text-Grey font-24 d-block m-0"> {props.t("TextAlignmentMessage")}</Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </>}
                        <TabPane key="5" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faExpandAlt} className="textGrey" /></span>}>
                            <div>
                                <div className="container">
                                    <div className="row py-5 align-items-center">
                                        <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                            <div className=" text-center p10">
                                                <img src={`${`${getImagesURL_LDN(props.CompanyData, props.CompanyName, props.cdnLink)}maximize.png`}`} alt="" className="mt-3 mb50" />
                                                <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                    <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('Maximize')} </Label>
                                                    <Label className="text-Grey font-24 d-block m-0"> {props.t("MaximizeMessage")}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane key="6" className="TabEachPool tabbar" tab={<span> | </span>} disabled>
                            <div>

                            </div>
                        </TabPane>
                        <TabPane key="7" className="TabEachPool" tab={<span> <FontAwesomeIcon icon={faUndo} className="textGrey mr-3" /> <FontAwesomeIcon icon={faRedo} className="textGrey ml-3" /></span>}>
                            <div>
                                <div className="container">
                                    <div className="row py-5 align-items-center">
                                        <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100">
                                            <div className=" text-center p10">
                                                <img src={`${props.cdnLink}imgs/redo-undo.png`} alt="" className="mt-3 mb50" />
                                                <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                                    <Label className="text-Grey font-32 t-bold d-block mb-2"> {props.t('UndoRedo')} </Label>
                                                    <Label className="text-Grey font-24 d-block m-0"> {props.t("UndoRedoMessage")}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </Fade>
    </Modal>;
}
export const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgb(0, 160, 223)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

//#endregion
export const SkeletonWrapper = ({ children, loading, ClassName }: any) => {
    return <Skeleton
        active
        loading={loading}
        avatar={{ className: "d-none" }}
        paragraph={false}
        className={ClassName ? ClassName : "w200"}
    >
        {children}
    </Skeleton>;
}
interface SearchInputProps {
    value: number | string;
    onChange: any;
    type: InputType;
    name: string;
    className?: any;
    SearchRequest: any;
    validateLoginTrigger: number;
    validateLoginTriggerTxt: string;
}


export class SearchInput extends React.Component<SearchInputProps> {
    state = {
        typingTimeout: 0 as any,
    }
    componentWillUnmount() {
        if (this.state.typingTimeout) {
            console.log("Clear timeout")
            clearTimeout(this.state.typingTimeout);
        }
    }
    render() {
        return (<div>
            <Form.Item
                label={""}
                hasFeedback
                validateStatus={ValidationTXT[this.props.validateLoginTrigger]}
                help={this.props.validateLoginTriggerTxt} >
                <Input
                    bsSize="lg"
                    type={this.props.type || "text"}
                    name={this.props.name}
                    value={this.props.value}
                    className="ValidatingInput"
                    onChange={this.props.onChange}
                    onKeyUp={(e: any) => {
                        //only when user stops typing 
                        const self = this;
                        let _value = `${e.target.value}`;
                        if (self.state.typingTimeout) {
                            clearTimeout(self.state.typingTimeout);
                        }
                        if (!['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft']?.includes(e.key)) {
                            self.setState({
                                typingTimeout: setTimeout(function () {
                                    self.props.SearchRequest(_value);
                                }, 1000)
                            });
                        }
                    }}
                />
            </Form.Item>
        </div>);
    }
}
interface DateRangeDropDownProps {
    Date: any;
    OnChange: any;
    t: Function;
    options?: any[];
}
export class DateRangeDropDown extends React.Component<DateRangeDropDownProps> {
    state = {
        Date: this.props.Date || [new Date(), new Date()],
        isOpen: false,
        dateType: { label: this.props.t('Today'), value: 'Today' },
    }
    //this.state.dateType.value === "ownRange"?`${this.props.Date[0]} - ${this.props.Date[1]}`:
    options: any = this.props.options || [
        { label: this.props.t('Today'), value: 'Today' },
        { label: this.props.t('Last3days'), value: '3days' },
        { label: this.props.t('Last7days'), value: '7days' },
        { label: this.props.t('Last30days'), value: '30days' },
        { label: this.props.t('Last3Months'), value: '3months' },
        { label: this.props.t('CustomDate'), value: 'ownRange' }
    ]

    setIsOpen = (isOpen: boolean) => {
        this.setState({ isOpen });
    }
    DateOnChange = (date: any) => {
        console.log("DateOnChange: ", date);
        this.setState({ Date: date });
    }
    DateTypeOnChange = (dateType: any) => {
        this.setState({ dateType });
        console.log({ dateType });
        let _date = [new Date(), new Date()];
        const _type = dateType.value;
        if (_type === "30days") {
            _date = [GetDayPriorBy(29), new Date()]; //as values 
        }
        else if (_type === "3months") {
            _date = [GetDayPriorBy(89), new Date()];
        }
        else if (_type === "3days") {
            _date = [GetDayPriorBy(2), new Date()];
        }
        else if (_type === "7days") {
            _date = [GetDayPriorBy(6), new Date()];
        }
        else if (_type === "Today") {
            _date = [new Date(), new Date()];
        } else if (_type === "ownRange") {
            this.setIsOpen(true);
            console.log(" this.setIsOpen(true);")
        }
        if (_type !== "ownRange") {
            this.props.OnChange(_date);
        }
    }
    ReturnValue = () => {
        if (this.state.dateType.value === "ownRange") {
            return { value: this.state.dateType.value, label: `${this.props.Date[0]?.toLocaleDateString()} - ${this.props.Date[1]?.toLocaleDateString()}` };
        }
        return this.state.dateType;
    }
    onAccept = (date: any) => {
        const isValidDate = function (date: any) {
            return new Date(date).toString() !== 'Invalid Date';
        }
        try {
            const [start, end] = date;
            if (isValidDate(start)
                && isValidDate(end)
                && start.getTime() <= end.getTime()
                && start.getTime() > GetDayPriorBy(365 * 20).getTime() //20 years max 
                && end.getTime() < GetDayPostBy(365 * 20).getTime()
            ) {
                this.props.OnChange(date);
            } else {
                toastr.warning('', this.props.t('InValidDateRange'));
                return;

            }
        } catch (e) {
            toastr.error('', this.props.t('InValidDateRange'));
            console.error("onAccept", e);
            return;
        }
    }
    render() {
        return (
            <>
                <Select
                    className={"react-select-container w150 MediumFilterSelect"}
                    classNamePrefix={"react-select cursor-pointer MediumFilterSelect  "}
                    isSearchable={true}
                    isMulti={false}
                    value={this.ReturnValue()}
                    options={this.options}
                    onChange={this.DateTypeOnChange}
                />
                <div hidden>
                    <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                        <MobileDateRangePicker
                            startText={this.props.t("StartDate")}
                            endText={this.props.t("EndDate")}
                            value={this.state.Date}
                            onChange={this.DateOnChange}
                            onAccept={(date: any) => this.onAccept(date)}
                            open={this.state.isOpen}
                            onClose={() => this.setIsOpen(false)}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField variant="standard" {...startProps} />
                                    <Box> to </Box>
                                    <TextField variant="standard" {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider></div>
            </>);
    }

}


//#region DynamicMargin
export function Get_Hour_Minute(time: string) {
    return time?.split?.(":") || [];
}
export const RenderSymbolMarginTier = ({ Securitylabel, list, selectedSessionID, t, closeAndGenerate, UpdateTiers, isEdit, col }
    : { t: Function, Securitylabel: string, list: TierClass[], selectedSessionID: number, closeAndGenerate: any, UpdateTiers: any, isEdit: boolean, col: number }) => {
    try {
        const index = list.findIndex((item: TierClass) => item.SessionID === selectedSessionID);
        const Tier: TierClass = list?.[index];
        //#region @functions
        const ReDrawMarginTiers = () => {
            let tiers = [...list];
            let currentTier = list?.[index];
            let _list: IMarginTiles[] = [];//dont take old as redraw new 
            for (let i: number = 0; i < +(currentTier.levels); i++) {
                _list.push({ level: `${i}`, Leverage: "", Trenches: "" });
            }
            currentTier.MarginTiles = _list;
            UpdateTiers(tiers);
        }
        const RedrawMatginTiersOnEnterKey = (e: any) => {
            if (e.keyCode === 13)
                ReDrawMarginTiers();
        }
        const levelOnChange = (e: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.levels = +(e.target.value);
            UpdateTiers(tiers);
        }
        const FromDateOnChange = (value: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.FromDay = value.value;
            UpdateTiers(tiers);
        }
        const onFromTimeChange = (time: any, timeString: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            const [hour, minute] = Get_Hour_Minute(timeString);
            currentTier.FromHour = hour;
            currentTier.FromMinute = minute;
            UpdateTiers(tiers);
        }
        const onToTimeChange = (time: any, timeString: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            const [hour, minute] = Get_Hour_Minute(timeString);
            currentTier.ToHour = hour;
            currentTier.ToMinute = minute;
            UpdateTiers(tiers);
        }
        const toDateOnChange = (value: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.ToDay = value.value;
            UpdateTiers(tiers);
        }
        const GenerateMarginTiers = () => {
            try {
                let tiers: any = [...list];
                let currentTier: any = list?.[index];
                let _marginTiers: any = "";
                for (let i: number = 0; i < Tier.MarginTiles.length; i++) {
                    _marginTiers += `T${Tier.MarginTiles[i].level}=${currentTier.MarginTiles[i].Trenches || 0}:${currentTier.MarginTiles[i].Leverage || 0};`;
                }
                currentTier.MarginTiers = _marginTiers;
                UpdateTiers(tiers);
            } catch (e) {
                console.error('GenerateMarginTiers', e);
            }
        }
        const TrenchesOnChange = (e: any, item: IMarginTiles) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            let _marginTitles: any[] = [...(currentTier?.MarginTiles || [])];
            for (let current of _marginTitles) {
                if (String(current.level) === String(item.level)) {
                    current.Trenches = e.target.value;
                    break;
                }
            }
            currentTier.MarginTiles = _marginTitles;
            UpdateTiers(tiers);
        }
        const LeverageOnChange = (e: any, item: IMarginTiles) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            let _marginTitles: any[] = [...(currentTier?.MarginTiles || [])];
            for (let current of _marginTitles) {
                if (String(current.level) === String(item.level)) {
                    current.Leverage = e.target.value;
                    break;
                }
            }
            currentTier.MarginTiles = _marginTitles;
            UpdateTiers(tiers);
        }
        const ResetMarginTiers = () => { // rest all Values 
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.levels = "";
            currentTier.MarginTiles = [];
            currentTier.MarginTiers = "";
            UpdateTiers(tiers);
        }
        //#endregion
        return <> < Row className="mt-2" >
            <Col md={col} className="d-inline-flex pl-0 ">
                <Col md={6} className="d-flex align-items-start flex-column">
                    <div>
                        <Label for="from" className="LabelCreate2 d-block font-14">
                            {t("StartDayOfTheWeekWithTime")}
                        </Label>
                    </div>
                    <div className="mt-auto w100p">
                        <Select
                            className="react-select-container MedSelect DarkMode"
                            classNamePrefix="react-select cursor-pointer LargeSelect DarkMode "
                            options={DAY_OPTIONS}
                            isSearchable={true}
                            isMulti={false}
                            value={{ value: Tier.FromDay, label: DAY_DICTIONARY[Tier.FromDay] }}
                            placeholder={"FromDay"}
                            onChange={FromDateOnChange}
                        />
                        <TimePicker
                            value={moment(`${Tier.FromHour || "00"}:${Tier.FromMinute || "00"}:00`, "HH:mm")}
                            defaultValue={moment("00:00:00", "HH:mm")}
                            format={"HH:mm"}
                            onChange={onFromTimeChange}
                        />
                    </div>
                </Col>
                <Col md={6} className=" d-flex align-items-start flex-column">
                    <div>
                        <Label for="from" className="LabelCreate2 d-block font-14">
                            {t("EndDayOfTheWeekWithTime")}
                        </Label>
                    </div>
                    <div className="mt-auto w100p">
                        <Select
                            className="react-select-container MedSelect DarkMode"
                            classNamePrefix="react-select cursor-pointer LargeSelect DarkMode "
                            options={DAY_OPTIONS}
                            isSearchable={true}
                            isMulti={false}
                            value={{ value: Tier.ToDay, label: DAY_DICTIONARY[Tier.ToDay] }}
                            placeholder={"ToDay"}
                            onChange={toDateOnChange}
                        />
                        <TimePicker
                            value={moment(`${Tier.ToHour || "00"}:${Tier.ToMinute || "00"}:00`, "HH:mm")}
                            defaultValue={moment("00:00:00", "HH:mm")}
                            format={"HH:mm"}
                            onChange={onToTimeChange}
                        />
                    </div>

                </Col>

            </Col>
        </Row >
            <Row className="mt-2">
                <Col md={col}>
                    <FormGroup>
                        <BasicValidation validate={(ValidateExpLimit(Tier.ProfileExpLimit) && +(Tier.ProfileExpLimit) > -1) || ValidateInput(`${Tier.levels}`)} title={t('Levels')} />
                        <Input
                            type="text"
                            name="levels"
                            bsSize="lg"
                            className="mb-3"
                            value={Tier.levels}
                            onChange={levelOnChange}
                            //onBlur={this.ReDrawMarginTiers}
                            onKeyUp={RedrawMatginTiersOnEnterKey}
                            onBlur={ReDrawMarginTiers} />
                    </FormGroup>
                </Col>
            </Row>
            <BasicValidation validate={CheckIFTrenchesIntervalIsValid(Tier.MarginTiles)} title={t('MarginTiers')} />
            {
                Tier?.MarginTiles
                && Tier?.MarginTiles?.length > 0 &&
                <Row>
                    <Col md={col}>
                        <Table id="tablesInModals"
                            onBlur={GenerateMarginTiers}
                        >
                            <thead>
                                <tr>
                                    <td style={{ width: "55px" }}>{t('Level')}</td>
                                    <td>{t('Trenches')}</td>
                                    <td>{ReturnSymbolSecType(Securitylabel)}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Tier.MarginTiles
                                    && Tier.MarginTiles.length > 0
                                    && Tier.MarginTiles.map((item: IMarginTiles, index: number) => {
                                        return (<tr key={`${item.level}`}>
                                            <td>
                                                <Label className="LabelCreate2 text-center"> T{item.level} </Label>
                                            </td>
                                            <td>
                                                <InputPostiveNumber
                                                    type="number"
                                                    min={0}
                                                    bsSize="lg"
                                                    className="smallInput d-inline modalInput2"
                                                    value={item.Trenches}
                                                    onChange={(e: any) => TrenchesOnChange(e, item)}
                                                    onBlur={(e: any) => TrenchesOnChange(e, item)} />
                                                {index === (Tier.MarginTiles.length - 1) ? ' - ∞ ' : ` - ${Tier.MarginTiles[index + 1].Trenches ? Tier.MarginTiles[index + 1].Trenches : "T" + (index + 1)}`}
                                            </td>
                                            <td>
                                                <InputPostiveNumber
                                                    type="number"
                                                    min={0}
                                                    bsSize="lg"
                                                    className="smallInput modalInput2"
                                                    value={item.Leverage}
                                                    onChange={(e: any) => LeverageOnChange(e, item)}
                                                    onBlur={(e: any) => LeverageOnChange(e, item)} />
                                            </td>
                                        </tr>);
                                    })}
                            </tbody>
                        </Table> </Col>
                </Row>}
            <Row>
                <Col md={col}>
                    {false && <Button className="mr-2 ButtonsModal mt-2" onClick={closeAndGenerate}>  {t('back')} </Button>}
                    <Button className="mr-2 ButtonsModal mt-2" onClick={GenerateMarginTiers}>  {t('GenerateMarginTiers')} </Button>
                    <Button className="mr-2 ButtonsModal mt-2" onClick={ResetMarginTiers}>  {t('Reset')} </Button>
                </Col>
            </Row >
            <Row>
                <Col md={col}>
                    <FormGroup>
                        <Input type="textarea" bsSize="lg" rows={3} value={Tier.MarginTiers} disabled />
                    </FormGroup>
                </Col>
            </Row></>;
    } catch (e) {
        console.error('RENDER_SYMBOL_MARGIN_TIER: ', e);
        return <></>;
    }
}
export const RenderAccountMarginTier = ({ selectedSecurities, list, selectedSessionID, t, closeAndGenerate, UpdateTiers, isEdit, col }
    : { t: Function, selectedSecurities: BasicSelectOption[], list: TierClass[], selectedSessionID: number, closeAndGenerate: any, UpdateTiers: any, isEdit: boolean, col: number }) => {
    try {
        const index = list.findIndex((item: TierClass) => item.SessionID === selectedSessionID);
        const Tier: TierClass = list?.[index];
        //#region @functions AccountExp
        const ReDrawMarginTiles = () => {
            let tiers = [...list];
            let currentTier = list?.[index];
            let _list: any[] = [];//dont take old as redraw new 
            for (let i: number = 0; i < parseInt(String(Tier.levels), 10); i++) {
                let _itemsFromValue: any = selectedSecurities && selectedSecurities?.length > 0 ?
                    ConvertIntoDictionaryWithSpecificValue(selectedSecurities, "label", "") : {};// label here check
                _list.push({ level: `${i}`, Trenches: "", ..._itemsFromValue });
            }
            currentTier.MarginTiles = _list;
            UpdateTiers(tiers);
        }
        const RedrawMatginTiersOnEnterKey = (e: any) => {
            if (e.keyCode === 13) {
                ReDrawMarginTiles();
            }
        }
        const levelOnChange = (e: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.levels = +(e.target.value);
            UpdateTiers(tiers);
        }
        const FromDateOnChange = (value: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.FromDay = value.value;
            UpdateTiers(tiers);
        }
        const onFromTimeChange = (time: any, timeString: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            const [hour, minute] = Get_Hour_Minute(timeString);
            currentTier.FromHour = hour;
            currentTier.FromMinute = minute;
            UpdateTiers(tiers);
        }
        const onToTimeChange = (time: any, timeString: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            const [hour, minute] = Get_Hour_Minute(timeString);
            currentTier.ToHour = hour;
            currentTier.ToMinute = minute;
            UpdateTiers(tiers);
        }
        const toDateOnChange = (value: any) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.ToDay = value.value;
            UpdateTiers(tiers);
        }
        const GenerateMarginTiers = () => {
            let tiers: any = [...list];
            let currentTier: any = list?.[index];
            const GenerateMarginString = () => {
                let _marginTiers: any = "";
                if (selectedSecurities && selectedSecurities?.length > 0 && Tier.MarginTiles && Tier?.MarginTiles?.length > 0) {
                    for (let i: number = 0; i < selectedSecurities?.length; i++) {
                        _marginTiers += `${ReturnSecuirtyTypeTitle(selectedSecurities[i].label)}: `;
                        for (let k: number = 0; k < Tier.MarginTiles.length; k++) {
                            _marginTiers += `T${Tier.MarginTiles[k].level}=${Tier.MarginTiles[k].Trenches || 0}:${Tier.MarginTiles[k][selectedSecurities?.[i].label] || 0};`;
                        }
                        _marginTiers += `\n`;
                    }
                }
                currentTier.MarginTiers = _marginTiers;
                UpdateTiers(tiers);
            }
            //const GenerateAccSecTypesList = () => {//_marginTiersList
            //    let _AccSecTypesList: any[] = [];
            //    if (selectedSecurities && selectedSecurities?.length > 0 && Tier.MarginTiles && Tier.MarginTiles.length > 0) {
            //        for (let i: number = 0; i < selectedSecurities.length; i++) {
            //            let _ID = +(selectedSecurities[i].value);
            //            let _margin = '';
            //            for (let k: number = 0; k < Tier.MarginTiles.length; k++) {
            //                _margin += `T${Tier.MarginTiles[k].level}=${Tier.MarginTiles[k].Trenches || 0}:${Tier.MarginTiles[k][selectedSecurities[i].label] || 0};`;
            //            }
            //            _AccSecTypesList.push({ AccExpID: 0, SecTypeID: _ID, MargTiers: _margin });
            //        }
            //    }
            //    //LogMessage('GenerateMarginTiers', _marginTiersList);
            //    currentTier.AccSecTypesList = _AccSecTypesList;
            //    UpdateTiers(tiers);
            //}
            try {
                GenerateMarginString();
                //GenerateMarginStringToSend(); // GenerateMarginStringToSend ReturnMarginStringToSend in casting or tho to use
                //GenerateAccSecTypesList();

            } catch (e) {
                console.error('GenerateMarginTiers', e);
            }
        }
        const TrenchesOnChange = (e: any, item: IMarginTiles) => {
            let tiers = [...list];
            let currentTier = list?.[index];
            let _marginTitles: any[] = [...(currentTier?.MarginTiles || [])];
            for (let current of _marginTitles) {
                if (String(current.level) === String(item.level)) {
                    current.Trenches = e.target.value;
                    break;
                }
            }
            currentTier.MarginTiles = _marginTitles;
            UpdateTiers(tiers);
        }
        const LeveragesOnChange = (e: any, item: any, LeverageType: any) => {
            //console.log(e, item, LeverageType);
            let tiers = [...list];
            let currentTier = list?.[index];
            let _marginTitles: any[] = [...(currentTier?.MarginTiles || [])];
            for (let current of _marginTitles) {
                if (String(current.level) === String(item.level)) {
                    current[LeverageType] = e.target.value;
                    break;
                }
            }
            currentTier.MarginTiles = _marginTitles;
            UpdateTiers(tiers);
        }
        const ResetMarginTiers = () => { // rest all Values 
            let tiers = [...list];
            let currentTier = list?.[index];
            currentTier.levels = "";
            currentTier.MarginTiles = [];
            currentTier.MarginTiers = "";
            UpdateTiers(tiers);
        }
        //#endregion
        return <> < Row className="mt-2" >
            <Col md={col} className="d-inline-flex">
                <div>
                    <Label for="from" className="LabelCreate2 d-block font-14">
                        {t("StartDayOfTheWeekWithTime")}
                    </Label>
                    <Select
                        className="react-select-container MedSelect DarkMode"
                        classNamePrefix="react-select cursor-pointer LargeSelect DarkMode "
                        options={DAY_OPTIONS}
                        isSearchable={true}
                        isMulti={false}
                        value={{ value: Tier.FromDay, label: DAY_DICTIONARY[Tier.FromDay] }}
                        placeholder={"FromDay"}
                        onChange={FromDateOnChange}
                    />
                    <TimePicker
                        value={moment(`${Tier.FromHour || "00"}:${Tier.FromMinute || "00"}:00`, "HH:mm")}
                        defaultValue={moment("00:00:00", "HH:mm")}
                        format={"HH:mm"}
                        onChange={onFromTimeChange}
                    />
                </div>
                <div className="ml-2">
                    <Label for="from" className="LabelCreate2 d-block font-14">
                        {t("EndDayOfTheWeekWithTime")}
                    </Label>
                    <Select
                        className="react-select-container MedSelect DarkMode"
                        classNamePrefix="react-select cursor-pointer LargeSelect DarkMode "
                        options={DAY_OPTIONS}
                        isSearchable={true}
                        isMulti={false}
                        value={{ value: Tier.ToDay, label: DAY_DICTIONARY[Tier.ToDay] }}
                        placeholder={"ToDay"}
                        onChange={toDateOnChange}
                    />
                    <TimePicker
                        value={moment(`${Tier.ToHour || "00"}:${Tier.ToMinute || "00"}:00`, "HH:mm")}
                        defaultValue={moment("00:00:00", "HH:mm")}
                        format={"HH:mm"}
                        onChange={onToTimeChange}
                    />
                </div>

            </Col>
        </Row >
            <Row className="mt-2">
                <Col md={col}>
                    <FormGroup>
                        <BasicValidation validate={(ValidateExpLimit(Tier.ProfileExpLimit) && +(Tier.ProfileExpLimit) > -1) || ValidateInput(`${Tier.levels}`)} title={t('Levels')} />
                        <Input
                            type="text"
                            name="levels"
                            bsSize="lg"
                            className="mb-3"
                            value={Tier.levels}
                            onChange={levelOnChange}
                            //onBlur={this.ReDrawMarginTiers}
                            onKeyUp={RedrawMatginTiersOnEnterKey}
                            onBlur={ReDrawMarginTiles} />
                    </FormGroup>
                </Col>
            </Row>
            <BasicValidation validate={CheckIFTrenchesIntervalIsValid(Tier.MarginTiles)} title={t('MarginTiers')} />
            {
                Tier.MarginTiles
                && Tier.MarginTiles.length > 0 &&
                <Row>
                    <Col md={col}>
                        <Table id="tablesInModals"
                            onBlur={GenerateMarginTiers}
                        >
                            <thead>
                                <tr>
                                    <td style={{ width: "55px" }}>{t('Level')}</td>
                                    <td>{t('Trenches')}</td>
                                    {selectedSecurities?.length > 0
                                        && selectedSecurities.map((item: BasicSelectOption) => {
                                            return (<td key={item.value}><div style={{ width: "120px" }}>{ReturnAccSecType(item.label)}</div></td>)
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {Tier.MarginTiles
                                    && Tier.MarginTiles.length > 0
                                    && Tier.MarginTiles.map((item: IMarginTiles, index: number) => {
                                        return (<tr key={`${item.level}`}>
                                            <td>
                                                <Label className="LabelCreate2 text-center"> T{item.level} </Label>
                                            </td>
                                            <td>
                                                <InputPostiveNumber
                                                    type="number"
                                                    min={0}
                                                    bsSize="lg"
                                                    className="smallInput d-inline modalInput2"
                                                    value={item.Trenches}
                                                    onChange={(e: any) => TrenchesOnChange(e, item)}
                                                    onBlur={(e: any) => TrenchesOnChange(e, item)} />
                                                {index === (Tier.MarginTiles.length - 1) ? ' - ∞ ' : ` - ${Tier.MarginTiles[index + 1].Trenches ? Tier.MarginTiles[index + 1].Trenches : "T" + (index + 1)}`}
                                            </td>
                                            {selectedSecurities && selectedSecurities.length > 0
                                                && selectedSecurities.map((element: BasicSelectOption) => {
                                                    return (< td key={element.label}>
                                                        <InputPostiveNumber
                                                            type="number"
                                                            min={0}
                                                            style={{ width: "90px" }}
                                                            bsSize="lg"
                                                            className=" modalInput"
                                                            value={Tier.MarginTiles[index][element.label]}
                                                            onChange={(e: any) => LeveragesOnChange(e, item, element.label)}
                                                            onBlur={(e: any) => LeveragesOnChange(e, item, element.label)}
                                                        //item not element
                                                        />
                                                    </td>)
                                                })
                                            }
                                        </tr>);
                                    })}
                            </tbody>
                        </Table> </Col>
                </Row>}
            <Row>
                <Col md={col}>
                    {false && <Button className="mr-2 ButtonsModal mt-2" onClick={closeAndGenerate}>  {t('back')} </Button>}
                    <Button className="mr-2 ButtonsModal mt-2" onClick={GenerateMarginTiers}>  {t('GenerateMarginTiers')} </Button>
                    <Button className="mr-2 ButtonsModal mt-2" onClick={ResetMarginTiers}>  {t('Reset')} </Button>
                </Col>
            </Row >
            <Row>
                <Col md={col}>
                    <FormGroup>
                        <Input type="textarea" bsSize="lg" rows={3} value={Tier.MarginTiers} disabled />
                    </FormGroup>
                </Col>
            </Row></>;
    } catch (e) {
        console.error('RENDER_ACCOUNT_MARGIN_TIER: ', e);
        return <></>;
    }
}
//#endregion
//#region LiveChangeAVant

interface IProps {
    language: BasicSelectOption;
    CompanySettings: LoadCompanyLogoResult;
    ClientData: GetClientData;
    lang: translationType;
    userData: IUserData;
    push: any;
    location: any;
    CompanyData: ICompanyData;
    CompanyDataProps: GetCompanyDataResult;
    ChangeLanguage: any;
    theme: any;
    t: any;
    i18n: any;
}
const mapStateToProps = (state: IStore) => ({
    CompanySettings: state.CompanySettings,
    ClientData: state.auth.ClientData,
    userData: state.auth.UserData,
    lang: state.language.value,
    location: state.router.location,
    history: state.history,
    Servers: state.auth.Servers,
    theme: state.theme,
    CompanyData: state.auth.CompanyData,
    LangselectedOption: state.language,
    language: state.language,
    CompanyDataProps: state.CompanyData,
});
const dispatchProps = { push }

class LiveChatClass extends React.Component<IProps> {
    componentDidUpdate(prevProps: IProps) {

    }
    componentDidMount() {
    }
    //#region LiveChatWidget Avant-Markets
    handleNewEvent = (event: EventHandlerPayload<'onNewEvent'>) => {
        console.log('LiveChatWidget.onNewEvent', event)
    }
    CheckIfClientInInternalPage = () => {
        return (
            !store?.getState()?.auth?.UserData?.isAdmin
            && !CheckIfIsExternalPage(window?.location?.href)
            && store?.getState()?.auth?.isAuth);
    }
    //#endregion
    render() {
        //if not avant remove all compnent
        const isClientInInternalPage = this.CheckIfClientInInternalPage();
        const isAvant = CheckIfCompanyIs("Avant");
        const isPRIMEX = CheckIfCompanyIs("PRIMEX");
        if (!isAvant && !isPRIMEX) {
            return <></>;
        }
        return (<LiveChatWidget
            license={isPRIMEX ? "14324394" : "13890753"}
            visibility={isClientInInternalPage ? "minimized" : "hidden"}
            onNewEvent={this.handleNewEvent}
        />)
    }
}

export const LiveChat = withTranslation()(connect(mapStateToProps, dispatchProps)(LiveChatClass) as any) as any;


//#endregion