import * as types from "../constants";

const initialState: any = {};

export default function reducer(state = initialState, actions: types.UpdateFILTRATIONDict) {
    switch (actions.type) {
        case "UPDATE_FILTRATION_DICT": {
            return {
                ...state,
                ...actions.payload
            };
        }
        default:
            return state;
    }
}