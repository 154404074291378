import * as types from "../constants";
import { DashboardItems } from "../../Interfaces/Interfaces";

const initialState: DashboardItems[] = []

export default function reducer(state = initialState, actions: types.UpdateDashboardItems) {
    switch (actions.type) {
        case "UPDATE_DASHBOARD_ITEMS":
            return actions.payload;
        default:
            return state;
    }
}
