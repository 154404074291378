import * as types from "../constants";

const initialState: types.PushNotificationStatusClass = {
    PushNotificationStatus: [],
    signalRIsConnected: false,
}

export default function reducer(state = initialState, actions: types.PushNotifications | types.SignalR | types.ClearPushNotifications) {
    switch (actions.type) {
        case "Push_Notifications":
            return {
                ...state,
                PushNotificationStatus: [...actions.payload.PushNotificationStatus]
            }
        case "SIGNAL_R":
            return {
                ...state,
                signalRIsConnected: actions.payload
            }
        case "CLEAR_PUSH_NOTIFICATIONS":
            return initialState
        default:
            return state;
    }
}
