import { GUTradersOptions, BUTraders } from "../Interfaces/Interfaces";
import { ServersOptions } from "../Interfaces/Interfaces";

/**
     * Helper to fill option from redux.
     */
export function FillServerOptionsHelper(ServersData: any[], dontDisplayTypeSix: boolean, hideMT5?: boolean) {//edit name
    var _serverOptions: any[] = [];

    if (ServersData) {
        for (var k = 0; k < ServersData.length; k++) {
            //if (!dontDisplayTypeSix /*|| ServersData[k].ServerType !== 6*/) {// if flag ON will check other parameter 
            //    //let _MT = +(ServersData[k].Type ) !== 6? " - MT" : " - ";
            if (!hideMT5 || ServersData[k].ServerType !== "MT5") {
                _serverOptions.push({
                    value: ServersData[k].ServerID, label: ServersData[k].ServerName + " - " + ServersData[k].ServerType,
                    serverID: ServersData[k].ServerID,
                    serverName: ServersData[k].ServerName,
                    type: ServersData[k].ServerType,
                    wlName: ServersData[k].wlName,
                    wlid: ServersData[k].wlid,
                });
            }
        }
    }
    else
        _serverOptions.push(new ServersOptions());
    return _serverOptions;
};
/**
     * Helper to fill option from /getservers request.
     */
export function FillServerDetailsHelper(ServersData: any[], FilterFlag: boolean) {
    var _serverOptions: any[] = [];
    if (ServersData) {
        for (var k = 0; k < ServersData.length; k++) {
            //if (!FilterFlag || ServersData[k].Type !== 6) {// if flag ON will check other parameter 
            //let _MT = +(ServersData[k].Type) !== 6 ? " - MT" : " - ";
            _serverOptions.push({
                value: ServersData[k].ServerID, label: ServersData[k].ServerName + ' - ' + ServersData[k].Type,
                serverID: ServersData[k].ServerID,
                serverName: ServersData[k].ServerName,
                type: ServersData[k].Type,
                wlName: ServersData[k].wlName,
                wlid: ServersData[k].wlid,
            });
            //}
        }
    } else
        _serverOptions.push(new ServersOptions());
    return _serverOptions;
};
export function FillLoginsOptionsHelper(GetGUTradersResult: BUTraders[], serverSelectedValue: any, isMAM?: boolean) {
    var _loginOptions: GUTradersOptions[] = [];
    try {
        if (GetGUTradersResult) {
            if (GetGUTradersResult.length !== 0) {
                for (var k = 0; k < GetGUTradersResult.length; k++) {
                    if (GetGUTradersResult[k].ServerID === serverSelectedValue) {
                        if (!isMAM || GetGUTradersResult[k].ProfileID !== 0)
                            _loginOptions.push({
                                ...GetGUTradersResult[k],
                                MasterLogin: GetGUTradersResult[k].MasterLogin,
                                ProfileID: GetGUTradersResult[k].ProfileID,
                                AccountID: GetGUTradersResult[k].AccountID,
                                ServerID: GetGUTradersResult[k].ServerID,
                                ServerType: GetGUTradersResult[k].ServerType,
                                ServerName: GetGUTradersResult[k].ServerName,
                                ARuleID: GetGUTradersResult[k].ARuleID,
                                ARuleName: GetGUTradersResult[k].ARuleName,
                                Currency: GetGUTradersResult[k].Currency,
                                label: GetGUTradersResult[k].MasterLogin + " - " + GetGUTradersResult[k].Currency,
                                value: GetGUTradersResult[k].MasterLogin
                            });
                    }
                }
            }
        }
    } catch (e) {
        console.error("FillLoginsOptionsHelper",e)
    }
    if (_loginOptions.length === 0) {
        _loginOptions.push(new GUTradersOptions());
    }
    return _loginOptions;
};
//check if not needed any more
export function FillLoginsOptionsHelperGUIB(GetGUIBsResult: any[], serverSelectedValue: any) {
    var _loginOptions: any[] = [];
    if (GetGUIBsResult !== null) {
        if (GetGUIBsResult.length !== 0)
            for (var k = 0; k < GetGUIBsResult.length; k++) {
                if (GetGUIBsResult[k].ServerID === serverSelectedValue) {//a[k] as list 
                    _loginOptions.push({
                        value: GetGUIBsResult[k].AccountID,
                        label: GetGUIBsResult[k].Login + " - "
                            + GetGUIBsResult[k].Currency,
                        clientwebsite: GetGUIBsResult[k].clientwebsite,
                        token: GetGUIBsResult[k].Token
                    });
                }

            }

    }
    if (_loginOptions.length === 0) {
        _loginOptions.push({
            value: -1,
            label: "NO Logins"
        });;
    }
    return _loginOptions;
};
