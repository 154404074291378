import { faBell, faCopy, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Divider, Form, Input as AntInput } from 'antd';
import Checkbox from 'antd/es/checkbox';
import Radio from 'antd/es/radio';
import { AxiosResponse } from 'axios';
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { default as Select } from 'react-select';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, UncontrolledDropdown } from "reactstrap";
import { BasicValidation } from '../../components/ReusableComponents/ReusableElements/BasicElements';
import { ProfileModal } from '../../components/ReusableComponents/ReusableElements/Modals';
import { CallAxiosRequest } from '../../Helpers/axiosHelpers';
import { FillServerOptionsHelper } from '../../Helpers/DataFillingHelpers';
import { CheckPasswordStrength, GetPermValName, password_generator, ReturnCountryCode, ReturnOptionByValue, ReturnSelectOptions, SetUSAFirstCounty, TimeZonesOptions, validateDate, validateEmail, ValidateInput, ValidateSelect } from '../../Helpers/DataHelpers';
import { BasicSelectOption } from '../../Interfaces/IApplicationForm';
import { ActionContact, Contact, DashboardClass, GUTraders, GUTradersOptions, ProfileInfoAction, SendPassword, Servers, ServersOptions, translationType, UploadKYCDocumentRequest, YoonitRequest, YoonitResponse } from '../../Interfaces/Interfaces';
import { Login, Logout, UpdateTimezone } from '../../redux/actions/AuthActions';
import { ChangeLanguage } from '../../redux/actions/languageAction';
import { GetAdminData, ICompanyData, ILanguageData, IPerms, IStore, IUserData } from '../../redux/constants';



const mapStateToProps = (state: IStore) => ({
    LanguageData: state.LanguageData,
    UserPermissions: state.auth.UserPermissions,
    AdminData: state.auth.AdminData,
    userData: state.auth.UserData,
    lang: state.language.value,
    history: state.history,
    GetURL: state.auth.UserData.yoonitGetURL,
    Servers: state.auth.Servers,
    theme: state.theme,
    ActionURL: state.auth.UserData.yoonitActionURL,
    CompanyData: state.auth.CompanyData,
});
const $ = require("jquery");

const dispatchProps = { push, Login, Logout, UpdateTimezone, ChangeLanguage }
//const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;
export interface MyProfileProps {
    LanguageData: ILanguageData;
    lang: translationType;
    userData: IUserData;
    push: any;
    GetURL: string;
    theme: any;
    ActionURL: string;
    CompanyData: ICompanyData;
    Servers: any;
    openProfile: any;
    closeProfile: any;
    UpdateTimezone: any;
    ChangeLanguage: any;
    AdminData: GetAdminData;
    UserPermissions: IPerms[];
    i18n: any;
    t: any;
}

export interface MyProfileState {
    EmailtoSendPassword: string;
    countriesOptions: any[];
    contactCountriesSelectedValue: BasicSelectOption;
    GetContactInfoResult: Contact;
    selectedEmail: string;
    resetEmail: string;
    SendByEmail: boolean;
    passwordValue: any;
    GetGUTradersResult: GUTraders[];
    GetDashboardDataResponse: DashboardClass;
    serverSelectOptions: ServersOptions[];
    loginSelectOptions: GUTradersOptions[];
    serverSelectedValue: ServersOptions;
    loginSelectedValue: GUTradersOptions;
    capitalAloc: any;
    YTDROIChart: any;
    isLoading: boolean;
    ProfilesCopyMe: number;
    GetGUDataResponse: any;
    LoadPlatformLinksResponse: any;
    currentPassword: any;
    newPassword: any;
    ConfirmPassword: any;
    currentEmail: any;
    currentPasswordEmail: any;
    newEmail: any;
    languageSelectOptions: any;
    languageSelectedValue: any;
    LinkPlatform: any;
    PLink: any;
    PDesc: any;
    profileImg: any;
    openPassword: boolean;
    openLangauge: boolean;
    openLinks: boolean;
    openTimeZone: boolean;
    TimeZone: number;
    TimeZonesOptions: any[];
    TimeZoneSelectedValue: any;
    openProfileNotification: boolean;
    autoGeneratePassword: string;
    openAutoGenerate: boolean;
    openEditContact: boolean;
    AddContactClass: ActionContact;
    dateString: string;
}
var companyName: any; var userName: any; var Token: any;

class MyProfileAdmin extends React.Component<MyProfileProps, MyProfileState> {
    state = {
        EmailtoSendPassword: '',
        countriesOptions: [],
        contactCountriesSelectedValue: new BasicSelectOption(),
        GetContactInfoResult: new Contact() as any,
        selectedEmail: '',
        resetEmail: '',
        SendByEmail: false,
        passwordValue: 1,
        autoGeneratePassword: "",
        GetGUTradersResult: [],
        GetDashboardDataResponse: new DashboardClass(),
        serverSelectOptions: [],
        loginSelectOptions: [],
        serverSelectedValue: new ServersOptions(),
        loginSelectedValue: new GUTradersOptions(),
        capitalAloc: [],
        YTDROIChart: [],
        isLoading: false,
        ProfilesCopyMe: -1,
        GetGUDataResponse: {
            Email: "",
            FullName: "",
            UserStatus: "",
            ProfileImage: "",
            Phone: "",
            CountryName: "",
            Nationality: "",
            Address: "",
            ZipCode: "",
            EmploymentStatus: "",
        },
        LoadPlatformLinksResponse: [],
        currentPassword: '',
        newPassword: '',
        ConfirmPassword: '',
        currentEmail: '',
        currentPasswordEmail: '',
        newEmail: '',
        languageSelectOptions: [],
        languageSelectedValue: { value: "en" },
        LinkPlatform: '',
        PLink: [],
        PDesc: [],
        profileImg: "", openPassword: false, openLangauge: false, openLinks: false,
        openTimeZone: false, TimeZone: 0, TimeZonesOptions: [], TimeZoneSelectedValue: new BasicSelectOption() as any, openProfileNotification: false,
        openAutoGenerate: false,
        openEditContact: false,
        AddContactClass: new ActionContact(),
        dateString: "",
    }
    componentDidMount() {
        this.LoadTimeZones();
        companyName = this.props.CompanyData.CompanyName;
        Token = this.props.userData.Token;
        userName = this.props.AdminData.Email;
        this.FillServerOptions(this.props.Servers, true);
        this.languageOptionsFunction(this.props.LanguageData.Options);
        this.setState({ isLoading: true });
    }
    FillServerOptions = (ServersData: Servers[], FilterFlag: boolean) => {
        let _options = FillServerOptionsHelper(ServersData, FilterFlag);
        this.setState({ serverSelectOptions: _options, serverSelectedValue: _options[0] });
    };
    languageOptionsFunction = (languageOptions: any) => {
        var langOpt = [];
        for (var k = 0; k < languageOptions.length; k++) {
            langOpt.push({
                value: languageOptions[k].value,
                label: languageOptions[k].label
            });
        }
        this.setState({ languageSelectOptions: langOpt, languageSelectedValue: langOpt[0] }, () => this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue"));
    }
    LoadPlatformLinks = () => {
        var LoadPlatformLinksParams: any = {
            CompanyName: companyName,
            Token: Token,
            UserName: userName,
            ObjData: {
                Platform: this.state.serverSelectedValue.type,
            }
        };
        CallAxiosRequest<any, any>(
            this.props.GetURL,
            `CRM/LoadPlatformLinks`,
            LoadPlatformLinksParams,
            { Token: this.props.userData.Token },
            (res: any) => {

                this.setState({ LoadPlatformLinksResponse: res.data.listRes });
                if (this.state.LoadPlatformLinksResponse.length === 0) {
                    this.setState({ LinkPlatform: 'None' });
                }
                else {
                    this.setState({ PLink: this.state.LoadPlatformLinksResponse });
                }
            },
            () => { },
            this.props.t

        );
    }
    LoadTimeZones = () => {
        //var timezoneOptions = timezones.default;
        var timezoneOptions = TimeZonesOptions(this.props.t);
        var options = [];
        for (let k = 0; k < timezoneOptions.length; k++) {
            options.push({ value: timezoneOptions[k].tzCode, label: timezoneOptions[k].text, tzCode: timezoneOptions[k].tzCode, offset: timezoneOptions[k].offset });
        }
        this.setState({ TimeZonesOptions: options, TimeZoneSelectedValue: options[0] });
        //sent the valueSent for api
    }
    UpdateMailLang = (e: any) => {
        e.preventDefault();
        var UpdateMailLangParams = {
            CompanyName: companyName,
            Token: Token,
            ObjData: {
                Language: this.state.languageSelectedValue.value,
                //GUID: GUID
                //ClientID :  "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                BUID: "75f5fad2-9aa9-48d8-a753-a5b71535f1e3",//static
            }
        };

        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `MAMM/UpdateMailLang`,
            UpdateMailLangParams,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IsAuth) { toastr.success('', this.props.t('Updated')) } else {
                    toastr.error('', this.props.t('FailedAction'));
                }
            },
            () => { },
            this.props.t

        );
    }
    UpdateUserPassword = () => {
        //add a modal for it
        let _Password = this.state.newPassword;
        let _SendByEmail = false;
        if (this.state.passwordValue === 1) {
            _SendByEmail = this.state.SendByEmail;
            _Password = password_generator(7);
            this.setState({ newPassword: _Password });//for  auto generate 
        }
        var UpdateUserPasswordParams: YoonitRequest<any> = {
            CompanyName: companyName,
            Token: Token,
            UserName: userName,
            ClientName: this.state.GetContactInfoResult ? `${this.state.GetContactInfoResult.FirstName ? this.state.GetContactInfoResult.FirstName : ""}${this.state.GetContactInfoResult.LastName ? this.state.GetContactInfoResult.LastName : ""}` : "",
            ObjData: {
                ContactID: this.props.AdminData.ContactID,
                Password: btoa(_Password),
                SendByEmail: _SendByEmail,
                Email: this.props.AdminData.Email,
                SendToEmail: this.state.resetEmail,
                IsAdmin: true
            }
        };
        CallAxiosRequest<any, number>(
            this.props.ActionURL,
            `Trader/UpdateUserPassword`,
            UpdateUserPasswordParams,
            { Token: this.props.userData.Token },
            (res: any) => {
                ;
                if (res.data.IntRes > 0) {
                    toastr.success(" ", this.props.t('PasswordChanged'));
                    this.closePasswordModal();
                    if (String(this.state.passwordValue) === "1") {
                        this.setState({
                            newPassword: UpdateUserPasswordParams.ObjData?.Password ? atob(UpdateUserPasswordParams.ObjData?.Password) : ""
                        });
                        this.openAutoGenerate();
                    }
                } else if (res.data.IntRes === -2) {
                    toastr.warning("", this.props.t('ContactDoesnotExist'));
                } else if (res.data.IntRes === -10) {
                    toastr.warning("", this.props.t('PasswordUpdateFailed'));
                } else {
                    toastr.warning(" ", this.props.t('FailedAction'));
                }
            },
            () => {
            },
            this.props.t

        );

    }
    onDetailsChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        } as any);
    };
    serverSelectOnChange = (serverSelectedValue: any) => {
        this.setState({ serverSelectedValue }, this.LoadPlatformLinks);
    };
    handleImg = (binary: any, name: any, type: any, addresImg: any, DocumentType: any) => {
        if (binary !== undefined) {
        }
        if (name !== undefined) {
        }
        if (type !== undefined) {
        }
        let UpdateUserProfileImageParms: YoonitRequest<UploadKYCDocumentRequest> = new YoonitRequest<UploadKYCDocumentRequest>();
        UpdateUserProfileImageParms.ObjData = new UploadKYCDocumentRequest();
        //pp
        //UpdateUserProfileImageParms.objData.DocumentType ="pp" no type here
        UpdateUserProfileImageParms.Token = Token;
        UpdateUserProfileImageParms.CompanyName = companyName;
        //UpdateUserProfileImageParms.objData.GUID = GUID;
        //UpdateUserProfileImageParms.ObjData.ClientID =  "3fa85f64-5717-4562-b3fc-2c963f66afa6";//removed
        UpdateUserProfileImageParms.ObjData.ContactID = "4FD31932-E48C-444E-ABEE-9320F21A41C5";
        //UpdateUserProfileImageParms.ObjData.ContactID = "4FD31932-E48C-444E-ABEE-9320F21A41C5";
        UpdateUserProfileImageParms.ObjData.Type = "pp";
        UpdateUserProfileImageParms.ObjData.BinaryDocument = addresImg;
        //DocumentName
        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `MAMM/UpdateUserProfileImage`,
            UpdateUserProfileImageParms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes === -1)
                    toastr.error('', this.props.t('FailedAction'));
                else if (res.data.IntRes === -5)
                    toastr.error('', this.props.t('WrongFormatWasUploaded'));
                else if (res.data.IntRes < 0)
                    toastr.error('', this.props.t('FailedAction'));
                else {
                    this.setState({ profileImg: res.data.LiveRes.DocPath });// res.data.
                    toastr.success('', this.props.t('DocumentUploadedSuccessfully'));
                }
            },
            () => {
                toastr.error('', this.props.t('ImageNotUpdated'));
            },
            this.props.t

        );

    }
    openAutoGenerate = () => {
        this.setState({
            openAutoGenerate: true,
            EmailtoSendPassword: this.props.userData.UserName
        })
    }
    closeAutoGenerate = () => {
        this.setState({ openAutoGenerate: false })
    }
    selectOptionByValue = (value: any, list: any[], selectedValue: any) => {
        for (let i: number = 0; i < list.length; i++) {
            if (String(value) === String(list[i].value)) //icons on icon
                this.setState({ [selectedValue]: { ...list[i] } } as any)
        }
    }
    openEditContact = () => {
        this.selectOptionByValue(this.state.AddContactClass.CountryID, this.state.countriesOptions, "contactCountriesSelectedValue");
        this.setState({ openEditContact: true })
    }
    closeEditContact = () => {
        this.setState({ openEditContact: false })
    }
    languageOnChange = (languageSelectedValue: any) => {
        this.setState({ languageSelectedValue });
    };
    TimeZoneOnChange = (TimeZoneSelectedValue: any) => {
        this.setState({ TimeZoneSelectedValue })
    }
    OpenLanguageModal = () => {
        this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
        this.setState({ openLangauge: true })
    }
    closeLanguageModal = () => {
        this.setState({ openLangauge: false })
    }
    OpenPasswordModal = () => {
        this.resetUpdatePassword();
        this.setState({ openPassword: true })
    }
    closePasswordModal = () => {
        this.setState({ openPassword: false })
    }

    openTimeZone = () => {
        this.selectOptionByValue(this.state.GetContactInfoResult && this.state.GetContactInfoResult.TimeZoneCode ? this.state.GetContactInfoResult.TimeZoneCode : "", this.state.TimeZonesOptions, "TimeZoneSelectedValue");
        this.setState({ openTimeZone: true })
    }
    closeTimeZone = () => {
        this.setState({ openTimeZone: false })
    }
    triggerUserImg = () => {
        $('#userImg').trigger("click");
    }
    openProfileNotification = () => {
        this.setState({ openProfileNotification: true })
    }
    closeProfileNotification = () => {
        this.setState({ openProfileNotification: false })
    }
    navigateToNotification = (value: any) => {
        this.props.push('/PortalSettings/Notifications');
    }
    OnChangRadioPassword = (e: any) => {
        this.setState({
            passwordType: e.target.value,
            [e.target.name]: e.target.value
        } as any, this.generatePassword);
    }
    generatePassword = () => {
        this.setState({ autoGeneratePassword: password_generator(7) })
    }
    inputOnchange = (e: any) => {//for all
        this.setState({ [e.target.name]: e.target.value } as any)
    }
    resetUpdatePassword = () => {
        this.setState({
            passwordValue: 1,
            newPassword: "",
            SendByEmail: false,
        })
    }
    contactInputOnchange = (e: any) => {//for all
        e.persist();
        this.setState((prevstate: any) => ({
            AddContactClass: {
                ...prevstate.AddContactClass,
                [e.target.name]: e.target.value
            }
        }))
    }
    changeDate = (e: any) => {
        e.persist();
        this.setState({ dateString: e.target.value })
        this.setState((prevstate: any) => ({
            AddContactClass: {
                ...prevstate.AddContactClass,
                DOB: e.target.value
            }
        }))
    }
    FillSelectOptions = (SelectOpt: any, stateName: string, value: string, label: string) => {
        let _options: any = [];
        for (let k = 0; k < SelectOpt.length; k++) {
            _options.push({ value: SelectOpt[k][value], label: SelectOpt[k][label], Data: SelectOpt[k], ...SelectOpt[k] });
        }
        this.setState({ [stateName]: _options } as any);

    };
    GetCountries = () => {
        let GetCountriesParams: YoonitRequest<number> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: 0
        };
        CallAxiosRequest<any, any>(
            this.props.GetURL,
            `Trader/GetCountries`,
            GetCountriesParams,
            { Token: this.props.userData.Token },
            (res: any) => {
                if (res.data.ListRes) {
                    let _countries = SetUSAFirstCounty(res.data.ListRes);
                    this.setState({ countriesOptions: ReturnSelectOptions(_countries, "CountryID", "CountryName") },
                        () => this.setState({ contactCountriesSelectedValue: ReturnOptionByValue(this.state.GetContactInfoResult?.CountryID, this.state.countriesOptions) })
                    );
                }
                this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    GetContactInfoCall = () => {
        let GetContactInfoParams: YoonitRequest<string> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: this.props.AdminData.ContactID
        };
        CallAxiosRequest<any, any>(
            this.props.GetURL,
            `Trader/GetContactInfo`,
            GetContactInfoParams,
            { Token: this.props.userData.Token },
            (res: AxiosResponse<YoonitResponse<Contact>>) => {
                if (res.data.LiveRes) {
                    let _GetContactInfoResult: any = res.data.LiveRes;
                    let _temp: any = new Contact();
                    Object.keys(_GetContactInfoResult).forEach(function (key) {
                        if (_GetContactInfoResult[key] === null) {
                            _GetContactInfoResult[key] = _temp[key];
                        }
                    })
                    this.setState({
                        GetContactInfoResult: _GetContactInfoResult,
                        AddContactClass: _GetContactInfoResult
                    }, () =>
                        this.selectOptionByValue(this.state.GetContactInfoResult && this.state.GetContactInfoResult.TimeZoneCode ? this.state.GetContactInfoResult.TimeZoneCode : "", this.state.TimeZonesOptions, "TimeZoneSelectedValue")

                    )
                    if (res.data.LiveRes.CountryID) {
                        this.setState({ contactCountriesSelectedValue: ReturnOptionByValue(res.data.LiveRes.CountryID, this.state.countriesOptions) });
                    }
                    if (res?.data?.LiveRes?.Language) {
                        this.selectOptionByValue(res?.data?.LiveRes?.Language, this.state.languageSelectOptions, "languageSelectedValue");
                    } else {
                        this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
                    }
                    this.setState({ dateString: res?.data?.LiveRes?.DOB ? res?.data?.LiveRes?.DOB : '' });
                }
                this.setState({ isLoading: false });
            },
            () => {
                this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    validateEdit = () => {
        return (ValidateInput(this.state.AddContactClass.FirstName)
            && ValidateInput(this.state.AddContactClass.LastName)
            //&& ValidateInput(this.state.AddContactClass.Email)
            ////&& ValidateSelect(this.state.c)
            //&& ValidateInput(this.state.AddContactClass.Phone)
        )
    }
    UpdateContact = () => {
        let ManageContactsUserPermValName: string = GetPermValName('Manage Contacts', this.props.UserPermissions);
        //add a modal for it
        let UpdateContactParams: YoonitRequest<ActionContact> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            UserName: this.props.AdminData.Email,
            ClientName: this.state.AddContactClass ? `${this.state.AddContactClass.FirstName} ${this.state.AddContactClass.LastName}` : "",
            ObjData: this.state.AddContactClass
        };
        let _ObjData: any = this.state.AddContactClass;
        _ObjData.DOB = validateDate(this.state.dateString) ? this.state.dateString : null;
        _ObjData.CountryID = String(_ObjData.CountryID) === "-1" ? null : _ObjData.CountryID;
        _ObjData.SalutationID = String(_ObjData.SalutationID) === "-1" ? null : _ObjData.SalutationID;
        _ObjData.MaritalStatusID = String(_ObjData.MaritalStatusID) === "-1" ? null : _ObjData.MaritalStatusID;
        _ObjData.MilitaryStatusID = !_ObjData.MilitaryStatusID || String(_ObjData.MilitaryStatusID) === "-1" ? null : _ObjData.MilitaryStatusID;
        _ObjData.StatusID = String(_ObjData.StatusID) === "-1" ? null : _ObjData.StatusID;
        _ObjData.ContactOwner = this.state.AddContactClass.ContactOwner ? this.state.AddContactClass.ContactOwner : null;
        _ObjData.ContactID = this.props.AdminData.ContactID;
        //_ObjData.ClientID = null;
        _ObjData.GraduationDate = null;
        _ObjData.CreationDate = null;
        _ObjData.IsActive = _ObjData.IsActive === "true" ? true : false;
        _ObjData.UnsubscribedFromAllEmail = _ObjData.UnsubscribedFromAllEmail === "true" ? true : false;
        _ObjData.Perms = ManageContactsUserPermValName;
        _ObjData.AUID = this.props.AdminData.AUID;
        _ObjData.Language = ValidateSelect(this.state.languageSelectedValue) ? this.state.languageSelectedValue.value : "en";
        //add values
        _ObjData.TimeZone = this.state.TimeZoneSelectedValue.offset ? this.state.TimeZoneSelectedValue.offset : 0;
        _ObjData.TimeZoneCode = this.state.TimeZoneSelectedValue.tzCode ? this.state.TimeZoneSelectedValue.tzCode : "";
        UpdateContactParams.ObjData = _ObjData;

        if (this.validateEdit()) {
            this.setState({ isLoading: true });
            CallAxiosRequest<any, number>(
                this.props.ActionURL,
                `Trader/UpdateContact`,
                UpdateContactParams,
                { Token: this.props.userData.Token },
                (res: any) => {

                    if (res.data.IntRes > 0) {
                        toastr.success("", this.props.t('ContactWasUpdatedSuccessfully'));
                        this.closeEditContact();
                    } else if (res.data.IntRes === -2) {
                        toastr.warning("", this.props.t('EmailExists'));
                    }
                    else if (res.data.IntRes === -403) {
                        toastr.warning("", this.props.t('YouDontHavePermissionToProceedWithThisAction'));
                    }
                    else
                        toastr.warning("", this.props.t('FailedAction'));
                    this.setState({ isLoading: false });
                    this.props.closeProfile();
                },
                () => {
                    this.closeEditContact();
                    this.props.closeProfile();
                    this.setState({ isLoading: false });
                },
                this.props.t

            );
        }
        else {
            toastr.warning("", this.props.t('FillRequiredFields'))
        }
    };
    contactCountriesOnChange = (value: any) => {
        this.setState({ contactCountriesSelectedValue: value });
        this.setState((prevstate: any) => ({
            AddContactClass: {
                ...prevstate.AddContactClass,
                CountryID: +(value.value)
            }
        }))
    };
    componentDidUpdate(prevProps: MyProfileProps) {
        if (this.props.openProfile && (prevProps.openProfile !== this.props.openProfile)) {
            this.LoadTimeZones();
            this.GetCountries();//TODO add to redux as my profile get called  
            this.GetContactInfoCall();
            //this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");//on each open //account lang not selected in redux
        }
    }
    ReturnTimeZone = (TimeZoneoffset: string) => {
        let _result: number = 0;
        let sign = TimeZoneoffset.charAt(0);
        let offset = TimeZoneoffset.substr(1);
        let hrs = parseInt(offset.split(":")[0], 10);
        let mins = parseInt(offset.split(":")[1], 10);
        if (sign === "+")
            _result = (hrs * 60) + mins;
        else
            _result = (hrs * -60) - mins;
        return _result;
    }
    UpdateUserTimeZone = async () => {
        let Parms: YoonitRequest<ProfileInfoAction | any> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: {
                ContactID: this.props.AdminData.ContactID,
                TimeZone: this.state.TimeZoneSelectedValue.offset,
                TimeZoneCode: this.state.TimeZoneSelectedValue.tzCode,
            }
        };
        this.setState({ isLoading: true });
        await CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `Trader/UpdateUserTimeZone`,
            Parms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes > 0) {
                    this.closeLanguageModal();
                    toastr.success(this.props.t('TimeZone'), this.props.t('Updated'));
                    //this.props.UpdateTimezone(this.ReturnTimeZone(String(this.state.TimeZoneSelectedValue.offset)));
                    this.props.UpdateTimezone(this.state.TimeZoneSelectedValue.offset);
                }
                else
                    this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    UpdateUserLanguage = async () => {
        let Parms: YoonitRequest<ProfileInfoAction | any> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: {
                ContactID: this.props.AdminData.ContactID,
                Language: this.state.languageSelectedValue.value
            }
        };
        this.setState({ isLoading: true });
        await CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `Trader/UpdateUserLanguage`,
            Parms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes > 0) {
                    this.closeLanguageModal();
                    toastr.success(this.props.t('Language'), this.props.t('Updated'));
                    this.props.ChangeLanguage(this.state.languageSelectedValue);
                    this.props.i18n.changeLanguage(this.state.languageSelectedValue.value);
                    document.documentElement.setAttribute('lang', this.state.languageSelectedValue.value);
                    if (this.state.languageSelectedValue.value === "ar") {
                        document.documentElement.setAttribute('dir', "rtl");
                    }
                    else {
                        document.documentElement.setAttribute('dir', "ltr");
                    }
                }
                else
                    this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    SaveAllConfiguration = async () => {
        await this.UpdateUserLanguage();
        await this.UpdateUserTimeZone();
        this.UpdateContact();// they must be send in update contact 
    }
    copyUsername = () => {
        var copyText: any = document.getElementById("copyUsername");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        toastr.success("", this.props.t('EmailCopied'))
    }
    copyPassword = () => {
        var copyText: any = document.getElementById("copyPassword");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        toastr.success("", this.props.t('PasswordCopied'))
    }
    onCheckBoxChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.checked } as any)
    }
    SendPasswordByEmail = () => {
        if (!validateEmail(this.state.EmailtoSendPassword)) {
            toastr.warning("", this.props.t('AddEmailPlease'))
        }
        else {
            let SendPasswordByEmailParams: YoonitRequest<SendPassword> = {
                CompanyName: this.props.CompanyData.CompanyName,
                Token: this.props.userData.Token,
                UserName: this.props.AdminData.Email,
                ClientName: this.state.GetContactInfoResult ? `${this.state.GetContactInfoResult.FirstName ? this.state.GetContactInfoResult.FirstName : ""}${this.state.GetContactInfoResult.LastName ? this.state.GetContactInfoResult.LastName : ""}` : "",
                ObjData: {
                    IsAdmin: true,
                    UserEmail: this.state.resetEmail,
                    Email: this.state.EmailtoSendPassword,
                    Password: btoa(this.state.newPassword)
                }
            };
            this.setState({ isLoading: true });
            CallAxiosRequest<any, number>(
                this.props.ActionURL,
                `Trader/SendPasswordByEmail`,
                SendPasswordByEmailParams,
                { Token: this.props.userData.Token },
                () => {
                    toastr.success("", this.props.t('EmailSent'));
                    this.closeAutoGenerate();
                    this.closeEditContact();
                    this.props.closeProfile();
                    this.setState({ isLoading: false });
                },
                () => {
                    this.closeEditContact();
                    this.closeAutoGenerate();
                    this.props.closeProfile();
                    this.setState({ isLoading: false });
                    toastr.error("", this.props.t('FailedAction'));
                },
                this.props.t

            );
        }
    };
    render() {
        //var PortalSettingsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Portal Settings', this.props.UserPermissions);
        //var ManageUsers: boolean = CheckIfPermAlllowedByName('Manage Users', PortalSettingsUserPerms.Perms);//no perm as own user
        return (
            <div>
                <ProfileModal
                    small={true}
                    title={this.props.t('MyProfile')}
                    theme={this.props.theme.currentTheme}
                    open={this.props.openProfile}
                    handleClose={this.props.closeProfile}
                    backBtn={true}
                    nobuttons={false}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <div className="d-flex">
                                <div className="d-flex">
                                    <div className="p-2">
                                        <span className="d-sm-inline-block hoverIcon">
                                            <span className=" hoverIcon grayColor  ">
                                                <Avatar className="avatar avatarUser ml-4 mb-2">{this.state.GetContactInfoResult ? `${this.state.GetContactInfoResult.FirstName ? this.state.GetContactInfoResult.FirstName.charAt(0) : ""}${this.state.GetContactInfoResult.LastName ? this.state.GetContactInfoResult.LastName.charAt(0) : ""}` : ""} </Avatar>
                                            </span>
                                        </span>

                                    </div>
                                    <div className="p-2">
                                        <div className=" mt-2" >
                                            <h4 className="CardTitleProfile d-block " > <span className=" t-bold font-24">{this.props.t('Welcome')}  {`${this.state.GetContactInfoResult.FirstName}`} </span>  </h4>
                                            <Label className="LabelCreate2 d-block"> {this.props.t('YourAccountIs')} {this.state.GetContactInfoResult.StatusType ? this.props.t(this.state.GetContactInfoResult.StatusType) : "-"}  </Label>
                                            <Button className="mr-2 ButtonsModal mt-2 mb-2 MngBtn" onClick={this.navigateToNotification}> <FontAwesomeIcon icon={faBell} className="mr-2 cursor-pointer" />{this.props.t('ManageNotifications')}  </Button>
                                        </div>
                                    </div>
                                    <div className="ml-auto">
                                        <UncontrolledDropdown>
                                            <span className="d-sm-inline-block hoverIcon">

                                                <DropdownToggle nav caret className="mt2 pl-0">
                                                    <FontAwesomeIcon icon={faEllipsisV} className="mr-2 AddTaskPlus cursor-pointer" height={20} width={20} />
                                                </DropdownToggle>

                                            </span>

                                            <DropdownMenu right className="ProfileDropdwon UserDropdown">
                                                <DropdownItem className="DropDownFix hoverIcon ProfileDrop cursor-pointer text-center" onClick={this.OpenPasswordModal}>
                                                    <Row md={12}>
                                                        <Label className="textWhite text-center"> {this.props.t('ManagePassword')} </Label>
                                                    </Row>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>

                                <div className="p-2">

                                    <div className=" mt-2" >
                                        <h4 className="CardTitleProfile d-block " > <span className=" t-bold font-24"> </span>  </h4>
                                        <Label className="LabelCreate2 d-block">  </Label>

                                    </div>
                                </div>
                            </div>
                            <Divider className="mt-3 mb-3" />

                            <Row>
                                <Col md={12}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ChooseTimeZone')}</Label>
                                    <Select
                                        className="react-select-container noborderApp"
                                        classNamePrefix="react-select cursor-pointer noborderApp  "
                                        options={this.state.TimeZonesOptions}
                                        isSearchable={true}
                                        value={this.state.TimeZoneSelectedValue}
                                        onChange={this.TimeZoneOnChange}
                                        placeholder={this.props.t('ChooseTimeZone')}
                                    />
                                </Col>
                                <Col md={12} className="mt-2 mb-3">
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ChooseALanguage')}</Label>
                                    <Select
                                        className="react-select-container noborderApp"
                                        classNamePrefix="react-select cursor-pointer noborderApp  "
                                        options={this.state.languageSelectOptions}
                                        isSearchable={true}
                                        value={this.state.languageSelectedValue}
                                        onChange={this.languageOnChange}
                                    />
                                </Col>
                            </Row>
                            <Divider className="mt-3 mb-3" />
                            <Row className="mt-2 mb-2">
                                <Col md={12}> <h5 className="t-bold text-secondary mt-4"> {this.props.t('ContactInformation')}</h5> </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('Email')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        //onChange={this.contactInputOnchange}
                                        readOnly
                                        value={this.state.GetContactInfoResult && this.state.GetContactInfoResult.Email}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('FirstName')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="FirstName"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.FirstName}
                                    />
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('LastName')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="LastName"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.LastName}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ContactDisplayName')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        onChange={this.contactInputOnchange}
                                        readOnly
                                        value={this.state.AddContactClass.FirstName + " " + this.state.AddContactClass.LastName}
                                    />
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('DateOfBirth')}</Label>
                                    <Input
                                        type="date"
                                        value={this.state.AddContactClass.DOB ? String(this.state.AddContactClass.DOB?.split('T')[0]) : ""}
                                        onChange={this.changeDate}
                                        className="editedInput font-16"
                                        bsSize="lg"
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('Country')}</Label>
                                    <Select
                                        className="react-select-container noborderApp"
                                        classNamePrefix="react-select cursor-pointer noborderApp  "
                                        isSearchable={true}
                                        isMulti={false}
                                        getOptionLabel={(option: any) => this.props.t(option?.CountryCode ? ReturnCountryCode(option?.CountryCode) : option.label)}
                                        options={this.state.countriesOptions}
                                        value={this.state.contactCountriesSelectedValue}
                                        onChange={this.contactCountriesOnChange}
                                    />

                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('Phone')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="Phone"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.Phone}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('MobilePhoneNumber')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="MobilePhoneNumber"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.MobilePhoneNumber}
                                    />
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('City')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="City"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.City}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ZipCode')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="ZipCode"
                                        onChange={this.contactInputOnchange}
                                        value={this.state.AddContactClass.ZipCode}
                                    />
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('Skype')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="Skype"
                                        value={this.state.AddContactClass.Skype}
                                        onChange={this.contactInputOnchange}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('Wechat')}</Label>
                                    <Input
                                        type="text"
                                        className="editedInput font-16"
                                        bsSize="lg"
                                        name="Wechat"
                                        value={this.state.AddContactClass.Wechat}
                                        onChange={this.contactInputOnchange}
                                    />
                                </Col>

                            </Row>

                            <div className="ButtonsBottom">
                                <div className="btnModal">
                                    <div className="floatRight">
                                        <Button className="mr-2 ButtonsModal mt-2" onClick={this.SaveAllConfiguration}> {this.props.t('Save')}  </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('ChangePassword')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openPassword}
                    handleClose={this.closePasswordModal}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateUserPassword}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>

                                    <Radio.Group onChange={this.OnChangRadioPassword} name="passwordValue" defaultValue={1}>
                                        <Radio value={1} className="d-block" > <span className="LabelCreate2 mt-2"> {this.props.t('AutoGeneratePassword')} </span></Radio>

                                        <Radio value={2} className="mt-3"><span className="LabelCreate2">{this.props.t('CreateYourOwnPassword')}</span></Radio>
                                    </Radio.Group>
                                    {this.state.passwordValue === 2 &&
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Item
                                                        label={this.props.t('NewPassword')}
                                                        hasFeedback={false}
                                                        validateStatus="error"
                                                        help={CheckPasswordStrength(this.state.newPassword) >= 4 ? "" : this.props.t('WeakPassword')}
                                                    >
                                                        <AntInput.Password
                                                            autoComplete="new-password"
                                                            type="password"
                                                            name="newPassword"
                                                            id="Password"
                                                            value={this.state.newPassword}
                                                            onChange={this.inputOnchange}
                                                        />
                                                    </Form.Item>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('Credentials')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openAutoGenerate}
                    handleClose={this.closeAutoGenerate}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.state.SendByEmail ? this.SendPasswordByEmail : this.closeAutoGenerate}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="username" className="LabelCreate2 d-contents font-14 d-block t-bold" >{this.props.t('Username')} </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="password" className="LabelCreate2 d-contents font-14 d-block " >{this.props.t('Password')} </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <div className="input-group ">
                                                <div className="input-group-prepend attachClip">
                                                    <span className="input-group-text cursor-pointer" onClick={this.copyUsername}> <FontAwesomeIcon className="AddTaskPlus " icon={faCopy} /> </span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.props.AdminData.Email}
                                                    name="copyUsername" id="copyUsername" className="copyUsername"
                                                    onClick={this.copyUsername} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <div className="input-group ">
                                                <div className="input-group-prepend attachClip">
                                                    <span className="input-group-text cursor-pointer" onClick={this.copyPassword}> <FontAwesomeIcon className="AddTaskPlus " icon={faCopy} /> </span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.state.newPassword}
                                                    name="copyPassword" id="copyPassword" className="copyPassword"
                                                    onClick={this.copyPassword} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Divider className="mt-3 mb-3" />
                                <Row>
                                    <Col md={12}>
                                        <Checkbox
                                            className="d-block mb-3 "
                                            value={this.state.SendByEmail}
                                            onChange={this.onCheckBoxChange}
                                            name="SendByEmail">
                                            <span className="LabelCreate2"> {this.props.t('SendPasswordByEmail')}</span>
                                        </Checkbox>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <BasicValidation
                                                title={this.props.t('EmailTheNewPasswordToTheFollowing')}
                                                validate={!this.state.SendByEmail || validateEmail(this.state.EmailtoSendPassword)}
                                            />
                                            <Input
                                                type="email"
                                                name="EmailtoSendPassword"
                                                bsSize="lg"
                                                value={this.state.EmailtoSendPassword}
                                                placeholder={this.props.t('Email')}
                                                onChange={this.inputOnchange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('EditContactInfo')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openEditContact}
                    handleClose={this.closeEditContact}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateContact} > {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="FirstName" className="LabelCreate2 d-contents font-14" >{this.props.t('FirstName')}  </Label>
                                        <Input
                                            type="text"
                                            id="ContactFirstName"
                                            bsSize="lg"
                                            name="FirstName"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.FirstName}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="LastName" className="LabelCreate2 d-contents font-14" >{this.props.t('LastName')}  </Label>
                                        <Input
                                            type="text"
                                            id="ContactLAstNAme"
                                            bsSize="lg"
                                            name="LastName"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.LastName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="DisplayName" className="LabelCreate2 d-contents font-14" > {this.props.t('ContactDisplayName')}   </Label>
                                        <Input
                                            type="text"
                                            bsSize="lg"
                                            readOnly
                                            value={this.state.AddContactClass.FirstName + " " + this.state.AddContactClass.LastName}
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ContactEmail" className="LabelCreate2 d-contents font-14" >{this.props.t('Email')}   </Label>
                                        <Input
                                            type="text"
                                            name="Email"
                                            id="Email"
                                            bsSize="lg"
                                            readOnly
                                            value={this.state.AddContactClass.Email}
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="dueDate" className="LabelCreate2 d-block" >{this.props.t('DateOfBirth')} </Label>
                                        <Input
                                            className="dateReact"
                                            type="date"
                                            value={this.state.AddContactClass.DOB ? String(this.state.AddContactClass.DOB?.split('T')[0]) : ""}
                                            onChange={this.changeDate}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ContactCountry" className="LabelCreate2 d-contents font-14" >{this.props.t('Country')} </Label>
                                        <Select
                                            className="react-select-container MedSelect"
                                            classNamePrefix="react-select cursor-pointer LargeSelect  "
                                            isSearchable={true}
                                            isMulti={false}
                                            getOptionLabel={(option: any) => this.props.t(option?.CountryCode ? ReturnCountryCode(option?.CountryCode) : option.label)}
                                            options={this.state.countriesOptions}
                                            value={this.state.contactCountriesSelectedValue}
                                            onChange={this.contactCountriesOnChange}
                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ContactPhone" className="LabelCreate2 d-contents font-14" > {this.props.t('Phone')}   </Label>
                                        <Input
                                            type="text"
                                            id="Phone"
                                            bsSize="lg"
                                            name="Phone"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.Phone}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ContactMobile" className="LabelCreate2 d-contents font-14" >{this.props.t('MobilePhoneNumber')}  </Label>
                                        <Input
                                            type="text"
                                            id="MobilePhoneNumber"
                                            bsSize="lg"
                                            name="MobilePhoneNumber"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.MobilePhoneNumber}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ContactCity" className="LabelCreate2 d-contents font-14" > {this.props.t('City')}  </Label>
                                        <Input
                                            type="text"
                                            id="City"
                                            bsSize="lg"
                                            name="City"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.City}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="ContactZipCode" className="LabelCreate2 d-contents font-14" > {this.props.t('ZipCode')}   </Label>
                                        <Input
                                            type="text"
                                            id="ZipCode"
                                            bsSize="lg"
                                            name="ZipCode"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.ZipCode}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ContactResidentialAddress" className="LabelCreate2 d-contents font-14" >{this.props.t('Address')} </Label>
                                        <Input
                                            type="text"
                                            id="ResidentialAddress"
                                            bsSize="lg"
                                            name="ResidentialAddress"
                                            onChange={this.contactInputOnchange}
                                            value={this.state.AddContactClass.ResidentialAddress}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ContactSkypeEdit" className="LabelCreate2 d-contents font-14" >{this.props.t('Skype')}</Label>
                                        <Input
                                            type="text"
                                            id="ContactSkypeEdit"
                                            bsSize="lg"
                                            name="Skype"
                                            value={this.state.AddContactClass.Skype}
                                            onChange={this.contactInputOnchange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="ContactWeChatEdit" className="LabelCreate2 d-contents font-14" >{this.props.t('Wechat')}</Label>
                                        <Input
                                            type="text"
                                            id="ContactWeChatEdit"
                                            bsSize="lg"
                                            name="Wechat"
                                            value={this.state.AddContactClass.Wechat}
                                            onChange={this.contactInputOnchange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('ChangePreferableLangauge')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openLangauge}
                    handleClose={this.closeLanguageModal}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateUserLanguage}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>
                                    <label className="LabelCreate2"> {this.props.t('ChooseALanguage')} <b className="iconNes">*</b></label>
                                    <Select
                                        className="react-select-container LargeSelect"
                                        classNamePrefix="react-select cursor-pointer LargeSelect "
                                        options={this.state.languageSelectOptions}
                                        isSearchable={true}
                                        value={this.state.languageSelectedValue}
                                        onChange={this.languageOnChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('ChangeTimeZone')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openTimeZone}
                    handleClose={this.closeTimeZone}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateUserTimeZone}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>
                                    <label className="LabelCreate2"> {this.props.t('ChooseTimeZone')} <b className="iconNes">*</b></label>
                                    <Select
                                        className="react-select-container LargeSelect"
                                        classNamePrefix="react-select cursor-pointer LargeSelect "
                                        options={this.state.TimeZonesOptions}
                                        isSearchable={true}
                                        value={this.state.TimeZoneSelectedValue}
                                        onChange={this.TimeZoneOnChange}
                                        placeholder={this.props.t('ChooseTimeZone')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default withTranslation()(connect(mapStateToProps, dispatchProps)(MyProfileAdmin) as any) as any;