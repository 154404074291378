import { Icon } from 'antd';
import { push } from 'connected-react-router';
import * as React from 'react';
import { Suspense } from 'react';
import { connect } from "react-redux";
import { RouteProps } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import Lazy from "../components/Lazy";
import ExternalLayout from '../components/ReusableComponents/Layouts/ExternalLayout';
import InternalLayout from '../components/ReusableComponents/Layouts/InternalLayout';
import { CheckIfCompanyIs, DisplayZendDeskChat, GetPermissionDetailsByName } from '../Helpers/DataHelpers';
import { UserPermissionOBjectClass } from '../Interfaces/Interfaces';
import Page404 from '../Pages/Auth/Page404';
import { Logout } from '../redux/actions/AuthActions';
import { RemoveAllMessages } from '../redux/actions/NotificationActions';
import { ClearPushNotifications } from '../redux/actions/PushNotificationActions';
import { GetAdminData, GetClientData, ICompanyData, IPerms, IStore } from '../redux/constants';
import store, { history } from '../redux/store';
import { ExternalRoutes, InternalRoutes } from "./index";
import PrivateRoute from './PrivateRoute';


const mapStateToProps = (state: IStore) => ({
    userData: state.auth,
    CompanyData: state.auth.CompanyData,
    AdminData: state.auth.AdminData,
    ClientData: state.auth.ClientData,
    UserPermissions: state.auth.UserPermissions,
    isAuth: state.auth.isAuth,
    isTokenExpired: state.auth.isTokenExpired,
    isTokenValid: state.auth.isTokenValid,
    location: state.router.location,
});
export interface AppProps {
    userData?: any;
    CompanyData: ICompanyData;
    AdminData: GetAdminData;
    ClientData: GetClientData;
    UserPermissions: IPerms[];
    history: any;
    location: any;
    routes?: any;
    isAuth: boolean;
    isTokenExpired: boolean;
    isTokenValid: boolean;
}
export interface AppState {
    PreviousPath: string | null;
}
interface Props2 extends RouteProps {
    component: new (props: any) => React.Component | any;
    userData: any;
    layoutComp: any;
    path: any;
    closedMenu: any;
    subtab: any;
}
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

const MapPrivateRoutes = (routes: any, userData: any, PreviousPath: string | null) =>
    routes.map(({ path, component: Component, closedMenu, subtab }: Props2, index: any) =>
        <PrivateRoute
            key={`${path}${index}`}
            path={path}
            PreviousPath={PreviousPath}
            exact
            isAuth={userData.isAuth}
            component={
                (props: any) => (
                    <InternalLayout PreviousPath={PreviousPath} path={path} closedMenu={closedMenu} subtab={subtab}>
                        <Suspense fallback={<Lazy />}>
                            <Component {...props} />
                        </Suspense>
                    </InternalLayout >
                )
            }
        />
    );

const MapExternalRoutes = (routes: any) =>
    routes.map(({ path, component: Component2, layoutComp: any }: Props2, index: any) =>
        <Route
            key={`${path}${index}`}
            path={path}
            exact
            render={props => (
                <ExternalLayout removeScroll={CheckIfCompanyIs("Icon") ? (path === "/SignUp" || path === "/RealAccountIframe") : false}>
                    <Component2 {...props} />
                </ExternalLayout>
            )}
        />
    );

class Routes extends React.Component<AppProps, AppState> {
    state = {
        PreviousPath: null,
    }
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.location !== this.props.location) {
            this.setState({ PreviousPath: this.props.location && this.props.location ? this.props.location.pathname : null })
        }
    }
    componentDidMount() {
        this.LogoutIFPrimexClient();
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.location !== this.props.location) {
            this.LogoutIFPrimexClient();
        }
    }
    CheckIfIsExternalPage(href: any): boolean {
        if (!href) return false;
        for (let route of ExternalRoutes) {
            if (href && href.toLowerCase().includes(route.path.toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    LogoutIFPrimexClient = () => {
        const LogoutAndClearCache = () => {
            store.dispatch(ClearPushNotifications({
                PushNotificationStatus: [],
                signalRIsConnected: false
            }));
            store.dispatch(RemoveAllMessages());
            store.dispatch(Logout());
            //store.dispatch(push('/'));
        }
        if (!!store?.getState()?.auth?.isAuth && !store?.getState()?.auth?.UserData?.isAdmin && CheckIfCompanyIs("PRIMEX")) {
            LogoutAndClearCache();
        }
    }
    render() {
        let ClientsUserPerms: UserPermissionOBjectClass = GetPermissionDetailsByName('Clients', this.props.UserPermissions)
        if (this.CheckIfIsExternalPage(window?.location?.href) || !this.props.userData?.UserData?.isAdmin) {
            DisplayZendDeskChat(false);
        } else {
            DisplayZendDeskChat(true);
        }
        return (
            <React.Suspense fallback={<Lazy />}>
                <Router history={history}>
                    <React.Fragment>
                        <Switch>
                            {MapExternalRoutes(ExternalRoutes)}
                            {MapPrivateRoutes(
                                InternalRoutes(this.props.userData.UserData.isAdmin, this.props.CompanyData, this.props.UserPermissions, this.props.ClientData, this.props.isAuth), this.props.userData,
                                this.state.PreviousPath
                            )}
                            {this.props.isAuth &&
                                <Route
                                    render={() => (
                                        <InternalLayout PreviousPath={this.state.PreviousPath} path={""} closedMenu={""} subtab={""}>
                                            <Suspense fallback={<Lazy />}>
                                                <Page404
                                                    isBonus={this.props.userData.UserData.isAdmin && this.props.CompanyData.IsBonus && ClientsUserPerms.Allowed}
                                                    isAdmin={this.props.userData.UserData.isAdmin}
                                                    isAuth={this.props.isAuth /*&& this.props.isTokenValid && !this.props.isTokenExpired*/}
                                                />
                                            </Suspense>
                                        </InternalLayout >
                                    )} />}
                            {!this.props.isAuth &&
                                <Route
                                    render={() => (
                                        <Page404
                                            isBonus={this.props.userData.UserData.isAdmin && this.props.CompanyData.IsBonus && ClientsUserPerms.Allowed}
                                            isAdmin={this.props.userData.UserData.isAdmin}
                                            isAuth={this.props.isAuth && this.props.isTokenValid && !this.props.isTokenExpired}
                                        />)} />}
                        </Switch>
                    </React.Fragment>
                </Router>
            </React.Suspense >
        );
    }
}
export default connect(mapStateToProps, { push })(Routes);
