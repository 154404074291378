import { HubConnectionBuilder } from '@microsoft/signalr';
import { notification } from 'antd';
import i18next from 'i18next';
import { CheckIfAUIDIsEnabled, ReturnActionMessage } from '../Helpers/DataHelpers';
import { IMessage } from '../Interfaces/Interfaces';
import { AddNewMessage } from '../redux/actions/NotificationActions';
import { SetSignalR } from '../redux/actions/PushNotificationActions';
import { HandleServerConnectivity } from '../redux/actions/ServersConnectivityAction';
import * as types from "../redux/constants";
import store from '../redux/store';

// action for user authentication and receiving the access_token
let connection: any = null;
declare var require: any;
const moment: any = require('moment');
function t(key: string) {
    return i18next.t(key)
}
function CheckIfValidCredinations(getState: any) {
    if (!getState()
        || !getState().auth
        || !getState().auth.isAuth
        || !getState().auth.UserData)
        return {
            error: true,
            isAdmin: false
        }
    return {
        error: getState().auth.UserData.isAdmin ? (getState().auth.AdminData === undefined || getState().auth.AdminData === null) : (getState().auth.ClientData === undefined || getState().auth.ClientData === null),
        isAdmin: getState().auth.UserData.isAdmin
    }
}
const signalRMiddleware = ({ getState }: any) => (next: any) => async (action: any) => {
    if (action.type === types.AUTH_LOGIN) {
        next(action)
        let _CheckIfValidCredinations = CheckIfValidCredinations(getState);
        ConnectToSignalR(_CheckIfValidCredinations, getState);
    }
    if (action.type === types.AUTH_LOGOUT) {
        if (connection) {
            connection.stop();
        }
        connection = null;
    }
    if (action.type === types.CHECK_SIGNALR) {
        let _CheckIfValidCredinations = CheckIfValidCredinations(getState);
        if (!_CheckIfValidCredinations.error) {
            if (connection && connection.connectionState === "Disconnected")
                startConnection();
            else if (!connection) {
                ConnectToSignalR(_CheckIfValidCredinations, getState);
            }
        }
        else {
            if (connection)
                connection.stop();
            connection = null;
        }
    }
    return next(action);
};

export default signalRMiddleware;

function ConnectToSignalR(_CheckIfValidCredinations: { error: boolean; isAdmin: any; }, getState: any) {
    if (!_CheckIfValidCredinations.error && _CheckIfValidCredinations.isAdmin) {
        let _email = !_CheckIfValidCredinations.error ? getState().auth.AdminData.Email : "";
        CreateConnection(getState().auth.AdminData, getState().auth.CompanyData.CompanyName, getState().auth.UserData.id, _email, _CheckIfValidCredinations);
        startConnection();
    }
    else if (!_CheckIfValidCredinations.error && !_CheckIfValidCredinations.isAdmin) {
        let _email = !_CheckIfValidCredinations.error ? getState().auth.ClientData.Email : "";
        CreateConnection(getState().auth.ClientData, getState().auth.CompanyData.CompanyName, getState().auth.UserData.id, _email, _CheckIfValidCredinations);
        startConnection();
    }
}

function CreateConnection(adminData: types.GetAdminData, companyName: string, userId: string, email: string, checkIfValidCredinations: any) {
    let _connectionHub = store.getState() && store.getState().CompanyData && store.getState().CompanyData.SignalRHubURL ? store.getState().CompanyData.SignalRHubURL : "";

    connection = new HubConnectionBuilder()
        .withUrl(`${_connectionHub}session?groupName=${companyName}&userId=${userId}&email=${email}&isAdmin=${checkIfValidCredinations.isAdmin}`)
        // .withAutomaticReconnect()
        .build();
    connection.on('onNewMessage', (data: types.ActionMessage) => {
        console.log('onNewMessage', data);
        if (checkIfValidCredinations.isAdmin) {
            if (data && (data.actionType === "Clients assigned to you" || data.actionType === "Contact assigned to you" || data.actionType === "Lead assiged to you")) {
                let _isAUIDEnabled = CheckIfAUIDIsEnabled(adminData ? adminData.AUID : "", data.auiDs);
                if (_isAUIDEnabled === true) {
                    let _message: IMessage = ReturnActionMessage(data, (value: any) => t(value));//TODO check why code down is same 
                    notification.open({
                        message: _message.title,
                        description: _message.content
                    });
                    data.messageDate = moment().toString();
                    data.messageTitle = _message.title;
                    data.messageContent = _message.content;
                    store.dispatch(AddNewMessage(data));// change to dispatch
                }
            }
            else {
                let _message: IMessage = ReturnActionMessage(data, (value: any) => t(value));

                notification.open({
                    message: _message.title,
                    description: _message.content
                });
                data.messageDate = moment().toString();
                data.messageTitle = _message.title;
                data.messageContent = _message.content;
                store.dispatch(AddNewMessage(data))// change to dispatch put after works 
            }
        }
    });
    connection.on('onSeverChange', (data: types.ServersStatus) => {
        console.log('onSeverChange', { data });
        if (!data.isWebAPI)
            store.dispatch(HandleServerConnectivity({ ServerID: data.serverID, IsConnected: data.isConnected, IsWebAPI: data.isWebAPI, ID: `${data.serverID}` }));
        else
            store.dispatch(HandleServerConnectivity({ ServerID: data.serverID, IsConnected: data.isConnected, IsWebAPI: data.isWebAPI, ID: `${data.serverID}WebAPI` }));
    });
}

function startConnection() {
    connection.start()
        .then(() => {
            store.dispatch(SetSignalR(true));
            //store.dispatch(AddSignalr(connection));
            //console.log('call addToGroup');
        }).catch((e: any) => console.log("startConnection", e));
}
