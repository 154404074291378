import { faCopy, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Divider, Form, Input as AntInput, Skeleton } from 'antd';
import { default as ButtonANTD } from "antd/es/button";
import Checkbox from 'antd/es/checkbox';
import Radio from 'antd/es/radio';
import { push } from "connected-react-router";
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { default as Select } from 'react-select';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row, UncontrolledDropdown } from "reactstrap";
import { BasicValidation } from '../../components/ReusableComponents/ReusableElements/BasicElements';
import { ProfileModal } from '../../components/ReusableComponents/ReusableElements/Modals';
import { CallAxiosRequest } from '../../Helpers/axiosHelpers';
import { FillServerOptionsHelper } from '../../Helpers/DataFillingHelpers';
import { CheckPasswordStrength, password_generator, TimeZonesOptions, validateEmail } from '../../Helpers/DataHelpers';
import { BasicSelectOption } from '../../Interfaces/IApplicationForm';
import { BURequest, DashboardClass, GetGUDataResult, GUTraders, GUTradersOptions, LoadPlatformLinksResult, ProfileInfoAction, SendPassword, Servers, ServersOptions, translationType, UploadKYCDocumentRequest, YoonitRequest } from '../../Interfaces/Interfaces';
import { Login, Logout, UpdateTimezone } from '../../redux/actions/AuthActions';
import { ChangeLanguage } from '../../redux/actions/languageAction';
import { GetClientData, ICompanyData, ILanguageData, IStore, IUserData } from '../../redux/constants';

const mapStateToProps = (state: IStore) => ({
    LanguageData: state.LanguageData,
    ClientData: state.auth.ClientData,
    userData: state.auth.UserData,
    lang: state.language.value,
    history: state.history,
    URL: state.auth.UserData.yoonitGetURL,
    Servers: state.auth.Servers,
    theme: state.theme,
    ActionURL: state.auth.UserData.yoonitActionURL,
    CompanyData: state.auth.CompanyData,
});
const $ = require("jquery");

const dispatchProps = { push, Login, Logout, ChangeLanguage, UpdateTimezone }
//const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;
interface MyProfileProps {
    LanguageData: ILanguageData;
    ClientData: GetClientData;
    lang: translationType;
    userData: IUserData;
    push: any;
    ChangeLanguage: any;
    URL: string;
    theme: any;
    ActionURL: string;
    CompanyData: ICompanyData;
    Servers: any;
    openProfile: any;
    closeProfile: any;
    i18n: any;
    t: any;
    UpdateTimezone: any;
}

interface MyProfileState {
    LoadPlatformLinksLoading: boolean;
    GetBUDataLoading: boolean;
    platformList: any[];
    EmailtoSendPassword: string;
    resetEmail: string;
    SendByEmail: boolean;
    passwordValue: any;
    autoGeneratePassword: string;
    GetGUTradersResult: GUTraders[];
    GetDashboardDataResponse: DashboardClass;
    serverSelectOptions: ServersOptions[];
    loginSelectOptions: GUTradersOptions[];
    serverSelectedValue: ServersOptions;
    loginSelectedValue: GUTradersOptions;
    capitalAloc: any;
    YTDROIChart: any;
    isLoading: boolean;
    ProfilesCopyMe: number;
    GetGUDataResult: any;
    LoadPlatformLinksResponse: any;
    currentPassword: any;
    newPassword: any;
    ConfirmPassword: any;
    currentEmail: any;
    currentPasswordEmail: any;
    newEmail: any;
    languageSelectOptions: any;
    languageSelectedValue: any;
    LinkPlatform: any;
    PLink: any;
    PDesc: any;
    profileImg: any;
    OpenMail: boolean;
    openPassword: boolean;
    openLangauge: boolean;
    openLinks: boolean;
    openTimeZone: boolean;
    TimeZone: number;
    TimeZonesOptions: any[];
    TimeZoneSelectedValue: any;
    openAutoGenerate: boolean;
}
var companyName: any; var Token: any;

class MyProfile extends React.Component<MyProfileProps, MyProfileState> {
    state = {
        LoadPlatformLinksLoading: false,
        GetBUDataLoading: false,
        platformList: [] as any,
        EmailtoSendPassword: '',
        resetEmail: '',
        SendByEmail: false,
        passwordValue: 1,
        autoGeneratePassword: "",
        GetGUTradersResult: [],
        GetDashboardDataResponse: new DashboardClass(),
        serverSelectOptions: [],
        loginSelectOptions: [],
        serverSelectedValue: new ServersOptions(),
        loginSelectedValue: new GUTradersOptions(),
        capitalAloc: [],
        YTDROIChart: [],
        isLoading: false,
        ProfilesCopyMe: -1,
        GetGUDataResult: new GetGUDataResult(),
        LoadPlatformLinksResponse: [],
        currentPassword: '',
        newPassword: '',
        ConfirmPassword: '',
        currentEmail: '',
        currentPasswordEmail: '',
        newEmail: '',
        languageSelectOptions: [],
        languageSelectedValue: { value: "en" },
        LinkPlatform: '',
        PLink: [],
        PDesc: [],
        profileImg: "",
        OpenMail: false, openPassword: false, openLangauge: false, openLinks: false, openTimeZone: false, TimeZone: 0,
        TimeZonesOptions: [], TimeZoneSelectedValue: new BasicSelectOption() as any,
        openAutoGenerate: false,
    }
    componentDidMount() {
        this.LoadTimeZones();
        companyName = this.props.CompanyData.CompanyName;
        //GUID = ClientData.GlobalUser.guid;
        Token = this.props.userData.Token;
        //Token = ClientData.UserData.token;
        this.FillServerOptions(this.props.Servers, true);
        this.languageOptionsFunction(this.props.LanguageData.Options);

    }
    LoadPlatformLinks = () => {
        let Parms: YoonitRequest<any> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
        };
        this.setState({ LoadPlatformLinksLoading: true });
        CallAxiosRequest<any, any>(
            this.props.URL,
            `crm/LoadPlatformLinks`,
            Parms,
            { Token: this.props.userData.Token },
            (res: any) => {
                if (res.data.ListRes) {
                    this.setState({ platformList: res.data.ListRes })
                }
                this.setState({ LoadPlatformLinksLoading: false });
            },
            () => {
                this.setState({ LoadPlatformLinksLoading: false });
            },
            this.props.t
        );
    }
    openAutoGenerate = () => {
        this.setState({
            openAutoGenerate: true,
            EmailtoSendPassword: this.props.userData.UserName,
        })
    }
    closeAutoGenerate = () => {
        this.setState({ openAutoGenerate: false })
    }
    GetBUData = () => {
        var GetGUDataParams = {
            CompanyName: companyName,
            Token: Token,
            ObjData: {
                BUID: this.props.ClientData.BUID,
                ContactID: this.props.ClientData.ContactID
            }
        };
        this.setState({ GetBUDataLoading: true });
        CallAxiosRequest<any, BURequest>(
            this.props.URL,
            `Trader/GetBUData`,
            GetGUDataParams,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.LiveRes)
                    this.setState({ GetGUDataResult: res.data.LiveRes }, () => {
                        this.selectOptionByValue(this.state.GetGUDataResult && this.state.GetGUDataResult.TimeZoneCode ? this.state.GetGUDataResult.TimeZoneCode : "", this.state.TimeZonesOptions, "TimeZoneSelectedValue");
                    });
                if (res?.data?.LiveRes?.Language) {
                    this.selectOptionByValue(res?.data?.LiveRes?.Language, this.state.languageSelectOptions, "languageSelectedValue");
                } else {
                    this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
                }
                //this.LoadPlatformLinks();//TOD check use

                //var wholeImg = res.data.LiveRes.ProfileImage;
                //this.setState({ profileImg: wholeImg });// to set Image
                ////if (wholeImg === "" || wholeImg === null || wholeImg === undefined) //@point  img removed
                ////    this.setState({ profileImg: imgPro });
                this.setState({ GetBUDataLoading: false });
            },
            () => {
                this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
                this.setState({ GetBUDataLoading: false });
            },
            this.props.t

        );
    }
    LoadTimeZones = () => {
        //var timezoneOptions = timezones.default;
        var timezoneOptions = TimeZonesOptions(this.props.t);
        var options = [];
        for (let k = 0; k < timezoneOptions.length; k++) {
            options.push({ value: timezoneOptions[k].tzCode, label: timezoneOptions[k].text, tzCode: timezoneOptions[k].tzCode, offset: timezoneOptions[k].offset });
        }
        this.setState({ TimeZonesOptions: options, TimeZoneSelectedValue: options[0] });
        //sent the valueSent for api
    }
    TimeZoneOnChange = (TimeZoneSelectedValue: any) => {
        this.setState({ TimeZoneSelectedValue })
    }
    FillServerOptions = (ServersData: Servers[], FilterFlag: boolean) => {
        let _options = FillServerOptionsHelper(ServersData, FilterFlag);
        this.setState({ serverSelectOptions: _options, serverSelectedValue: _options[0] });
    };
    languageOptionsFunction = (languageOptions: any) => {
        var langOpt = [];
        for (var k = 0; k < languageOptions.length; k++) {
            langOpt.push({
                value: languageOptions[k].value,
                label: languageOptions[k].label
            });
        }
        this.setState({ languageSelectOptions: langOpt, languageSelectedValue: langOpt[0] });
    }
    UpdateUserEmail = (e: any) => {
        e.preventDefault();

        var UpdateUserEmailParams: YoonitRequest<any> = {//ProfileInfo
            CompanyName: companyName,
            Token: Token,
            ObjData: {
                CurrPassword: btoa(this.state.currentPasswordEmail),
                Email: this.state.newEmail,
                BUID: this.props.ClientData.BUID,
            }
        };

        if (this.state.newEmail !== this.state.currentEmail) {
            CallAxiosRequest<any, any>(
                this.props.ActionURL,
                `MAMM/UpdateUserEmail`,
                UpdateUserEmailParams,
                { Token: this.props.userData.Token },
                (res: any) => {

                    if (res.data.IsAuth) {
                        var intRes = res.data.IntRes;
                        if (intRes === -1) {
                            toastr.error('', this.props.t('FailedAction'));
                        }
                        if (intRes === -5) {
                            toastr.error('', this.props.t('CurrentPasswordDoesntMatch'));
                        }
                        if (intRes === 1) {
                            toastr.success('', this.props.t('Updated'));
                        }
                    } else {
                        toastr.error('', this.props.t('FailedAction'));
                    }
                },

                () => {
                },
                this.props.t
            );
        }
        else {
            toastr.error('', this.props.t('EmailConfirmationFailed'));
        }
    }
    UpdateMailLang = (e: any) => {
        e.preventDefault();
        var UpdateMailLangParams = {
            CompanyName: companyName,
            Token: Token,
            ObjData: {
                Language: this.state.languageSelectedValue.value,
                BUID: this.props.ClientData.BUID,
            }
        };

        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `MAMM/UpdateMailLang`,
            UpdateMailLangParams,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IsAuth) { toastr.success('', this.props.t('Updated')); } else {
                }
            },
            () => { },
            this.props.t

        );
    }
    resetUpdatePassword = () => {
        this.setState({
            passwordValue: 1,
            newPassword: "",
            SendByEmail: false,
        })
    }
    UpdateClientPassword = () => {
        //add a modal for it
        let _Password = this.state.newPassword;
        let _SendByEmail = false;
        if (this.state.passwordValue === 1) {
            _SendByEmail = this.state.SendByEmail;
            _Password = password_generator(7);
        }
        let UpdateClientPasswordParams = {
            CompanyName: companyName,
            Token: Token,
            UserName: this.props.ClientData.Email,
            ClientName: this.state.GetGUDataResult ? `${this.state.GetGUDataResult.FirstName ? this.state.GetGUDataResult.FirstName : ""}${this.state.GetGUDataResult.LastName ? this.state.GetGUDataResult.LastName : ""}` : "",
            ObjData: {
                ContactID: this.props.ClientData.ContactID,
                Password: btoa(_Password),
                SendByEmail: _SendByEmail,
                Email: this.props.ClientData.Email,
                SendToEmail: this.state.resetEmail,
                IsAdmin: false
            }
        };
        CallAxiosRequest<any, number>(
            this.props.ActionURL,
            `Trader/UpdateClientPassword`,
            UpdateClientPasswordParams,
            { Token: this.props.userData.Token },
            (res: any) => {
                ;
                if (res.data.IntRes > 0) {
                    toastr.success(" ", this.props.t('PasswordChanged'));
                    this.closePasswordModal();
                    if (String(this.state.passwordValue) === "1") {
                        this.setState({
                            newPassword: UpdateClientPasswordParams.ObjData?.Password ? atob(UpdateClientPasswordParams.ObjData?.Password) : ""
                        });
                        this.openAutoGenerate();
                    }
                } else if (res.data.IntRes === -2) {
                    toastr.warning("", this.props.t('ContactDoesnotExist'));
                } else if (res.data.IntRes === -10) {
                    toastr.warning("", this.props.t('PasswordUpdateFailed'));
                } else {
                    toastr.warning(" ", this.props.t('FailedAction'));
                }
            },
            () => {
            },
            this.props.t

        );

    }
    onDetailsChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        } as any);
    };
    serverSelectOnChange = (serverSelectedValue: any) => {
        this.setState({ serverSelectedValue });
    };
    handleImg = (binary: any, name: any, type: any, addresImg: any, DocumentType: any) => {
        if (binary !== undefined) {
        }
        if (name !== undefined) {
        }
        if (type !== undefined) {
        }
        let UpdateUserProfileImageParms: YoonitRequest<UploadKYCDocumentRequest> = new YoonitRequest<UploadKYCDocumentRequest>();
        UpdateUserProfileImageParms.ObjData = new UploadKYCDocumentRequest();
        //pp
        //UpdateUserProfileImageParms.objData.DocumentType ="pp" no type here
        UpdateUserProfileImageParms.Token = Token;
        UpdateUserProfileImageParms.CompanyName = companyName;
        //UpdateUserProfileImageParms.objData.GUID = GUID;
        //UpdateUserProfileImageParms.ObjData.ClientID =  "3fa85f64-5717-4562-b3fc-2c963f66afa6";//removed
        UpdateUserProfileImageParms.ObjData.ContactID = this.props.ClientData.ContactID;
        //UpdateUserProfileImageParms.ObjData.ContactID = this.props.ClientData.ContactID;
        UpdateUserProfileImageParms.ObjData.Type = "pp";
        UpdateUserProfileImageParms.ObjData.BinaryDocument = addresImg;
        //DocumentName
        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `MAMM/UpdateUserProfileImage`,
            UpdateUserProfileImageParms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes === -1)
                    toastr.error('', this.props.t('FailedAction'));
                else if (res.data.IntRes === -5)
                    toastr.error('', this.props.t('WrongFormatWasUploaded'));
                else if (res.data.IntRes < 0)
                    toastr.error('', this.props.t('FailedAction'));
                else {
                    this.setState({ profileImg: res.data.LiveRes.DocPath });// res.data.
                    toastr.success('', this.props.t('DocumentUploadedSuccessfully'));
                }
            },
            () => {
                toastr.error('', this.props.t('ImageNotUpdated'));
            },
            this.props.t

        );
    }
    languageOnChange = (languageSelectedValue: any) => {
        this.setState({ languageSelectedValue });
    };
    onLanguageChange = (selectedLanguage: any) => {
        this.setState({ languageSelectedValue: selectedLanguage });
    }
    OpenLanguageModal = () => {
        this.selectOptionByValue(this.props.lang, this.state.languageSelectOptions, "languageSelectedValue");
        this.setState({ openLangauge: true })
    }

    OpenPasswordModal = () => {
        this.resetUpdatePassword();
        this.setState({ openPassword: true })
    }

    OpenMailModal = () => {
        this.setState({ OpenMail: true })
    }
    selectOptionByValue = (value: any, list: any[], selectedValue: string) => {
        if (value) {
            for (let i: number = 0; i < list.length; i++) {
                if (String(value) === String(list[i].value)) {
                    this.setState({ [selectedValue]: { label: list[i].label, value: list[i].value, Data: list[i].Data } } as any);
                }
            }
        }
    }
    openTimeZone = () => {
        this.selectOptionByValue(this.state.GetGUDataResult && this.state.GetGUDataResult.TimeZoneCode ? this.state.GetGUDataResult.TimeZoneCode : "", this.state.TimeZonesOptions, "TimeZoneSelectedValue");
        this.setState({ openTimeZone: true })
    }
    closeLanguageModal = () => {
        this.setState({ openLangauge: false })
    }

    closePasswordModal = () => {
        this.setState({ openPassword: false })
    }

    closeMailModal = () => {
        this.setState({ OpenMail: false })
    }
    closeTimeZone = () => {
        this.setState({ openTimeZone: false })
    }
    triggerUserImg = () => {
        $('#userImg').trigger("click");
    }
    componentDidUpdate(prevProps: MyProfileProps) {
        if (this.props.openProfile && (prevProps.openProfile !== this.props.openProfile)) {
            this.GetBUData();
            this.LoadTimeZones();//as lang may changed
            let isA: boolean = this.props.userData.isAdmin; //not on didmount all time
            let isClientCRM: boolean = !isA && this.props.CompanyData.IsCRM;
            if (isClientCRM)
                this.LoadPlatformLinks();

        }
    }
    OnChangRadioPassword = (e: any) => {
        this.setState({
            passwordType: e.target.value,
            [e.target.name]: e.target.value
        } as any, this.generatePassword);
    }
    generatePassword = () => {
        this.setState({ autoGeneratePassword: password_generator(7) })
    }
    inputOnchange = (e: any) => {//for all
        this.setState({ [e.target.name]: e.target.value } as any)
    }
    UpdateUserLanguage = () => {
        let Parms: YoonitRequest<ProfileInfoAction | any> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: {
                ContactID: this.props.ClientData.ContactID,
                Language: this.state.languageSelectedValue.value
            }
        };
        this.setState({ isLoading: true });
        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `Trader/UpdateUserLanguage`,
            Parms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes > 0) {
                    this.closeLanguageModal();
                    toastr.success(this.props.t('Language'), this.props.t('Updated'));
                    this.props.ChangeLanguage(this.state.languageSelectedValue);
                    this.props.i18n.changeLanguage(this.state.languageSelectedValue.value);
                    document.documentElement.setAttribute('lang', this.state.languageSelectedValue.value);
                    if (this.state.languageSelectedValue.value === "ar") {
                        document.documentElement.setAttribute('dir', "rtl");
                    }
                    else {
                        document.documentElement.setAttribute('dir', "ltr");
                    }
                }
                else
                    toastr.error('', this.props.t('FailedAction'));
                this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    ReturnTimeZone = (TimeZoneoffset: string) => {
        let _result: number = 0;
        let sign = TimeZoneoffset.charAt(0);
        let offset = TimeZoneoffset.substr(1);
        let hrs = parseInt(offset.split(":")[0], 10);
        let mins = parseInt(offset.split(":")[1], 10);
        if (sign === "+")
            _result = (hrs * 60) + mins;
        else
            _result = (hrs * -60) - mins;
        return _result;
    }
    UpdateUserTimeZone = () => {
        let Parms: YoonitRequest<ProfileInfoAction | any> = {
            CompanyName: this.props.CompanyData.CompanyName,
            Token: this.props.userData.Token,
            ObjData: {
                ContactID: this.props.ClientData.ContactID,
                TimeZone: this.state.TimeZoneSelectedValue.offset,
                TimeZoneCode: this.state.TimeZoneSelectedValue.tzCode,
            }
        };
        this.setState({ isLoading: true });
        CallAxiosRequest<any, any>(
            this.props.ActionURL,
            `Trader/UpdateUserTimeZone`,
            Parms,
            { Token: this.props.userData.Token },
            (res: any) => {

                if (res.data.IntRes > 0) {
                    this.closeLanguageModal();
                    toastr.success(this.props.t('TimeZone'), this.props.t('Updated'));
                    //this.props.UpdateTimezone(this.ReturnTimeZone(String(this.state.TimeZoneSelectedValue.offset)));
                    this.props.UpdateTimezone(this.state.TimeZoneSelectedValue.offset);
                }
                else
                    toastr.error('', this.props.t('FailedAction'));
                this.setState({ isLoading: false });
            },
            () => {
                this.setState({ isLoading: false });
            },
            this.props.t

        );
    }
    UpdateLanguageAndTimeZone = () => {
        this.UpdateUserLanguage();
        this.UpdateUserTimeZone();
    }
    copyUsername = () => {
        var copyText: any = document.getElementById("copyUsername");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        toastr.success("", this.props.t('EmailCopied'))
    }
    copyPassword = () => {
        var copyText: any = document.getElementById("copyPassword");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        toastr.success("", this.props.t('PasswordCopied'))
    }
    onCheckBoxChange = (e: any) => {
        this.setState({ [e.target.name]: e.target.checked } as any)
    }
    SendPasswordByEmail = () => {
        if (!validateEmail(this.state.EmailtoSendPassword)) {
            toastr.warning("", this.props.t('AddEmailPlease'))
        }
        else {
            let SendPasswordByEmailParams: YoonitRequest<SendPassword> = {
                CompanyName: this.props.CompanyData.CompanyName,
                Token: this.props.userData.Token,
                UserName: this.props.ClientData.Email,
                ClientName: this.state.GetGUDataResult ? `${this.state.GetGUDataResult.FirstName ? this.state.GetGUDataResult.FirstName : ""}${this.state.GetGUDataResult.LastName ? this.state.GetGUDataResult.LastName : ""}` : "",
                ObjData: {
                    IsAdmin: false,
                    Email: this.state.EmailtoSendPassword,
                    UserEmail: this.state.resetEmail,
                    Password: btoa(this.state.newPassword)
                }
            };
            this.setState({ isLoading: true });
            CallAxiosRequest<any, number>(
                this.props.ActionURL,
                `Trader/SendPasswordByEmail`,
                SendPasswordByEmailParams,
                { Token: this.props.userData.Token },
                (res: any) => {
                    toastr.success("", this.props.t('EmailSent'));
                    this.props.closeProfile();
                    this.closeAutoGenerate();
                    this.setState({ isLoading: false });
                },
                () => {
                    this.closeAutoGenerate();
                    this.props.closeProfile();
                    this.setState({ isLoading: false });
                    toastr.error("", this.props.t('FailedAction'));
                },
                this.props.t
            );
        }
    };
    render() {
        var isA: boolean = this.props.userData.isAdmin;
        var isClientCRM: boolean = !isA && this.props.CompanyData.IsCRM;
        return (
            <div>
                <ProfileModal
                    small={true}
                    title={this.props.t('MyProfile')}
                    theme={this.props.theme.currentTheme}
                    open={this.props.openProfile}
                    handleClose={this.props.closeProfile}
                    backBtn={true}
                    nobuttons={true}
                    buttons={<ButtonANTD
                        loading={this.state.isLoading}
                        disabled={this.state.isLoading}
                        className="mr-2 ButtonsModal mt-2"
                        onClick={this.UpdateLanguageAndTimeZone}>
                        {this.props.t('Save')}
                    </ButtonANTD>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <div>
                                <div className=" mb-3">
                                    <Skeleton avatar loading={this.state.GetBUDataLoading} active paragraph={{ rows: 4 }} >
                                        <div className="d-flex">
                                            <div className="p-2">
                                                <span className="d-sm-inline-block hoverIcon">
                                                    <span className=" hoverIcon grayColor  ">
                                                        <Avatar className="avatar avatarUser ml-4 mb-2">{this.state.GetGUDataResult ? `${this.state.GetGUDataResult.FirstName ? this.state.GetGUDataResult.FirstName.charAt(0) : ""}${this.state.GetGUDataResult.LastName ? this.state.GetGUDataResult.LastName.charAt(0) : ""}` : ""} </Avatar>
                                                    </span>
                                                </span>

                                            </div>
                                            <div className="p-2">
                                                <div className=" mt-2" >
                                                    <h4 className="CardTitleProfile d-block " > <span className=" t-bold font-24">{this.props.t('Welcome')}  {`${this.state.GetGUDataResult.FirstName}`} </span>  </h4>
                                                    <Label className="LabelCreate2 d-block"> {this.props.t('YourAccountIs')} {this.state.GetGUDataResult.UserStatus ? this.props.t(this.state.GetGUDataResult.UserStatus) : ' - '}   </Label>

                                                </div>
                                            </div>
                                            <div className="ml-auto">
                                                <UncontrolledDropdown>
                                                    <span className="d-sm-inline-block hoverIcon">

                                                        <DropdownToggle nav caret className="mt2 pl-0">
                                                            <FontAwesomeIcon icon={faEllipsisV} className="mr-2 AddTaskPlus cursor-pointer" height={20} width={20} />
                                                        </DropdownToggle>

                                                    </span>

                                                    <DropdownMenu right className="ProfileDropdwon UserDropdown">
                                                        <DropdownItem className="DropDownFix hoverIcon ProfileDrop cursor-pointer text-center" onClick={this.OpenPasswordModal}>
                                                            <Row md={12}>
                                                                <Label className="textWhite text-center"> {this.props.t('ManagePassword')} </Label>
                                                            </Row>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                        <Divider className=" mt-3 mb-3" />
                                        <Row>
                                            <Col md={12}>
                                                <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ChooseTimeZone')}</Label>
                                                <Select
                                                    className="react-select-container noborderApp"
                                                    classNamePrefix="react-select cursor-pointer noborderApp  "
                                                    options={this.state.TimeZonesOptions}
                                                    isSearchable={true}
                                                    value={this.state.TimeZoneSelectedValue}
                                                    onChange={this.TimeZoneOnChange}
                                                    placeholder={this.props.t('ChooseTimeZone')}
                                                />
                                            </Col>
                                            <Col md={12} className="mt-4 mb-3">
                                                <Label className="text-darkBlue t-bold font-16 ml-3"> {this.props.t('ChooseALanguage')}</Label>
                                                <Select
                                                    className="react-select-container noborderApp"
                                                    classNamePrefix="react-select cursor-pointer noborderApp  "
                                                    options={this.state.languageSelectOptions}
                                                    isSearchable={true}
                                                    value={this.state.languageSelectedValue}
                                                    onChange={this.languageOnChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Skeleton>
                                    <Divider className=" mt-3 mb-3" />
                                    {isClientCRM && <>
                                        <h5 className=" LabelCreate2 "> {this.props.t('Platforms')} </h5>
                                        <Skeleton loading={this.state.LoadPlatformLinksLoading} active paragraph={{ rows: 3 }} >
                                            {this.RenderPlatformLinks()}
                                        </Skeleton>
                                    </>}

                                </div>

                            </div>

                        </div>
                    }
                />


                <ProfileModal
                    small={true}
                    title={this.props.t('ChangePassword')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openPassword}
                    handleClose={this.closePasswordModal}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateClientPassword}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>

                                    <Radio.Group onChange={this.OnChangRadioPassword} name="passwordValue" defaultValue={1}>
                                        <Radio value={1} className="d-block" > <span className="LabelCreate2 mt-2"> {this.props.t('AutoGeneratePassword')} </span></Radio>

                                        <Radio value={2} className="mt-3"><span className="LabelCreate2">{this.props.t('CreateYourOwnPassword')}</span></Radio>
                                    </Radio.Group>
                                    {this.state.passwordValue === 2 &&
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Item
                                                        label={this.props.t('NewPassword')}
                                                        hasFeedback={false}
                                                        validateStatus="error"
                                                        help={CheckPasswordStrength(this.state.newPassword) >= 4 ? "" : this.props.t('WeakPassword')}
                                                    >
                                                        <AntInput.Password
                                                            autoComplete="new-password"
                                                            type="password"
                                                            name="newPassword"
                                                            id="Password"
                                                            value={this.state.newPassword}
                                                            onChange={this.inputOnchange}
                                                        />
                                                    </Form.Item>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('Credentials')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openAutoGenerate}
                    handleClose={this.closeAutoGenerate}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.state.SendByEmail ? this.SendPasswordByEmail : this.closeAutoGenerate}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <div>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="username" className="LabelCreate2 d-contents font-14 d-block t-bold" >{this.props.t('Username')} </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="password" className="LabelCreate2 d-contents font-14 d-block " >{this.props.t('Password')} </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <div className="input-group ">
                                                <div className="input-group-prepend attachClip">
                                                    <span className="input-group-text cursor-pointer" onClick={this.copyUsername}> <FontAwesomeIcon className="AddTaskPlus " icon={faCopy} /> </span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.props.ClientData.Email}
                                                    name="copyUsername" id="copyUsername" className="copyUsername"
                                                    onClick={this.copyUsername} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <div className="input-group ">
                                                <div className="input-group-prepend attachClip">
                                                    <span className="input-group-text cursor-pointer" onClick={this.copyPassword}> <FontAwesomeIcon className="AddTaskPlus " icon={faCopy} /> </span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.state.newPassword}
                                                    name="copyPassword" id="copyPassword" className="copyPassword"
                                                    onClick={this.copyPassword} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Divider className="mt-3 mb-3" />
                                <Row>
                                    <Col md={12}>
                                        <Checkbox
                                            className="d-block mb-3 "
                                            value={this.state.SendByEmail}
                                            onChange={this.onCheckBoxChange}
                                            name="SendByEmail">
                                            <span className="LabelCreate2"> {this.props.t('SendPasswordByEmail')}</span>
                                        </Checkbox>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <BasicValidation
                                                title={this.props.t('EmailTheNewPasswordToTheFollowing')}
                                                validate={!this.state.SendByEmail || validateEmail(this.state.EmailtoSendPassword)}
                                            />
                                            <Input
                                                type="email"
                                                name="EmailtoSendPassword"
                                                bsSize="lg"
                                                value={this.state.EmailtoSendPassword}
                                                placeholder={this.props.t('Email')}
                                                onChange={this.inputOnchange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    }
                />
                <ProfileModal
                    small={true}
                    title={this.props.t('ChangePreferableLangauge')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openLangauge}
                    handleClose={this.closeLanguageModal}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateUserLanguage}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>
                                    <label className="LabelCreate2"> {this.props.t('ChangePreferableLangauge')} <b className="iconNes"></b></label>
                                    <Select
                                        className="react-select-container LargeSelect"
                                        classNamePrefix="react-select cursor-pointer LargeSelect "
                                        options={this.state.languageSelectOptions}
                                        isSearchable={true}
                                        value={this.state.languageSelectedValue}
                                        onChange={this.languageOnChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />

                <ProfileModal
                    small={true}
                    title={this.props.t('ChangeTimeZone')}
                    theme={this.props.theme.currentTheme}
                    open={this.state.openTimeZone}
                    handleClose={this.closeTimeZone}
                    backBtn={false}
                    nobuttons={true}
                    buttons={<Button className="mr-2 ButtonsModal mt-2" onClick={this.UpdateUserTimeZone}> {this.props.t('Save')}  </Button>}
                    body={
                        <div className="horzOverFlowYXHidden m10" >
                            <Row>
                                <Col md={12}>
                                    <label className="LabelCreate2"> {this.props.t('ChooseTimeZone')} <b className="iconNes">*</b></label>
                                    <Select
                                        className="react-select-container LargeSelect"
                                        classNamePrefix="react-select cursor-pointer LargeSelect "
                                        options={this.state.TimeZonesOptions}
                                        isSearchable={true}
                                        value={this.state.TimeZoneSelectedValue}
                                        onChange={this.TimeZoneOnChange}
                                        placeholder={this.props.t('ChooseTimeZone')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </div>
        );
    }

    RenderPlatformLinks = () => {
        return <Row>
            <Col md={12} className="mt-4 mb-3">
                <Label className="text-darkBlue t-bold font-16 ml-3">
                    {this.props.t('Server')}</Label>
                <Select
                    className="react-select-container noborderApp"
                    classNamePrefix="react-select cursor-pointer noborderApp  "
                    options={this.state.serverSelectOptions}
                    isSearchable={true}
                    value={this.state.serverSelectedValue}
                    onChange={this.serverSelectOnChange}
                />
            </Col>
            <Col md={12} >
                <div className="input-group">

                    {!this.state.platformList || this.state.platformList.length === 0 &&
                        <a className="list-group-item noPlatformsLinks">
                            <span className="float-right text-muted-color">
                            </span>
                            {this.props.t('None')}
                        </a>}
                    {this.state.platformList && this.state.platformList.length !== 0 &&
                        this.state.platformList
                            .filter((item: LoadPlatformLinksResult) => item.ServerID === this.state.serverSelectedValue?.value)
                            .map((item: LoadPlatformLinksResult) => {
                                return (
                                    <Col md={12} className="mt-2 mb-2">
                                        <a className="list-group-item platformsLinks"
                                            target="_blank"
                                            href={item.PLLink}>
                                            <span className="float-right text-muted-color">
                                                {this.props.t('Download')}
                                            </span>
                                            {item.PLDesc}
                                        </a>
                                    </Col>
                                );
                            })}
                </div>
            </Col>
        </Row>;
    }
}

export default withTranslation()(connect(mapStateToProps, dispatchProps)(MyProfile) as any) as any;