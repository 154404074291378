import axios from 'axios';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Label } from "reactstrap";
import { getImagesURL_LDN } from '../../Helpers/DataHelpers';
import { IUserData } from '../../redux/constants';
import store from '../../redux/store';


interface ErrorBoundaryState {
    hasError: boolean;
}
interface ErrorBoundaryProps {
    children?: any;
    t: any;
}


class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error: any) {
        console.error(error);
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);Apllication Insight //Tracktrace
        console.error(error, errorInfo);
        this.LogErrorToAI(`URL: ${this.GetURL()}, ${error || "error"}: ${errorInfo ? JSON.stringify(errorInfo) : ""} `);
    }
    GetURL = () => {
        try {
            return window?.location?.href || "";
        } catch (e) {
            return "";
        }
    };
    GetAuthData = () => {
        try {
            let UserData: IUserData = store?.getState()?.auth?.UserData;
            return `UserName: ${UserData.UserName || ""}`;//send only needed stuff
        } catch (e) {
            return "";
        }
    };
    LogErrorToAI = (Message: string) => {
        axios.post<boolean>("api/auth/LogErrorToAI", { Message })
            .then().catch((e: any) => { console.error('SendMessage', e); });
    }
    render() {
        if (this.state.hasError) {
            return <div className=" mainExternal signin heightSighnIn extraHeight">
                <div className="container">
                    <div className="row py-5 align-items-center">
                        <Col sm="10" md="10" lg="10" className="mx-auto d-table h-100 containerMsg">
                            <div className=" text-center p10" >
                                <img src={`${getImagesURL_LDN(
                                    store?.getState()?.CompanyData?.BannerCDNURL,
                                    store?.getState()?.auth?.CompanyData?.CompanyName,
                                    store?.getState()?.CompanyData?.CDNURL)}oopsPic.png`} alt="" className="mt-3" />
                                <div className="d-block mt-3 text-center m10 align-self-center justify-content-center">
                                    <Label className="text-Grey font-32 t-bold d-block mb-2"> {this.props.t('Oopssomethingwentwrong')} </Label>
                                    <Label className="text-Grey font-24 d-block m-0"> {this.props.t("Reloadandiftheissuepersistspleasecontactus")}</Label>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>;
        }

        return this.props.children;
    }
}
export default withTranslation()(ErrorBoundary as any);
