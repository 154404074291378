import * as types from "../constants";
const initialState: types.LoginAction = {
    isAuth: false,
    isPrimarySalt: true,
    isTokenExpired: false,
    isTokenValid: true,
    GUARules: [
        { checked: "checked", aRuleName: 'ELS', aRuleID: 1, aRuleOrder: 1 },
        { checked: "checked", aRuleName: 'ECS', aRuleID: 2, aRuleOrder: 2 },
        { checked: "checked", aRuleName: 'BLS', aRuleID: 15, aRuleOrder: 3 },
        { checked: "checked", aRuleName: 'EV', aRuleID: 3, aRuleOrder: 4 },
        { checked: "checked", aRuleName: 'Percentage', aRuleID: 13, aRuleOrder: 5 },
        { checked: "checked", aRuleName: 'Multiplier', aRuleID: 14, aRuleOrder: 6 },
        { checked: "checked", aRuleName: 'Fixed Lot', aRuleID: 16, aRuleOrder: 7 },
        { checked: "checked", aRuleName: '% of Equity', aRuleID: 17, aRuleOrder: 8 }],
    Servers: [],
    CompanyData: {
        CompanyID: "",
        CompanyName: "",
        CompanyTitle: "",
        SignalRHubURL: "",
        IsIB: false,
        IsMM: false,
        IsBonus: false,
        IsCRM: false,
        IsDynamicMargin: false,
    },
    UserData: {
        id: "",
        Token: "",
        RefreshToken: "",
        isAdmin: false,
        isFirstUser: false,
        yoonitGetURL: "",
        yoonitActionURL: "",
        UserName: "",
        Language: "en",
        TimeZone: 0,
    },
    ClientData:
    {
        BUID: "",
        ClientID: "",
        ContactID: "",
        ClientRangeID: "",
        ContactStatus: "",
        SalutationID: -1,
        SalutationTxt: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        CountryID: -1,
        CountryName: "",
        CountryCode: "",
        DOB: "",
        ProfileImage: "",
        IsActive: false,
        RequireKYC: false,
        KYCStatus: "",
        KYCReason: "",
        hasKYC: false,
        NmbrOfAcc: 0,
        CardValidation: false,
        IsValid: false,
        KTID: false,
        AllowAddDemoAcc: false,
        IsClientSegment: false,
        RiskStatementReminder: "",
        PoolID: 0,
        ZipCode: "",
        ResidentialAddress: "",
        City: "",
        NationalIDCardNumber: "",
    },
    AdminData: {
        AUID: "",
        ContactID: "",
        ContactStatus: "",
        SalutationID: -1,
        SalutationTxt: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        CountryID: -1,
        CountryName: "",
        DOB: "",
        ProfileImage: "",
        IsActive: false
    },
    UserPermissions: [],
    CompanyAuth: true //so if not set then true
};

export default function reducer(state = initialState, actions: any): types.LoginAction {
    switch (actions.type) {
        case types.AUTH_LOGIN: {
            return {
                ...state,
                isAuth: actions.payload.isAuth,
                isPrimarySalt: actions.payload.isPrimarySalt,
                isTokenExpired: actions.payload.isTokenExpired,
                isTokenValid: actions.payload.isTokenValid,
                //GUARules: actions.payload.guaRules,
                CompanyData: actions.payload.CompanyData,
                //GlobalUser: actions.payload.globalUser,              
                UserData: actions.payload.UserData,
                ClientData: actions.payload.ClientData,
                AdminData: actions.payload.AdminData,
                Servers: actions.payload.Servers,
                UserPermissions: actions.payload.UserPermissions,
            };
        }

        case types.REFRESH_TOKENS:
            {
                return {
                    ...state,
                    UserData: {
                        ...state.UserData,
                        Token: actions.payload.token,
                        RefreshToken: actions.payload.refreshToken
                    }
                };
            }

        case types.UPDATE_TIMEZONE:
            {
                return {
                    ...state,
                    UserData: {
                        ...state.UserData,
                        TimeZone: actions.payload,
                    }
                };
            }
        case types.UPDATE_ISVALID:
            {
                return {
                    ...state,
                    ClientData: {
                        ...state.ClientData,
                        IsValid: actions.payload,
                    }
                };
            }
        case types.UPDATE_NUMBER_OF_ACCOUNTS:
            {
                return {
                    ...state,
                    ClientData: {
                        ...state.ClientData,
                        NmbrOfAcc: +(state.ClientData.NmbrOfAcc || 0) + 1,
                    }
                };
            }
        case types.AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
